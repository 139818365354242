import React, { memo, useContext } from "react";
import { useSelector } from "react-redux";
import {
  ImageLink,
  PrintIframeRenderer,
  PrintIframeAction,
} from "components/elements";
import I18NContext from "library/contexts/i18N";
import { selectIsPageLoading } from "library/sagas/views/home/drawer/reports/common/selector";
import { getScorecardPrintHTML } from "./helper";
import { COMMON } from "library/constants";

const preferredOrientation = COMMON.LANDSCAPE;

const PrintScorecard = ({ shopCode, statementDate, UIConfig }) => {
  const { messages, Localise } = useContext(I18NContext);
  const loading = useSelector(selectIsPageLoading);

  const iframeId = `iframeScoreCard_${shopCode}`;

  const handleScorecardPrint = () => {
    PrintIframeAction(
      getScorecardPrintHTML({
        iframeId,
        shopCode,
        statementDate,
        UIConfig,
        Localise,
        messages,
        preferredOrientation,
      }),
      { orientation: preferredOrientation },
      () => {},
      iframeId
    );
  };

  return (
    <>
      {!loading ? (
        <ImageLink
          {...{
            imageName: "print",
            tooltipMsg: `Print Scorecard`,
            onPress: handleScorecardPrint,
            testID: `scorecard_print`,
            accessibilityLabel: `scorecard_print`,
            customImageStyle: { width: 30, height: 30 },
          }}
        />
      ) : null}
      <PrintIframeRenderer id={iframeId} />
    </>
  );
};

export default memo(PrintScorecard);
