import React from "react";
import { Platform } from "react-native";
import { useSelector } from "react-redux";
import { useRoute } from "@react-navigation/native";

import {
  selectShopCode,
  selectShopPreferences,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";

import { AccordionWithBorder } from "components/elements";

import Environment from "library/utils/environment";

import PaymentTypes from "./payment-types";
import HouseAccountSettings from "./house-accounts";
import TerminalSettings from "./terminal-settings";
import QuickbooksIntegration from "./quick-books";
import { Entitlements } from "library/utils/entitlements";
import get from "lodash/get";
import AppSettingsContext from "library/contexts/appSettings";
import { hasQuickBooksAccess } from "library/utils/featureAvailability";

const Payments = ({ loadingKey, saveSettings }) => {
  const { params: { openQuickBooksIntegration = false } = {} } = useRoute();

  const { permissions = {} } = React.useContext(AppSettingsContext);
  const shopCode = useSelector(selectShopCode);
  const shopSettings = useSelector(selectShopPreferences);

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const paymentTerminalEnabledForMembercode =
    shopSettings["payment_terminal"] === "true";

  const enablePaymentTerminal = Environment.get(
    "ENABLE_PAYMENT_TERMINAL",
    ""
  ).split(",");

  const hasAccessToPaymentTerminal =
    enablePaymentTerminal.includes("ALL") ||
    enablePaymentTerminal.includes(shopCode);

  const isQuickBooksEnabled = hasQuickBooksAccess(permissions, shopCode);
  const selectedShopPermissions = get(permissions, shopCode, {});
  const isHouseAccountsLateFeeElibileShop = selectedShopPermissions[
    Entitlements.HOUSE_ACCOUNT
  ]?.includes(Entitlements.HOUSE_ACCOUNT_PERMISSIONS.LATE_FEE);

  return (
    <>
      <AccordionWithBorder title={"Payment Types"}>
        <PaymentTypes
          shopSettings={shopSettings}
          loadingKey={loadingKey}
          saveSettings={saveSettings}
        />
      </AccordionWithBorder>

      {isHouseAccountsLateFeeElibileShop && (
        <AccordionWithBorder title={"House Accounts"}>
          <HouseAccountSettings
            shopSettings={shopSettings}
            loadingKey={loadingKey}
            saveSettings={saveSettings}
          />
        </AccordionWithBorder>
      )}

      {isQuickBooksEnabled && Platform.OS === "web" && !isElectronApp && (
        <AccordionWithBorder
          title={"QuickBooks Integration"}
          imagePath={"beta-label"}
          defaultOpen={openQuickBooksIntegration}
        >
          <QuickbooksIntegration shopCode={shopCode} />
        </AccordionWithBorder>
      )}

      {isElectronApp &&
        paymentTerminalEnabledForMembercode &&
        hasAccessToPaymentTerminal && (
          <AccordionWithBorder title={"Terminal Settings"} defaultOpen={true}>
            <TerminalSettings
              shopSettings={shopSettings}
              saveSettings={saveSettings}
            />
          </AccordionWithBorder>
        )}
    </>
  );
};

export default Payments;
