import { takeLatest, call, put, select } from "redux-saga/effects";
import {
  fetchScorecardRating,
  fetchScorecardComments,
  setScorecardRating,
  setScorecardComments,
} from "./slice";
import { selectScorecardDate } from "./selector";
import { selectShopCode } from "../common/selector";
import { setStatus, setToast } from "../common/slice";
import { request } from "library/utils/request";
import moment from "moment";

const scorecardRatingsRequest = (params) =>
  request("scorecard-ratings", params);
const scorecardCommentsRequest = (params) =>
  request("scorecard-comments", params);

const processScorecardComments = (scoreCardComments) => {
  const processedComments =
    scoreCardComments?.map((each) => ({
      ...each,
      orderDate: moment(each.orderDateTime).format("MM/DD/YYYY"),
      messageDate: moment(each.messageDateTime).format("MM/DD/YYYY"),
    })) || [];
  const rejectComments =
    processedComments.filter(
      (comment) => comment.messageType === "REJ" && !comment.penaltyCode?.trim()
    ) || [];
  const cancelComments =
    processedComments.filter(
      (comment) => comment.messageType === "CAN" && !comment.penaltyCode?.trim()
    ) || [];
  const drpComments =
    processedComments.filter(
      (comment) =>
        ["CAN", "REJ"].includes(comment?.messageType) &&
        !!comment.penaltyCode?.trim()
    ) || [];

  return {
    rejectComments: rejectComments?.length > 0 ? rejectComments : [{}],
    cancelComments: cancelComments?.length > 0 ? cancelComments : [{}],
    drpComments: drpComments?.length > 0 ? drpComments : [{}],
  };
};

const processScorecardRating = (scorecardRating, shopCode) => {
  return scorecardRating?.length > 0
    ? scorecardRating
    : [{ memberCode: shopCode }];
};

function* getScoreCardRatings() {
  try {
    yield put(setStatus({ ratingLoading: true }));
    const shopCode = yield select(selectShopCode);
    const statementDate = yield select(selectScorecardDate);

    const scoreCardRatings = yield call(scorecardRatingsRequest, {
      shopCode,
      statementDate,
    });

    yield put(
      setScorecardRating(processScorecardRating(scoreCardRatings, shopCode))
    );
    yield put(setStatus({ ratingLoading: false }));
  } catch (error) {
    const shopCode = yield select(selectShopCode);
    yield put(setScorecardRating(processScorecardRating([], shopCode)));
    yield put(setStatus({ ratingLoading: false }));
    yield put(
      setToast({ message: "Failed to load scorecard rating", type: "error" })
    );
    console.error("API call failed:", error);
  }
}

function* getScoreCardComments() {
  try {
    yield put(setStatus({ commentsLoading: true }));
    const shopCode = yield select(selectShopCode);
    const statementDate = yield select(selectScorecardDate);

    const scoreCardComments = yield call(scorecardCommentsRequest, {
      shopCode,
      statementDate,
    });

    const processedComments = processScorecardComments(scoreCardComments);

    yield put(setScorecardComments(processedComments));
    yield put(setStatus({ commentsLoading: false }));
  } catch (error) {
    yield put(setScorecardComments(processScorecardComments([])));
    yield put(setStatus({ commentsLoading: false }));
    yield put(
      setToast({ message: "Failed to load scorecard comments", type: "error" })
    );
    console.error("API call failed:", error);
  }
}

export function* watchSaga() {
  yield takeLatest(fetchScorecardRating.type, getScoreCardRatings);
  yield takeLatest(fetchScorecardComments.type, getScoreCardComments);
}

export default watchSaga;
