import { Platform } from "react-native";
import {
  phoneNumberFormatter,
  formatCardMessage,
  concatenateStrings,
} from "library/utils/formatter";
import UserProfileStorage from "library/storage/userProfile";
import get from "lodash/get";
import moment from "moment";
import { qrPlaceHolder } from "components/views/drawer/order-details/helper.js";
import { extractTimeFromDI } from "components/views/drawer/create-order/helper";

const getCardCSS = ({ bodyHeight }) => {
  return `@page {
        size: auto;
        margin: 0px;
        margin-bottom:30mm;
       /* this affects the margin in the printer settings */
   }
    .rdw-left-aligned-block {
        text-align: left !important;
   }
    .rdw-right-aligned-block {
        text-align: right !important;
   }
    .rdw-center-aligned-block {
        text-align: center !important;
   }
    .rdw-justify-aligned-block {
        text-align: justify !important;
   }
    .printCardContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
   }
    .content {
        padding: 5px;
        display: flex;
        flex-direction: column;
        width: 390px;
        height: 300px;
        align-items: center;
        font-size: 16px;
   }
    .message {
        width: 100%;
   }
    .address {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        padding: 5px;
        min-height: 120px;
   }
    displayNone{
        display: none;
   }
    .heading3{
        font-size: 20px !important;
        font-weight: bold;
   }
    .mb-2{
        margin-bottom: 4px;
   }
    .left{
        display:flex;
        flex-direction: row;
        width: 100%;
   }
    .right{
        display:flex;
        flex-direction: row-reverse;
        width: 100%;
   }
    .center{
        text-align: center;
        align-items: center;
   }
    .recipName{
        word-break: break-all;
   }
    .cardMessage{
        text-align:center;
        word-break:break-word;
        white-space: pre-wrap;
   }
    .editorContent > p, ol, ul, pre, blockquote, h1, h2, h3, h4, h5, h6 {
            margin: 0px;
            padding: 0px;
   }
    .ql-font-arial {
        font-family: "Arial";
   }
    .ql-font-georgia {
        font-family: "Georgia";
   }
    .ql-font-verdana {
        font-family: "Verdana";
   }
    .ql-font-impact {
        font-family: "Impact";
   }
    .ql-font-tahoma {
        font-family: "Tahoma";
   }
    .ql-font-times {
        font-family: "Times New Roman", Times, serif;
   }
    .ql-align-left {
        text-align: left;
   }
    .ql-align-right {
        text-align: right;
   }
    .ql-align-center {
        text-align: center;
   }
    .ql-align-justify {
        text-align: justify;
   }
    .deliveryContainer {
      width: 95%;
      display: flex;
      flex-direction: column;
      min-height: 120px;
      justify-content: center;
    }
    .deliveryInfo {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 14px;
      width: 100%;
    }
    .deliveryInfo .block {
      width: 50%;
    }
    .qrPlaceHolder {
      display: flex;
      align-items: flex-start;
    }
    @media print {
        html, body {
            width: 100%;
            height: ${bodyHeight};
            -ms-overflow-style: none;
            -webkit-print-color-adjust: exact;
       }
        .page-break-flow-root {
            display: flow-root;
            page-break-after: always;
       }
   }`;
};
// manual print. numberOfPrints will return number of copies. since html body adds 8px margin for first page, adding 8px space for second.
export const getSingleCardPrintHTML = ({
  recipientInfo,
  receivingMember,
  cardSettings,
  cardMessage,
  fileInfo,
  isPickUpOrder,
  orderSource = "",
  isAdhocTriFold = false,
  pickUpBy = "",
  numberOfPrints = 1,
  qrImageData = "",
  zoneDetails = [],
  orderDetails = {},
  isSinglePrint = true,
}) => {
  const {
    addressLine1,
    addressLine2 = "",
    city,
    firstName = "",
    lastName = "",
    phone: recPhone = "",
    state,
    zip,
    locationName = "",
  } = recipientInfo || {};

  const {
    erosOrderNumber,
    deliveryInfo: {
      deliveryDate,
      orderDeliveryTime = "",
      deliveryInstructions = "",
      pickUpDateTime = "",
      deliveryZoneName = "",
    } = {},
    pickupInfo,
    isRushOrder = false,
  } = orderDetails;

  const { storePickupDateTime = "" } = pickupInfo || {};

  const recipientFirstLine = addressLine2
    ? `${addressLine1}, ${addressLine2}`
    : addressLine1;

  const recName = firstName + " " + lastName;

  let {
    businessName,
    websites = [],
    email,
    phone: floristPhone = "",
    addressLine1: floristAddressLine1 = "",
    city: floristCity = "",
    state: floristState = "",
    zip: floristZip = "",
  } = receivingMember;

  let floristShopContactSettings = "";

  const shopPreferences = UserProfileStorage.getShopPreferences(
    receivingMember.memberCode
  );

  let isShopNameOverridden = false;
  let isStreetAddressOverridden = false;
  let isPhoneOverridden = false;
  let isEmailOverridden = false;
  let isWebsiteOverridden = false;

  floristShopContactSettings = get(
    shopPreferences,
    "florist_contact_information_settings",
    ""
  );

  if (floristShopContactSettings == "JDE" || floristShopContactSettings == "") {
    floristShopContactSettings =
      '{"shopName":"","streetAddress":"","aptSuite":"","city":"","state":"","zip":"","phone":"","email":"","website":"","additionalMessage":"","contactName":"","printed":[],"web":[],"network":[]}';
  }

  let {
    shopName = "",
    streetAddress = "",
    aptSuite = "",
    city: overriddenCity = "",
    state: overriddenState = "",
    zip: overriddenZip = "",
    phone: overriddenPhone = "",
    email: overriddenEmail = "",
    website = "",
    printed = [],
  } = JSON.parse(floristShopContactSettings);

  if (printed.includes("shopName") && shopName != "") {
    isShopNameOverridden = true;
  }
  if (printed.includes("streetAddress") && streetAddress != "") {
    isStreetAddressOverridden = true;
  }
  if (printed.includes("phone") && overriddenPhone != "") {
    isPhoneOverridden = true;
  }
  if (printed.includes("email") && overriddenEmail != "") {
    isEmailOverridden = true;
  }
  if (printed.includes("website") && website != "") {
    isWebsiteOverridden = true;
  }

  businessName = isShopNameOverridden ? shopName : businessName;
  floristPhone = isPhoneOverridden ? overriddenPhone : floristPhone;
  floristAddressLine1 = isStreetAddressOverridden
    ? streetAddress + " " + aptSuite
    : floristAddressLine1;
  floristCity = isStreetAddressOverridden ? overriddenCity : floristCity;
  floristState = isStreetAddressOverridden ? overriddenState : floristState;
  floristZip = isStreetAddressOverridden ? overriddenZip : floristZip;
  email = isEmailOverridden ? overriddenEmail : email;

  let {
    storeMessage,
    floristContactInfo,
    orderInfo = [],
    contactInfoTopHalf = "Florist Info",
    contactInfoBottomHalf = "Recipient Info",
    floristWebsite = "",
    isLogoOpted,
    logoOrientation,
    isBannerOpted,
    bannerOrientation,
  } = cardSettings;

  floristWebsite = isWebsiteOverridden ? website : floristWebsite;

  const isFloristInfoTop = contactInfoTopHalf === "Florist Info";

  // Order For this option is Recipient Info top & Delivery Info bottom
  const hasDeliveryInfo = contactInfoBottomHalf === "Delivery Info";

  let isTop = true;
  let isLeft = true;
  let width = 0;
  let height = 0;

  if (isLogoOpted) {
    const positions = logoOrientation.split(" ");
    isTop = positions[0] !== "Bottom";
    isLeft = positions[1] !== "Right";
    width = 130;
    height = 130;
  } else if (isBannerOpted) {
    isTop = bannerOrientation === "Top";
    width = 380;
    height = 120;
  }

  const pickupBy = isPickUpOrder
    ? orderSource === "UberEats"
      ? "Uber Eats"
      : orderSource === "DoorDash"
      ? "DoorDash"
      : orderSource === "MOL"
      ? "MOL"
      : pickUpBy
    : "";
  const floristContactData = floristContactInfo || [];
  const ua = (navigator && navigator.userAgent) || "";
  const isAndroidBrowser = ua.includes("Android");
  const bodyHeight =
    Platform.OS === "android" ? `99%` : isAndroidBrowser ? `auto` : `auto`;
  const defaultStoreMsg =
    "This Handcrafted Bouquet is Designed Especially for you.";

  const formattedDeliveryDate = moment(deliveryDate).format("MM/DD/YYYY");

  const deliverByTime =
    orderDeliveryTime ||
    (isRushOrder && extractTimeFromDI(deliveryInstructions)); //For old rush orders before timed delivery feature

  const dateTime = storePickupDateTime || pickUpDateTime;
  const formattedDeliveryTime =
    deliverByTime || (dateTime ? moment(dateTime).format("h:mm A") : "");

  const deliveryZone = zoneDetails[0]?.name || deliveryZoneName || "";

  const printCardContent = `<div class="page-break-flow-root">
       <div class="printCardContainer">
      <div class="content" style="justify-content:${
        !!fileInfo.uri && isTop ? "flex-start" : "center"
      }; overflow:hidden;">
            <div class="message">
            ${
              !isTop
                ? `<div  class="cardMessage editorContent"
            data-testid="sender_recipient_message"
           > ${formatCardMessage(cardMessage)}</div>`
                : ``
            }
               ${
                 fileInfo.uri
                   ? `<div class="${isLeft ? "left" : "right"}" >    
                        <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="${width}px"
                        height="${height}px"
                        viewBox="0 0 ${width} ${height}"
                        enable-background="new 0 0 ${width} ${height}"
                        xml:space="preserve"
                        >               
                           <image
                              id="image0"
                              width="${width}"
                              height="${height}"
                              x="0"
                              y="0"
                              href="${fileInfo.uri}"
                           />
                        </svg>
                     </div>`
                   : ``
               }
               ${
                 isTop
                   ? `<div class="cardMessage editorContent" style=padding:5px;text-align:center; data-testid="sender_recipient_message"> ${formatCardMessage(
                       cardMessage
                     )}</div>`
                   : ``
               }               
               
            </div>
         </div>
         <div class="content">
            <div style="font-size:16px;text-align:center;justify-content:center;">
                ${storeMessage ?? defaultStoreMsg}
            </div>
         </div>
         <div class=${isAdhocTriFold ? "content" : "content center"}>
         ${
           !isFloristInfoTop
             ? isAdhocTriFold
               ? `<div class="editorContent" style="width:100%;text-align:center;margin-bottom: 5px;" data-testid="recipient_address">${recipientInfo}</div>`
               : `<div class="address center editorContent">
              <div class="heading3 mb-2 center recipName">
                ${recName}
              </div>
              <div class="mb-2">${phoneNumberFormatter(recPhone)}</div>
              ${
                !isPickUpOrder
                  ? `${
                      recipientInfo.locationName
                        ? `<div class="mb-2" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${locationName}</div>`
                        : ``
                    } 
                  <div class="mb-2">${recipientFirstLine}</div>
               <div class="mb-2">${city}, ${state}, ${zip}</div>`
                  : `Store Pickup by ${pickupBy}`
              }
            </div>`
             : ``
         }
         ${
           !hasDeliveryInfo || isAdhocTriFold
             ? `<div class="address center">                  
               ${
                 floristContactData.includes("Florist Name")
                   ? `<div class="heading3 center mb-2" data-testid="shopName">${businessName}</div>`
                   : ``
               }
               ${
                 floristContactData.includes("Florist Website")
                   ? `<div class="mb-2" data-testid="shopWebsite">${
                       floristWebsite || websites[0] || ""
                     }</div>`
                   : ``
               }
               ${
                 floristContactData.includes("Florist Email") && email
                   ? `<div class="mb-2" data-testid="shopEmailId">${email}</div>`
                   : ``
               }
               ${
                 floristContactData.includes("Florist Phone")
                   ? `<div class="mb-2" data-testid="shopPhoneNumber">${phoneNumberFormatter(
                       floristPhone
                     )}</div>`
                   : ``
               }
               ${
                 floristContactData.includes("Florist Address")
                   ? `<div>
                           <div class="mb-2">${floristAddressLine1}</div>                  
                           <div>${floristCity}, ${floristState}, ${floristZip}</div>
                        </div>`
                   : ``
               }
                </div>`
             : `<div class="deliveryContainer">
                  <div class="deliveryInfo">
                  ${
                    orderInfo.includes("Delivery Date")
                      ? `<div class="block">
                        <div class="mb-2"><b>Delivery Date:</b> ${formattedDeliveryDate}</div></div>`
                      : ``
                  }
                  ${
                    orderInfo.includes("Delivery Zone")
                      ? `<div class="block">
                    <div class="mb-2"><b>Delivery Zone:</b> ${deliveryZone}</div></div>`
                      : ``
                  }
                  ${
                    orderInfo.includes("Delivery Time")
                      ? `<div class="block">
                            <div class="mb-2"><b>Delivery Time:</b> ${formattedDeliveryTime}</div></div>`
                      : ``
                  }
                  ${
                    orderInfo.includes("Order No")
                      ? `<div class="block">
                    <div class="mb-2"><b>Order No:</b> ${erosOrderNumber}</div></div>`
                      : ``
                  }
                  </div>
                  <div class="qrPlaceHolder">${qrPlaceHolder(qrImageData, {
                    width: 75,
                    height: 75,
                  })}</div>
                </div>`
         }
            ${
              isFloristInfoTop
                ? isAdhocTriFold
                  ? `<div class="editorContent" style="width:100%;text-align:center;margin-top: 5px;" data-testid="recipient_address">${recipientInfo}</div>`
                  : `<div class="address center editorContent">
                     <div class="heading3 mb-2 center recipName">
                       ${recName}
                     </div>
                     <div class="mb-2">${phoneNumberFormatter(recPhone)}</div>
                     ${
                       !isPickUpOrder
                         ? `${
                             recipientInfo.locationName
                               ? `<div class="mb-2" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${locationName}</div>`
                               : ``
                           } 
                         <div class="mb-2">${recipientFirstLine}</div>
                      <div class="mb-2">${city}, ${state}, ${zip}</div> 
                     `
                         : `Store Pickup by ${pickupBy}`
                     }
                   </div>`
                : ``
            }
         </div>  
      </div>
    </div>`;

  return `${
    isSinglePrint
      ? `<!DOCTYPE html>
          <html xmlns="http://www.w3.org/1999/xhtml">
          <head>
             <title>Mercury HQ</title>
          </head>
          <body onload="window.parent.postMessage({ action: 'iframe-loaded' }, '*');">
            <div style="display: none">
              <style type="text/css" media="print,screen">
                    ${getCardCSS({ bodyHeight })}          
              </style>
            </div>
            <div>`
      : ``
  }
              ${concatenateStrings(
                printCardContent,
                `<div style="margin-top: 8px">${printCardContent}</div>`,
                numberOfPrints
              )}
              ${
                isSinglePrint
                  ? `</div>
          </body>
        </html>
       `
                  : ``
              }`;
};

// auto print trigger
export const getMultiCardPrintHTML = (
  data,
  imagesData,
  iframeId,
  qrImagesData,
  additional_sympathy_card = ""
) => {
  const diplayPrintCardHtml = data
    .map((order) => {
      const {
        orderItemId = "",
        deliveryInfo: {
          deliveryMethod,
          cardMessage,
          pickUpBy = "",
          occasion = "",
        } = {},
        recipientInfo = {},
        receivingMember = {},
        orderSource = "",
      } = order.orderDetails?.orderItems[0] || {};
      const { cardInfo: { cardSettings: cardSettingsText = "{}" } = {} } =
        order.orderDetails;

      const cardSettings = JSON.parse(cardSettingsText);

      let { isLogoOpted, isBannerOpted, logoImageURL, bannerImageURL } =
        cardSettings;

      const isPickUpOrder =
        orderSource === "DoorDash" ||
        orderSource === "UberEats" ||
        (orderSource === "MOL" && deliveryMethod === "MOL_CUSTOMER_PICKUP") ||
        deliveryMethod === "STORE_PICKUP" ||
        deliveryMethod === "WALK_IN";

      const numberOfPrints =
        additional_sympathy_card === "true" && occasion === "FUNERAL" ? 2 : 1;

      const imageUrl = isLogoOpted
        ? logoImageURL
        : isBannerOpted
        ? bannerImageURL
        : "";

      const imageData = imagesData[imageUrl];
      const fileInfo = {
        uri: imageData ? `data:image/png;base64,${imageData}` : "",
        name: imageUrl,
      };
      const zoneDetails = [];
      const qrImageData = qrImagesData[orderItemId];

      const html = getSingleCardPrintHTML({
        recipientInfo,
        receivingMember,
        cardSettings,
        cardMessage,
        fileInfo,
        isPickUpOrder,
        orderSource,
        isAdhocTriFold: false,
        pickUpBy,
        numberOfPrints,
        qrImageData,
        zoneDetails,
        orderDetails: order.orderDetails?.orderItems[0],
        isSinglePrint: false,
      });
      return html;
    })
    .join("");

  const ua = (navigator && navigator.userAgent) || "";
  const isAndroidBrowser = ua.includes("Android");
  const bodyHeight =
    Platform.OS === "android" ? `99%` : isAndroidBrowser ? `auto` : `auto`;

  return `<!DOCTYPE html>
          <html xmlns="http://www.w3.org/1999/xhtml">
          <head>
             <title>Mercury HQ</title>
          </head>
          <body onload="window.parent.postMessage({ action: '${iframeId}-loaded' }, '*');">
             <div style="display: none">
                <style type="text/css" media="print,screen">
                   ${getCardCSS({ bodyHeight })}             
                </style>
             </div>
             ${diplayPrintCardHtml}
          </body>
          </html>
       `;
};
