import {
  acceptAction,
  acceptPrint,
  inDesign,
  designed,
  outForDelivery,
  confirmDelivery,
  attemptedDelivery,
  rejectAction,
  copyAction,
  readyForPickup,
  pickedUp,
} from "components/views/drawer/order-listing/actionsConfig";

export const UIConfig = [
  {
    key: "needsAction",
    title: "Needs Action",
    displayStatus: "open",
    countDownTimer: true,
  },
  { key: "todayOrders", title: "Today's Orders", displayStatus: "open" },
  { key: "tomorrowOrders", title: "Tomorrow's Orders", displayStatus: "open" },
  { key: "futureOrders", title: "Future Orders" },
  { key: "undeliveredOrders", title: "Undelivered Orders" },
  { key: "outgoingOrders", title: "Outgoing Orders" },
  { key: "completedOrders", title: "Delivered Orders" },
  { key: "cancelledOrders", title: "Canceled Orders" },
  { key: "rejectedOrders", title: "Rejected Orders" },
  { key: "forfeitedOrders", title: "Forfeited Orders" },
  {
    key: "unassignedOrders",
    title: "Unassigned Orders",
    displayStatus: "open",
  },
  { key: "assignedOrders", title: "Assigned Orders", displayStatus: "open" },
];

export const InitOrdersData = {
  needsAction: { orders: [] },
  todayOrders: { orders: [] },
  tomorrowOrders: { orders: [] },
  futureOrders: { orders: [] },
  undeliveredOrders: { orders: [] },
  outgoingOrders: { orders: [] },
  completedOrders: { orders: [] },
  cancelledOrders: { orders: [] },
  rejectedOrders: { orders: [] },
  forfeitedOrders: { orders: [] },
  assignedOrders: { orders: [] },
  unassignedOrders: { orders: [] },
};

export const InitViewMoreCounts = {
  needsAction: 10,
  todayOrders: 10,
  tomorrowOrders: 10,
  futureOrders: 10,
  undeliveredOrders: 10,
  outgoingOrders: 10,
  completedOrders: 10,
  cancelledOrders: 10,
  rejectedOrders: 10,
  forfeitedOrders: 10,
  assignedOrders: 10,
  unassignedOrders: 10,
};

export const ListOfColumns = [
  "displayDeliveryDate",
  "customerName",
  "recipientCity",
  "recipientName",
  "productFirstChoiceDescription",
  "displayPrice",
];

export const ListOfMobileColumns = [
  "displayPrice",
  "displayDeliveryDate",
  "deliveryInstructions",
  "customerName",
  "messageNumber",
  "recipientCity",
  "zip",
  "recipientName",
  "locationType",
  "productFirstChoiceDescription",
  "items",
];

export const ListOfSubColumns = {
  displayDeliveryDate: "deliveryDateSubColumn",
  customerName: "messageNumber",
  messageNumber: "customerOrderId",
  recipientCity: ["zip", "deliveryZoneName"],
  recipientName: "locationType",
  productFirstChoiceDescription: "items",
  displayPrice: "priceSubColumn",
};

export const ListOfOrderStatus = {
  set1: {
    start: "1w",
    initStatuses: [
      "NEW",
      "VIEWED",
      "ACKNOWLEDGED",
      "PRINTED",
      "DS_REQUESTED",
      "DS_REQUEST_PENDING",
      "ACKNOWLEDGE_PRINT",
      "ERROR",
      "DESIGNED",
      "DESIGN",
      "OUT_FOR_DELIVERY",
    ],
    status: [
      "NEW",
      "VIEWED",
      "ACKNOWLEDGED",
      "PENDING",
      "SENT",
      "FORWARDED",
      "PRINTED",
      "DS_REQUESTED",
      "DS_REQUEST_PENDING",
      "ACKNOWLEDGE_PRINT",
      "DESIGN",
      "DESIGNED",
      "OUT_FOR_DELIVERY",
      "REJECTED",
      "CANCELLED",
      "DELIVERED",
      "ERROR",
      "FORFEITED",
    ],
  },
};

export const ListOfActions = {
  incoming: [acceptAction, acceptPrint, rejectAction, copyAction],
  accepted: [
    inDesign,
    designed,
    outForDelivery,
    confirmDelivery,
    attemptedDelivery,
    rejectAction,
    copyAction,
  ],
  design: [
    designed,
    outForDelivery,
    confirmDelivery,
    attemptedDelivery,
    rejectAction,
    copyAction,
  ],
  designed: [
    outForDelivery,
    confirmDelivery,
    attemptedDelivery,
    rejectAction,
    copyAction,
  ],
  delivery: [confirmDelivery, attemptedDelivery, rejectAction, copyAction],
  completed: [rejectAction, copyAction],
  outgoing: [{ key: "cancel", label: "Cancel" }, copyAction],

  //For Pickup Orders
  "incoming-pickup": [
    acceptAction,
    acceptPrint,
    inDesign,
    readyForPickup,
    pickedUp,
    copyAction,
  ],
  "accepted-pickup": [inDesign, designed, readyForPickup, pickedUp, copyAction],
  "design-pickup": [designed, readyForPickup, pickedUp, copyAction],
  "designed-pickup": [readyForPickup, pickedUp, copyAction],
  "delivery-pickup": [pickedUp, copyAction],
  "completed-pickup": [copyAction],

  // errors
  dsfullfillmenterror: [rejectAction],
  "settlement-error": [],
};

export const ListOfSubActions = {
  "review-chats": [{ key: "markAsUnread", label: "Mark Unread" }],
  "view-chats": [{ key: "markAsRead", label: "Mark as Read" }],
};

export const ListOfFilters = {
  "Date Range": [
    {
      title: "Today",
      value: "0",
    },
    {
      title: "Tomorrow",
      value: "1",
    },
    {
      title: "This Week",
      value: "7",
    },
    {
      title: "This Month",
      value: "this-month",
    },
    { title: "Custom Range", value: "custom-date-range" },
  ],
  "Individual Filters": [
    {
      title: "Unprinted Orders",
      value: "unprintedOrders",
    },
    {
      title: "Flagged Orders",
      value: "flaggedOrders",
    },
  ],
  Categories: [
    {
      title: "Needs Action",
      value: "needsAction",
    },
    {
      title: "Today's Orders",
      value: "todayOrders",
    },
    {
      title: "Tomorrow's Orders",
      value: "tomorrowOrders",
    },
    {
      title: "Future Orders",
      value: "futureOrders",
    },
    {
      title: "Undelivered Orders",
      value: "undeliveredOrders",
    },
    {
      title: "Outgoing Orders",
      value: "outgoingOrders",
    },
    {
      title: "Delivered Orders",
      value: "completedOrders",
    },
    {
      title: "Canceled Orders",
      value: "cancelledOrders",
    },
    {
      title: "Rejected Orders",
      value: "rejectedOrders",
    },
    {
      title: "Forfeited Orders",
      value: "forfeitedOrders",
    },
    {
      title: "Inbound Orders",
      value: "INBOUND",
    },
    {
      title: "Outbound Orders",
      value: "OUTBOUND",
    },
    {
      title: "Rush Orders",
      value: "rush",
    },
    {
      title: "Timed Orders",
      value: "timedOrders",
    },
  ],
  "Order Status": [
    {
      title: "Assigned Orders",
      value: "assignedOrders",
    },
    {
      title: "Unassigned Orders",
      value: "unassignedOrders",
    },
    {
      title: "Approve Requests",
      value: "approve-request",
    },
    {
      title: "View Chats",
      value: "view-chats",
    },
    {
      title: "View Responses",
      value: "view-responses",
    },
    {
      title: "Incoming",
      value: "incoming",
    },
    {
      title: "Outgoing",
      value: "outgoing",
    },
    {
      title: "Accepted",
      value: "accepted",
    },
    {
      title: "In Design",
      value: "in-design",
    },
    {
      title: "Designed",
      value: "designed",
    },
    {
      title: "Delivery",
      value: "delivery",
    },
    {
      title: "Completed",
      value: "completed",
    },
    {
      title: "Active Orders",
      value: "active-orders",
    },
  ],
  "Order Source": [
    {
      title: "Local Orders",
      value: "Local",
    },
    {
      title: "Mercury Online",
      value: "MOL:FOL",
    },
    { title: "FTD", value: "FTD" },
    { title: "Teleflora", value: "Teleflora" },
    { title: "Bloomnet", value: "Bloomnet" },
    { title: "Email Order", value: "Email Order" },
    { title: "TEL eFlorist", value: "TEL eFlorist" },
  ],
};

export const ListOfSortOptions = [
  { key: "Price: Low to High", label: "Price: Low to High" },
  { key: "Price: High to Low", label: "Price: High to Low" },
  { key: "Order Date: New to Old", label: "Order Date: New to Old" },
  { key: "Order Date: Old to New", label: "Order Date: Old to New" },
  { key: "Order Status (A to Z)", label: "Order Status: A-Z" },
  { key: "Order Status (Z to A)", label: "Order Status: Z-A" },
  { key: "Delivery Date: New to Old", label: "Delivery Date: New to Old" },
  { key: "Delivery Date: Old to New", label: "Delivery Date: Old to New" },
  { key: "Delivery Zone Name (A to Z)", label: "Delivery Zone Name: A-Z" },
  { key: "Delivery Zone Name (Z to A)", label: "Delivery Zone Name: Z-A" },
  {
    key: "Default: Delivery Date + Rush/Timed",
    label: "Default: Delivery Date + Rush/Timed",
  },
];

export const OrderFetchInterval = 2 * 60 * 1000; // 2 mins
