import mercuryOnline from "./mercury-online";
import productCatalog from "./product-catalog";
import customerDirectory from "./customer-directory";
import giftCard from "./gift-cards";
import molRefunds from "./mol-refunds";
import ftdAdmin from "./ftd-admin";
import shopSettings from "./shop-settings";
import deliveryTasks from "./delivery-tasks";
import delivery from "./delivery";
import createOrder from "./create-order";
import events from "./events";
import help from "./help";
import qrScan from "./qr-scan";
import ordersNew from "./orders-new";
import notificationsCenter from "./notifications";
import reports from "./reports";

const sagas = [
  ...[
    mercuryOnline,
    customerDirectory,
    giftCard,
    molRefunds,
    deliveryTasks,
    delivery,
    createOrder,
    events,
    help,
    qrScan,
    ordersNew,
    notificationsCenter,
  ],
  ...shopSettings,
  ...productCatalog,
  ...ftdAdmin,
  ...reports,
];

export default sagas;
