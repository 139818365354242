import React, { useContext, useMemo } from "react";
import { View } from "react-native";
import tw from "tailwind-rn";
import get from "lodash/get";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import I18NContext from "library/contexts/i18N";
import { Form, FormField } from "components/elements/forms";
import { SaveCancelButtons } from "components/wrappers";
import { ToasterHandler } from "components/elements";
import { selectCanEditOrder } from "library/sagas/ongoing/order-details/selector";
import { DELIVER_BY_REGEX } from "components/views/drawer/create-order/helper";
import { formatDeliveryInstructions } from "./helper";

const UpsertDeliveryInstructions = ({
  index = 0,
  triggerAction,
  hasSettlementError,
}) => {
  const { messages, Localise } = useContext(I18NContext);
  const { values } = useFormikContext();
  const canEditOrder = useSelector(selectCanEditOrder);

  const deliveryInfoPath = `orderItems.${index}.deliveryInfo`;
  const { deliveryInstructions, isDSSubmitted } = get(
    values,
    deliveryInfoPath,
    {}
  );

  const isEditable = canEditOrder && !hasSettlementError && !isDSSubmitted;

  const initialInstructions = useMemo(() => {
    return deliveryInstructions?.replace(DELIVER_BY_REGEX, "");
  }, [deliveryInstructions]);

  const handleOnSubmit = (values, formikBag) => {
    const formattedInstructions = formatDeliveryInstructions(
      deliveryInstructions,
      values.deliveryInstructions
    );
    const updatesObj = {
      deliveryInstructions: {
        path: `orderItems.${index}.deliveryInfo.deliveryInstructions`,
        value: formattedInstructions,
      },
    };

    triggerAction({
      action: "update-delivery-instructions",
      params: updatesObj,
      resolve: () => {
        formikBag.setSubmitting(false);
        ToasterHandler(
          "hooray",
          `${Localise(messages, "Delivery Instructions updated successfully.")}`
        );
      },
      reject: () => {
        formikBag.setSubmitting(false);
        ToasterHandler(
          "uh oh",
          Localise(messages, "Something went wrong. Please try again!")
        );
      },
    });
  };

  return (
    <Form
      initialValues={{
        deliveryInstructions: initialInstructions,
      }}
      onSubmit={(values, formikBag) => {
        if (formikBag.isSubmitting) return;

        handleOnSubmit(values, formikBag);
      }}
      render={() => (
        <View style={{ zIndex: -2 }}>
          <View style={[tw("flex mb-2"), { zIndex: -10 }]}>
            <FormField
              name={"deliveryInstructions"}
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={"Enter Delivery Instructions"}
              label={Localise(messages, "Delivery Instructions")}
              labelStyle={{
                fontSize: 14,
                fontWeight: "normal",
              }}
              containerStyle={{
                width: "100%",
                marginTop: 5,
                paddingHorizontal: 0,
              }}
              multiline={true}
              numberOfLines={3}
              maxNumberOfLines={5}
              isUpdateOnChange={true}
              editable={isEditable}
              testID={"delivery_instructions"}
              renderErrorMessage={false}
              errorStyle={{ paddingBottom: 0 }}
              {...((!deliveryInstructions ||
                deliveryInstructions.length <= 100) && { maxLength: 100 })}
            />
          </View>
          <SaveCancelButtons
            buttonTitle={Localise(messages, "Save")}
            cancelTestId="delivery_instructions_cancel_btn"
            saveTestId="delivery_instructions_save_btn"
          />
        </View>
      )}
    />
  );
};

export default React.memo(UpsertDeliveryInstructions);
