import { put, call, select } from "redux-saga/effects";

import { request } from "library/utils/request";

import get from "lodash/get";

import { saveShopSettings } from "../../common/slice";
import { setApiResponse, fetchStorePickupStatus, setApiError } from "../slice";
import {
  selectShopCode,
  selectShopGroupMembers,
  selectShopPreferences,
} from "../../common/selector";
import { selectStorePickup } from "../selector";
import UserProfileStorage from "library/storage/userProfile";

export function* handleFetchStorePickup({ fetchStores = true, stores = {} }) {
  const serviceRequest = (params) =>
    request("get-store-pickup-settings", params);
  const shopCode = yield select(selectShopCode);

  try {
    const { design_time = "02:00", store_pickup_enabled = "Inactive" } =
      UserProfileStorage.getShopPreferences(shopCode);
    let pickupRes;

    try {
      const response = yield call(serviceRequest, { shopCode });
      pickupRes = processStorePickupResponse(response);
    } catch {
      pickupRes = processStorePickupResponse({
        designTime: design_time,
        localPickup: store_pickup_enabled,
      });
    }

    yield put(
      setApiResponse({
        path: "storePickup",
        content: { stores, fetchStores, ...pickupRes },
      })
    );
    if (fetchStores) {
      yield put(fetchStorePickupStatus());
    }
  } catch (error) {
    yield put(
      setApiError({
        path: "storePickup",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleFetchStorePickupStatus() {
  try {
    const { content } = yield select(selectStorePickup);
    const shopGroupMembers = yield select(selectShopGroupMembers);
    const { pickup_locations = [] } = yield select(selectShopPreferences);

    const stores = Object.assign({}, shopGroupMembers);

    Object.keys(stores).forEach((code) => {
      stores[code] = {
        ...stores[code],
        status:
          (content?.localPickup ?? "Inactive") === "Active" &&
          get(pickup_locations, "0", "").length > 0
            ? pickup_locations.includes(code)
            : true,
      };
    });

    yield put(
      setApiResponse({
        path: "storePickup",
        content: {
          ...content,
          stores,
          fetchStores: false,
        },
      })
    );
  } catch (error) {
    yield put(
      setApiError({
        path: "storePickup",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleSaveStorePickup(action = {}) {
  const { params, resolve, reject } = get(action, "payload", {});
  const { content } = yield select(selectStorePickup);
  const memberCodes = Object.keys(get(content, "stores", {}));

  const requestPayload = processStorePickupResponse(params);
  const { localPickup = "", designTime = "" } = requestPayload || {};
  const storesOpted = Object.keys(params?.stores ?? {}).filter(
    (shop) => params?.stores[shop].status
  );
  const values = storesOpted.length ? storesOpted : [""];
  const shopCode = yield select(selectShopCode);
  const serviceRequest = () =>
    request("update-store-pickup-settings", {
      shopCode,
      ...requestPayload,
      memberCodes,
    });
  const payload = {
    preferences: [
      ...(params?.localPickup === "Active"
        ? [
            {
              id: "pickup_locations",
              values,
            },
            //turn off service bureau when localpickup is active
            {
              id: "is_service_bureau_member",
              values: ["false"],
            },
          ]
        : []),
      { id: "design_time", values: [designTime] },
      { id: "store_pickup_enabled", values: [localPickup] },
    ],
  };

  try {
    yield put(saveShopSettings({ payload }));
    yield call(serviceRequest);
    const shopPreferences = UserProfileStorage.getShopPreferences(shopCode);
    UserProfileStorage.setShopPreferences(shopCode, {
      ...shopPreferences,
      design_time: designTime,
      store_pickup_enabled: localPickup,
    });

    yield call(handleFetchStorePickup, {
      fetchStores: false,
      stores: params?.stores ?? {},
    });

    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        path: "storePickup",
        error: "Something went wrong, please try again1",
      })
    );
    reject && reject();
  }
}

const processStorePickupResponse = (response) => {
  const {
    localPickup = "Inactive",
    // curbsidePickup = "Active",
    designTime = "02:00",
  } = response;

  const designPeriod = designTime.toString().includes(":")
    ? designTime
    : designTime.toString().includes(".")
    ? `0${designTime.toString().split(".")[0]}:30`
    : `0${designTime}:00`;

  return {
    localPickup,
    // curbsidePickup,
    designTime: designPeriod,
  };
};
