import React, { memo } from "react";
import { Image } from "react-native";
import { CheckBox } from "react-native-elements";
import IMAGES from "static/assets/images";
import { theme } from "styles/theme";
import I18NContext from "library/contexts/i18N";

// eslint-disable-next-line react/display-name
const CustomCheckbox = memo((props) => {
  const {
    onPress,
    title,
    checked,
    inputContainerStyle = {},
    size = 18,
    iconSize = 20,
  } = props;

  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <CheckBox
      title={Localise(messages, title)}
      containerStyle={[theme.CheckBox.inputContainerStyle, inputContainerStyle]}
      size={size}
      checkedIcon={
        <Image
          source={IMAGES["checkbox-checked"]}
          style={{ width: iconSize, height: iconSize }}
        />
      }
      uncheckedIcon={
        <Image
          source={IMAGES["checkbox-unchecked"]}
          style={{ width: iconSize, height: iconSize }}
        />
      }
      checked={checked}
      testID={title}
      accessibilityLabel={title}
      onPress={onPress}
    />
  );
});

export default CustomCheckbox;
