import React, { useState, useCallback } from "react";
import { View, ScrollView } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { Input, Text } from "react-native-elements";

import AGGridContainer from "components/containers/ag-grid";

import { TabBarNew } from "components/elements";
import { LabelField } from "components/wrappers";

import {
  orderGroupsConfig,
  columnDefs,
  fetchRecords,
  detailsColDefs,
  updateNotifications,
  getMainMenuItems,
} from "./config";
import { setPageActions } from "library/sagas/views/home/drawer/notifications/slice";
import { CustomRTE } from "components/views/drawer/ftd-admin/mol-settings/helper";
import { selectDashboardShopCode } from "library/sagas/ongoing/global-data/selector";

import {
  selectAPIResponse,
  selectActions,
} from "library/sagas/views/home/drawer/notifications/selector";
import capitalize from "lodash/capitalize";
import { DeviceContext } from "library/contexts/appSettings";

import tw from "tailwind-rn";
import { fonts } from "styles/theme";

import "./styles.css";
import { isEmpty } from "lodash";

const NotificationCenter = () => {
  const dispatch = useDispatch();

  const apiData = useSelector(selectAPIResponse);
  const shopCode = useSelector(selectDashboardShopCode);
  const { isDesktop } = React.useContext(DeviceContext);

  const { searchQuery = "", orderGroups = {} } = useSelector(selectActions);

  const [showReadOnly, setShowReadOnly] = useState(null);
  const { category = "" } = orderGroups;

  const onGridReady = useCallback(
    (gridInstance, gridProps) => {
      fetchRecords({
        gridInstance,
        gridProps,
        dispatch,
        searchQuery,
        orderGroups,
        shopCode,
      });
    },
    [showReadOnly, searchQuery, JSON.stringify(orderGroups)]
  );

  return (
    <View style={tw("flex flex-1 py-1")}>
      <View style={tw("flex-row items-center w-1/4")}>
        <Input
          style={{
            padding: 5,
            height: 30,
            width: 250,
          }}
          errorStyle={{ paddingBottom: 0 }}
          onChangeText={(value) => {
            dispatch(setPageActions({ type: "searchQuery", value: value }));
          }}
          value={searchQuery}
          placeholder={"Search by any column"}
          rightIcon={
            searchQuery.length > 0 ? (
              <Text
                style={{
                  paddingRight: 5,
                  fontWeight: "bold",
                }}
                onPress={() => {
                  dispatch(setPageActions({ type: "searchQuery", value: "" }));
                }}
              >
                X
              </Text>
            ) : undefined
          }
        />
        <View style={tw("flex flex-row items-center justify-center ml-5")}>
          <TabBarNew
            groups={orderGroupsConfig(apiData)}
            selectedGroups={[orderGroups]}
            onSelectionChange={(category, value) => {
              dispatch(
                setPageActions({
                  type: "orderGroups",
                  value: { category, values: [value] },
                })
              );
              setShowReadOnly(
                value === 0 ? false : value === "all" ? null : true
              );
            }}
          />
        </View>
      </View>
      {category === "deletedFolder" ? (
        <LabelField
          text={`You can restore all the messages in trash folder within 60 days from the date of message recieved. Messages are auto deleted after 60 days.`}
          style={{
            ...fonts.style4,
            marginVertical: 20,
          }}
        />
      ) : null}
      <View style={{ height: 700 }}>
        <AGGridContainer
          onGridReady={onGridReady}
          columnDefs={columnDefs(dispatch, searchQuery, orderGroups, apiData)}
          serverSideEnableClientSideSort={true}
          masterDetail={true}
          getMainMenuItems={(params) =>
            getMainMenuItems({ apiData, dispatch, orderGroups, ...params })
          }
          onRowClicked={(event) => {
            const node = event.node;
            const { column: { colDef: { headerName = "" } = {} } = {} } =
              event?.api?.getFocusedCell() || {};

            const { data = {} } = node || {};

            const isStatusColumn =
              headerName === "Status" || headerName === "Action";
            if (!isEmpty(node) && headerName && !isStatusColumn) {
              if (!data.hasBeenRead) {
                updateNotifications({
                  data,
                  apiData,
                  api: event.api,
                  dispatch,
                });
              }
              node.setExpanded(!node.expanded);
            }
          }}
          rowHeight={50}
          gridOptions={{
            defaultColDef: {
              flex: isDesktop ? 0 : 1,
            },
            getRowId: (params) => {
              return `${
                params.data.notificationId || `${params.data.updatedOn}`
              }`;
            },
            onSelectionChanged: (params) => {
              const selectionState = params.api.getServerSideSelectionState();
              dispatch(
                setPageActions({ type: "rowSelection", value: selectionState })
              );
            },
            getRowStyle: () => {
              return { cursor: "pointer" };
            },
          }}
          suppressServerSideInfiniteScroll={false}
          pagination={false}
          detailRowAutoHeight={true}
          detailCellRenderer={({ data }) => {
            const { type = "", messageText = "" } = data;
            const CustomMessageBody = () => {
              return (
                <ScrollView>
                  <View
                    style={[
                      tw("flex flex-row items-start justify-start"),
                      {
                        fontSize: 12,
                        minHeight: 150,
                        left: 100,
                        right: 100,
                        maxHeight: 500,
                        width: "90%",
                        padding: 10,
                      },
                    ]}
                  >
                    <CustomRTE
                      value={capitalize(messageText)}
                      styles={{ width: "100%" }}
                      readOnly={true}
                      setValue={(val) => {}}
                      onEditorStateChange={() => {}}
                    />
                  </View>
                </ScrollView>
              );
            };

            return (
              <>
                {type === "CATALOG" ? (
                  <View
                    style={{
                      height: 300,
                      width: isDesktop ? "50%" : "100%",
                      padding: 20,
                      marginLeft: isDesktop ? 250 : 10,
                    }}
                  >
                    <AGGridContainer
                      columnDefs={detailsColDefs}
                      rowData={data?.children || []}
                      rowHeight={50}
                      pagination={false}
                      suppressServerSideInfiniteScroll={false}
                      gridOptions={{
                        defaultColDef: {
                          flex: isDesktop ? 0 : 1,
                        },
                        rowModelType: "clientSide",
                      }}
                    />
                  </View>
                ) : (
                  <CustomMessageBody />
                )}
              </>
            );
          }}
        />
      </View>
    </View>
  );
};

export default NotificationCenter;
