import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { formatPrice } from "library/utils/formatter";

export const canCallDeliveryFee = (
  orderInfo,
  selectedMemberCode,
  index = 0,
  values,
  setFieldValue,
  orderResponse,
  reCalculateDF
) => {
  if (isEmpty(orderResponse)) return false;
  const { addressLine1, city, state, zip, country } = orderInfo.recipientInfo;
  const { deliveryDate, deliveryMethod } = orderInfo.deliveryInfo;

  const orderItem = orderResponse.orderItems[index];
  const orderCustomerId = orderResponse?.customerInfo?.customerId;
  const {
    addressLine1: orderAddressLine1,
    city: orderCity,
    state: orderState,
    zip: orderZip,
    country: orderCountry,
  } = orderItem?.recipientInfo || {};
  const {
    deliveryDate: orderDeliveryDate,
    deliveryMethod: orderDeliveryMethod,
  } = orderItem?.deliveryInfo || {};
  const { memberCode } = orderItem?.receivingMember || {};
  const orderSource = orderItem?.orderSource || "";

  if (deliveryMethod === "FLORIST_PARTNER") return false;

  if (
    ["Bloomnet", "Teleflora"].includes(orderSource) ||
    (["TEL eFlorist", "Email Order"].includes(orderSource) && !reCalculateDF)
  )
    return false;

  if (
    addressLine1 !== orderAddressLine1 ||
    city !== orderCity ||
    state !== orderState ||
    zip !== orderZip ||
    country !== orderCountry ||
    (!["TEL eFlorist", "Email Order"].includes(orderSource) &&
      deliveryDate !== orderDeliveryDate) ||
    selectedMemberCode !== memberCode ||
    deliveryMethod !== orderDeliveryMethod ||
    orderInfo?.isRushOrder !== orderItem?.isRushOrder ||
    values?.customerInfo?.customerId !== orderCustomerId
  ) {
    return true;
  } else {
    // If no change of address or other details then don't call the delivery-fee call.. Setting delivery fee from order details.
    const deliveryFeePath = `orderItems.${index}.deliveryFee`;
    const currentDeliveryFee = get(values, deliveryFeePath);
    const orderDeliveryFee = get(
      values,
      `orderItems.${index}.oldValues.totalDeliveryFee`
    );
    if (formatPrice(currentDeliveryFee) !== formatPrice(orderDeliveryFee)) {
      setFieldValue(deliveryFeePath, orderDeliveryFee);
    }

    return false;
  }
};
