/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Input } from "react-native-elements";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-native-elements";

import { useNavigation } from "@react-navigation/native";

import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import UserProfileStorage from "library/storage/userProfile";
import { selectDashboardShopCode } from "library/sagas/ongoing/global-data/selector";
import {
  setCurrentPage,
  setQrScanVariant,
} from "library/sagas/ongoing/global-data/slice";
import {
  setPageInitialise,
  setPageActions,
  resetSlice,
  assignDesigner,
  triggerRefreshGrid,
  fetchDesigners,
  orderDetailActions,
  setRefreshGrid,
} from "library/sagas/views/home/drawer/orders-new/slice";
import {
  selectGridInstance,
  selectGridProps,
  selectLoading,
  selectActions,
  selectPageLimit,
  selectDesigners,
  selectLimitExceeded,
  selectGridColumnDefination,
  selectNeedActionCount,
  selectRefreshGrid,
  selectZones,
} from "library/sagas/views/home/drawer/orders-new/selector";
import { hasDesignCenterFeature } from "library/utils/featureAvailability";
import I18NContext from "library/contexts/i18N";
import PageStorage from "library/storage/pageStorage";

import {
  TabBarNew,
  CustomModal,
  PrintIframeRenderer,
  Tooltip,
} from "components/elements";
import { Form, FormFieldPicker } from "components/elements/forms";
import AGGridContainer from "components/containers/ag-grid";
import OrderDetails from "components/views/drawer/order-details";
import {
  ListOfActions,
  ListOfSubActions,
} from "components/views/drawer/order-listing/current-orders/config";

import IMAGES from "static/assets/images";
import { colors, backgroundColors } from "styles/theme";
import { fonts } from "styles/theme";

import debounce from "lodash/debounce";
import tw from "tailwind-rn";

import { orderGroupsConfig, columnDefs, getDeliveryMethod } from "./config";
import "./styles.css";

const OrdersNew = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const gridRef = useSelector(selectGridInstance);
  const gridProps = useSelector(selectGridProps);
  const isLoading = useSelector(selectLoading);
  const shopCode = useSelector(selectDashboardShopCode);
  const pageLimit = useSelector(selectPageLimit);
  const designers = useSelector(selectDesigners);
  const showLimitMessage = useSelector(selectLimitExceeded);
  const columnDefination = useSelector(selectGridColumnDefination);
  const needsActionCount = useSelector(selectNeedActionCount);
  const refreshGrid = useSelector(selectRefreshGrid);
  const zones = useSelector(selectZones);
  const {
    searchQuery = "",
    orderGroups = [],
    gridState = [],
    showDesigners = false,
  } = useSelector(selectActions);
  const formRef = useRef();
  const isGridReadyCalled = useRef(false);
  const { isDesktop } = useContext(DeviceContext);
  const { permissions } = useContext(AppSettingsContext);
  const { messages, Localise } = useContext(I18NContext);

  const [orderDetailsData, setOrderDetailsData] = useState(null);
  const [resetModalVisible, setResetModalVisibile] = useState(false);
  const [columnPannelVisible, setColumnPanelVisible] = useState(
    gridRef?.api?.isToolPanelShowing() ?? false
  );

  const showGridReset =
    gridState.length > 0 ||
    (orderGroups.length > 0 &&
      !orderGroups.filter((e) => e.category === "all").length) ||
    searchQuery.length > 0 ||
    showDesigners;
  const { memberCodes = [] } = UserProfileStorage.getAuthGroup();
  const sendingMember = shopCode === "all" ? memberCodes[0] : shopCode;
  const tabbarGroups = orderGroupsConfig.map((e) =>
    e.category === "actions"
      ? {
          ...e,
          tabs: e.tabs.map((i) =>
            i.label === "Needs Action" ? { ...i, badge: needsActionCount } : i
          ),
        }
      : e
  );
  const columns = (
    Object.keys(columnDefination).length === gridState.length
      ? gridState
      : Object.keys(columnDefination)
  ).map((item) => {
    const key = typeof item === "string" ? item : item.colId;
    const col = gridState.find((e) => e.colId === key) || {};

    return {
      ...columnDefination[key],
      hide: col.hide ?? columnDefination[key]?.hide,
      width: col.width ?? columnDefination[key]?.width,
      appliedFilter: col.appliedFilter ?? columnDefination[key]?.appliedFilter,
      appliedSort: col.appliedSort ?? columnDefination[key]?.appliedSort,
      appliedSearch: col.appliedSearch ?? columnDefination[key]?.appliedSearch,
      ...(key === "assignments"
        ? {
            filterParams: {
              filterOptions: columnDefination[
                key
              ].filterParams.filterOptions.map((e) => {
                return e.rowId === "fulfillment_details.delivery_zone_id.in"
                  ? {
                      ...e,
                      filters: zones.map((e) => ({
                        label: e?.autoRouteId ?? "",
                        name: e?.name ?? "",
                      })),
                    }
                  : e;
              }),
            },
          }
        : {}),
    };
  });
  const isEnhancedDesignerEnabled = memberCodes.some((eachshop) =>
    hasDesignCenterFeature(permissions, eachshop)
  );

  const resetModalContent = {
    content: (
      <Text
        style={[
          tw("p-4"),
          { fontSize: 15, color: colors.highlighter, textAlign: "left" },
        ]}
      >
        {
          "Are you sure you want to reset your grid settings? This will: \n\n - Restore all columns to their default view and positions. \n - Remove any filters or sorting currently applied. \n - Clear your saved preferences. \n\n You can always customize your view again after the reset."
        }
      </Text>
    ),
    buttons: [
      { type: "secondary", title: "Cancel" },
      { type: "primary", title: "Reset Settings" },
    ],
  };

  const debouncedGridState = debounce(() => {
    dispatch(
      setPageActions({
        type: "saveGridState",
      })
    );
  }, 100);

  const onGridReady = (gridInstance, gridProps) => {
    if (!isGridReadyCalled.current) {
      isGridReadyCalled.current = true;
      setTimeout(() => {
        dispatch(setPageInitialise({ gridInstance, gridProps, sendingMember }));
      }, 0);
    }
  };

  useEffect(() => {
    if (!refreshGrid) return;
    dispatch(setRefreshGrid(false));
    dispatch(triggerRefreshGrid());
  }, [refreshGrid]);

  useEffect(() => {
    showDesigners && dispatch(fetchDesigners({ memberCodes }));
  }, [showDesigners]);

  useEffect(() => {
    dispatch(
      setPageActions({
        type: "columnDefination",
        value: columnDefs(dispatch, gridState, showDesigners),
      })
    );
  }, [gridState]);

  return (
    <View style={tw("flex flex-1 py-1")}>
      <View
        style={tw("flex flex-row flex-wrap items-center justify-between px-2")}
      >
        <View style={tw("flex-row items-center")}>
          <Input
            style={{
              padding: 5,
              height: 30,
              width: 180,
            }}
            errorStyle={{ paddingBottom: 0 }}
            onChangeText={(val) => {
              dispatch(setPageActions({ type: "searchQuery", value: val }));
            }}
            value={searchQuery}
            placeholder={"Search by any column"}
            rightIcon={
              searchQuery.length > 0 ? (
                <Text
                  style={{ fontWeight: "bold" }}
                  onPress={() => {
                    dispatch(
                      setPageActions({ type: "searchQuery", value: "" })
                    );
                  }}
                >
                  X
                </Text>
              ) : undefined
            }
          />
        </View>
        <View style={tw("flex flex-row")}>
          {isLoading && (
            <ActivityIndicator
              style={{ marginRight: 5 }}
              color={colors.activityIndicator}
              testID={"loader"}
            />
          )}
          <TabBarNew
            groups={tabbarGroups}
            selectedGroups={orderGroups}
            onSelectionChange={(category, value) => {
              dispatch(
                setPageActions({
                  type: "orderGroups",
                  value: { category, value },
                })
              );
            }}
          />
        </View>
        <View style={tw("flex flex-row items-center")}>
          {isEnhancedDesignerEnabled && (
            <View
              style={[
                tw("mr-2"),
                showDesigners
                  ? {
                      backgroundColor: "#F5F5F5",
                      borderRadius: 5,
                      shadowColor: "#000000",
                      shadowOffset: {
                        width: 0,
                        height: 1,
                      },
                      shadowRadius: 5,
                      shadowOpacity: 1.0,
                    }
                  : {},
              ]}
            >
              <Tooltip
                text={Localise(messages, "Design Center")}
                renderForWebOnly={true}
              >
                <TouchableOpacity
                  onPress={() => {
                    dispatch(
                      setPageActions({
                        type: "showDesigners",
                        value: { showDesigners: !showDesigners, memberCodes },
                      })
                    );
                  }}
                  testID={"open_designers_area"}
                  accessibilityLabel={"open_designers_area"}
                >
                  <Image
                    style={{
                      width: 35,
                      height: 35,
                    }}
                    resizeMode={"cover"}
                    source={IMAGES["unassigned"]}
                  />
                </TouchableOpacity>
              </Tooltip>
            </View>
          )}
          {showGridReset && (
            <TouchableOpacity
              style={tw("mr-2")}
              onPress={() => {
                setResetModalVisibile(true);
              }}
            >
              <Image
                style={{ width: 25, height: 25 }}
                resizeMode={"contain"}
                source={IMAGES["reset"]}
              />
            </TouchableOpacity>
          )}
          <TouchableOpacity
            style={tw("mr-2")}
            onPress={() => {
              dispatch(setQrScanVariant("order-scan"));
              dispatch(setCurrentPage("qr-code-scan"));
              navigation.navigate("qr-code-scan");
            }}
          >
            <Tooltip
              text={Localise(messages, "QR Scanning")}
              renderForWebOnly={true}
            >
              <Image
                style={{ width: 25, height: 25 }}
                resizeMode={"contain"}
                source={IMAGES["qr_code_scan"]}
              />
            </Tooltip>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setColumnPanelVisible(!columnPannelVisible);
              gridProps?.toggleColumnPanel();
            }}
          >
            <View
              style={[
                tw("mr-2"),
                columnPannelVisible
                  ? {
                      backgroundColor: "#F5F5F5",
                      borderRadius: 5,
                      shadowColor: "#000000",
                      shadowOffset: {
                        width: 0,
                        height: 1,
                      },
                      shadowRadius: 5,
                      shadowOpacity: 1.0,
                      padding: 6,
                    }
                  : {},
              ]}
            >
              <Image
                style={{ width: 25, height: 25 }}
                resizeMode={"contain"}
                source={IMAGES["pivot_menu"]}
              />
            </View>
          </TouchableOpacity>
          <View
            style={{
              width: 2,
              height: 30,
              backgroundColor: "#294B5D",
              marginHorizontal: 10,
            }}
          />
          <View style={tw("flex-row items-center mr-4")}>
            <TouchableOpacity
              style={[tw("flex-row items-center")]}
              onPress={() => {
                PageStorage.setShowNewOrdersPage(
                  "set_show_new_orders_page",
                  false
                );
                window.location.reload();
              }}
            >
              <Text style={{ color: "#294B5D" }}>{"Classic View"}</Text>
            </TouchableOpacity>
            <View style={{ marginRight: 10 }} />
            <View
              style={[
                tw("flex-row items-center"),
                {
                  backgroundColor: "#F5F5F5",
                  padding: 5,
                  borderRadius: 5,
                  shadowColor: "#000000",
                  shadowOffset: {
                    width: 0,
                    height: 1,
                  },
                  shadowRadius: 5,
                  shadowOpacity: 1.0,
                },
              ]}
            >
              <View
                style={{
                  backgroundColor: "#D2547C",
                  padding: 3,
                  borderRadius: 5,
                }}
              >
                <Text style={{ ...fonts.heading5, color: "white" }}>
                  {"Beta"}
                </Text>
              </View>
              <View style={{ marginRight: 5 }} />
              <Text style={{ ...fonts.heading5, color: "#294B5D" }}>
                {"View"}
              </Text>
            </View>
          </View>
        </View>
      </View>
      {showDesigners && (
        <View style={tw("flex flex-row items-center")}>
          <Form
            innerRef={formRef}
            initialValues={{
              designerAction: "assign-to-designer",
              selectedDesigner: "",
              selectedOrders: [],
            }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={({ selectedOrders = [], selectedDesigner = "" }) => {
              dispatch(
                assignDesigner({
                  selectedOrders,
                  memberCode: sendingMember,
                  designer: {
                    id: designers.find((e) => e.id === selectedDesigner).id,
                    name: designers.find((e) => e.id === selectedDesigner)
                      .fullName,
                  },
                })
              );
            }}
            render={({
              values: {
                designerAction = "",
                selectedDesigner = "",
                selectedOrders = [],
              } = {},
              handleSubmit,
            }) => {
              const enableApplyBtn =
                designerAction !== ""
                  ? designerAction === "assign-to-designer"
                    ? !!selectedDesigner && selectedOrders.length
                    : selectedOrders.length
                  : false;

              return (
                <View
                  style={tw("flex flex-1 flex-row items-start mx-2")}
                  testID={"assign_for_design"}
                >
                  <View>
                    <FormFieldPicker
                      placeholder={{
                        label: "Select",
                        value: "select",
                      }}
                      containerStyle={{
                        width: "100%",
                        paddingTop: 7,
                      }}
                      data={[
                        {
                          label: `Assign to Designer`,
                          value: `assign-to-designer`,
                        },
                        {
                          label: `Remove Designer`,
                          value: `remove-designer`,
                        },
                      ]}
                      name={"designerAction"}
                    />
                    <Text style={[fonts.error, tw("text-justify mx-2")]}>
                      {Localise(
                        messages,
                        "Note:- This action applies only to the orders visible below."
                      )}
                    </Text>
                  </View>
                  {designerAction === "assign-to-designer" && (
                    <FormFieldPicker
                      placeholder={{
                        label: "Select",
                        value: "select",
                      }}
                      containerStyle={{
                        width: "20%",
                        paddingLeft: 5,
                        paddingTop: 7,
                      }}
                      data={designers.map((e) => ({
                        label: e.fullName,
                        value: e.id,
                      }))}
                      name={"selectedDesigner"}
                    />
                  )}
                  <Button
                    containerStyle={{ margin: 7 }}
                    title={Localise(messages, "Apply")}
                    onPress={handleSubmit}
                    testID={"Apply"}
                    accessibilityLabel={"Apply"}
                    disabled={!enableApplyBtn}
                    loading={isLoading}
                  />
                  {showLimitMessage && (
                    <Text style={[fonts.error, tw("text-justify mx-2 my-4")]}>
                      {Localise(
                        messages,
                        "** Some records are not showing up. Please adjust your filters to display all available results."
                      )}
                    </Text>
                  )}
                </View>
              );
            }}
          />
        </View>
      )}
      <View style={tw("my-1")} />
      <AGGridContainer
        onGridReady={onGridReady}
        paginationPageSize={pageLimit}
        columnDefs={columns}
        onColumnMoved={debouncedGridState}
        onColumnResized={debouncedGridState}
        onColumnVisible={debouncedGridState}
        groupDisplayType={"singleColumn"}
        rowGroupPanelShow={"never"}
        suppressServerSideInfiniteScroll={false}
        autoGroupColumnDef={{
          headerName: showDesigners ? "Group By - Designers" : "Group By",
          minWidth: 300,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          suppressMenu: true,
        }}
        getMainMenuItems={(params) => {
          return columnDefination[params.column.colId].filterParams.sortOptions;
        }}
        gridOptions={{
          suppressHorizontalScroll: false,
          getRowId: (params) => params.data.id,
          onRowSelected: ({
            source = "",
            node: { selected = false } = {},
            data: { group = false, id = "", childOrders = [] } = {},
          } = {}) => {
            if (!id.length) return;

            if (source === "uiSelectAll") {
              const updatedOrders = selected ? ["all"] : [];
              formRef.current.setFieldValue("selectedOrders", updatedOrders);
            } else if (source === "checkboxSelected" && group) {
              const selectedOrders = formRef.current.values.selectedOrders;
              formRef.current.setFieldValue(
                "selectedOrders",
                selected
                  ? [...selectedOrders, ...childOrders]
                  : selectedOrders.filter((e) => !childOrders.includes(e))
              );
            } else {
              const selectedOrders = formRef.current.values.selectedOrders;
              const updatedOrders = selectedOrders.includes(id)
                ? selectedOrders.filter((e) => e !== id)
                : [...selectedOrders, id];
              formRef.current.setFieldValue("selectedOrders", updatedOrders);
            }
          },
        }}
        onRowClicked={(event) => {
          const { data: { group = false } = {} } = event;
          const columnId =
            event?.event?.target?.closest(".ag-cell")?.getAttribute("col-id") ??
            "";
          ![
            columnDefination.fulfillment.colId,
            columnDefination.actions.colId,
          ].includes(columnId) &&
            !group &&
            setOrderDetailsData(event.data);
        }}
        getChildCount={(data) => {
          return data ? data.childCount : 0;
        }}
      />
      {orderDetailsData && (
        <div
          className="overlay-wrapper"
          onClick={() => {
            setOrderDetailsData(null);
          }}
        >
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            {orderDetailsData && (
              <OrderDetails
                isSmallScreen={!isDesktop}
                recordId={orderDetailsData.external_id}
                deliveryMethod={getDeliveryMethod(
                  orderDetailsData.source,
                  orderDetailsData.fulfillment_type,
                  orderDetailsData.eros_details
                )}
                sourceMemberCode={sendingMember}
                actionsList={ListOfActions}
                subActionsList={ListOfSubActions}
                onComplete={() => {
                  setOrderDetailsData(null);
                }}
                onAction={(value) => {
                  dispatch(
                    orderDetailActions({
                      rowId: orderDetailsData.id,
                      orderDetailAction: value,
                    })
                  );
                }}
                setMenuAction={() => {}}
                recordAction={""}
                menuAction={""}
              />
            )}
          </div>
        </div>
      )}
      <CustomModal
        modalVisible={resetModalVisible}
        modalContent={resetModalContent}
        primaryhandler={() => {
          setResetModalVisibile(false);
          dispatch(
            setPageActions({
              type: "showDesigners",
              value: { showDesigners: !showDesigners, memberCodes },
            })
          );
          dispatch(resetSlice());
          dispatch(triggerRefreshGrid());
        }}
        secondaryhandler={() => {
          setResetModalVisibile(false);
        }}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: backgroundColors.secondary, textAlign: "center" },
        ]}
      ></CustomModal>
      <PrintIframeRenderer />
    </View>
  );
};

export default OrdersNew;
