import React from "react";
import { DeviceContext } from "library/contexts/appSettings";
import HeaderLarge from "./large";
import HeaderSmall from "./small";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reset as resetCurrentOrders } from "library/sagas/ongoing/current-orders/slice";
import { reset as resetStaffListing } from "library/sagas/ongoing/staff-listing/slice";
import {
  setCurrentPage,
  setShopCode,
} from "library/sagas/ongoing/global-data/slice";

const Header = ({
  currentPage,
  layoutMode,
  setCurrentPage,
  subHeader,
  resetCurrentOrders,
  resetStaffListing,
  setShopCode,
  showWelcomeSection = true,
}) => {
  const isEmbedded = layoutMode === "embedded";
  const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);
  const handleReset = () => {
    setShopCode("all");
    resetCurrentOrders();
    resetStaffListing();
  };

  return isEmbedded ? null : isDesktop ? (
    <HeaderLarge
      subHeader={subHeader}
      reset={handleReset}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      showWelcomeSection={showWelcomeSection}
    />
  ) : (
    <HeaderSmall
      isMobile={isMobile}
      isTablet={isTablet}
      subHeader={subHeader}
      reset={handleReset}
      showWelcomeSection={showWelcomeSection}
    />
  );
};

const mapStateToProps = (state = {}) => {
  return {
    currentPage: state.mhq.ongoing.global.currentPage,
    layoutMode: state.mhq.ongoing.global.layoutMode,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetCurrentOrders,
      resetStaffListing,
      setCurrentPage,
      setShopCode,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
