import {
  put,
  call,
  fork,
  delay,
  select,
  takeLatest,
  takeEvery,
  all,
} from "redux-saga/effects";

import * as Navigation from "library/utils/navigation";
import AppSettingsStorage from "library/storage/appSettings";
import {
  processResponse,
  processNotificationMessages,
} from "library/utils/shopSettings";
import UserProfileStorage from "library/storage/userProfile";
import { request } from "library/utils/request";
import { isMHQNonCoreMember } from "library/utils/entitlements";

import { IntegrationSettingsKeys } from "components/views/drawer/shop-settings/orders/integration-settings/config";
import { TerminalSettingsKeys } from "components/views/drawer/shop-settings/payments/terminal-settings/config";
import {
  PrinterSettingsKeys,
  nonStandardEligiblePrinterSettings,
} from "components/views/drawer/shop-settings/orders/printer-settings/config";

import get from "lodash/get";
import uniq from "lodash/uniq";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";
import { hasQuickBooksAccess } from "library/utils/featureAvailability";

import {
  setInit,
  setApiResponse,
  setApiError,
  setSideCarTitle,
  setActiveTab,
  setSideCar,
  setNotificationSettings,
  setDefaultOLCNotifications,
  fetchShopSettings,
  fetchShopPaymentSettings,
  fetchDefaultNotifications,
  fetchShopGroupMembers,
  saveShopGroupMembers,
  saveActiveShopGroupMembers,
  saveShopSettings,
  allShopsUpdateShopSettings,
  setSuccess,
  setFailure,
  fetchShopHours,
  saveShopHours,
  fetchQuickBooksAuthorizationStatus,
  setQuickBooksAuthorizationStatus,
  fetchLogoImages,
  setShopLogoData,
  saveLogoImages,
  getTerminalActivationCodes,
  setMarketingEmail,
  setReplyEmailVerificationStatus,
  setFromEmailVerificationStatus,
  fetchMarketingEmails,
  addMarketingEmail,
  setPromoCodesList,
  savePromoCode,
  fetchPromoCodes,
  updatePromoCode,
  deletePromoCode,
  getShopSettings,
} from "./slice";
import { setAutoPrintOrders } from "library/sagas/ongoing/current-orders/slice";
import { saveStorePickup } from "../delivery-pickup/slice";
import {
  fetchGlobalFees,
  fetchDeliveryExceptions,
  fetchCitiesForExceptions,
  fetchStorePickup,
} from "../delivery-pickup/slice";
import {
  fetchDeliveryCutoffTimes,
  fetchCutoffExceptions,
} from "../delivery-cutoff-times/slice";

import {
  selectShopCode,
  selectShopPreferences,
  selectActiveTab,
} from "./selector";
import { selectStorePickup } from "../delivery-pickup/selector";
import { handleFetchShopHours, handleSaveShopHours } from "../shop-hours";
import common from "library/utils/common";

import moment from "moment";
import { promoParams } from "components/views/drawer/shop-settings/marketing/promoCodes/helper";

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */

function* handlePageIntialize() {
  try {
    yield put(fetchShopGroupMembers());
    yield put(fetchShopSettings());
    yield put(fetchQuickBooksAuthorizationStatus());
    const memberCode = yield select(selectShopCode);
    yield put(
      fetchPromoCodes({
        params: promoParams({ memberCode }),
      })
    );
  } catch {
    yield put(setFailure());
  }
}

function* handleNavigation(action = {}) {
  const name = get(action, "payload", "");
  yield Navigation.navigate("shop-settings", {
    screen: name,
    params: {
      openPrintPreference: undefined,
      openQuickBooksIntegration: undefined,
      openOccasionReminder: undefined,
    },
  });
}

function* handleSetSidecarAction(action = {}) {
  const { type, data = {}, Localise, messages } = get(action, "payload", {});
  const { city, state, countryId, name } = data;
  const address = `${startCase(toLower(city))} ${state}/${countryId}`;

  yield put(
    setSideCarTitle(
      type === "enclosure-card"
        ? "Tri-Fold Card Preview"
        : type === "order-invoice-card"
        ? "Order Invoice Preview"
        : type === "new-coverage"
        ? "New Coverage Area"
        : type === "city-zipcodes"
        ? `${address} ${Localise(messages, "Zip Codes")}`
        : type === "priceMinimumProducts"
        ? `Products Under Minimum`
        : ["florist_partner_details", "search_florist_partners"].includes(type)
        ? "Florist Partner Selection"
        : type === "shared-catalog-member-mappings"
        ? `${name} Details`
        : ""
    )
  );
}

function* handleFetchShopSettings() {
  const shopSettingsRequest = (params) => request("get-shop-settings", params);
  const shopDetailsRequest = (params) => request("get-shop-details", params);
  const activeTab = yield select(selectActiveTab);

  const shopCode = yield select(selectShopCode);
  const isMHQNonCoreShop = isMHQNonCoreMember(shopCode);

  try {
    const settingsResponse = yield call(shopSettingsRequest, {
      ids: uniq([
        "card_settings",
        "additional_sympathy_card",
        "additional_sympathy_invoice",
        "delivery_service",
        "auto_accept_orders",
        "auto_print_accept_orders",
        "bulk_print_orders",
        "auto_send_to_delivery_service",
        "florist_display_contact_info",
        "delivery_provider",
        "bloomlink_enabled",
        "pickup_locations",
        "design_time",
        "store_pickup_enabled",
        "delivery_pickup_time",
        "rush_fee_enabled",
        "retail_delivery_fee_enabled",
        "rush_submitted_by",
        "rush_delivered_by",
        "enable_auto_wiring",
        "is_service_bureau_member",
        "deliveryServiceActiveSince",
        "delivery_service_pickup_instructions",
        "no_delivery_service_zone",
        "florist_contact_information_settings",
        "invoice_print_card_section3_settings",
        ...IntegrationSettingsKeys["telOrders"],
        ...IntegrationSettingsKeys["emailOrders"],
        ...nonStandardEligiblePrinterSettings,
        ...(isMHQNonCoreShop
          ? [
              "cash_or_check",
              "credit_card",
              "paid_elsewhere",
              "gift_cards",
              "pay_later",
              "house_account_invoice",
              "house_account_latefeeCharge_enabled",
              "house_account_latefeeAmount",
              "house_account_statement_store_address_enabled",
              "payment_terminal",
              "delivery_fee",
              "tax_delivery_fee",
              "retail_delivery_fee",
              "tax_retail_delivery_fee",
              "relay_fee",
              "local_order_tax_settings",
              "wired_order_tax_settings",
              ...PrinterSettingsKeys,
              ...TerminalSettingsKeys,
              "house_account_statement_notification_settings",
              "order_confirmation_notification_settings",
              "pickup_confirmation_notification_settings",
              "order_started_notification_settings",
              "order_dd_changed_notification_settings",
              "pick_up_date_changed_notification_settings",
              "order_out_for_delivery_notification_settings",
              "order_delivered_notification_settings",
              "order_pickedup_notification_settings",
              "walkin_delivered_notification_settings",
              "order_not_delivered_notification_settings",
              "order_ready_for_pickup_notification_settings",
              "credit_card_saved_notification_settings",
              "pay_later_notification_settings",
              "gift_cards_notification_settings",
              "event_proposal_notification_settings",
              "email_ha_invoices_notification_settings",
              "tax_on_local_delivery_fee",
              "tax_on_outside_local_delivery_fee",
              "tax_on_service_fee",
              "tax_on_relay_fee",
              "designed_image_preview_notification_settings",
              "attempted_delivery_flagged_followup",
            ]
          : []),
        "qr_code_enabled",
        "qr_code_global_enabled",
        "qr_code_invoice_enabled",
        "qr_code_worksheet_enabled",
        "qr_code_order_details_enabled",
        "qr_code_trifold_enabled",
        "birthday_occasion_notification_settings",
        "anniversary_occasion_notification_settings",
      ]),
      memberCode: shopCode,
    });
    const detailsResponse = yield call(shopDetailsRequest, {
      memberCode: shopCode,
    });

    if (
      !(
        settingsResponse &&
        settingsResponse.preferences &&
        settingsResponse.preferences.length
      )
    )
      throw "error";

    const result = processResponse(settingsResponse);

    if (result?.rush_submitted_by && result?.rush_delivered_by) {
      result.rush_submitted_by = moment(
        result?.rush_submitted_by,
        "HH:mm:ss"
      ).format("YYYY-MM-DDTHH:mm:ss");
      result.rush_delivered_by = moment(
        result?.rush_delivered_by,
        "HH:mm:ss"
      ).format("YYYY-MM-DDTHH:mm:ss");
    }

    if (
      (result.florist_contact_information_settings == "JDE" ||
        result.florist_contact_information_settings == undefined ||
        result.florist_contact_information_settings == "") &&
      detailsResponse != null &&
      detailsResponse.length > 0
    ) {
      const defaultFloristContactInfo = {
        shopName: detailsResponse[0]?.businessName,
        streetAddress: detailsResponse[0]?.address.addressLine1,
        aptSuite: "",
        city: detailsResponse[0]?.address?.city,
        state: detailsResponse[0]?.address?.state,
        zip: detailsResponse[0]?.address?.zip,
        phone: detailsResponse[0]?.phone,
        email: detailsResponse[0]?.email,
        website: "",
        additionalMessage: "",
        contactName: detailsResponse[0]?.contactName,
        printed: [],
        web: [],
        network: [],
      };
      request("save-shop-settings", {
        preferences: [
          {
            id: "florist_contact_information_settings",
            values: [JSON.stringify(defaultFloristContactInfo)],
          },
        ],
        memberCode: shopCode,
      }).catch((error) => {
        console.log(error);
      });
    }

    yield put(
      setApiResponse({
        path: "shopDetails",
        content: get(detailsResponse, "0", {}),
      })
    );
    yield put(
      setApiResponse({
        path: "shopPreferences",
        content: result,
      })
    );

    // Updating the profile storage with latest results
    const preferences = UserProfileStorage.getShopPreferences(shopCode);
    UserProfileStorage.setShopPreferences(shopCode, {
      ...preferences,
      ...result,
    });

    yield put(fetchGlobalFees());
    if (activeTab) {
      yield put(fetchDeliveryExceptions());
      yield put(fetchCitiesForExceptions());
      yield put(fetchShopHours());
      yield put(fetchDeliveryCutoffTimes());
      yield put(fetchCutoffExceptions());
      yield put(fetchStorePickup());
    }
    yield put(fetchLogoImages({ memberCode: shopCode }));
    yield delay(2000);
    yield put(setSuccess());
  } catch {
    yield put(setFailure());
  }
}

function* handleSaveShopSettings(action = {}) {
  const {
    payload,
    updateProfileStorage,
    resolve = () => {},
    reject = () => {},
  } = get(action, "payload", {});
  const { isAutoPrintToggle = false, ...other } = payload;
  const shopCode = yield select(selectShopCode);
  const shopSettings = yield select(selectShopPreferences);
  const { content: storePickupContent = {} } = yield select(selectStorePickup);
  const serviceRequest = (params) => request("save-shop-settings", params);
  const updateAutoPrintServiceRequest = (params) =>
    request("update-auto-print-orders", params);

  if (isAutoPrintToggle) {
    try {
      const currentTime = moment().utc().format();
      yield call(updateAutoPrintServiceRequest, {
        payload: [
          {
            memberCode: shopCode,
            lastSyncTime: currentTime,
            autoPrintOrders: [],
          },
        ],
      });
      yield put(
        setAutoPrintOrders({
          timeStamp: currentTime,
          initialTimeStamp: currentTime,
          orders: [],
        })
      );
    } catch (error) {
      console.log("Error - ", error);
    }
  }
  try {
    const is_service_bureau_member =
      other.preferences[0].id === "is_service_bureau_member" &&
      other.preferences[0].values[0] === "true";

    if (is_service_bureau_member) {
      // turn off auto-wiring when service bureau is on.
      other.preferences.push({
        id: "enable_auto_wiring",
        values: ["false"],
      });

      const { localPickup = "" } = storePickupContent;
      // turn off localpickup when service bureau is on.
      if (localPickup === "Active") {
        yield put(
          saveStorePickup({
            params: { ...storePickupContent, localPickup: "Inactive" },
          })
        );
      }
    }

    const res = yield call(serviceRequest, {
      ...other,
      memberCode: shopCode,
    });
    if (res && res.preferences && res.preferences.length) {
      let result = processResponse(res);

      yield put(
        setApiResponse({
          path: "shopPreferences",
          content: { ...shopSettings, ...result },
        })
      );

      if (updateProfileStorage) {
        const preferences = UserProfileStorage.getShopPreferences(shopCode);
        UserProfileStorage.setShopPreferences(shopCode, {
          ...preferences,
          ...result,
        });
      }
      const { preferences = [] } = payload;

      if (preferences[0].id === "card_settings") {
        yield put(fetchLogoImages({ memberCode: shopCode }));
      }

      resolve();
    }
  } catch (error) {
    reject(error);
  }
}

function* handleAllShopsUpdateShopSettings(action = {}) {
  const {
    payload,
    shopCodes,
    updateProfileStorage,
    resolve = () => {},
    reject = () => {},
  } = get(action, "payload", {});
  const shopCode = yield select(selectShopCode);
  const shopSettings = yield select(selectShopPreferences);
  const serviceRequest = (params) => request("save-shop-settings", params);
  try {
    const resp = yield all(
      shopCodes.map((eachShopCode) => {
        return call(serviceRequest, {
          ...payload,
          memberCode: eachShopCode,
        });
      })
    );
    const index = shopCodes.findIndex((code) => code === shopCode);
    if (
      resp[index] &&
      resp[index].preferences &&
      resp[index].preferences.length
    ) {
      const result = processResponse(resp[index]);

      yield put(
        setApiResponse({
          path: "shopPreferences",
          content: { ...shopSettings, ...result },
        })
      );
    }

    resp.map((res, i) => {
      if (res && res.preferences && res.preferences.length) {
        let result = processResponse(res);

        if (updateProfileStorage) {
          const preferences = UserProfileStorage.getShopPreferences(
            shopCodes[i]
          );
          UserProfileStorage.setShopPreferences(shopCodes[i], {
            ...preferences,
            ...result,
          });
        }
      }
    });
    resolve();
  } catch (error) {
    reject(error);
  }
}

function* handleFetchQuickBooksAuthorizationStatus() {
  const shopCode = yield select(selectShopCode);
  const permissions = AppSettingsStorage.getAllPermissions();
  const hasQuickBooksFeature = hasQuickBooksAccess(permissions, shopCode);

  if (hasQuickBooksFeature) {
    const quickBooksRequest = (params) =>
      request("get-quickBooks-authorization-status", params);
    try {
      const quickBooksResponse = yield call(quickBooksRequest, {
        memberCode: shopCode,
      });
      yield put(
        setQuickBooksAuthorizationStatus(quickBooksResponse.isAuthorized)
      );
    } catch (error) {
      console.log(error);
      yield put(
        setApiError({
          path: "quickBooksAuthorizationStatus",
          error: "Something went wrong, please try again",
        })
      );
    }
  }
}
function* handleFetchShopPaymentSettings() {
  const shopCode = yield select(selectShopCode);
  const shopSettingsRequest = (params) => request("get-shop-settings", params);

  try {
    const settingsResponse = yield call(shopSettingsRequest, {
      ids: [
        [
          "cash_or_check",
          "credit_card",
          "paid_elsewhere",
          "house_account_invoice",
          "payment_terminal",
          "gift_cards",
          "pay_later",
        ],
      ],
      memberCode: shopCode,
    });

    if (
      settingsResponse &&
      settingsResponse.preferences &&
      settingsResponse.preferences.length
    ) {
      const result = processResponse(settingsResponse);
      yield put(
        setApiResponse({
          path: "shopPreferences",
          content: result,
        })
      );
    }
  } catch (error) {
    yield put(
      setApiError({
        path: "shopPreferences",
        error: "Something went wrong, please try again",
      })
    );
  }
}

function* handleFetchDefaultNotifications() {
  const shopCode = yield select(selectShopCode);
  const shopSettings = yield select(selectShopPreferences);

  const defaultNotificationsRequest = (params) =>
    request("get-default-notifications", params);

  try {
    const response = yield call(defaultNotificationsRequest, {
      memberCode: shopCode,
    });

    if (response) {
      const result = processNotificationMessages(response, shopSettings);
      yield put(setNotificationSettings(result));
      yield put(setDefaultOLCNotifications(response));
    }
  } catch (error) {
    yield put(
      setApiError({
        path: "shopPreferences",
        error: "Something went wrong, please try again",
      })
    );
  }
}

function* handleFetchMarketingEmails(action = {}) {
  const params = get(action, "payload", "");
  const { marketingEmail, accountId, isFromEmail = false } = params;

  const shopCode = yield select(selectShopCode);

  const getEmailsRequest = (params) => request("get-marketing-emails", params);

  try {
    const response = yield call(getEmailsRequest, {
      memberCode: shopCode,
      email: marketingEmail,
      accountId,
    });

    const verificationStatus = response?.find(
      (x) => x?.email_address === marketingEmail
    )?.confirm_status;

    if (verificationStatus) {
      if (isFromEmail) {
        yield put(setFromEmailVerificationStatus(verificationStatus));
      } else {
        yield put(setReplyEmailVerificationStatus(verificationStatus));
      }
    }
  } catch (error) {
    yield put(
      setApiError({
        path: "emailVerificationStatusResponse",
        error: "Something went wrong, please try again",
      })
    );
  }
}

function* handleAddMarketingEmail(action = {}) {
  const params = get(action, "payload", "");
  const { email, accountId, isFromEmail = false } = params;

  const addMarketingEmailRequest = (params) =>
    request("add-marketing-email", params);

  try {
    const response = yield call(addMarketingEmailRequest, {
      email,
      accountId,
    });

    if (response && !isFromEmail) {
      yield put(setMarketingEmail(response?.email_address));
    }
  } catch (error) {
    yield put(
      setApiError({
        path: "addMarketingEmailError",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleFetchShopGroupMembers() {
  const memberRequest = (params) => request("get-florist-account", params);
  const shopCode = yield select(selectShopCode);

  try {
    const memberCodes = yield call(memberRequest, { memberCode: shopCode });
    const stores = processMemberCodeResponse(shopCode, memberCodes);
    const activeMembers = Object.keys(stores).filter(
      (key) => stores[key].status !== "INACTIVE"
    );
    yield put(saveActiveShopGroupMembers(activeMembers));

    let allStores = {};
    for (let key in stores) {
      const { status, ...rest } = stores[key];
      allStores[key] = rest;
    }
    yield put(saveShopGroupMembers(allStores));
    yield fork(fetchMemberExtendedInformation, allStores);
  } catch (error) {
    yield put(saveShopGroupMembers({}));
  }
}

function* fetchMemberExtendedInformation(stores) {
  const basicInfoRequest = (params) => request("get-shop-basic-info", params);
  try {
    const basicResponse = yield call(basicInfoRequest, {
      requestedMemberCodes: Object.keys(stores),
    });
    const shopGroups = Object.assign(Object.assign({}, stores), basicResponse);
    yield put(saveShopGroupMembers(shopGroups));
  } catch (error) {
    yield put(saveShopGroupMembers({}));
  }
}

const processMemberCodeResponse = (shopCode, memberCodes) => {
  const stores = {};
  const memberData = get(memberCodes, "mainMembers", []).find(
    (m) =>
      get(m, "member.memberCode", "") === shopCode ||
      get(m, "branchMembers", []).find(
        (m) => get(m, "memberCode", "") === shopCode
      )
  );

  const mainMember = get(memberData, "member", {});
  const branchMembers = get(memberData, "branchMembers", []);

  stores[mainMember.memberCode] = {
    memberCode: mainMember.memberCode,
    status: mainMember.status,
  };

  if (branchMembers.length > 0) {
    branchMembers.forEach((branch = {}) => {
      const { memberCode = "", status = "" } = branch;
      if (memberCode) stores[memberCode] = { memberCode, status };
    });
  }

  return stores;
};

export function* handleFetchLogoImages(action = {}) {
  const params = get(action, "payload", "");
  const { memberCode } = params;

  const { card_settings: { logoImageURL = "", bannerImageURL = "" } = {} } =
    yield select(selectShopPreferences);

  const serviceRequest = (params) => request("download-image", params);
  try {
    const data = yield all({
      logoInfo: logoImageURL
        ? call(serviceRequest, { memberCode, imageName: logoImageURL })
        : "",
      bannerInfo: bannerImageURL
        ? call(serviceRequest, { memberCode, imageName: bannerImageURL })
        : "",
    });

    let fileInfo = {};

    fileInfo = {
      logoInfo: {
        uri: data.logoInfo?.source
          ? `data:image/png;base64,${data.logoInfo?.source}`
          : "",
        name: logoImageURL || "",
      },
      bannerInfo: {
        uri: data.bannerInfo?.source
          ? `data:image/png;base64,${data.bannerInfo?.source}`
          : "",
        name: bannerImageURL || "",
      },
    };

    yield put(setShopLogoData(fileInfo));
  } catch (error) {
    setApiError({
      path: "shopPreferences",
      error: "Something went wrong, please try again",
    });
  }
}

function* handleLogoImageUpload(action = {}) {
  const { params, resolve, reject } = get(action, "payload", "");
  const { memberCode, values } = params;
  const { card_settings } = yield select(selectShopPreferences);

  const serviceRequest = (params) => request("upload-image", params);

  try {
    let cardSettings = { ...card_settings };
    const response = yield all(
      ["logoImageURL", "bannerImageURL"].map((val) => {
        const logoURL = values[val] || "";
        const image =
          val === "logoImageURL" ? values["logoName"] : values["bannerName"];
        return (
          logoURL &&
          call(serviceRequest, {
            memberCode,
            imageName: image,
            imageContent: logoURL.includes("data:image")
              ? logoURL.split(",")[1]
              : logoURL,
          })
        );
      })
    );

    response.forEach((res) => {
      const fileName = (res.source && res.source.split("%2F")[1]) || "";
      if (fileName === values["logoName"]) {
        cardSettings.logoImageURL = fileName;
      }
      if (fileName === values["bannerName"]) {
        cardSettings.bannerImageURL = fileName;
      }
    });
    let payload = {
      preferences: [
        {
          id: "card_settings",
          values: [JSON.stringify(cardSettings)],
        },
      ],
    };
    yield put(
      saveShopSettings({ payload, updateProfileStorage: true, resolve, reject })
    );
  } catch (error) {
    reject(error);
  }
}

function* handleGetTerminalActivationCodes(action = {}) {
  const {
    payload = {},
    resolve = () => {},
    reject = () => {},
  } = get(action, "payload", {});

  const getActivationCodesRequest = (params) =>
    request("get-terminal-activation-codes", params);

  try {
    const res = yield call(getActivationCodesRequest, payload);

    if (res && res?.tokens && res.tokens?.length) {
      resolve(res);
    } else {
      reject("Unable to get activation codes, please try again.");
    }
  } catch (error) {
    reject(error || "Unable to get activation codes, please try again.");
  }
}

function* handleSavePromoCode(action = {}) {
  const {
    params = {},
    resolve = () => {},
    reject = () => {},
  } = get(action, "payload", {});
  const serviceRequest = (params) => request("save-promo-code", params);
  try {
    const response = yield call(serviceRequest, params);
    resolve && resolve(response);
  } catch (error) {
    reject && reject(error);
  }
}

function* handleFetchPromoCodes(action = {}) {
  const {
    params = {},
    resolve = () => {},
    reject = () => {},
  } = get(action, "payload", {});
  const serviceRequest = (params) => request("get-promo-code", params);
  try {
    const response = yield call(serviceRequest, params);
    const promoCodes = common.groupByMemberCode(response);
    yield put(setPromoCodesList(promoCodes));
    resolve && resolve(response);
  } catch (error) {
    reject && reject(error);
  }
}

function* handleUpdatePromoCode(action = {}) {
  const {
    params = {},
    resolve = () => {},
    reject = () => {},
  } = get(action, "payload", {});
  const serviceRequest = (params) => request("update-promo-code", params);
  try {
    const response = yield call(serviceRequest, params);
    resolve && resolve(response);
  } catch (error) {
    reject && reject(error);
  }
}

function* handleDeletePromoCode(action = {}) {
  const {
    params = {},
    resolve = () => {},
    reject = () => {},
  } = get(action, "payload", {});
  const serviceRequest = (params) => request("delete-promo-code", params);
  try {
    const response = yield call(serviceRequest, params);
    resolve && resolve(response);
  } catch (error) {
    reject && reject(error);
  }
}

function* handleGetShopSettings(action = {}) {
  const {
    params: { memberCode, ids, updateStorage },
    resolve = () => {},
    reject = () => {},
  } = get(action, "payload", {});
  const shopSettingsRequest = (params) => request("get-shop-settings", params);

  try {
    const settingsResponse = yield call(shopSettingsRequest, {
      ids: uniq([...ids]),
      memberCode,
    });

    if (
      !(
        settingsResponse &&
        settingsResponse.preferences &&
        settingsResponse.preferences.length
      )
    )
      throw "error";

    const result = processResponse(settingsResponse);

    if (updateStorage) {
      // Updating the profile storage with latest results
      const preferences = UserProfileStorage.getShopPreferences(memberCode);
      UserProfileStorage.setShopPreferences(memberCode, {
        ...preferences,
        ...result,
      });
    }
    resolve && resolve();
  } catch {
    console.log("Error while fetching latest shop settings :>> ");
    reject && reject();
  }
}

/**
 * Will be using when integrating UI with backend
 */
export function* watchSaga() {
  yield takeLatest(setInit.type, handlePageIntialize);
  yield takeLatest(setActiveTab.type, handleNavigation);
  yield takeLatest(setSideCar.type, handleSetSidecarAction);
  yield takeLatest(fetchShopGroupMembers.type, handleFetchShopGroupMembers);
  yield takeLatest(fetchShopSettings.type, handleFetchShopSettings);
  yield takeLatest(
    fetchShopPaymentSettings.type,
    handleFetchShopPaymentSettings
  );
  yield takeLatest(
    fetchDefaultNotifications.type,
    handleFetchDefaultNotifications
  );

  yield takeEvery(fetchMarketingEmails.type, handleFetchMarketingEmails);
  yield takeLatest(addMarketingEmail.type, handleAddMarketingEmail);

  yield takeLatest(
    fetchQuickBooksAuthorizationStatus.type,
    handleFetchQuickBooksAuthorizationStatus
  );

  yield takeLatest(saveShopSettings.type, handleSaveShopSettings);
  yield takeEvery(
    allShopsUpdateShopSettings.type,
    handleAllShopsUpdateShopSettings
  );
  yield takeLatest(fetchShopHours.type, handleFetchShopHours);
  yield takeLatest(saveShopHours.type, handleSaveShopHours);
  yield takeEvery(fetchLogoImages.type, handleFetchLogoImages);
  yield takeEvery(saveLogoImages.type, handleLogoImageUpload);
  yield takeLatest(
    getTerminalActivationCodes.type,
    handleGetTerminalActivationCodes
  );
  yield takeLatest(savePromoCode.type, handleSavePromoCode);
  yield takeLatest(fetchPromoCodes.type, handleFetchPromoCodes);
  yield takeLatest(updatePromoCode.type, handleUpdatePromoCode);
  yield takeLatest(deletePromoCode.type, handleDeletePromoCode);
  yield takeLatest(getShopSettings.type, handleGetShopSettings);
}

export default watchSaga;
