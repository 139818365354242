import React, { memo, useContext, useEffect } from "react";
import { View, Text, ScrollView, Platform } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import { fonts } from "styles/theme";
import tw from "tailwind-rn";
import moment from "moment";

import UserProfileStorage from "library/storage/userProfile";
import {
  fetchScorecardRating,
  fetchScorecardComments,
} from "library/sagas/views/home/drawer/reports/scorecard/slice";
import { selectShopCode } from "library/sagas/views/home/drawer/reports/common/selector";
import {
  selectScorecardDate,
  selectScorecardRating,
  selectScorecardComments,
} from "library/sagas/views/home/drawer/reports/scorecard/selector";

import {
  AppSettingsContext,
  DeviceContext,
} from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { Entitlements } from "library/utils/entitlements";
import {
  getScorecardMonthOptions,
  getScorecardUpdateDate,
} from "library/utils/scorecard";
import { getUIConfig } from "./config";
import { REPORTS } from "library/constants";
import LabelWithHelpIcon from "components/wrappers/label-with-help-icon";

import CustomMonthPicker from "./month-picker";
import PrintScorecard from "./print-scorecard";
import Table from "./custom-table";

const Scorecard = () => {
  const dispatch = useDispatch();

  const shopNames = UserProfileStorage.getAllShopNames();
  const { permissions } = useContext(AppSettingsContext);
  const { messages, Localise } = useContext(I18NContext);
  const { isMobile } = useContext(DeviceContext);

  const shopCodes = Object.keys(permissions)
    .map(
      (memberCode) =>
        Entitlements.SCORECARD in permissions[memberCode] && memberCode
    )
    .filter(Boolean);
  const shopCode = useSelector(selectShopCode) || shopCodes[0];
  const statementDate = useSelector(selectScorecardDate);
  const ratingDetails = useSelector(selectScorecardRating);
  const commentsData = useSelector(selectScorecardComments);
  const scorecardUpdateDate = getScorecardUpdateDate();
  const scorecardInfoText = Localise(
    messages,
    REPORTS.SCORECARD_UPDATE(scorecardUpdateDate)
  );
  const firstMonthOption = getScorecardMonthOptions()[0]?.value;
  // allow only for first month
  const orderNumberClickable = moment(statementDate).isSame(
    firstMonthOption,
    "month"
  );
  const UIConfig = getUIConfig({
    statementDate,
    ratingDetails,
    commentsData,
    isMobile,
    orderNumberClickable,
  });

  useEffect(() => {
    if (!shopCode || !statementDate) return;
    dispatch(fetchScorecardRating());
    dispatch(fetchScorecardComments());
  }, [shopCode, statementDate]);

  return (
    <View>
      <View
        style={[tw("flex flex-row flex-wrap items-center justify-between")]}
      >
        <Text style={[fonts.heading2, tw("text-center")]}>
          {Localise(messages, "Fulfillment Scorecard")}
        </Text>
        <Text style={[fonts.heading3, tw("text-center my-4")]}>
          {shopCode} {shopNames[shopCode]}
        </Text>
        <View style={[tw("flex flex-row items-center justify-between")]}>
          <CustomMonthPicker />
          <PrintScorecard {...{ shopCode, statementDate, UIConfig }} />
        </View>
      </View>
      <View style={[tw("flex flex-row-reverse flex-wrap")]}>
        <LabelWithHelpIcon
          labelText={scorecardInfoText}
          tooltipText={isMobile ? "" : scorecardInfoText}
          labelStyles={{
            ...fonts.default,
            ...tw("mr-2"),
            fontSize: 12,
            fontWeight: "500",
          }}
          viewStyles={[tw("flex-row-reverse items-center")]}
          iconType="info"
          imageSize={15}
        />
      </View>

      {UIConfig.map((config, index) => {
        return (
          <View key={index}>
            <LabelWithHelpIcon
              labelText={Localise(messages, config.title)}
              tooltipText={isMobile ? "" : Localise(messages, config.info)}
              tooltipHeight={200}
              labelStyles={{ ...fonts.heading3, marginBottom: 5 }}
              iconType="info"
              imageSize={20}
            />
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={true}
              contentContainerStyle={{
                flexGrow: 1,
                width: Platform.OS === "web" ? "100%" : "auto",
                minWidth: 300,
              }}
            >
              <Table {...config} />
            </ScrollView>
          </View>
        );
      })}
    </View>
  );
};

export default memo(Scorecard);
