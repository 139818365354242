import React, { memo } from "react";
import { View } from "react-native";
import get from "lodash/get";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { Form, FormField } from "components/elements/forms";
import { SaveCancelButtons } from "components/wrappers";
import { InfoLabel, ToasterHandler } from "components/elements";

import { useSelector } from "react-redux";
import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";
import { isActiveOrder } from "library/utils/orderListing";
import { isDeliveryDatePast } from "library/utils/createOrder";
import { colors } from "styles/theme";

const OrderNotes = ({ index = 0, triggerAction }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const orderDetailResponse = useSelector(selectRecordData);
  const {
    status = "",
    hasDSFulfillmentError = false,
    hasFulfillmentError = false,
    notes: initialNotes = "",
    deliveryInfo: { deliveryDate },
  } = get(orderDetailResponse, `orderItems.${index}`, {});

  // Hide modify order action button in case order delivery date is older than 90 days
  const hideModifyOrderAction = isDeliveryDatePast(deliveryDate);

  const eligibleToEdit =
    (isActiveOrder(status) && !hideModifyOrderAction) ||
    hasDSFulfillmentError ||
    hasFulfillmentError;

  const toolTipText = `${Localise(
    messages,
    "Order Notes (Internal Use Only)"
  )}\n\n${Localise(
    messages,
    "Please note that any comments added here are for internal use only.  These notes are private, designed to assist your team in managing orders effectively.  Notes entered in this section will not be visible to customers or recipients and will not appear on any printed documents."
  )}`;

  const handleOnSubmit = (values, formikBag) => {
    const updatesObj = {
      orderNotes: {
        path: `orderItems.${index}.notes`,
        value: values.notes,
      },
    };

    triggerAction({
      action: "update-order-notes",
      params: updatesObj,
      resolve: () => {
        formikBag.setSubmitting(false);
        ToasterHandler(
          "success",
          `${Localise(messages, "Order Notes updated successfully.")}`
        );
      },
      reject: () => {
        formikBag.setSubmitting(false);
        ToasterHandler(
          "uh oh",
          Localise(messages, "Something went wrong. Please try again!")
        );
      },
    });
  };

  return (
    <View>
      <View style={tw("flex flex-row mb-2 mr-3 items-center justify-center")}>
        <InfoLabel
          toolTipProps={{
            showToolTip: true,
            toolTipText,
          }}
          iconProps={{
            iconStyle: { marginLeft: 7 },
            size: 18,
            iconPosition: "right",
          }}
          labelProps={{
            showLabel: true,
            labelText: "Order Notes",
            labelStyle: {
              fontWeight: "700",
              fontSize: 18,
              color: colors.primary,
            },
          }}
        />
      </View>
      <Form
        initialValues={{
          notes: initialNotes,
        }}
        onSubmit={(values, formikBag) => {
          if (formikBag.isSubmitting) return;

          handleOnSubmit(values, formikBag);
        }}
        render={() => (
          <View>
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="new-password"
              name={"notes"}
              containerStyle={{
                width: "100%",
                paddingLeft: 0,
              }}
              inputContainerStyle={{ backgroundColor: "white" }}
              placeholder={Localise(messages, "Enter Order Notes")}
              multiline={true}
              numberOfLines={7}
              maxNumberOfLines={7}
              spellCheck={true}
              isUpdateOnChange={true}
              maxLength={400}
              showRemainingChars
              editable={eligibleToEdit}
              grayedOutOnDisable
            />
            <SaveCancelButtons
              buttonTitle={Localise(messages, "Save")}
              cancelTestId="order_notes_cancel_btn"
              saveTestId="order_notes_save_btn"
            />
          </View>
        )}
      />
    </View>
  );
};

export default memo(OrderNotes);
