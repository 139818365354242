export const getInitialValues = (details) => {
  const initalValues = {
    customerType: undefined,
    firstName: undefined,
    lastName: undefined,
    businessName: undefined,
    phone: "",
    isAddressRequired: false,
    isEmailRequired: false,
    emailMarketingOptIn: true,
    emailOptIn: true,
    address: {
      addressLine1: undefined,
      addressLine2: undefined,
      country: "US",
      city: undefined,
      state: undefined,
      zipcode: undefined,
      addressType: "Billing",
      isPrimary: true,
    },
  };
  return {
    ...initalValues,
    ...details,
  };
};

export const createMenuOptions = [
  {
    key: "createOrder",
    label: "Create New Order",
    route: {
      name: "create-order",
      params: { type: "", action: "create" },
    },
  },
  {
    key: "createSubscription",
    label: "Create New Subscription",
    route: {
      name: "create-order",
      params: {
        type: "subscription",
        action: "create",
      },
    },
  },
];
