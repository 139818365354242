import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectAddressVerificationInfo = createSelector(
  selectState,
  ({ addressVerificationInfo }) => addressVerificationInfo
);

export const selectSelectedProducts = createSelector(
  selectState,
  ({ selectedproducts }) => selectedproducts
);

export const selectCurrentProduct = createSelector(
  selectState,
  ({ currentproduct }) => currentproduct
);

export const selectCurrentAddon = createSelector(
  selectState,
  ({ currentAddon }) => currentAddon
);

export const selectAvailableMembers = createSelector(
  selectState,
  ({ members }) => members
);

export const selectImages = createSelector(
  selectState,
  ({ imagesData }) => imagesData
);

export const selectDashboardShopCode = createSelector(
  selectState,
  ({ shopCode }) => shopCode
);

export const selectCreateSubscriptionShopCode = createSelector(
  selectState,
  ({ createSubscriptionShopCode }) => createSubscriptionShopCode
);

export const selectShowSessionInfo = createSelector(
  selectState,
  ({ showSessionInfo }) => showSessionInfo
);

export const selectShowSignatureModal = createSelector(
  selectState,
  ({ isShowSignatureModal }) => isShowSignatureModal
);

export const selectSignatureImage = createSelector(
  selectState,
  ({ signatureImage }) => signatureImage
);

export const selectCurrentPage = createSelector(
  selectState,
  ({ currentPage }) => currentPage
);

export const selectIsRouteSaveSuccess = createSelector(
  selectState,
  ({ isRouteSaveSuccess }) => isRouteSaveSuccess
);

export const selectIsRouteEditing = createSelector(
  selectState,
  ({ isRouteEditing }) => isRouteEditing
);

export const selectIsAutoFill = createSelector(
  selectState,
  ({ isAutoFill }) => isAutoFill
);

export const selectYOffsetScroll = createSelector(
  selectState,
  ({ YOffsetScroll }) => YOffsetScroll
);

export const selectIsBulkDSRequestUploadInProgress = createSelector(
  selectState,
  ({ isBulkDSRequestUploadInProgress }) => isBulkDSRequestUploadInProgress
);

export const selectDesignerInfo = createSelector(
  selectState,
  ({ designersInfo }) => designersInfo || []
);

export const selectIsQuickBooksSessionExpired = createSelector(
  selectState,
  ({ isQuickBooksSessionExpired }) => isQuickBooksSessionExpired
);

export const selectQrScanVariant = createSelector(
  selectState,
  ({ qrScanVariant }) => qrScanVariant
);

export const selectOpenQrScanner = createSelector(
  selectState,
  ({ openScanner }) => openScanner
);

export const selectLogoURLs = createSelector(
  selectState,
  ({ logoURLs }) => logoURLs
);

export const selectDeletCardModal = createSelector(
  selectState,
  ({ deleteCard }) => deleteCard
);

export const selectRecalcTaxAndFees = createSelector(
  selectState,
  ({ isRecalcTaxAndFees }) => isRecalcTaxAndFees
);

export const selectUserAction = createSelector(
  selectState,
  ({ isUserAction }) => isUserAction
);

export const selectUpsertDesignerStatus = createSelector(
  selectState,
  ({ upsertDesignerStatus }) => upsertDesignerStatus
);

export const selectDistanceInMiles = createSelector(
  selectState,
  ({ distanceInMiles }) => distanceInMiles
);

export const selectIsOrdersCallInProgress = createSelector(
  selectState,
  ({ isOrdersCallInProgress }) => isOrdersCallInProgress
);

export const selectIsOrderSearchApplied = createSelector(
  selectState,
  ({ isOrderSearchApplied }) => isOrderSearchApplied
);

export const selectIsOrderSplitCallInProgress = createSelector(
  selectState,
  ({ isOrderSplitCallInProgress }) => isOrderSplitCallInProgress
);

export const selectCanCallRetryFetchOrders = createSelector(
  selectState,
  ({ canCallRetryFetchOrders }) => canCallRetryFetchOrders
);

export const selectCurrentTimeMinus30Seconds = createSelector(
  selectState,
  ({ currentTimeMinus30Seconds }) => currentTimeMinus30Seconds
);

export const selectIsEmbeddedMode = createSelector(
  selectState,
  ({ layoutMode }) => layoutMode === "embedded"
);
