import React, { memo, useContext } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import LabelWithHelpIcon from "components/wrappers/label-with-help-icon";
import { useNavigation } from "@react-navigation/native";
import { useDispatch } from "react-redux";

import { createStyles, minWidth } from "react-native-media-queries";
import { backgroundColors, fonts } from "styles/theme";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { rowThreshold, rowHeight } from "../config";

const Rows = ({ data, columns }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  return (
    <>
      {data.map((item, rowIndex) => (
        <View key={rowIndex} style={styles.row}>
          {columns.map(
            (
              { key, style, clickable = false, onPress = () => {} },
              colIndex
            ) => {
              const displayText = (
                <Text numberOfLines={2}>{item[key] || "-"}</Text>
              );

              return (
                <View
                  key={key}
                  style={[
                    tw("flex flex-row items-center justify-center"),
                    styles.cell,
                    style,
                    colIndex !== columns.length - 1 && styles.cellBorder,
                  ]}
                >
                  {clickable && item[key] ? (
                    <TouchableOpacity
                      style={[fonts.link1]}
                      onPress={() => {
                        onPress({ navigation, dispatch, data: item });
                      }}
                      testID={`scorecard_${key}_${item[key]}`}
                    >
                      {displayText}
                    </TouchableOpacity>
                  ) : (
                    displayText
                  )}
                </View>
              );
            }
          )}
        </View>
      ))}
    </>
  );
};

const Table = ({ data, columns }) => {
  const { messages, Localise } = useContext(I18NContext);
  const maxTableHeight = rowThreshold * (rowHeight + 10) - 30; // Limit table height dynamically
  const hasScroll = data.length > rowThreshold;

  return (
    <View style={styles.container}>
      {/* Table Header */}
      <View
        style={[
          styles.row,
          styles.header,
          hasScroll ? { paddingRight: 15 } : {},
        ]}
      >
        {columns.map(({ name, key, style, tooltipText = "" }, colIndex) => (
          <View
            key={key}
            style={[
              tw("flex flex-row items-center justify-center"),
              styles.cell,
              style,
              colIndex !== columns.length - 1 && styles.cellBorder,
            ]}
          >
            <LabelWithHelpIcon
              labelText={Localise(messages, name)}
              tooltipText={Localise(messages, tooltipText)}
              labelStyles={{
                ...fonts.default,
                ...tw("mr-2"),
                fontSize: 14,
                fontWeight: "500",
                textAlign: "center",
                paddingLeft: 1,
              }}
              iconType="info"
            />
          </View>
        ))}
      </View>

      {/* Conditional ScrollView with Dynamic Height */}
      {hasScroll ? (
        <ScrollView style={[styles.scrollView, { maxHeight: maxTableHeight }]}>
          <Rows data={data} columns={columns} />
        </ScrollView>
      ) : (
        <Rows data={data} columns={columns} />
      )}
    </View>
  );
};

const styles = createStyles(
  {
    container: { borderWidth: 1, borderColor: "#ccc", marginBottom: 20 },
    scrollView: { flexGrow: 0 }, // Prevents unnecessary expansion
    row: {
      flexDirection: "row",
      padding: 10,
      borderBottomWidth: 1,
      borderColor: "#ccc",
      minHeight: rowHeight,
    },
    header: {
      backgroundColor: backgroundColors.tableHeader,
      fontWeight: "bold",
    },
    cell: { paddingVertical: 5, paddingHorizontal: 10 },
    cellBorder: { borderRightWidth: 1, borderColor: "#ccc" },
  },
  minWidth(767, { container: { width: "100%" } })
);

export default memo(Table);
