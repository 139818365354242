import moment from "moment";
import UserProfileStorage from "library/storage/userProfile";
import { Platform } from "react-native";

const getCSS = (preferredOrientation) => {
  return `@page {
        font-family: Arial, sans-serif;
        size: A4 ${preferredOrientation};
        margin: 20px;
        -webkit-margin-after: 50px;
    }
    .print-header {
        position: fixed;
        top: 0;
        left: 0;
        width: ${Platform.OS === "ios" ? "140%" : "96%"};
        background: white;
        text-align: center;
        margin: 0px 20px;
    }
    .print-header table {
        width: 100%;
    }
    .print-header th {
        font-size: 22px;
        padding: 5px;
    }
    .print-title {
        font-size: 25px;
        text-align: left;
        white-space: nowrap;
    }
    .shop-name {
        text-align: center;
        font-weight: bold;
    }
    .statement-month {
        text-align: right;
        white-space: nowrap;
    }
    .tableTitle {
        font-size: 20px;
        margin-bottom: 10px;
        break-after: avoid;
        page-break-after: avoid;
    }
    .tableHeaderCell {
        font-weight: bold;
        vertical-align: middle;
    }
    .tableCell {
        text-align: center;
        align-items: center;
        justify-content: center;
        border: 1px solid gray;
        font-family: Arial, sans-serif;
        padding: 0px 10px;
    }
    @media print {
        html, body {
            height: auto;
            -ms-overflow-style: none;
            font-family: Arial, sans-serif;
        }
        .print-header {
            position: fixed;
            top: 0;
            z-index: 5;
        }
        .printContainer {
            margin: 50px 10px 10px 10px;
            background-color: white;
            overflow:hidden;
            z-index: 10;
        }
        .table-responsive {
            min-height: 0.01%;
            overflow-x: auto;
        }
        .table {
            border-collapse: collapse;
            width: 100%;
            max-width: 100%;
            border-spacing: 2px;
        }
        thead {
            display: table-header-group;
        }
        tbody {
            display: table-row-group;
            border-color: inherit;
        }
        tr {
            text-align: left;
            display: table-row;
            vertical-align: top;
            border-color: inherit;
            break-inside: avoid;
            page-break-inside: avoid;
        }
        .table > tbody > tr > td,
        .table > tbody > tr > th,
        .table > tfoot > tr > td,
        .table > tfoot > tr > th,
        .table > thead > tr > td,
        .table > thead > tr > th {
            padding: 8px;
            line-height: 1.3;
        }

        /* Keep table header & first row together */
        thead, 
        thead tr,
        tbody tr:first-child {
            break-inside: avoid;
            page-break-inside: avoid;
        }
    }`;
};

export const getScorecardPrintHTML = ({
  iframeId,
  shopCode,
  statementDate,
  UIConfig,
  Localise,
  messages,
  preferredOrientation,
}) => {
  const shopNames = UserProfileStorage.getAllShopNames();
  const statementMonth = moment(statementDate).format("MMMM YYYY");
  const shopName = shopNames[shopCode] || "";
  const shopCodeName = `${shopCode} ${shopName}`;

  const printHeader = () => `<div class="print-header">
        <table>
            <tr>
                <th class="print-title">${Localise(
                  messages,
                  "Fulfillment Scorecard"
                )}</th>
                <th class="shop-name">${shopCodeName}</th>
                <th class="statement-month">${statementMonth}</th>
            </tr>
        </table>
    </div>`;

  const printContent = () => `<div class="printContainer">
    ${UIConfig.map((each, tableIndex) => {
      const { title, columns, data } = each;
      return `<div class="table-responsive">
        <table class="table">
            <thead>
                ${
                  tableIndex > 0
                    ? ` <tr><th><div style="padding-top:${
                        tableIndex > 0 ? "12px" : "0px"
                      };"></div></th></tr>`
                    : ""
                }
                <tr><th class="tableTitle tableHeaderCell" colspan="2">
                        <div>${Localise(messages, title)}</div>
                </th></tr>
                <tr>${columns
                  .map((eachCol) => {
                    const { name, printStyle: { width, textAlign } = {} } =
                      eachCol;
                    return `<th class="tableCell tableHeaderCell" style="width:${width}; text-align:${textAlign}">${Localise(
                      messages,
                      name
                    )}</th>`;
                  })
                  .join("")}
                </tr>
            </thead>
            <tbody>
            ${data
              .map((item) => {
                return `<tr>${columns
                  .map((eachCol) => {
                    const { key, printStyle: { width, textAlign } = {} } =
                      eachCol;
                    return `<td class="tableCell" style="width:${width}; text-align:${textAlign}">${
                      item[key] || "-"
                    }</td>`;
                  })
                  .join("")}
                </tr>`;
              })
              .join("")}
            </tbody>
        </table>
        </div>`;
    }).join("")}
    </div>`;

  return `<!DOCTYPE html>
    <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <title>Mercury HQ</title>
            <style type="text/css" media="print,screen">${getCSS(
              preferredOrientation
            )}</style>
        </head>
        <body onload="window.parent.postMessage({ action: '${iframeId}-loaded' }, '*');">
            ${printHeader()}
            ${printContent()}
        </body>
    </html>`;
};
