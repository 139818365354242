import React, { useState } from "react";
import { useSelector } from "react-redux";
import { View, Platform } from "react-native";

import { SideCar } from "components/wrappers";
import {
  Form,
  FormField,
  FormFieldPicker,
  FormFieldDatePicker,
  FormFieldAutoComplete,
} from "components/elements/forms";

import { CustomRTE } from "components/views/drawer/ftd-admin/mol-settings/helper";

import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { selectSideCarTitle } from "library/sagas/views/home/drawer/ftd-admin/member-settings/common/selector";
import { selectSideCarData } from "library/sagas/views/home/drawer/ftd-admin/member-settings/notifications/selector";

import { setSideCar } from "library/sagas/views/home/drawer/ftd-admin/member-settings/common/slice";
import { getUIConfig, notificationSenderTypes } from "./ui-config";

import { fonts } from "styles/theme";
import capitalize from "lodash/capitalize";
import toUpper from "lodash/toUpper";

import tw from "tailwind-rn";

const NotificationHistory = () => {
  return (
    <SideCar titleSelector={selectSideCarTitle} onCloseAction={setSideCar}>
      <View style={tw("flex flex-col")}>
        <Details />
      </View>
    </SideCar>
  );
};

const Details = () => {
  const { data = {} } = useSelector(selectSideCarData);
  const { isDesktop, isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  const [searchQuery, setSearchQuery] = useState("");

  const configData = {
    memberCodes: data.memberCodes,
    senderType: capitalize(data.type.toLowerCase()),
    startDate: data.availableDate,
    messageTitle: data.messagePayload.subject,
    messageBody: data.messageText,
  };
  const initialValues = getUIConfig(configData);

  return (
    <View>
      <Form
        initialValues={initialValues}
        // onSubmit={onSubmit}
        validateOnChange={true}
        // validationSchema={getValidationSchemaPrice(Localise, messages)}
        validateOnBlur={false}
        render={({ values, setFieldValue }) => {
          const {
            memberCodes = [],
            messageBody = "",
            contentStatus = false,
          } = values;

          const dropDownValues = data.memberCodes
            .filter((e) => toUpper(e).includes(toUpper(searchQuery)))
            .map((code) => {
              return {
                label: code,
                value: code,
              };
            });

          const selectedData = dropDownValues.filter((code) =>
            memberCodes.includes(code.value)
          );
          const unSelectedData = dropDownValues.filter(
            (code) => !memberCodes.includes(code.value)
          );
          const filteredDropDownData = [...selectedData, ...unSelectedData];
          return (
            <View style={[tw("flex flex-col")]}>
              <View
                style={[
                  tw("flex flex-row mt-5 flex-wrap justify-between"),
                  { paddingHorizontal: 9, zIndex: 200 },
                ]}
              >
                <FormFieldAutoComplete
                  name="memberCodes"
                  label={"Sent Member Codes"}
                  outerContainerStyle={{
                    zIndex: 10,
                    width: isMobile ? "100%" : 200,
                    paddingLeft: 0,
                  }}
                  labelStyle={fonts.heading4}
                  data={[...filteredDropDownData]}
                  placeholder={
                    memberCodes.length === 1
                      ? Localise(messages, memberCodes[0])
                      : memberCodes.length > 1
                      ? `${memberCodes.length} Shops Selected`
                      : Localise(messages, "Select Shop Codes")
                  }
                  initialDataLength={filteredDropDownData.length}
                  isMultiSelect={true}
                  disabled={true}
                  onSelect={() => {
                    setFieldValue("memberCodes", memberCodes);
                  }}
                  onBlur={() => setSearchQuery("")}
                  showOnFocus={true}
                  setFocusBack={true}
                  onChangeText={(val) => setSearchQuery(val)}
                  listDisplayValues={["label"]}
                />

                <FormFieldPicker
                  placeholder={{ label: "Select From", value: "" }}
                  containerStyle={{
                    width: isDesktop ? 200 : "100%",
                  }}
                  data={notificationSenderTypes}
                  name="senderType"
                  label="Category"
                  disabled={true}
                  defaultValue={
                    notificationSenderTypes.find(
                      (e) => e.value.toLowerCase() === data.type.toLowerCase()
                    ).value
                  }
                />
                <FormFieldDatePicker
                  dateValueFormat="YYYY-MM-DD"
                  name="startDate"
                  label={"Date"}
                  placeholder="MM/DD/YYYY"
                  containerStyle={{
                    zIndex: 120,
                    paddingLeft: 0,
                    marginLeft: -5,
                    marginTop: -5,
                    width: isMobile ? "100%" : 150,
                    maxWidth: 800,
                    height: Platform.OS !== "web" ? 40 : "auto",
                  }}
                  alignIconRight={true}
                  iconName="calendar"
                  iconType="font-awesome"
                  onValueChange={(val) => {}}
                  errorStyle={{ paddingBottom: 0 }}
                  disabled={true}
                />
              </View>
              <FormField
                name={"messageTitle"}
                label={"Message Preview"}
                labelStyle={fonts.heading4}
                placeholder={"Enter Message Preview"}
                containerStyle={{
                  width: "100%",
                  marginTop: isMobile ? 8 : 0,
                  paddingHorizontal: 9,
                  zIndex: -1,
                }}
                multiline={true}
                numberOfLines={2}
                editable={false}
              />
              <CustomRTE
                value={messageBody}
                placeholder={Localise(messages, "Enter Message")}
                styles={{ paddingHorizontal: 0 }}
                setValue={(val) => {
                  setFieldValue("messageBody", val);
                }}
                onEditorStateChange={() => {
                  if (contentStatus) return;
                  setFieldValue("contentStatus", true);
                }}
                readOnly={true}
              />

              {/* <SaveCancelButtons
                buttonTitle={Localise(messages, "Send")}
                onCancel={() => {}}
              /> */}
            </View>
          );
        }}
      />
    </View>
  );
};

export default NotificationHistory;
