import React, { useRef, useContext, useMemo, memo } from "react";
import { View, Text, Image, TouchableOpacity, Platform } from "react-native";
import { CheckBox } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";

import { setDesignersAreaEnabled } from "library/sagas/ongoing/current-orders/slice";
import {
  setActiveTab,
  saveShopSettings,
} from "library/sagas/views/home/drawer/shop-settings/common/slice";
import {
  saveAddonFeatureSettings,
  fetchFloristSubscrptionsList,
} from "library/sagas/views/home/drawer/shop-settings/addons/slice";
import {
  setActiveHelpTab,
  setActiveHelpSubTab,
} from "library/sagas/views/home/drawer/help/slice";
import {
  selectShopCode,
  selectActiveShopGroupMembers,
  selectShopPreferences,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { selectAddonsDetails } from "library/sagas/views/home/drawer/shop-settings/addons/selector";
import I18NContext from "library/contexts/i18N";
import {
  AppSettingsContext,
  DeviceContext,
} from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import PageStorage from "library/storage/pageStorage";

import { CustomModal, ToasterHandler, ImageLink } from "components/elements";
import { Form } from "components/elements/forms";
import { Currency, SaveCancelButtons } from "components/wrappers";
import IMAGES from "static/assets/images";

//utils
import useStateIfMounted from "library/utils/useStateIfMounted";
import * as Navigation from "library/utils/navigation.js";
import reloadUserPermissions from "library/utils/userPermissions";
import { getCostInCurrency } from "library/utils/formatter";
import { isPremiumMember } from "library/utils/entitlements";

//constants(messages)
import { ERROR_MESSAGES } from "library/constants";

import { colors, fonts, theme, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";
import get from "lodash/get";
import startCase from "lodash/startCase";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import moment from "moment";

import {
  roles,
  planTypes,
  instructionsConfig,
  settingsNavigationRoute,
  customHelpDocNames,
  featureNames,
} from "./ui-config";
import {
  addonSettingsEligibleRoles,
  defaultAddonsEligibleRoles,
} from "components/views/drawer/shop-settings/addons/ui-config";
import { Template } from "components/views/drawer/help/common-questions/input";

//components
import FeatureToggle from "./featureToggle";
import SubscriptionDates from "./subscriptiondates";
import { resetOccasionReminderSettings } from "../../helper";

const AddonComponent = ({ featureDetails = {}, shopCodes = [] }) => {
  const dispatch = useDispatch();
  const AddonFeatureFormRef = useRef();

  const shopCode = useSelector(selectShopCode);
  const addonsDetails = useSelector(selectAddonsDetails);
  const shopSettings = useSelector(selectShopPreferences);
  const activeShopGroupMembers =
    useSelector(selectActiveShopGroupMembers) || shopCodes;

  const { Localise, messages } = useContext(I18NContext);
  const { isDesktop, isMobile } = useContext(DeviceContext);
  const { userProfile } = useContext(UserProfileContext);
  const { setPermissions = () => {} } = useContext(AppSettingsContext);

  const userRoles = get(userProfile, "authGroups.0.roles", []);
  const settingsEditable = userRoles.some((role) =>
    addonSettingsEligibleRoles.includes(startCase(role.toLowerCase()))
  );
  const {
    name: featureName = "",
    displayName = "",
    description = "",
    costs = [],
    plan = "MONTHLY",
    type = "ADDON",
    additionalInformation: { roles: featureEligibleRoles = [] } = {},
  } = featureDetails;
  const settingsDetails =
    addonsDetails.find((each) => each.name === featureName) || {};

  const {
    startDate = "",
    endDate = "",
    enabled: featureTurnedOn = false,
    additionalInformation: { roles: enabledRoles = [] } = {},
  } = settingsDetails;
  const featurePricing = getCostInCurrency({ Currency, costs, shopCode });
  const packageFeature =
    isPremiumMember("all") &&
    ![featureNames.ADV_ROUTE_OPTIMISE].includes(featureName);

  const hasFeatureAccess = useMemo(
    () => featureTurnedOn || packageFeature,
    [featureTurnedOn, packageFeature]
  );

  const [featureEnabled, setFeatureEnabled] =
    useStateIfMounted(hasFeatureAccess);
  const [featureConfirmModal, setFeatureConfirmModal] = useStateIfMounted({
    message: "",
    showModal: false,
  });
  const [showReLoginModal, setShowReLoginModal] = useStateIfMounted(false);
  const [showFeatureLoader, setShowFeatureLoader] = useStateIfMounted(false);
  const featureEnabledInCurrentMonth =
    featureEnabled &&
    ![featureNames.ADV_ROUTE_OPTIMISE].includes(featureName) && //Allow to turn off AdvanceRouteOptimise feature in same month
    moment(moment.utc(startDate).toDate()).isSame(moment(), "month");

  const betaImageDisplayFeatures = ["QuickBooks Integration"];

  const shouldShowBetaImage = betaImageDisplayFeatures.includes(displayName);

  const modals = (
    <>
      {featureConfirmModal.showModal && (
        <CustomModal
          modalVisible={featureConfirmModal.showModal}
          modalContent={{
            content: (
              <Text
                style={{
                  ...tw("p-4"),
                  fontSize: 15,
                  color: colors.highlighter,
                }}
              >
                {Localise(messages, featureConfirmModal.message)}
              </Text>
            ),
            buttons: [
              { type: "secondary", title: Localise(messages, "Cancel") },
              { type: "primary", title: Localise(messages, "Confirm") },
            ],
          }}
          primaryhandler={() => {
            setShowFeatureLoader(true);
            saveAddonSettings({
              values: {
                ...featureConfirmModal.values,
                eligibleRoles: defaultAddonsEligibleRoles,
              },
              resolve: () => {
                setShowFeatureLoader(false);
                setShowReLoginModal(true); // show Relogin popup for all roles

                if ([featureNames.DESIGN_CENTER].includes(featureName)) {
                  PageStorage.setOrdersPageData({
                    ...(PageStorage.getOrdersPageActions() || {}),
                    isDesignCenterEnabled: false,
                    actions: {},
                    designCenterSelections: {},
                    viewMoreCounts: {},
                  });
                  dispatch(setDesignersAreaEnabled(false));
                } else if (
                  featureName === featureNames.OCCASION_REMINDER &&
                  !featureEnabled
                ) {
                  //Reset occasion reminder settings on TurnOff.
                  const updatedSettings =
                    resetOccasionReminderSettings(shopSettings);
                  dispatch(
                    saveShopSettings({
                      payload: { preferences: updatedSettings },
                    })
                  );
                }

                const toasterMessage = `The ${displayName} ${Localise(
                  messages,
                  `add-on feature has been successfully turned ${
                    featureEnabled ? "on" : "off"
                  }`
                )}`;
                ToasterHandler("success", toasterMessage);
              },
              reject: () => {
                setFeatureEnabled(!featureEnabled);
                setShowFeatureLoader(false);
              },
            });
            setFeatureConfirmModal({
              values: {},
              showModal: false,
              message: "",
            });
          }}
          secondaryhandler={() => {
            setFeatureEnabled(!featureEnabled);
            setFeatureConfirmModal({
              values: {},
              showModal: false,
              message: "",
            });
          }}
          contentStyle={[
            tw("border border-black p-2"),
            {
              backgroundColor: backgroundColors.secondary,
              textAlign: "center",
            },
          ]}
          modalStyle={
            Platform.OS !== "web"
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  backgroundColor: "#00000070",
                  color: "#FFFFFF",
                }
              : {
                  ...(featureConfirmModal.styles || {}),
                }
          }
        />
      )}
      {showReLoginModal && (
        <CustomModal
          modalVisible={showReLoginModal}
          modalContent={{
            content: (
              <Text
                style={{
                  ...tw("p-4"),
                  fontSize: 15,
                  color: colors.highlighter,
                }}
              >
                {Localise(
                  messages,
                  `Please log in again, and inform employees that they should log out and back in for the changes to take effect.`
                )}
              </Text>
            ),
            buttons: [{ type: "primary", title: Localise(messages, "OK") }],
          }}
          primaryhandler={() => {
            setShowReLoginModal(false);
          }}
          contentStyle={[
            tw("border border-black p-2"),
            {
              backgroundColor: backgroundColors.secondary,
              textAlign: "center",
            },
          ]}
          modalStyle={
            Platform.OS !== "web"
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  backgroundColor: "#00000070",
                  color: "#FFFFFF",
                }
              : {
                  width: "35%",
                }
          }
        />
      )}
    </>
  );

  const getReqPayload = (values = {}) => {
    const { applyForAllShops } = values;

    return {
      name: featureName,
      enabled: featureEnabled,
      memberCodes: applyForAllShops ? [...activeShopGroupMembers] : [shopCode],
      startDate: featureTurnedOn ? startDate : moment().utc().toISOString(), // current UTC dateTime
      endDate: featureEnabled ? undefined : moment().utc().toISOString(),
      additionalInformation: {
        roles: featureEnabled
          ? get(values, "eligibleRoles", defaultAddonsEligibleRoles)
          : defaultAddonsEligibleRoles,
      },
      type,
    };
  };

  const saveAddonSettings = ({
    values = {},
    resolve = () => {},
    reject = () => {},
  }) => {
    dispatch(
      saveAddonFeatureSettings({
        reqPayload: getReqPayload(values),
        resolve: () => {
          reloadUserPermissions({
            refreshEntitlementsCache: false,
            cacheControl: "refresh",
            shopCodes: values.applyForAllShops
              ? [...activeShopGroupMembers]
              : [shopCode],
            resolve: (updatedPermissions) => {
              setPermissions(updatedPermissions);
              dispatch(
                fetchFloristSubscrptionsList({
                  reject: () => {
                    ToasterHandler(
                      "uh oh",
                      Localise(messages, ERROR_MESSAGES.GENERAL_ERROR)
                    );
                  },
                })
              );
              resolve();
            },
          });
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, ERROR_MESSAGES.GENERAL_ERROR)
          );
          reject();
        },
      })
    );
  };

  const formSubmit = (values = {}, formikBag) => {
    saveAddonSettings({
      values,
      resolve: () => {
        formikBag?.setSubmitting(false);
        setShowReLoginModal(true);
        ToasterHandler(
          "success",
          `The ${displayName} ${Localise(
            messages,
            `add-on feature permission settings have been saved.`
          )}`
        );
      },
      reject: () => {
        formikBag?.setSubmitting(false);
        formikBag?.resetForm();
      },
    });
  };

  return (
    <View
      key={`${displayName}`}
      style={{
        marginVertical: 15,
        borderColor: colors.grayScaleLight,
        borderWidth: 1,
        borderRadius: 3,
        paddingVertical: 10,
        paddingHorizontal: isMobile ? 10 : 20,
        flex: 1,
      }}
    >
      {modals}
      <Form
        initialValues={{
          eligibleRoles: uniq(
            sortBy([...defaultAddonsEligibleRoles, ...enabledRoles])
          ),
          applyForAllShops: true,
        }}
        innerRef={AddonFeatureFormRef}
        onSubmit={(values, formikBag) => formSubmit(values, formikBag)}
        enableReinitialize={true}
        render={({ values, setFieldValue, dirty }) => {
          const { eligibleRoles = [] } = values;
          const showAdditionalSettings =
            [
              featureNames.QR_CODE_SCAN,
              featureNames.QUICK_BOOKS,
              featureNames.OCCASION_REMINDER,
            ].includes(featureName) &&
            hasFeatureAccess &&
            featureEnabled;

          return (
            <View
              style={[
                tw(`flex flex-col justify-between mt-2`),
                {
                  flex: 1,
                  ...(Platform.OS == "web" && !settingsEditable
                    ? { cursor: "not-allowed" }
                    : {}),
                },
              ]}
            >
              <View>
                <View
                  style={[
                    tw(
                      "flex flex-row flex-wrap items-center justify-between mt-1"
                    ),
                  ]}
                >
                  <View style={{ flex: 1 }}>
                    {shouldShowBetaImage ? (
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Text style={{ ...fonts.heading1, fontSize: 15 }}>
                          {Localise(messages, `${displayName}`)}
                        </Text>
                        <Image
                          style={{ width: 25, height: 15, marginLeft: 10 }}
                          resizeMode="cover"
                          source={IMAGES["beta-label"]}
                        />
                      </View>
                    ) : (
                      <Text style={{ ...fonts.heading1, fontSize: 15 }}>
                        {Localise(messages, `${displayName}`)}
                      </Text>
                    )}
                  </View>

                  <View
                    style={[
                      tw(`flex flex-row items-center justify-end flex-wrap`),
                      {
                        ...(isMobile
                          ? { flex: showAdditionalSettings ? 1 : 0.3 }
                          : { flex: 0.4 }),
                      },
                    ]}
                  >
                    {/* Additional Settings Link */}
                    {showAdditionalSettings ? (
                      <ImageLink
                        {...{
                          imageName: "settings-new",
                          tooltipMsg: "Additional Settings",
                          onPress: () => {
                            const {
                              page,
                              screenRoute,
                              params = {},
                            } = settingsNavigationRoute[featureName];
                            dispatch(setActiveTab(screenRoute));
                            Navigation.navigate(page, {
                              screen: screenRoute,
                              params,
                            });
                          },
                          testID: `additional_Settings_${displayName}_addon`,
                          accessibilityLabel: `additional_Settings_${displayName}_addon`,
                        }}
                      />
                    ) : null}

                    {/* Help Link */}
                    {!isMobile ? (
                      <ImageLink
                        {...{
                          imageName: "help",
                          tooltipMsg: "Help",
                          navigationRoute: {
                            page: "help",
                            params: {
                              search:
                                customHelpDocNames[featureName] || featureName,
                            },
                          },
                          onPress: () => {
                            dispatch(setActiveHelpTab("training-materials"));
                            dispatch(setActiveHelpSubTab("mhq-sub-tab"));
                          },
                          testID: `${displayName}_help_link`,
                          accessibilityLabel: `${displayName}_help_link`,
                        }}
                      />
                    ) : null}
                  </View>
                </View>

                <Text style={[tw("text-justify"), { marginTop: 10 }]}>
                  {Localise(messages, `${description}`)}
                </Text>

                {packageFeature ? (
                  <View>
                    <Text style={[tw("my-3")]}>
                      *{" "}
                      {Localise(
                        messages,
                        `Included with Premium (no additional charge)`
                      )}
                    </Text>
                  </View>
                ) : (
                  <View
                    style={[
                      tw(
                        `flex ${
                          isMobile
                            ? "flex-col items-start"
                            : "flex-row items-center"
                        } flex-wrap mt-2`
                      ),
                    ]}
                  >
                    {/* Feature On/Off */}
                    <FeatureToggle
                      {...{
                        featureName,
                        settingsEditable,
                        featureEnabled,
                        setFeatureEnabled,
                        featureEnabledInCurrentMonth,
                        displayName,
                        featurePricing,
                        setFeatureConfirmModal,
                        showFeatureLoader,
                      }}
                    />
                    <Text style={[tw("mr-5 my-2")]}>
                      {Localise(messages, "Pricing")}: {featurePricing}{" "}
                      {Localise(
                        messages,
                        `per ${planTypes(plan, featureName)}`
                      )}
                      {activeShopGroupMembers?.length > 1
                        ? ` (${Localise(
                            messages,
                            `Includes access for all branch locations.`
                          )})`
                        : ``}
                    </Text>
                    <SubscriptionDates
                      {...{
                        featureEnabled,
                        startDate,
                        endDate,
                      }}
                    />
                  </View>
                )}

                {/* Access Roles */}
                {!!featureEnabled && (
                  <View
                    style={[
                      tw("mt-2"),
                      {
                        borderColor: colors.grayScaleLight,
                        borderWidth: 1,
                        borderRadius: 3,
                        padding: 10,
                      },
                    ]}
                  >
                    <Text style={[fonts.heading5, tw("mb-4")]}>
                      {Localise(messages, "Who has access to this feature?")}
                    </Text>

                    <View
                      style={[
                        tw("flex flex-row flex-1 items-start pb-2 flex-wrap"),
                      ]}
                    >
                      {roles
                        .filter(({ label }) =>
                          featureEligibleRoles.includes(label)
                        )
                        .map(({ label, key, editable = true }, index) => {
                          const isChecked =
                            packageFeature ||
                            eligibleRoles.includes(key) ||
                            defaultAddonsEligibleRoles.includes(key);
                          const isEditable = editable && settingsEditable;
                          return (
                            <View
                              key={`${key}_${index}`}
                              style={[
                                { marginBottom: 10 },
                                Platform.OS == "web" &&
                                (!isEditable || packageFeature)
                                  ? { cursor: "not-allowed" }
                                  : {},
                                isMobile ? { width: "100%" } : {},
                              ]}
                            >
                              <CheckBox
                                key={`${key}_${displayName}`}
                                testID={`${key}_${displayName}`}
                                accessibilityLabel={`${key}_${displayName}`}
                                Component={TouchableOpacity}
                                checked={isChecked}
                                disabled={!isEditable || packageFeature}
                                checkedIcon={
                                  <Image
                                    source={IMAGES["checkbox-checked"]}
                                    style={{ width: 20, height: 20 }}
                                  />
                                }
                                uncheckedIcon={
                                  <Image
                                    source={IMAGES["checkbox-unchecked"]}
                                    style={{ width: 20, height: 20 }}
                                  />
                                }
                                containerStyle={[
                                  theme.CheckBox.inputContainerStyle,
                                ]}
                                iconRight={false}
                                onPress={(props) => {
                                  let updatedRoles = [...eligibleRoles];
                                  if (isChecked) {
                                    updatedRoles = eligibleRoles.filter(
                                      (role) => role !== key
                                    );
                                  } else {
                                    updatedRoles.push(key);
                                  }
                                  setFieldValue(
                                    "eligibleRoles",
                                    uniq(sortBy(updatedRoles))
                                  );
                                }}
                                size={20}
                                textStyle={[
                                  theme.CheckBox.textStyle,
                                  tw("mr-6"),
                                ]}
                                title={Localise(messages, label)}
                              />
                            </View>
                          );
                        })}
                    </View>

                    {/* Save & Cancel Buttons */}
                    {featureEnabled && dirty ? (
                      <SaveCancelButtons
                        buttonTitle={Localise(messages, "Save")}
                        disableOnDirty={true}
                        saveTestId={`${displayName}_Save`}
                        cancelTestId={`${displayName}_Cancel`}
                      />
                    ) : null}
                  </View>
                )}
              </View>

              {/* Apply to All Shops */}
              {activeShopGroupMembers?.length > 1 ? (
                <View
                  style={[
                    tw("flex flex-row items-center mt-4 mb-2"),
                    { width: 200 },
                  ]}
                >
                  <Text style={[]}>
                    * {Localise(messages, "Applied to all branches.")}
                  </Text>
                </View>
              ) : null}
            </View>
          );
        }}
      />

      {/* Additonal Instructions */}
      {hasFeatureAccess ? (
        <Template
          isDesktop={isDesktop}
          childContent={instructionsConfig(featureName)}
          style={{ paddingLeft: 0 }}
          listType={"numberedList"}
        />
      ) : null}
    </View>
  );
};

export default memo(AddonComponent);
