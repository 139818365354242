import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  status: "progress",
  apiResponse: {},
  isRefund: "",
  isCancel: "",
  qrCode: "",
  orderDetailsFromScan: false,
  orderDetailsFromBulkScan: false,
  showOrderUnpaidModal: false,
  showRecipe: false,
  showPrint: false,
  error: "",
  orderActions: [],
  refundPreview: {},
  refundPreviewStatus: "",
  isSideCarOpen: false,
  routeDetails: {},
  orderViewersInfo: [],
  currentViewerInfo: {},
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setOrderData: (state, action) => {
      state.apiResponse = action.payload;
    },
    setPageInitialize: (state) => {
      state.status = "progress";
      state.apiResponse = initialState.apiResponse;
      state.error = "";
    },
    setPageInitSuccess: (state) => {
      state.status = "done";
      state.error = "";
    },
    setPageInitProgress: (state) => {
      state.status = "progress";
    },
    setPageInitFailure: (state) => {
      state.status = "fail";
    },
    setPageReset: (state) => {
      Object.assign(state, initialState);
    },
    setApiResponse: (state, action) => {
      state.apiResponse = action.payload;
      if (state.orderDetailsFromBulkScan) {
        state.orderDetailsFromBulkScan = !state.orderDetailsFromBulkScan;
      } else {
        state.showRecipe = initialState.showRecipe;
      }
    },
    setCancelFormOnRefund: (state, action) => {
      state.isRefund = action.payload;
    },
    setRefundFormOnCancel: (state, action) => {
      state.isCancel = action.payload;
    },
    setShowOrderUnpaidModal: (state, action) => {
      state.showOrderUnpaidModal = action.payload;
    },
    setApiError: (state, action) => {
      state.status = "fail";
      state.error = action.payload;
    },
    setQrCode: (state, action) => {
      state.qrCode = { ...state.qrCode, ...action.payload };
    },
    setOrderDetailsFromScan: (state, action) => {
      state.orderDetailsFromScan = action.payload;
    },
    setShowRecipe: (state, action) => {
      state.showRecipe = action.payload;
    },
    setShowPrint: (state, action) => {
      state.showPrint = action.payload;
    },
    setOrderActions: (state, action) => {
      state.orderActions = action.payload;
    },
    setOrderDetailsFromBulkScan: (state, action) => {
      state.orderDetailsFromBulkScan = action.payload;
    },
    setRefundPreview: (state, action) => {
      state.refundPreview = action.payload;
    },
    resetRefundPreview: (state) => {
      state.refundPreview = initialState.refundPreview;
      state.refundPreviewStatus = initialState.refundPreviewStatus;
    },
    setRefundPreviewInitProgress: (state) => {
      state.refundPreviewStatus = "progress";
    },
    setRefundPreviewInitSuccess: (state) => {
      state.refundPreviewStatus = "";
    },
    setOrderHeadHeight: (state, action) => {
      state.orderHeadHeight = action.payload;
    },
    setCanEditOrder: (state, action) => {
      state.canEditOrder = action.payload;
    },
    setIsSidecarOpen: (state, action) => {
      state.isSideCarOpen = action.payload;
    },
    setRouteDetails: (state, action) => {
      state.routeDetails = action.payload;
    },
    setOrderViewersInfo: (state, action) => {
      state.orderViewersInfo = action.payload;
    },
    setCurrentViewerInfo: (state, action) => {
      state.currentViewerInfo = action.payload;
    },

    // noop - only actions
    fetchOrderData: () => {},
    fetchAllOrdersData: () => {},
    generateQRCode: () => {},
    openOrderFromScan: () => {},
    lockOrder: () => {},
    lockStatus: () => {},
    saveOrderViewerInfo: () => {},
    updateOrderViewerInfo: () => {},
    fetchRefundPreview: () => {},
    modifyOrder: () => {},
    fetchRouteDetails: () => {},
    fetchOrderViewersInfo: () => {},
  },
});

export const {
  setPageInitialize,
  setPageInitSuccess,
  setPageInitProgress,
  setPageInitFailure,
  setPageReset,
  setApiResponse,
  setCancelFormOnRefund,
  setRefundFormOnCancel,
  setShowOrderUnpaidModal,
  setApiError,
  fetchOrderData,
  fetchAllOrdersData,
  generateQRCode,
  setOrderData,
  setQrCode,
  setOrderDetailsFromScan,
  setOrderDetailsFromBulkScan,
  setShowRecipe,
  setShowPrint,
  openOrderFromScan,
  setOrderActions,
  lockOrder,
  lockStatus,
  saveOrderViewerInfo,
  updateOrderViewerInfo,
  fetchOrderViewersInfo,
  fetchRefundPreview,
  setRefundPreview,
  resetRefundPreview,
  setRefundPreviewInitProgress,
  setRefundPreviewInitSuccess,
  setOrderHeadHeight,
  modifyOrder,
  setCanEditOrder,
  setIsSidecarOpen,
  fetchRouteDetails,
  setRouteDetails,
  setOrderViewersInfo,
  setCurrentViewerInfo,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
