export const imageLibraryOptions = {
  selectionLimit: 1,
  mediaType: "photo",
  includeBase64: true,
  maxWidth: 750,
  maxHeight: 750,
  quality: 0.8,
};
export const cameraOptions = {
  saveToPhotos: false,
  mediaType: "photo",
  includeBase64: true,
  maxWidth: 750,
  maxHeight: 750,
  quality: 0.8,
};
export const actionSheetOptions = {
  options: ["Camera", "Library", "Cancel"],
  cancelButtonIndex: 2,
  title: "Choose An Action",
};

export const maxImageSize = 3000000; // 3MB
