import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "react-native";
import { Text } from "react-native-elements";

import {
  Form,
  FormFieldSwitch,
  FormFieldPicker,
} from "components/elements/forms";
import { ToasterHandler, Spinner } from "components/elements";
import { SaveCancelButtons } from "components/wrappers";
import I18NContext from "library/contexts/i18N";
import { selectShopPreferences } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { selectStorePickup } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/selector";
import { saveStorePickup } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/slice";
import { DeviceContext } from "library/contexts/appSettings";

import { fonts } from "styles/theme";
import tw from "tailwind-rn";

import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import { getUIConfig } from "./ui-config";
import { getValidationSchema } from "./yup";

const StorePickup = ({ showStorePickupLocations = false }) => {
  const dispatch = useDispatch();
  const { pickup_locations = [] } = useSelector(selectShopPreferences);
  const { content: initialValues } = useSelector(selectStorePickup);
  const { messages, Localise } = React.useContext(I18NContext);
  const { isMobile } = React.useContext(DeviceContext);

  const UIConfig = getUIConfig(Localise, messages);
  const validationSchema = getValidationSchema(Localise, messages);

  const PickupLoader = () => {
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );
  };

  if (isEmpty(initialValues)) {
    return <PickupLoader />;
  }

  const onSubmit = (values, formikBag) => {
    dispatch(
      saveStorePickup({
        params: values,
        resolve: () => {
          ToasterHandler(
            "excellent",
            Localise(messages, "Store Pickup has been updated")
          );

          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      render={({ values, setFieldValue, setFieldError, errors }) => {
        const stores = values?.stores ?? {};
        const isLoadingBasicInfo = values?.fetchStores ?? false;
        const isGlobalPickupActive = values["localPickup"] === "Active";
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (
            !isLoadingBasicInfo &&
            !Object.keys(stores).filter((code) => stores[code].status).length &&
            isGlobalPickupActive
          ) {
            setFieldError(
              "minimumStores",
              Localise(messages, "Make at least one store available for pickup")
            );
          }
        }, [stores]);

        return isLoadingBasicInfo ? (
          <PickupLoader />
        ) : (
          <>
            {UIConfig.map(({ type, name, title, options }, index) => {
              const isSwitchActive = values[name] === "Active";

              return (
                <React.Fragment key={index}>
                  {type ? (
                    <View
                      key={index}
                      style={tw(
                        `flex flex-row justify-start items-center mb-6`
                      )}
                    >
                      {type === "switch" ? (
                        <>
                          <Text style={{ width: 100, ...fonts.heading5 }}>
                            {title}
                          </Text>
                          <View style={{ width: isMobile ? "40%" : "20%" }}>
                            <View
                              style={{
                                paddingLeft: 5,
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <FormFieldSwitch
                                value={isSwitchActive}
                                onValueChange={(val) => {
                                  setFieldValue(
                                    name,
                                    val ? "Active" : "Inactive"
                                  );
                                  Object.keys(stores).filter((code) => {
                                    setFieldValue(
                                      `stores.${code}.status`,
                                      val
                                        ? get(pickup_locations, "0", "")
                                            .length > 0
                                          ? pickup_locations.includes(code)
                                          : true
                                        : val
                                    );
                                  });
                                }}
                              />
                              <Text style={{ paddingLeft: 20, width: 70 }}>
                                {Localise(messages, values[name])}
                              </Text>
                            </View>
                          </View>
                        </>
                      ) : (
                        <>
                          <Text style={{ width: 100, ...fonts.heading5 }}>
                            {title}
                          </Text>
                          <View style={{ width: isMobile ? "40%" : "20%" }}>
                            <FormFieldPicker
                              name={name}
                              placeholder={{
                                label: Localise(messages, "Select Duration"),
                              }}
                              data={options}
                              containerStyle={{
                                width: "100%",
                                height: 40,
                                padding: 0,
                              }}
                            />
                          </View>
                        </>
                      )}
                    </View>
                  ) : (
                    showStorePickupLocations &&
                    name === "stores" && (
                      <>
                        <View
                          style={tw(
                            `flex flex-row justify-start items-center mb-2`
                          )}
                        >
                          <Text style={[tw("mr-1"), fonts.heading4]}>
                            {title}
                          </Text>
                          <Text
                            style={[
                              tw("mr-1"),
                              {
                                ...fonts.error,
                                fontWeight: "600",
                                textAlign: "left",
                              },
                            ]}
                          >
                            {errors?.minimumStores ?? ""}
                          </Text>
                        </View>
                        {Object.keys(stores).map((store, i) => {
                          const {
                            memberCode = "",
                            businessName = "",
                            addressLine1 = "",
                            city = "",
                            state = "",
                            zip = "",
                          } = stores[store];
                          const storeAddress =
                            businessName !== ""
                              ? businessName +
                                ` (${memberCode})` +
                                "\n" +
                                addressLine1 +
                                "\n" +
                                city +
                                ", " +
                                state +
                                " " +
                                zip
                              : memberCode;
                          return (
                            <View
                              key={i}
                              style={[
                                tw(
                                  `flex flex-row justify-start items-center mb-2`
                                ),
                              ]}
                              pointerEvents={
                                isGlobalPickupActive ? "auto" : "none"
                              }
                            >
                              <Text
                                style={{
                                  marginTop: 10,
                                  marginRight: 40,
                                  width: isMobile ? "70%" : 300,
                                }}
                              >
                                {storeAddress}
                              </Text>
                              <View
                                style={{
                                  paddingLeft: 5,
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <FormFieldSwitch
                                  value={
                                    isGlobalPickupActive &&
                                    (stores[store]?.status ?? false)
                                  }
                                  onValueChange={(val) => {
                                    setFieldValue(
                                      `stores.${store}.status`,
                                      val
                                    );
                                  }}
                                />
                                {!isMobile && (
                                  <Text style={{ paddingLeft: 20 }}>
                                    {isGlobalPickupActive &&
                                    (stores[store]?.status ?? false)
                                      ? Localise(messages, "Pickup Available")
                                      : Localise(
                                          messages,
                                          "Pickup Unavailable"
                                        )}
                                  </Text>
                                )}
                              </View>
                            </View>
                          );
                        })}
                      </>
                    )
                  )}
                </React.Fragment>
              );
            })}
            <SaveCancelButtons
              disableOnDirty={true}
              buttonTitle={Localise(messages, "Save")}
            />
          </>
        );
      }}
    />
  );
};

export default StorePickup;
