import { takeLatest, call, put, select } from "redux-saga/effects";

import { request } from "library/utils/request";
import { fetchNotifications, setAPIResponse } from "./slice";
import { selectDashboardShopCode } from "library/sagas/ongoing/global-data/selector";

import UserProfileStorage from "library/storage/userProfile";

import moment from "moment";

function* handleFetchNotifications(action = {}) {
  const serviceRequest = (params) =>
    request("get-member-notifications", params);

  try {
    const { memberCodes = [] } = UserProfileStorage.getAuthGroup();
    const dashboardSelectedShopCode = yield select(selectDashboardShopCode);
    const shopCode =
      dashboardSelectedShopCode === "all"
        ? memberCodes[0]
        : dashboardSelectedShopCode;
    const response = yield call(serviceRequest, {
      shopCode,
      searchTerm: "",
      searchValue: "",
      types: "all",
      isDeleted: false,
    });

    const { notificationSearch = [] } = response;
    let initialData = [];
    initialData = notificationSearch
      ?.filter((data) =>
        moment().isSameOrAfter(moment(data?.availableDate || data?.updatedOn))
      )
      .map((data) => {
        return {
          ...data,
          messageSubject:
            data.type === "CATALOG"
              ? data.messageText
              : data.messagePayload.subject,
        };
      });
    initialData = initialData.reduce((acc, item, _index, arr) => {
      if (item.type === "CATALOG") {
        let catalogMessages = acc.find((entry) => entry.type === "CATALOG");

        if (!catalogMessages) {
          catalogMessages = {
            type: "CATALOG",
            messageText: item.messageText,
            messageSubject: item.messageText,
            createdOn: item.createdOn || item.updatedOn,
            isDeleted: item.isDeleted,
            updatedOn: item.updatedOn,
            hasBeenRead: arr.every((item) => item.hasBeenRead),
            memberCode: item.memberCode,
            children: [],
          };
          acc.push(catalogMessages);
        }

        catalogMessages.children.push(item);
      } else {
        acc.push(item);
      }

      return acc;
    }, []);
    yield put(setAPIResponse(initialData));
  } catch (error) {
    console.log("error>", error);
  }
}

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  // yield takeLatest(setPageInitialise.type, handleFetchNotifications);
  yield takeLatest(fetchNotifications.type, handleFetchNotifications);
}

export default watchSaga;
