import { createSlice } from "@reduxjs/toolkit";

import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  loading: false,
  shopCode: "",
  gridInstance: {},
  gridProps: {},
  columnDefination: [],
  api: [],
  data: [],
  designers: [],
  pageLimit: 10,
  showLimitExceeded: false,
  actions: {
    searchQuery: "",
    orderGroups: {
      category: "all",
      values: ["all"],
    },
    gridState: [],
    gridFilters: [],
    selectedRows: {},
    showDesigners: false,
    triggerUpdateCall: false,
  },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setPageInitialise: (state, action) => {
      state.shopCode = action?.payload?.sendingMember ?? "";
      state.gridInstance = action?.payload?.gridInstance ?? {};
      state.gridProps = action?.payload?.gridProps ?? {};
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    resetSlice: (state) => {
      Object.assign(state, initialState);
    },
    setAPIResponse: (state, action) => {
      state.api = action.payload;
    },
    setPageData: (state, action) => {
      state.data = action.payload;
    },
    setPageActions: (state, action) => {
      const { type = "", value = null } = action.payload;

      if (type === "searchQuery") {
        state.actions.searchQuery = value;
      } else if (type === "orderGroups") {
        state.actions.orderGroups = value;
      } else if (type === "triggerUpdate") {
        state.actions.triggerUpdateCall = true;
      } else if (type === "filters") {
        state.actions.gridFilters = [...state.actions.gridFilters, value];
      } else if (type === "rowSelection") {
        state.actions.selectedRows = value;
      }
    },
    setPageDesigners: (state, action) => {
      state.designers = action.payload;
    },
    setShowLimitExceeded: (state, action) => {
      state.showLimitExceeded = action.payload;
    },
    setGridDataSource: () => {},
    triggerRefreshGrid: () => {},

    fetchNotifications: () => {},
    fetchOrders: () => {},
    filterOrders: () => {},
    fetchPrintDetails: () => {},
    triggerMeetBallAction: () => {},
    assignDesigner: () => {},
  },
});

export const {
  setPageInitialise,
  setLoading,
  resetSlice,
  setAPIResponse,
  setPageData,
  setPageSearch,
  setOrderGroups,
  setGridState,
  setPageActions,
  setPageDesigners,
  setShowLimitExceeded,
  setGridDataSource,
  triggerRefreshGrid,

  fetchNotifications,
  fetchOrders,
  filterOrders,
  fetchPrintDetails,
  triggerMeetBallAction,
  assignDesigner,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
