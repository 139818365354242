import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";
import { selectState as selectGlobalState } from "../../../../../ongoing/global-data/selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectPageStatus = createSelector(
  selectState,
  ({ status }) => status
);

export const selectIsPageLoading = createSelector(selectPageStatus, (status) =>
  Object.values(status).some(Boolean)
);

export const selectToastDetails = createSelector(
  selectState,
  ({ toast }) => toast
);

export const selectTabNames = createSelector(
  selectState,
  ({ tabs }) => tabs || []
);

export const selectActiveTab = createSelector(
  selectState,
  ({ activeTab }) => activeTab || ""
);

export const selectShopCode = createSelector(
  selectState,
  selectGlobalState,
  ({ shopCode }, { shopCode: dashboardSelectedShopCode }) => {
    const memberCode = shopCode || dashboardSelectedShopCode;
    return memberCode !== "all" ? memberCode : "";
  }
);
