import { combineReducers } from "redux";
import { SLICE_NAME as sliceName } from "./constants";

import common from "./common/slice";
import sharedCatalogs from "./shared-catalogs/slice";
import manageGroups from "./manage-groups/slice";
import notifications from "./notifications/slice";

export default {
  [sliceName]: combineReducers({
    ...common,
    ...sharedCatalogs,
    ...manageGroups,
    ...notifications,
  }),
};
