import React, { useEffect, useContext } from "react";
import { Text } from "react-native-elements";
import { View, ActivityIndicator } from "react-native";
import { useSelector } from "react-redux";
import { ToasterHandler } from "components/elements";
import { FormFieldSwitch } from "components/elements/forms";

import UserProfileContext from "library/contexts/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { memberEntitlements } from "library/utils/entitlements";
import {
  selectShopDetails,
  selectShopGroupMembers,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";

import tw from "tailwind-rn";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";

import { fonts, colors } from "styles/theme";

const OrderSettings = ({
  shopSettings,
  saveSettings,
  loadingKey,
  isMHQNonCoreShop,
}) => {
  const { messages, Localise } = useContext(I18NContext);
  const { isDesktop } = useContext(DeviceContext);
  const { proxyUser } = useContext(UserProfileContext);
  const shopDetails = useSelector(selectShopDetails);
  const groupMembers = useSelector(selectShopGroupMembers);
  const [orderSettings, setOrderSettings] = useStateIfMounted({});
  const [printSettings, setPrintSettings] = useStateIfMounted({});
  const hasMOLEntitlement = [
    memberEntitlements.MERCURY_ONLINE,
    memberEntitlements.MHQ_CORE_MERCURY_ONLINE,
    memberEntitlements.MHQ_STANDARD_MERCURY_ONLINE,
    memberEntitlements.MHQ_PREMIUM_MERCURY_ONLINE,
    memberEntitlements.MHQ_CONN_MERCURY_ONLINE,
    memberEntitlements.MHQ_ESSENTIALS_MERCURY_ONLINE,
  ].includes(shopDetails.memberEntitlements?.memberEntitlementName);
  const memberCodes = Object.keys(groupMembers);

  const isMOLOnlyUser =
    shopDetails.memberEntitlements?.memberEntitlementName ===
    memberEntitlements.MERCURY_ONLINE;

  useEffect(() => {
    if (isEmpty(shopSettings)) return;
    setOrderSettings({
      auto_accept_orders: shopSettings["auto_accept_orders"],
      auto_print_accept_orders: shopSettings["auto_print_accept_orders"],
      bulk_print_orders: shopSettings["bulk_print_orders"],
      enable_auto_wiring: shopSettings["enable_auto_wiring"],
      is_service_bureau_member:
        shopSettings["is_service_bureau_member"] || "false",
      attempted_delivery_flagged_followup:
        shopSettings["attempted_delivery_flagged_followup"],
    });
    setPrintSettings({
      legal_size_invoice_print_settings:
        shopSettings["legal_size_invoice_print_settings"] || [],
      trifold_card_print_settings:
        shopSettings["trifold_card_print_settings"] || [],
      order_details_print_settings:
        shopSettings["order_details_print_settings"] || [],
      order_details_outgoing_print_settings:
        shopSettings["order_details_outgoing_print_settings"] || [],
      designer_worksheet_print_settings:
        shopSettings["designer_worksheet_print_settings"] || [],
      order_messages_incoming_print_settings:
        shopSettings["order_messages_incoming_print_settings"] || [],
      order_messages_outgoing_print_settings:
        shopSettings["order_messages_outgoing_print_settings"] || [],
    });
  }, [shopSettings]);

  return (
    <React.Fragment>
      {!isMOLOnlyUser && (
        <>
          <View style={[tw("flex flex-row items-center"), { marginTop: 4 }]}>
            <View style={{ paddingRight: 20, maxWidth: 210, minWidth: 210 }}>
              <Text style={fonts.heading5}>
                {Localise(messages, "Auto Accept Wire Orders")}
              </Text>
            </View>
            <FormFieldSwitch
              value={orderSettings["auto_accept_orders"] === "true"}
              onValueChange={(val) => {
                let payload = {
                  preferences: [
                    {
                      id: "auto_accept_orders",
                      values: [val ? "true" : "false"],
                    },
                  ],
                };
                saveSettings(payload);
              }}
              testID="auto_accept_orders"
            />
            <View style={{ paddingHorizontal: 20 }}>
              <Text>
                {orderSettings["auto_accept_orders"] === "true"
                  ? Localise(messages, "Enabled")
                  : Localise(messages, "Disabled")}
              </Text>
            </View>
            {loadingKey === "auto_accept_orders" && (
              <ActivityIndicator
                color={colors.activityIndicator}
                testID={"loader"}
              />
            )}
          </View>
          <View style={[tw("flex flex-row items-center"), { marginTop: 16 }]}>
            <View style={{ paddingRight: 20, maxWidth: 210, minWidth: 210 }}>
              <Text style={fonts.heading5}>
                {Localise(messages, "Auto Print Accepted Orders")}
              </Text>
            </View>
            <FormFieldSwitch
              value={orderSettings["auto_print_accept_orders"] === "true"}
              onValueChange={(val) => {
                const isBulkPrintOrdersEnabled =
                  orderSettings["bulk_print_orders"] === "true";
                if (!isBulkPrintOrdersEnabled || !val) {
                  let printerPreferences = [];
                  if (!val) {
                    const clonedPrintSettings = cloneDeep(printSettings);
                    Object.keys(clonedPrintSettings).map((each) => {
                      clonedPrintSettings[each]?.forEach((settings, index) => {
                        if (!isEmpty(settings) && settings?.auto_print) {
                          clonedPrintSettings[each]?.splice(index, 1, {
                            ...settings,
                            auto_print: false,
                          });
                        }
                      });
                      clonedPrintSettings[each]?.length &&
                        printerPreferences.push({
                          id: each,
                          values: [JSON.stringify(clonedPrintSettings[each])],
                        });
                    });
                  }

                  let payload = {
                    preferences: [
                      {
                        id: "auto_print_accept_orders",
                        values: [val ? "true" : "false"],
                      },
                      ...printerPreferences,
                    ],
                  };
                  saveSettings(payload);
                } else {
                  ToasterHandler(
                    "error",
                    Localise(
                      messages,
                      "Auto Print cannot be activated unless Bulk Print is deactivated"
                    )
                  );
                }
              }}
              testID="auto_print_accept_orders"
            />
            <View style={{ paddingHorizontal: 20 }}>
              <Text>
                {orderSettings["auto_print_accept_orders"] === "true"
                  ? Localise(messages, "Enabled")
                  : Localise(messages, "Disabled")}
              </Text>
            </View>
            {loadingKey === "auto_print_accept_orders" && (
              <ActivityIndicator
                color={colors.activityIndicator}
                testID={"loader"}
              />
            )}
          </View>
          <View style={[tw("flex flex-row items-center mt-4")]}>
            <View style={{ paddingRight: 20, minWidth: 210 }}>
              <Text style={fonts.heading5}>
                {Localise(messages, "Bulk Print Orders")}
              </Text>
            </View>
            <FormFieldSwitch
              value={orderSettings["bulk_print_orders"] === "true"}
              onValueChange={(val) => {
                const isAutoPrintAcceptEnabled =
                  orderSettings["auto_print_accept_orders"] === "true";
                if (!isAutoPrintAcceptEnabled || !val) {
                  let payload = {
                    preferences: [
                      {
                        id: "bulk_print_orders",
                        values: [val ? "true" : "false"],
                      },
                    ],
                  };
                  saveSettings(payload);
                } else {
                  ToasterHandler(
                    "error",
                    Localise(
                      messages,
                      "Bulk Print cannot be activated unless Auto Print is deactivated"
                    )
                  );
                }
              }}
              testID="bulk_print_orders"
            />
            <View style={{ paddingHorizontal: 20 }}>
              <Text>
                {orderSettings["bulk_print_orders"] === "true"
                  ? Localise(messages, "Enabled")
                  : Localise(messages, "Disabled")}
              </Text>
            </View>
            {loadingKey === "bulk_print_orders" && (
              <ActivityIndicator
                color={colors.activityIndicator}
                testID={"loader"}
              />
            )}
          </View>
          {isMHQNonCoreShop && (
            <View style={[tw("flex flex-row items-center mt-4")]}>
              <View style={{ paddingRight: 20, minWidth: 210 }}>
                <Text style={fonts.heading5}>
                  {Localise(messages, "Auto Flag for Attempted Delivery")}
                </Text>
              </View>
              <FormFieldSwitch
                value={
                  orderSettings["attempted_delivery_flagged_followup"] ===
                  "true"
                }
                onValueChange={(val) => {
                  let payload = {
                    preferences: [
                      {
                        id: "attempted_delivery_flagged_followup",
                        values: [val ? "true" : "false"],
                      },
                    ],
                  };
                  saveSettings(
                    payload,
                    true,
                    false,
                    () => {},
                    `Auto flag for attempted delivery ${
                      val ? "enabled" : "disabled"
                    }`
                  );
                }}
                testID="attempted_delivery_flagged_followup"
              />
              <View style={{ paddingHorizontal: 20 }}>
                <Text>
                  {orderSettings["attempted_delivery_flagged_followup"] ===
                  "true"
                    ? Localise(messages, "Enabled")
                    : Localise(messages, "Disabled")}
                </Text>
              </View>
              {loadingKey === "attempted_delivery_flagged_followup" && (
                <ActivityIndicator
                  color={colors.activityIndicator}
                  testID={"loader"}
                />
              )}
            </View>
          )}
        </>
      )}

      {!!proxyUser && hasMOLEntitlement && memberCodes.length === 1 && (
        <View
          style={tw(isDesktop ? "flex-row items-center mt-4" : "flex-col mt-4")}
        >
          <View style={[tw("flex flex-row items-center")]}>
            <View style={{ paddingRight: 20 }}>
              <Text style={fonts.heading5}>
                {Localise(messages, "Service Bureau Member")}
              </Text>
            </View>
            <FormFieldSwitch
              value={orderSettings["is_service_bureau_member"] === "true"}
              onValueChange={(value) => {
                const payload = {
                  preferences: [
                    {
                      id: "is_service_bureau_member",
                      values: [value ? "true" : "false"],
                    },
                  ],
                };
                saveSettings(payload);
              }}
              testID="is_service_bureau_member"
            />
            <View style={{ paddingHorizontal: 20 }}>
              <Text>
                {orderSettings["is_service_bureau_member"] === "true"
                  ? Localise(messages, "Enabled")
                  : Localise(messages, "Disabled")}
              </Text>
            </View>
            {loadingKey === "is_service_bureau_member" && (
              <ActivityIndicator
                color={colors.activityIndicator}
                testID={"loader"}
              />
            )}
          </View>
        </View>
      )}

      {hasMOLEntitlement &&
        memberCodes.length > 1 &&
        orderSettings["is_service_bureau_member"] === "false" && (
          <View
            style={tw(
              isDesktop ? "flex-row items-center mt-4" : "flex-col mt-4"
            )}
          >
            <View style={[tw("flex flex-row items-center")]}>
              <View style={{ paddingRight: 20 }}>
                <Text style={fonts.heading5}>
                  {Localise(messages, "Auto Transfer Mercury Online Orders:")}
                </Text>
              </View>
              <FormFieldSwitch
                value={orderSettings["enable_auto_wiring"] === "true"}
                onValueChange={(value) => {
                  const payload = {
                    preferences: [
                      {
                        id: "enable_auto_wiring",
                        values: [value ? "true" : "false"],
                      },
                    ],
                  };
                  saveSettings(payload);
                }}
                testID="enable_auto_wiring"
              />
              <View style={{ paddingHorizontal: 20 }}>
                <Text>
                  {orderSettings["enable_auto_wiring"] === "true"
                    ? Localise(messages, "Enabled")
                    : Localise(messages, "Disabled")}
                </Text>
              </View>
              {loadingKey === "enable_auto_wiring" && (
                <ActivityIndicator
                  color={colors.activityIndicator}
                  testID={"loader"}
                />
              )}
            </View>
            <Text style={tw("italic")}>
              {` (${Localise(
                messages,
                "Will send orders to a shop within your group that is closest to the delivery location"
              )})`}
            </Text>
          </View>
        )}
    </React.Fragment>
  );
};

export default OrderSettings;
