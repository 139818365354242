import { CommonActions } from "@react-navigation/native";
import { resetBasicInfo } from "library/sagas/ongoing/current-orders/slice";
import moment from "moment";

export const rowThreshold = 10; // Enable scrolling after N rows
export const rowHeight = 40;

//filter orders for selected dateRange
const customRangeFilter = (orderDateTime) => ({
  section: "Date Range",
  title: "Custom Range",
  value: {
    startDate: moment.utc(orderDateTime).format("YYYY-MM-DD"),
    endDate: moment.utc(orderDateTime).format("YYYY-MM-DD"),
    dateType: "ORDER_DATE",
  },
});

const redirectTo = "orders"; // Orders Listing Page

const redirectToOrdersWithFilters = ({ navigation, dispatch, data }) => {
  const { mercuryOrderNumber, orderDateTime } = data;

  dispatch(resetBasicInfo()); // reset Orders Filters

  navigation.dispatch(
    CommonActions.navigate({
      name: redirectTo,
      params: {
        filters: [customRangeFilter(orderDateTime)],
        search: { title: mercuryOrderNumber, value: mercuryOrderNumber },
      },
    })
  );
};

export const getUIConfig = ({
  ratingDetails,
  commentsData: {
    rejectComments = [],
    cancelComments = [],
    drpComments = [],
  } = {},
  isMobile,
  orderNumberClickable,
}) => {
  return [
    {
      title: "Scorecard Rating",
      info: `Enhancing Quality Rating: A faster response time contributes to a higher Quality Rating. To improve it, aim to minimize delayed response instances.
    \nImproving Price Change Rating: Maintaining initial pricing stability supports a better Price Change Rating. To enhance it, look for ways to minimize additional fund requests.
    \nOptimizing Refusal Rating: Accepting more orders helps strengthen your Refusal Rating. To improve it, focus on reducing order rejections.`,
      data: ratingDetails,
      columns: [
        {
          name: "Member Code",
          key: "memberCode",
          style: { width: isMobile ? 100 : "25%" },
          printStyle: { width: "25%" },
        },
        {
          name: "Quality Rating",
          key: "qualityRating",
          style: { width: isMobile ? 95 : "25%" },
          printStyle: { width: "25%" },
        },
        {
          name: "Price Change Rating",
          key: "priceChangeRating",
          style: { width: isMobile ? 98 : "25%" },
          printStyle: { width: "25%" },
        },
        {
          name: "Reject Rating",
          key: "rejectRating",
          style: { width: isMobile ? 95 : "25%" },
          printStyle: { width: "25%" },
        },
      ],
    },

    {
      title: "Reject Comments",
      data: rejectComments,
      columns: [
        {
          name: "Order Number",
          key: "mercuryOrderNumber",
          style: { width: isMobile ? 150 : "15%" },
          printStyle: { width: "15%" },
          clickable: orderNumberClickable,
          onPress: redirectToOrdersWithFilters,
        },
        {
          name: "Order Date",
          key: "orderDate",
          style: { width: isMobile ? 100 : "15%" },
          printStyle: { width: "15%" },
        },
        {
          name: "Message Date",
          key: "messageDate",
          style: { width: isMobile ? 100 : "15%" },
          printStyle: { width: "15%" },
        },
        {
          name: "Message Text",
          key: "messageText",
          style: {
            width: isMobile ? 300 : "55%",
            justifyContent: "flex-start",
            paddingHorizontal: 20,
          },
          printStyle: { width: "55%", textAlign: "left" },
        },
      ],
    },

    {
      title: "Cancel Comments",
      data: cancelComments,
      columns: [
        {
          name: "Order Number",
          key: "mercuryOrderNumber",
          style: { width: isMobile ? 150 : "15%" },
          printStyle: { width: "15%" },
          clickable: orderNumberClickable,
          onPress: redirectToOrdersWithFilters,
        },
        {
          name: "Order Date",
          key: "orderDate",
          style: { width: isMobile ? 100 : "15%" },
          printStyle: { width: "15%" },
        },
        {
          name: "Message Date",
          key: "messageDate",
          style: { width: isMobile ? 100 : "15%" },
          printStyle: { width: "15%" },
        },
        {
          name: "Message Text",
          key: "messageText",
          style: {
            width: isMobile ? 300 : "55%",
            justifyContent: "flex-start",
            paddingHorizontal: 20,
          },
          printStyle: { width: "55%", textAlign: "left" },
        },
      ],
    },

    {
      title: "DRP Comments",
      data: drpComments,
      columns: [
        {
          name: "Order Number",
          key: "mercuryOrderNumber",
          style: { width: isMobile ? 150 : "15%" },
          printStyle: { width: "15%" },
          clickable: orderNumberClickable,
          onPress: redirectToOrdersWithFilters,
        },
        {
          name: "Order Date",
          key: "orderDate",
          style: { width: isMobile ? 100 : "15%" },
          printStyle: { width: "15%" },
        },
        {
          name: "Message Date",
          key: "messageDate",
          style: { width: isMobile ? 100 : "15%" },
          printStyle: { width: "15%" },
        },
        {
          name: "Penalty Code",
          key: "penaltyCode",
          style: { width: isMobile ? 100 : "15%" },
          printStyle: { width: "15%" },
        },
        {
          name: "Message Text",
          key: "messageText",
          style: {
            width: isMobile ? 300 : "40%",
            justifyContent: "flex-start",
            paddingHorizontal: 20,
          },
          printStyle: { width: "40%", textAlign: "left" },
        },
      ],
    },
  ];
};
