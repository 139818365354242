import Environment from "library/utils/environment";
import moment from "moment";

// start showing previous month if today >= scorecardUpdateDate
export const getScorecardUpdateDate = () => {
  return Environment.get("SCORECARD_UPDATE_DATE", 6);
};

const getScorecardMonthsLimit = () => {
  return Environment.get("SCORECARD_MONTHS_LIMIT", 13);
};

export const getScorecardMonthOptions = () => {
  const monthLimit = getScorecardMonthsLimit();
  // Generate last n months with their first date excluding current month
  const monthsOptions = [];
  const currentDate = moment().date();
  const startMonth = currentDate < getScorecardUpdateDate() ? 2 : 1;

  for (let i = startMonth; i < monthLimit + startMonth; i++) {
    const firstDayOfMonth = moment().subtract(i, "months").startOf("month");
    monthsOptions.push({
      label: firstDayOfMonth.format("MMMM YYYY"), // Month Name Year (e.g. "January 2025")
      value: firstDayOfMonth.format("YYYY-MM-DD"), // The first date of the month
    });
  }

  return monthsOptions;
};
