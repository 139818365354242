/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
/* eslint-disable react/display-name */
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { setAPIResponse } from "library/sagas/views/home/drawer/notifications/slice";
import { useNavigation } from "@react-navigation/native";
import {
  Tooltip,
  PrintIframeAction,
  PrintIframeRenderer,
} from "components/elements";
import { ConfirmModal } from "components/wrappers";
import { request } from "library/utils/request";
import { Image, Input } from "react-native-elements";
import IMAGES from "static/assets/images";
import UserProfileStorage from "library/storage/userProfile";

import capitalize from "lodash/capitalize";
import toUpper from "lodash/toUpper";
import toLower from "lodash/toLower";
import { fonts } from "styles/theme";

import tw from "tailwind-rn";
import moment from "moment";

const { memberCodes = [] } = UserProfileStorage.getAuthGroup();

export const orderGroupsConfig = (data) => {
  const unRead = data.filter((item) => !item.isDeleted && !item.hasBeenRead);

  return [
    {
      category: "all",
      tabs: [
        {
          label: "All",
          value: "all",
          ...(data.length ? { badge: data.length } : {}),
        },
      ],
    },
    {
      category: "readStatus",
      tabs: [
        {
          label: "Unread",
          value: 0,
          ...(unRead.length ? { badge: unRead.length } : {}),
        },
        {
          label: "Read",
          value: 1,
        },
      ],
    },
    {
      category: "deletedFolder",
      tabs: [
        {
          label: "Trash Folder",
          value: "deleted",
        },
      ],
    },
  ];
};

export const detailsColDefs = [
  {
    headerName: "Date",
    width: 100,
    field: "createdOn",
    menuTabs: [],
    resizable: true,
    editable: false,
    cellRenderer: (props) => {
      const { data = {} } = props || {};
      const date = moment(data?.updatedOn).format("l");
      const day = moment(data?.updatedOn).format("LT");
      return (
        <View
          style={[
            tw("flex flex-col items-start justify-center"),
            { fontSize: 12, height: "100%" },
          ]}
        >
          <Text>{date}</Text>
          <Text>{day}</Text>
        </View>
      );
    },
  },
  {
    headerName: "SKU",
    field: "sku",
    width: 100,
    menuTabs: [],
    resizable: true,
    editable: false,
    cellRenderer: (props) => {
      const { messagePayload = {} } = props.data;
      const navigation = useNavigation();

      return (
        <View
          style={[
            tw("flex flex-col items-start justify-center"),
            { fontSize: 12, height: "100%" },
          ]}
        >
          <Text
            onPress={() =>
              navigation.navigate("product-catalog", {
                screen: "product",
                params: {
                  handle: messagePayload?.product[0]?.productId || "",
                },
              })
            }
            style={fonts.style3}
          >
            {messagePayload?.product[0]?.productId || ""}
          </Text>
        </View>
      );
    },
  },
  {
    headerName: "Product Name",
    field: "productName",
    width: 300,
    menuTabs: [],
    resizable: true,
    editable: false,
    cellRenderer: (props) => {
      const { messagePayload = {} } = props.data;

      return (
        <View
          style={[
            tw("flex flex-col items-start justify-center"),
            { fontSize: 12, height: "100%" },
          ]}
        >
          <Text>{messagePayload?.product[0].name || ""}</Text>
        </View>
      );
    },
  },
  {
    headerName: "Price",
    width: 150,
    resizable: true,
    editable: false,
    menuTabs: [],
    cellRenderer: (props) => {
      const { messagePayload = {} } = props.data;

      return (
        <View
          style={[
            tw("flex flex-col items-start justify-center"),
            { fontSize: 12, height: "100%" },
          ]}
        >
          <Text>{`$${
            messagePayload?.product[0]?.regionalPrices[1]?.price.toFixed(2) || 0
          }`}</Text>
        </View>
      );
    },
  },
  {
    headerName: "Collections",
    width: 150,
    resizable: true,
    editable: false,
    menuTabs: [],
    cellRenderer: (props) => {
      const { messagePayload = {} } = props.data;

      return (
        <View
          style={[
            tw("flex flex-col items-start justify-center"),
            { fontSize: 12, height: "100%" },
          ]}
        >
          <Text>
            {capitalize(messagePayload.product[0].collections.toString())}
          </Text>
        </View>
      );
    },
  },
];

export const columnDefs = (dispatch, searchQuery, orderGroups, apiData) => {
  return [
    {
      headerName: "",
      sortable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 80,
      field: "hasBeenRead",
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
      menuTabs: ["generalMenuTab"],
      headerComponent: CustomHeaderComponent,
      cellRenderer: (props) => {
        const {
          data = {},
          updateNotifications,
          fetchRecords,
          dispatch,
          api = {},
        } = props || {};
        return (
          <View
            style={[
              tw("flex flex-row items-center justify-start"),
              { fontSize: 12, height: "100%" },
            ]}
          >
            <Tooltip
              text={data?.hasBeenRead ? "Mark as unread" : "Mark as read"}
              renderForWebOnly={true}
            >
              <Image
                style={{
                  width: 30,
                  height: 30,
                }}
                onPress={() => {
                  updateNotifications({
                    data,
                    api,
                    fetchRecords,
                    dispatch,
                    apiData,
                    orderGroups,
                  });
                }}
                source={IMAGES[data?.hasBeenRead ? "email" : "unread-message"]}
                fsClass="fs-unmask"
              />
            </Tooltip>
          </View>
        );
      },
      cellRendererParams: {
        updateNotifications,
        dispatch,
        fetchRecords,
        searchQuery,
      },
    },
    {
      headerName: "Date",
      width: 120,
      field: "date",
      menuTabs: [],
      resizable: true,
      editable: false,
      sortable: true,
      cellRenderer: (props) => {
        const { data = {} } = props || {};
        const date = moment(data?.updatedOn).format("l");
        const day = moment(data?.updatedOn).format("dddd");
        return (
          <View
            style={[
              tw("flex flex-col items-start justify-center"),
              { fontSize: 12, height: "100%" },
            ]}
          >
            <Text>{date}</Text>
            <Text>{day}</Text>
          </View>
        );
      },
    },
    {
      headerName: "Time",
      width: 100,
      field: "time",
      cellStyle: {
        display: "flex",
        justifyContent: "center",
      },
      resizable: true,
      menuTabs: [],
      editable: false,
      sortable: true,
      cellRenderer: (props) => {
        const { data = {} } = props || {};
        const time = moment(data?.availableDate || data?.createdOn).format(
          "LT"
        );
        return (
          <View
            style={[
              tw("flex flex-row items-center justify-between"),
              { fontSize: 12, height: "100%" },
            ]}
          >
            <Text>{time}</Text>
          </View>
        );
      },
    },
    {
      headerName: "Subject",
      field: "message",
      resizable: true,
      editable: false,
      menuTabs: [],
      sortable: true,
      width: 650,
      cellRenderer: (props) => {
        const { data = {} } = props || {};

        const displayMessage =
          data?.type === "CATALOG"
            ? data?.messageText
            : data?.messagePayload?.subject;

        return (
          <View
            style={[
              tw("flex flex-row items-center justify-between"),
              { fontSize: 12, height: "100%" },
            ]}
          >
            <Text numberOfLines={1}> {capitalize(displayMessage)}</Text>
          </View>
        );
      },
    },
    {
      headerName: "From",
      resizable: true,
      editable: false,
      field: "sender",
      sortable: true,
      width: 375,
      filter: CustomFilterComponent,
      headerComponent: CustomHeaderComponent,
      menuTabs: ["filterMenuTab"],
      filterParams: {
        filterOptions: [
          {
            rowId: "sender",
            heading: "Sender Category",
            filters: [
              {
                label: "Release Notes",
                name: "Release Notes",
              },
              {
                label: "Quick Tips",
                name: "Quick Tips",
              },
              {
                label: "Did You Know",
                name: "Did You Know",
              },
              {
                label: "Alert",
                name: "Alert",
              },
              {
                label: "Other",
                name: "Other",
              },
              {
                label: "Marketing",
                name: "Marketing",
              },
              {
                label: "Merchandising",
                name: "Merchandising",
              },
              {
                label: "Catalog",
                name: "Catalog",
              },
              {
                label: "Mercury HQ",
                name: "Mercury HQ",
              },
              {
                label: "Mercury Online",
                name: "Mercury Online",
              },
            ],
          },
        ],
      },
      cellRenderer: (props) => {
        const { data = {} } = props || {};
        const { type = "", messageSender = "" } = data;
        const displayMessage = type === "OTHER" ? messageSender : type;
        return (
          <View
            style={[
              tw("flex flex-row items-center justify-between"),
              { fontSize: 12, height: "100%" },
            ]}
          >
            <Text numberOfLines={1}>
              {toUpper(displayMessage.replaceAll("_", " "))}
            </Text>
          </View>
        );
      },
    },

    {
      headerName: "Action",
      menuTabs: [],
      width: 150,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
      },
      cellRenderer: (props) => {
        const {
          data = {},
          updateNotifications,
          api = {},
          orderGroups = {},
        } = props || {};
        const { category = "" } = orderGroups;
        const [showPopup, setShowPopup] = useState(false);
        return (
          <View
            style={[
              tw("flex flex-row items-center justify-between"),
              { fontSize: 12, height: "100%" },
            ]}
          >
            <Tooltip
              text={category === "deletedFolder" ? "Restore" : "Delete"}
              renderForWebOnly={true}
            >
              <Image
                onPress={() => setShowPopup(!showPopup)}
                style={{
                  width: 25,
                  height: 25,
                }}
                source={
                  category === "deletedFolder"
                    ? IMAGES["restore"]
                    : require("static/assets/trash-can.png")
                }
              />
            </Tooltip>
            {category !== "deletedFolder" ? (
              <ConfirmModal
                modalVisible={!!showPopup}
                handlePrimary={() => {
                  updateNotifications({
                    data,
                    api,
                    isDeleted: true,
                    apiData,
                  });
                  setShowPopup(!showPopup);
                }}
                handleSecondary={() => {
                  setShowPopup(!showPopup);
                }}
                data={{
                  modal: {
                    content: `Deleted Messages can be restored from the trash folder with in 60 days`,
                  },
                }}
              />
            ) : null}

            <View style={{ width: 95 }}>
              <PrintIframeRenderer testID={"notification-page"} />
              <TouchableOpacity
                activeOpacity={0.5}
                onPress={() => PrintIframeAction(getNotificationInfo(data))}
                style={{ marginLeft: 5 }}
                testID="print"
                accessibilityLabel="print"
              >
                <Tooltip text={"Print Notification"} renderForWebOnly={true}>
                  <Image
                    style={{
                      width: 26,
                      height: 26,
                    }}
                    resizeMode="contain"
                    source={IMAGES["print"]}
                  />
                </Tooltip>
              </TouchableOpacity>
            </View>
          </View>
        );
      },
      cellRendererParams: {
        updateNotifications,
        dispatch,
        fetchRecords,
        searchQuery,
        orderGroups,
      },
    },
  ];
};

export const fetchRecords = async ({
  gridInstance,
  gridProps,
  dispatch = () => {},
  searchQuery = "",
  orderGroups = {},
  shopCode = "",
}) => {
  try {
    let initialData = [];
    const { category = "", values = [] } = orderGroups;
    const memberCode = shopCode === "all" ? memberCodes[0] : shopCode;

    const response = await request("get-member-notifications", {
      shopCode: memberCode,
      searchTerm: "",
      searchValue: "",
      types: "all",
      isDeleted: category === "deletedFolder" ? true : false,
    });
    initialData = response?.notificationSearch
      ?.filter((data) =>
        moment().isSameOrAfter(moment(data?.availableDate || data?.updatedOn))
      )
      .map((data) => {
        return {
          ...data,
          messageSubject:
            data.type === "CATALOG"
              ? data.messageText
              : data.messagePayload.subject,
        };
      });
    initialData = initialData.reduce((acc, item, _index, arr) => {
      if (item.type === "CATALOG") {
        let catalogMessages = acc.find((entry) => entry.type === "CATALOG");

        if (!catalogMessages) {
          catalogMessages = {
            type: "CATALOG",
            messageText: item.messageText,
            messageSubject: item.messageText,
            createdOn: item.createdOn,
            isDeleted: item.isDeleted,
            updatedOn: item.updatedOn,
            hasBeenRead: arr
              .filter((e) => e.type === "CATALOG")
              .every((item) => item.hasBeenRead),
            memberCode: item.memberCode,
            children: [],
          };
          acc.push(catalogMessages);
        }

        catalogMessages.children.push(item);
      } else {
        acc.push(item);
      }

      return acc;
    }, []);

    dispatch(setAPIResponse(initialData || []));

    const dataSource = {
      getRows: async function (params) {
        try {
          gridProps?.togglePaginationControls(false);
          const { sortModel = [] } = params.request;
          const { sender = {}, hasBeenRead = {} } =
            gridInstance?.api?.getFilterModel();

          let filteredData = initialData;
          const categoryFilters = Object.keys(sender);
          const statusFilter = Object.keys(hasBeenRead);

          if (categoryFilters.length > 0) {
            filteredData = filteredData.filter(
              (entry) =>
                toUpper(categoryFilters).includes(toUpper(entry.type)) &&
                sender[entry.type]
            );
          } else if (statusFilter.length > 0) {
            filteredData = filteredData.filter((entry) => {
              const matchingValue = entry.hasBeenRead ? "READ" : "UNREAD";

              return (
                toUpper(statusFilter).includes(matchingValue) &&
                hasBeenRead[matchingValue]
              );
            });
          }

          if (sortModel.length) {
            const { colId, sort } = sortModel[0];
            if (colId === "message") {
              filteredData = [...filteredData].sort((a, b) => {
                const subjectA = toLower(a.messageSubject);
                const subjectB = toLower(b.messageSubject);
                if (subjectA < subjectB) return sort === "asc" ? -1 : 1;
                if (subjectA > subjectB) return sort === "asc" ? 1 : -1;
                return 0;
              });
            } else if (colId === "date") {
              filteredData = [...filteredData].sort((a, b) => {
                return (
                  new Date(sort === "asc" ? a.updatedOn : b.updatedOn) -
                  new Date(sort === "asc" ? b.updatedOn : a.updatedOn)
                );
              });
            }
          }
          if (category === "readStatus") {
            const showReadOnly = values[0] === 0 ? false : true;
            filteredData = filteredData.filter(
              (item) => item.hasBeenRead === showReadOnly
            );
          }

          if (searchQuery) {
            filteredData = filteredData.filter((item) => {
              return (
                item?.type
                  ?.toLowerCase()
                  ?.includes(searchQuery.toLowerCase()) ||
                item?.messageText
                  ?.toLowerCase()
                  ?.includes(searchQuery.toLowerCase()) ||
                item?.messageSender
                  ?.toLowerCase()
                  ?.includes(searchQuery.toLowerCase()) ||
                item?.messagePayload?.messageSubject
                  ?.toLowerCase()
                  ?.includes(searchQuery.toLowerCase()) ||
                (item.messagePayload?.product &&
                  item.messagePayload?.product[0].name
                    ?.toLowerCase()
                    ?.includes(searchQuery.toLowerCase())) ||
                (item.messagePayload?.product &&
                  item.messagePayload?.product[0].productId
                    ?.toLowerCase()
                    ?.includes(searchQuery.toLowerCase()))
              );
            });
          }
          gridInstance?.api?.setServerSideSelectionState({
            selectAllChildren: false,
            toggledNodes: [],
          });

          if (
            (categoryFilters.length > 0 || statusFilter.length > 0) &&
            category !== "deletedFolder"
          )
            dispatch(setAPIResponse(filteredData || []));

          await params.successCallback(filteredData, filteredData.length);
        } catch (error) {
          console.error("Error fetching rows:", error);
          params.failCallback();
        }
      },
    };

    gridInstance?.api?.setServerSideDatasource(dataSource);
  } catch (err) {
    console.error("Error in fetching notifications:", err);
  }
};

export const updateNotifications = ({
  data = {},
  api,
  apiData: apiResponse = [],
  isDeleted = false,
  restoreAll = false,
  dispatch = () => {},
  selectAll = false,
  markAllAsRead = false,
}) => {
  const { notificationId = "", updatedOn = "" } = data;

  const { selectAllChildren = false, toggledNodes = [] } =
    api.getServerSideSelectionState();

  const nodeIds = toggledNodes.map((node) => node.nodeId);

  const catalogPayload = [];

  const filteredData = apiResponse
    .filter(
      (item) =>
        selectAllChildren ||
        nodeIds.includes(item.notificationId || item.updatedOn) ||
        item.notificationId === notificationId ||
        (item.type === "CATALOG" && item.updatedOn === updatedOn)
    )
    .map((val) => {
      let children = [];

      if (val.type === "CATALOG") {
        children = val.children.map((e) => {
          return {
            ...e,
            hasBeenRead: selectAll
              ? markAllAsRead
                ? true
                : false
              : !e.hasBeenRead,
            ...(isDeleted
              ? { isDeleted: restoreAll ? false : !e.isDeleted }
              : {}),
          };
        });
        catalogPayload.push(...children);
      }

      return {
        ...val,
        ...(val.type === "CATALOG" ? { children } : {}),
        hasBeenRead: selectAll
          ? markAllAsRead
            ? true
            : false
          : !val.hasBeenRead,
        ...(isDeleted
          ? { isDeleted: restoreAll ? false : !val.isDeleted }
          : {}),
      };
    });

  api.applyServerSideTransaction({
    ...(!isDeleted ? { update: filteredData } : {}),
    ...(isDeleted ? { remove: filteredData } : {}),
  });

  const updateApiData = apiResponse.map((entry) => {
    const { notificationId = "", type = "", updatedOn = "" } = entry;

    const updatedObject = filteredData.find((e) =>
      type === "CATALOG"
        ? e.updatedOn === updatedOn
        : e.notificationId === notificationId
    );

    if (
      updatedObject?.notificationId ||
      (type === "CATALOG" && updatedObject?.updatedOn)
    ) {
      return updatedObject;
    } else return entry;
  });

  dispatch(setAPIResponse(updateApiData));

  const payload = filteredData
    .filter((e) => e.type !== "CATALOG")
    .concat(...catalogPayload)
    .map((entry) => {
      return {
        type: entry.type,
        notificationId: entry.notificationId,
        memberCode: entry.memberCode,
        hasBeenRead: entry.hasBeenRead,
        isDeleted: entry.isDeleted,
      };
    });

  request("update-notifications", {
    shopCode: memberCodes[0],
    body: payload,
  })
    .then(() => {})
    .catch((error) => console.log("error", error));

  api.setServerSideSelectionState({
    selectAllChildren: false,
    toggledNodes: [],
  });
};

const CustomFilterComponent = forwardRef((props, ref) => {
  const [checked, setChecked] = useState({});
  const { filterOptions = [] } = props;

  useImperativeHandle(
    ref,
    () => ({
      isFilterActive() {
        return Object.values(checked).some((value) => value === true);
      },

      getModel() {
        if (!Object.values(checked).some((value) => value === true)) {
          return null;
        }
        return checked;
      },

      setModel(model) {
        if (model) {
          setChecked(model);
        } else {
          setChecked({});
        }
      },
    }),
    [JSON.stringify(checked)]
  );

  const onFilterChanged = (filterName) => {
    setChecked((prevChecked) => ({
      ...prevChecked,
      [toUpper(filterName)]: !prevChecked[toUpper(filterName)],
    }));

    props.filterChangedCallback();
  };

  return (
    <View style={{ padding: 10 }}>
      <Input
        containerStyle={{ paddingHorizontal: 0 }}
        inputContainerStyle={{
          height: 30,
          marginVertical: 5,
        }}
        errorStyle={{ paddingBottom: 0 }}
        onChangeText={(val) => {}}
        placeholder={"Search.."}
        leftIcon={
          <Image
            style={{ width: 12, height: 12 }}
            resizeMode={"contain"}
            source={IMAGES["search"]}
          />
        }
      />
      {filterOptions.map((e, i) => {
        const refinedOptions = e.filters.filter((e) =>
          e.label.toLowerCase().includes("".toLowerCase())
        );

        if (!refinedOptions.length) return null;

        return (
          <View style={{ paddingVertical: 5 }} key={i}>
            <Text style={{ fontSize: 14, fontWeight: 700, marginBottom: 5 }}>
              {e.heading}
            </Text>
            {refinedOptions.map(({ name }, j) => {
              const isChecked = checked[toUpper(name)] || false;

              return (
                <React.Fragment key={j}>
                  <TouchableOpacity
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    onPress={() => onFilterChanged(name)}
                  >
                    <View
                      style={{
                        width: 16,
                        height: 16,
                        borderWidth: 1,
                        borderColor: "#000",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 5,
                      }}
                    >
                      {isChecked && <Text style={{ fontSize: 12 }}>✓</Text>}
                    </View>
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        marginVertical: 5,
                      }}
                    >
                      {name}
                    </Text>
                  </TouchableOpacity>
                </React.Fragment>
              );
            })}
          </View>
        );
      })}
    </View>
  );
});

export const getNotificationInfo = (notificationData = {}) => {
  const catalogData = notificationData?.type === "CATALOG";
  let data = "";

  const catalogChildren = notificationData?.children?.map((entry) => {
    const messageData = entry.messagePayload?.product[0];
    return {
      sku: messageData.productId,
      name: messageData.name,
      price: messageData?.regionalPrices[1]?.price,
    };
  });
  catalogData
    ? [{ sku: "SKU", name: "Product Name", price: "Price", css: "font-bold" }]
        .concat(...catalogChildren)
        .forEach((ele, _index) => {
          data += `<tr class='${ele.css || "-"} p-6' >
       <td>${ele.sku || "-"}</td>
       ${`<td>${ele.name || "-"}</td>`}
       <td>$ ${ele.price || "-"}</td>
       </tr>`;
        })
    : [];

  const date = moment(
    notificationData?.availableDate || notificationData.updatedOn
  ).format("l");
  const day = moment(
    notificationData?.availableDate || notificationData.updatedOn
  ).format("dddd");

  return `<!DOCTYPE html>
        <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
           <title>Florist OS</title>
        </head>
        <body onload="window.parent.postMessage({ action: 'iframe-loaded' }, '*');">
           <div style="display: none">
              <style type="text/css" media="print,screen">
                 @page {
                 size: auto;
                 margin: 0px;
                 /* this affects the margin in the printer settings */
                 }  
                 .rdw-left-aligned-block {
                    text-align: left !important;
                 } 
                 .rdw-right-aligned-block {
                    text-align: right !important;
                 }   
                .rdw-center-aligned-block {
                    text-align: center !important;
                 }      
                 .rdw-justify-aligned-block {
                    text-align: justify !important;
                 }
                 .printCardContainer{               
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width:100%;
                 }
                 .content {
                    width:80%;
                    padding:10px;                                
                    display: flex;
                    flex-direction: column; 
                    font-size: 16px;  
                    margin: 10px;           
                 }
                 .heading1{
                    text-decoration: underline;
                    padding: 20px;
                    font-size: 24px !important;
                 }
                 .heading3{
                    font-size: 20px !important;
                    font-weight: bold;
                 }
                 .font-bold{
                    font-weight:bold;
                 }
                 .desciption{
                    display:flex;
                    flex-direction:column;
                    justify-content:space-between;
                    padding:20px 10px;
                    margin: 20px 10px;
                   
                 }
                .descriptionDetails{
                    display:flex;
                    margin-bottom: 10px;
                    width: 50%;
                   
                 }
                  .message-container{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width : 80%;
                    flex-wrap: wrap;
                    text-align: justify;
                    margin-top : 50px;
                   
                 }
                 .breakLine{
                    border: 1px solid #BDB6B5;
                    border-style:inset;
                    width:100%;
                 }
                 .p-6{
                   padding: 6px;
                }
                 @media print {
                 html,
                 body {
                    height: auto;
                    -ms-overflow-style: none;
                    width:100%;
                 }
                 *{ color-adjust: exact;  -webkit-print-color-adjust: exact; print-color-adjust: exact; }             
                 }
              </style>
           </div>
           <div class="printCardContainer">
              <div class="content">
                 <div class="heading1">
                   Notification
                 </div>
                 <div class="desciption">
                    <div class="font-bold descriptionDetails">
                      <span style="width: 70px"> From</span>
                      <span style="margin-left: 10px"> : ${toUpper(
                        notificationData?.type === "OTHER"
                          ? notificationData.messageSender
                          : notificationData?.type
                      )}</span>
                    </div>
                     <div class="font-bold descriptionDetails">
                      <span style="width: 70px"> Sent</span>
                      <span style="margin-left: 10px"> : ${capitalize(
                        day
                      )},  ${toUpper(date)}</span>
                    </div>
                     <div class="font-bold descriptionDetails">
                      <span style="width: 70px">  To</span>
                      <span style="margin-left: 10px"> : ${
                        notificationData.memberCode
                      }</span>
                    </div>
                     <div class="font-bold descriptionDetails">
                      <span style="width: 70px"> Subject</span>
                      <span style="margin-left: 10px"> : ${capitalize(
                        notificationData?.messageSubject
                      )}</span>
                    </div>
                  </div>
                 </div>
                 <div class="breakLine"></div>
                 <div class="message-container">
                      <div>  ${toUpper(
                        notificationData?.type !== "CATALOG"
                          ? notificationData.messageText
                          : ""
                      )}</div>
                  
                  </div>
                 <table style="width:50%;margin-left: 30px">
                  ${data}
               </table>
              </div>
           </div>
        </body>
        </html>
     `;
};

const CustomHeaderComponent = (props) => {
  const { toggledNodes = [], selectAllChildren = false } =
    props.api.getServerSideSelectionState();
  const { sender = {} } = props?.api?.getFilterModel();
  const categoryFilters = Object.keys(sender);
  const isFilterInActive = !categoryFilters.length;
  const filterIconRef = useRef(null);
  const hideIcon =
    props?.column?.colDef?.field === "hasBeenRead" &&
    !toggledNodes.length &&
    !selectAllChildren;
  const openFilter = () => {
    filterIconRef.current && props.showColumnMenu(filterIconRef.current);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>{props.displayName}</span>
        {!hideIcon ? (
          <div
            ref={filterIconRef}
            onClick={openFilter}
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 8,
              cursor: "pointer",
              ...(!isFilterInActive
                ? {
                    backgroundColor: "#DFE5EA",
                    padding: 5,
                    borderRadius: 5,
                    shadowColor: "#000000",
                    shadowOffset: {
                      width: 0,
                      height: 1,
                    },
                    shadowRadius: 5,
                    shadowOpacity: 1.0,
                  }
                : {}),
            }}
          >
            <Image
              style={{ width: 12, height: 12 }}
              resizeMode={"contain"}
              source={IMAGES["column_filter"]}
            />
            <span
              style={{
                position: "absolute",
                right: 4,
                top: 13,
                width: 8,
                height: 8,
                backgroundColor: isFilterInActive ? "transparent" : "#1F4256",
                borderRadius: "50%",
                marginRight: 4,
              }}
            ></span>
          </div>
        ) : null}
      </div>
    </>
  );
};

export const getMainMenuItems = (props) => {
  const { category = "" } = props.orderGroups;
  return [
    {
      name: "Mark all as read",
      action: () => {
        updateNotifications({
          api: props.api,
          apiData: props.apiData,
          markAllAsRead: true,
          selectAll: true,
          dispatch: props.dispatch,
        });
      },
      icon: `<img src=${IMAGES["email"]} style="width: 15px;" />`,
    },
    {
      name: "Mark all as unread",
      action: () => {
        updateNotifications({
          api: props.api,
          apiData: props.apiData,
          markAllAsRead: false,
          selectAll: true,
          dispatch: props.dispatch,
        });
      },
      icon: `<img src=${IMAGES["unread-message"]} style="width: 15px;" />`,
    },
    {
      name: category === "deletedFolder" ? "Restore all" : "Delete all",
      action: () => {
        updateNotifications({
          api: props.api,
          apiData: props.apiData,
          isDeleted: true,
          dispatch: props.dispatch,
          restoreAll: category === "deletedFolder" ? true : false,
        });
      },
      icon: `<img src=${
        IMAGES[category === "deletedFolder" ? "restore" : "delete"]
      } style="width: 15px;" />`,
    },
  ];
};
