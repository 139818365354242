import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Platform, ScrollView, TouchableOpacity } from "react-native";
import { LabelField, SaveCancelButtons, Section } from "components/wrappers";
import { ToasterHandler, FileSelector } from "components/elements";
import {
  Form,
  FormField,
  FormFieldPicker,
  FormFieldDatePicker,
  FormFieldAutoComplete,
} from "components/elements/forms";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import {
  setInit,
  saveNotifications,
  setSideCarData,
} from "library/sagas/views/home/drawer/ftd-admin/member-settings/notifications/slice";
import {
  setSideCar,
  setSideCarTitle,
} from "library/sagas/views/home/drawer/ftd-admin/member-settings/common/slice";
import {
  selectMemberCodes,
  selectEntitlements,
  selectNotificationsData,
} from "library/sagas/views/home/drawer/ftd-admin/member-settings/notifications/selector";
import { CustomRTE } from "components/views/drawer/ftd-admin/mol-settings/helper";

import { getUIConfig, notificationSenderTypes } from "./ui-config";
import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";
import toUpper from "lodash/toUpper";
import moment from "moment";

const Notifications = () => {
  const { isDesktop, isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  const allMemberCodes = useSelector(selectMemberCodes);
  const entitilementsData = useSelector(selectEntitlements);
  const { notificationHistory = [] } = useSelector(selectNotificationsData);
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setInit());
  }, []);

  const initialValues = getUIConfig();
  const onSubmit = (values, formikBag) => {
    const { startDate = "" } = values;
    const isNotificationScheduled = moment().isSameOrBefore(
      moment(startDate, "YYYY-MM-DD")
    );
    dispatch(
      saveNotifications({
        params: { values },
        resolve: () => {
          ToasterHandler(
            "looks great",
            Localise(
              messages,
              Localise(
                messages,
                isNotificationScheduled
                  ? `Notification has been successfully scheduled for ${startDate} `
                  : "Notification has been sent successfully."
              )
            )
          );
          formikBag.setSubmitting(false);
          formikBag.resetForm(initialValues);
        },
        reject: () => {
          ToasterHandler(
            "Uh Oh!",
            Localise(
              messages,
              Localise(
                messages,
                "Notification request failed. Please try again."
              )
            )
          );

          formikBag.setSubmitting(false);
        },
      })
    );
  };

  return (
    <View style={tw("mt-5")}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={true}
        // validationSchema={getValidationSchemaPrice(Localise, messages)}
        validateOnBlur={false}
        render={({ values, setFieldValue }) => {
          const {
            memberCodes = [],
            entitlements = [],
            messageBody = "",
            contentStatus = false,
            fileName = "",
          } = values;

          const dropDownValues = allMemberCodes
            .filter((e) => toUpper(e).includes(toUpper(searchQuery)))
            .map((code) => {
              return {
                label: code,
                value: code,
              };
            });

          const entitlementDropDown = [
            "all",
            ...Object.keys(entitilementsData),
          ].map((e) => {
            return {
              label:
                e === "all"
                  ? "Select All"
                  : toUpper(e.replace(/([A-Z])/g, " $1")),
              value: e,
            };
          });

          const selectedData = dropDownValues.filter((code) =>
            memberCodes.includes(code.value)
          );
          const unSelectedData = dropDownValues.filter(
            (code) => !memberCodes.includes(code.value)
          );
          const filteredDropDownData = [
            { label: "Select All", value: "all" },
            ...selectedData,
            ...unSelectedData,
          ];
          return (
            <View style={[tw("flex flex-col")]}>
              <View
                style={[
                  tw("flex flex-row mt-5 flex-wrap justify-between"),
                  { paddingHorizontal: 9, zIndex: 200 },
                ]}
              >
                <FormFieldAutoComplete
                  name="memberCodes"
                  label={"Send to Member Codes"}
                  outerContainerStyle={{
                    zIndex: 10,
                    width: isMobile ? "100%" : 200,
                    paddingLeft: 0,
                  }}
                  labelStyle={fonts.heading4}
                  data={[...filteredDropDownData]}
                  placeholder={
                    memberCodes.length === 1
                      ? Localise(messages, memberCodes[0])
                      : memberCodes.length > 1
                      ? `${memberCodes.length} Shops Selected`
                      : Localise(messages, "Select Shop Codes")
                  }
                  initialDataLength={filteredDropDownData.length}
                  isMultiSelect={true}
                  onSelect={(selectedValue) => {
                    const newMembers = [...memberCodes];
                    const index = newMembers.indexOf(selectedValue.value);
                    const unSelected = index >= 0;
                    unSelected
                      ? newMembers.splice(index, 1)
                      : newMembers.push(selectedValue.value);
                    if (unSelected && selectedValue.value === "all") {
                      setFieldValue("memberCodes", []);
                    } else if (selectedValue.value === "all") {
                      setFieldValue("memberCodes", ["all", ...allMemberCodes]);
                    } else {
                      setFieldValue("memberCodes", newMembers);
                    }
                  }}
                  onBlur={() => setSearchQuery("")}
                  showOnFocus={true}
                  setFocusBack={true}
                  onChangeText={(val) => setSearchQuery(val)}
                  listDisplayValues={["label"]}
                />
                <FormFieldAutoComplete
                  name="entitlements"
                  label={"Send to Entitlements"}
                  outerContainerStyle={{
                    zIndex: 10,
                    width: isMobile ? "100%" : 200,
                    paddingLeft: 0,
                  }}
                  labelStyle={fonts.heading4}
                  data={entitlementDropDown}
                  placeholder={
                    entitlements.length === 1
                      ? Localise(messages, entitlements[0])
                      : entitlements.length > 1
                      ? `${entitlements.length} Entitlemnets Selected`
                      : Localise(messages, "Select Entitlements")
                  }
                  initialDataLength={entitlementDropDown.length}
                  isMultiSelect={true}
                  onSelect={(selectedValue) => {
                    const newMembers = [...entitlements];
                    const index = newMembers.indexOf(selectedValue.value);
                    const unSelected = index >= 0;
                    unSelected
                      ? newMembers.splice(index, 1)
                      : newMembers.push(selectedValue.value);

                    const memberCodesData = [];
                    newMembers.forEach((entry) => {
                      entry !== "all" &&
                        memberCodesData.push(...entitilementsData[entry]);
                    });
                    if (unSelected && selectedValue.value === "all") {
                      setFieldValue("memberCodes", []);
                      setFieldValue("entitlements", []);
                    } else if (selectedValue.value === "all") {
                      setFieldValue("memberCodes", ["all", ...allMemberCodes]);
                      setFieldValue("entitlements", [
                        "all",
                        ...Object.keys(entitilementsData),
                      ]);
                    } else {
                      setFieldValue("entitlements", newMembers);
                      setFieldValue("memberCodes", memberCodesData);
                    }
                  }}
                  onBlur={() => setSearchQuery("")}
                  showOnFocus={true}
                  setFocusBack={true}
                  onChangeText={(val) => setSearchQuery(val)}
                  listDisplayValues={["label"]}
                />
                <FormFieldPicker
                  placeholder={{ label: "Select From", value: "" }}
                  containerStyle={{
                    width: isDesktop ? 200 : "100%",
                  }}
                  data={notificationSenderTypes}
                  name="senderType"
                  label="Category"
                />
                <FormFieldDatePicker
                  dateValueFormat="YYYY-MM-DD"
                  name="startDate"
                  label={"Date"}
                  placeholder="MM/DD/YYYY"
                  containerStyle={{
                    zIndex: 120,
                    paddingLeft: 0,
                    marginLeft: -5,
                    marginTop: -5,
                    width: isMobile ? "100%" : 150,
                    maxWidth: 800,
                    height: Platform.OS !== "web" ? 40 : "auto",
                  }}
                  alignIconRight={true}
                  iconName="calendar"
                  iconType="font-awesome"
                  onValueChange={(val) => {}}
                  errorStyle={{ paddingBottom: 0 }}
                />
                <FormFieldDatePicker
                  name={`startTime`}
                  label={Localise(messages, "Time")}
                  timeIntervals={30}
                  iconName={"clock"}
                  showTimeSelect={true}
                  showTimeSelectOnly={true}
                  timeCaption={Localise(messages, "Time")}
                  alignIconRight={false}
                  dateValueFormat={"YYYY-MM-DDTHH:mm:ss"}
                  containerStyle={{ width: 140, marginTop: -5 }}
                  dateFormat={"h:mm a"}
                  popperPlacement={"bottom"}
                  placeholder="HH:MM"
                />
              </View>
              <View
                style={[
                  tw("flex flex-row items-center"),
                  { paddingHorizontal: 9, marginVertical: 10 },
                ]}
              >
                <LabelField
                  text={"Upload an excel sheet with member codes"}
                  style={{
                    ...fonts.default,
                    marginRight: 10,
                  }}
                />
                <LabelField
                  text={fileName}
                  style={{
                    ...fonts.default,
                    marginHorizontal: fileName ? 20 : 0,
                  }}
                />
                <FileSelector
                  testID={`excel_data`}
                  accessibilityLabel="save"
                  disabled={false}
                  image={fileName}
                  buttonStyle={{ paddingVertical: 7 }}
                  containerStyle={{ margin: 0, marginRight: 5, width: 100 }}
                  onSelect={(data) => {
                    setFieldValue("memberCodes", data.memberCodes);
                    setFieldValue("fileName", data.fileName);
                  }}
                  readExcelData={true}
                />
              </View>
              <FormField
                name={"messageTitle"}
                label={"Message Preview"}
                labelStyle={fonts.heading4}
                placeholder={"Enter Message Preview"}
                containerStyle={{
                  width: "100%",
                  marginTop: isMobile ? 8 : 0,
                  paddingHorizontal: 9,
                  zIndex: -1,
                }}
                multiline={true}
                numberOfLines={3}
              />
              <CustomRTE
                value={messageBody}
                placeholder={Localise(messages, "Enter Message")}
                styles={{ paddingHorizontal: 0 }}
                setValue={(val) => {
                  setFieldValue("messageBody", val);
                }}
                onEditorStateChange={() => {
                  if (contentStatus) return;
                  setFieldValue("contentStatus", true);
                }}
              />

              <SaveCancelButtons
                buttonTitle={Localise(messages, "Send")}
                onCancel={() => {}}
              />
            </View>
          );
        }}
      />
      {notificationHistory.length ? (
        <Section
          title={`Notification History (${
            notificationHistory.filter((data) => data.type !== "CATALOG").length
          })`}
          defaultOpen={true}
          children={<HistoryDetails />}
        />
      ) : null}
    </View>
  );
};

const HistoryDetails = () => {
  const dispatch = useDispatch();
  const { notificationHistory = [] } = useSelector(selectNotificationsData);
  const onPress = (data) => {
    dispatch(setSideCar("notification-details"));
    dispatch(setSideCarTitle(`Notification Details`));
    dispatch(setSideCarData(data));
  };
  return (
    <View style={[{ height: 400 }, tw("flex flex-col")]}>
      <ScrollView>
        {notificationHistory
          .filter((data) => data.type !== "CATALOG")
          .sort(
            (a, b) =>
              moment(b.availableDate || b.createdOn) -
              moment(a.availableDate || a.createdOn)
          )
          .map((data, index) => {
            const {
              type = "",
              messagePayload = {},
              updatedOn = "",
              availableDate = "",
            } = data;
            const date = moment(updatedOn).format("l");
            const availableOn = moment(availableDate).format("l");
            return (
              <View key={index} style={tw("flex flex-row")}>
                <TouchableOpacity
                  style={{
                    borderWidth: 1,
                    borderColor: colors.grayScaleLight,
                    opacity: 1,
                    marginBottom: 10,
                    padding: 10,
                    flex: 1,
                    backgroundColor: colors.secondary,
                  }}
                  onPress={() => onPress(data)}
                >
                  <LabelField
                    text={`Created Date: ${date}`}
                    style={{ ...fonts.style2, marginTop: 4 }}
                  />
                  <LabelField
                    text={`Scheduled Date: ${availableOn}`}
                    style={{ ...fonts.style2, marginTop: 4 }}
                  />
                  <LabelField
                    text={`Category: ${type.replaceAll("_", " ")}`}
                    style={{ ...fonts.style2, marginTop: 4 }}
                  />
                  <LabelField
                    style={{ ...fonts.style2, marginTop: 4 }}
                    text={`Subject: ${messagePayload.subject}`}
                  />
                </TouchableOpacity>
              </View>
            );
          })}
      </ScrollView>
    </View>
  );
};
export default Notifications;
