/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useContext, memo } from "react";
import { View, Text } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import I18NContext from "library/contexts/i18N";
import {
  fetchZoneDetailsByLatLng,
  fetchLatLngAndZoneDetailsById,
  setZoneDetails,
  setGeoCodeDetails,
} from "library/sagas/views/home/drawer/create-order/slice";
import {
  selectIsEditOrder,
  selectZoneDetails,
  selectGeoCodeDetails,
} from "library/sagas/views/home/drawer/create-order/selector";
import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";
import { isSameAddress } from "library/utils/createOrder";

import { colors } from "styles/theme";
import tw from "tailwind-rn";
import get from "lodash/get";
import every from "lodash/every";
import { generateDeliveryInfoContent } from "components/views/drawer/order-details/helper";

const DeliveryZone = memo(
  ({
    memberCode = "",
    index,
    customStyle = [],
    orderDetailsPage = false,
    isVertical = false,
    zIndex = -1,
  }) => {
    const dispatch = useDispatch();
    const { messages, Localise } = useContext(I18NContext);
    const { values, setFieldValue } = useFormikContext();

    const isEditOrder = useSelector(selectIsEditOrder);
    const [{ name: zoneName = "", autoRouteId = "" } = {}] =
      useSelector(selectZoneDetails);
    const geoCodeDetails = useSelector(selectGeoCodeDetails) || {};
    const { latitude = "", longitude = "" } = geoCodeDetails;
    const orderDetailsResponse = useSelector(selectApiResponse);
    const { deliveryZoneId } = get(
      orderDetailsResponse,
      `orderItems.${index}.deliveryInfo`,
      {}
    );
    const respRecipientInfo = get(
      orderDetailsResponse,
      `orderItems.${index}.recipientInfo`,
      {}
    );
    const formikRecipient = get(
      values,
      `orderItems.${index}.recipientInfo`,
      {}
    );
    const addressChanged = !isSameAddress(respRecipientInfo, formikRecipient);

    const {
      addressLine1 = "",
      suite = "",
      city = "",
      country = "",
      state = "",
      zip = "",
    } = isEditOrder
      ? addressChanged
        ? formikRecipient
        : respRecipientInfo
      : orderDetailsPage
      ? respRecipientInfo
      : formikRecipient;

    const latLngZoneDependencies = [
      memberCode,
      addressLine1,
      city,
      country,
      state,
      zip,
    ];
    const latlngParams = {
      zipCode: zip,
      addressLine1: encodeURIComponent(addressLine1),
      suite: encodeURIComponent(suite),
      city: encodeURIComponent(city),
      state,
      country,
    };

    const getLatLngAndZoneDetails = useCallback(() => {
      // empty check for dependencies
      if (every(latLngZoneDependencies, (val) => !!val)) {
        dispatch(
          fetchZoneDetailsByLatLng({
            params: { shopCode: memberCode, latlngParams },
          })
        );
      }
    }, [...latLngZoneDependencies]);

    const getLatLngAndZoneDetailsById = useCallback(() => {
      // empty check for dependencies
      if (every([...latLngZoneDependencies, deliveryZoneId], (val) => !!val)) {
        dispatch(
          fetchLatLngAndZoneDetailsById({
            params: { shopCode: memberCode, deliveryZoneId, latlngParams },
          })
        );
      }
    }, [memberCode, deliveryZoneId]);

    useEffect(() => {
      if (isEditOrder) {
        if (deliveryZoneId && !addressChanged) {
          getLatLngAndZoneDetailsById();
        } else {
          getLatLngAndZoneDetails();
        }
      } else {
        if (orderDetailsPage && deliveryZoneId) {
          getLatLngAndZoneDetailsById();
        } else {
          getLatLngAndZoneDetails();
        }
      }

      return () => {
        dispatch(setZoneDetails([]));
        dispatch(setGeoCodeDetails({}));
      };
    }, [...latLngZoneDependencies]);

    useEffect(() => {
      setFieldValue(
        `orderItems.${index}.deliveryInfo.deliveryZoneId`,
        autoRouteId
      );
    }, [zoneName]);

    useEffect(() => {
      setFieldValue(`orderItems.${index}.recipientInfo.latitude`, latitude);
      setFieldValue(`orderItems.${index}.recipientInfo.longitude`, longitude);
    }, [geoCodeDetails]);

    return isVertical ? (
      generateDeliveryInfoContent({
        label: "Delivery Zone",
        name: zoneName,
        testID: "create-order-zone",
        zIndex,
      })
    ) : (
      <View style={[tw("flex flex-row items-start"), { marginBottom: 6 }]}>
        <Text
          style={[
            {
              color: colors.primary,
              paddingHorizontal: 5,
              fontSize: 12,
            },
            ...customStyle,
          ]}
          testID="create-order-zone-label"
        >
          {Localise(messages, `Delivery Zone`)}:
        </Text>
        <Text
          style={[{ color: colors.primary, fontSize: 12 }, ...customStyle]}
          testID="create-order-zone-name"
        >
          {Localise(messages, `${zoneName}`)}
        </Text>
      </View>
    );
  }
);

export default DeliveryZone;
