import React, { useContext, useEffect, useState } from "react";
import {
  View,
  ScrollView,
  Platform,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
  TouchableOpacity,
  ImageBackground,
  Image,
} from "react-native";
import {
  Form,
  FormField,
  FormFieldCheckBox,
  SubmitButton,
} from "components/elements/forms";
import { Text } from "react-native-elements";
import * as Yup from "yup";
import { DeviceContext } from "library/contexts/appSettings";
import UserAuthContext from "library/contexts/userAuth";
import ForgotPassword from "./forgot-password";
import UnlockPassword from "./unlock-password";
import { TermsAndConditionsLink } from "./terms-conditions";
import { HoverableOpacity, Picker } from "components/elements";
import IMAGES from "static/assets/images";
import { fonts, backgroundColors, colors } from "styles/theme";
import tw from "tailwind-rn";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { reset as resetCurrentOrders } from "library/sagas/ongoing/current-orders/slice";
import { reset as resetStaffListing } from "library/sagas/ongoing/staff-listing/slice";
import { setShopCode } from "library/sagas/ongoing/global-data/slice";
import {
  setCreateOrderShopCode,
  setShopCodeForDraftOrder,
} from "library/sagas/views/home/drawer/create-order/slice";

import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { useNavigation, CommonActions } from "@react-navigation/native";
import * as LocalAuthentication from "expo-local-authentication";
import DeviceInfo from "react-native-device-info";
import * as Keychain from "react-native-keychain";
import AppSettingsStorage from "library/storage/appSettings";
import I18NContext from "library/contexts/i18N";
import Environment from "library/utils/environment";
import { request } from "library/utils/request";
import UtilStorage from "library/storage/utilStorage";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import UserAuthStorage from "library/storage/userAuth";
import Logger from "library/utils/logger";
//import UserAuthUtils from "library/utils/userAuth";

let fcmService;

if (Platform.OS !== "web") {
  fcmService = require("library/utils/PushNotifications/FCMService").fcmService;
}

const Login = (props) => {
  const { isDesktop } = useContext(DeviceContext);
  const [isForgotPwd, setForgotPwd] = useStateIfMounted(false);
  const [isUnlockPwd, setUnlockPwd] = useStateIfMounted("hide");
  const [userId, setUserId] = useStateIfMounted("");
  //const [isEmailSent, showEmailSent] = useStateIfMounted(false);
  const isEmailSent = false;

  const {
    messages,
    AllowedLocales,
    currentLocale,
    setCurrentLocale,
    Localise,
  } = React.useContext(I18NContext);

  const changeLanguage = (value) => {
    AppSettingsStorage.setLanguage(value);
    setCurrentLocale(value);
  };

  const onReturnFromForgotPwd = (sentEmail) => {
    //sentEmail && showEmailSent(true);
    setForgotPwd(false);
  };

  useEffect(() => {
    if (loginType === "MANUAL") {
      UserAuthStorage.clearSSOUser();
    }
  }, []);
  const [loginType, setLoginType] = useState("");
  const isSsoUser = UserAuthStorage.getSSOUser();
  if (isSsoUser === "MANUAL") {
    setLoginType(isSsoUser);
    UserAuthStorage.clearSSOUser();
  }

  // When user previously logged out from app and when user opened or resumed app from any state (background/killed/foreground)
  // by click on notification in notification center then login screen will come so we are clearing all notifications in this case.
  useEffect(() => {
    if (Platform.OS !== "web") {
      fcmService.onNotificationOpenedListener((payload) =>
        fcmService.clearAllNotifications()
      );
      //fcmService.setApplicationBadge(0);
    }
  }, []);

  return (
    <ImageBackground
      fsClass="fs-unmask"
      source={IMAGES[`ftdBg`]}
      style={{ width: "100%", height: "100%" }}
    >
      <KeyboardAvoidingView
        style={tw("flex-1")}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        enabled
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              fsClass="fs-unmask"
              style={{
                ...tw("items-center justify-between"),
                minHeight: 600,
                minWidth: isDesktop ? 500 : 350,
                paddingHorizontal: 50,
                paddingVertical: 20,
                shadowColor: backgroundColors.black,
                borderColor: "#e2e2e2",
                borderWidth: 1,
                // shadowOffset: {
                //   width: 2,
                //   height: 3,
                // },
                // shadowRadius: 10,
                // shadowOpacity: 0.2,
                // elevation: 3,
              }}
            >
              <View style={tw("items-center justify-center p-6 mb-6")}>
                <Image
                  source={IMAGES["ftdLogo"]}
                  style={{
                    width: isDesktop ? 115 : 88,
                    height: isDesktop ? 115 : 88,
                    marginBottom: 20,
                  }}
                />
                {!isForgotPwd && isUnlockPwd !== "screen" && !isEmailSent && (
                  <Text
                    style={[
                      tw("text-center"),
                      {
                        fontFamily: fonts.fontFamily.bold,
                        fontSize: isDesktop ? 28 : 18,
                        color: backgroundColors.primary,
                        fontStyle: "italic",
                      },
                    ]}
                  >
                    {Localise(messages, "Welcome to")}
                  </Text>
                )}
                <Text
                  style={[
                    tw("text-center"),
                    {
                      fontFamily: fonts.fontFamily.bold,
                      fontSize: isDesktop ? 28 : 18,
                      color: backgroundColors.primary,
                      fontStyle: "italic",
                    },
                  ]}
                >
                  {isForgotPwd
                    ? Localise(messages, "Forgot your password?")
                    : isUnlockPwd === "screen"
                    ? Localise(messages, "Unlock your password")
                    : isEmailSent
                    ? Localise(messages, "Email Sent!")
                    : Localise(messages, "Mercury HQ")}
                </Text>
              </View>
              <View style={tw(`w-full`)}>
                {isForgotPwd ? (
                  <ForgotPassword
                    userId={userId}
                    showLogin={onReturnFromForgotPwd}
                  />
                ) : isUnlockPwd === "screen" ? (
                  <UnlockPassword
                    userId={userId}
                    showLogin={() => setUnlockPwd(false)}
                  />
                ) : isEmailSent ? (
                  <Text
                    style={{
                      ...fonts.heading5,
                      fontWeight: "600",
                      marginBottom: 300,
                    }}
                  >
                    Please check your email and follow the instructions to reset
                    your password
                  </Text>
                ) : (
                  <>
                    <LoginForm
                      loginType={loginType}
                      isUnlockPwd={isUnlockPwd}
                      setUnlockPwd={setUnlockPwd}
                      setForgotPwd={setForgotPwd}
                      setUserId={setUserId}
                      isDesktop={isDesktop}
                      props={props}
                    />
                    {AllowedLocales.length > 1 && (
                      <View
                        style={tw(
                          `flex items-baseline items-center ${
                            isDesktop ? "mt-5" : "mt-4"
                          }`
                        )}
                      >
                        <Text style={{ ...fonts.heading4, padding: 10 }}>
                          {Localise(messages, "Language")}
                        </Text>
                        <Picker
                          containerStyle={{
                            width: "50%",
                          }}
                          items={AllowedLocales}
                          placeholder={{}}
                          value={currentLocale}
                          onValueChange={(value) => changeLanguage(value)}
                        ></Picker>
                      </View>
                    )}
                  </>
                )}
              </View>
              {!isEmailSent && (
                <View
                  style={tw(
                    `items-center justify-center ${
                      isDesktop ? "pt-20" : "pt-6"
                    }`
                  )}
                >
                  {!isDesktop && (
                    <Text
                      style={{
                        ...fonts.heading2,
                        color: backgroundColors.primary,
                        fontWeight: "600",
                        fontSize: 14,
                      }}
                    >
                      Mercury HQ
                    </Text>
                  )}
                  <TermsAndConditionsLink />
                </View>
              )}
            </View>
          </ScrollView>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </ImageBackground>
  );
};

const LoginForm = ({
  isUnlockPwd,
  setUnlockPwd,
  setForgotPwd,
  setUserId,
  isDesktop,
  props,
  loginType,
}) => {
  const { signIn } = React.useContext(UserAuthContext);
  const [error, setError] = useStateIfMounted("");
  const [isPasswordSecure, setIsPasswordSecure] = useStateIfMounted(true);
  const navigation = useNavigation();
  const { messages, Localise } = React.useContext(I18NContext);
  const dispatch = useDispatch();

  const isSSOEnabled = Environment.get("ENABLE_SSO_LOGIN", false);
  //const deviceType = UserAuthUtils.getDeviceType();
  const isWebBrowser = false; //deviceType === "web";

  const validateLogin = (values, formikBag) => {
    // Need ro remove this when we modified Logout related logic like with out depending on API reponse if we removed current, completed orders and staff on logout.
    props.setShopCode("all");
    props.setCreateOrderShopCode("");
    dispatch(setShopCodeForDraftOrder(""));
    props.resetCurrentOrders();
    props.resetStaffListing();
    performSignIn(values, formikBag);
  };

  const validationSchema = Yup.object().shape({
    userId: Yup.string()
      .label("userId")
      .email(Localise(messages, "Enter a valid User ID"))
      .required(Localise(messages, "Enter User ID")),
    password: Yup.string()
      .label("password")
      .required(Localise(messages, "Enter Password")),
  });
  const ssoValidationSchema = Yup.object().shape({
    userId: Yup.string()
      .label("userId")
      .email(Localise(messages, "Enter a valid User ID"))
      .required(Localise(messages, "Enter User ID")),
    password: Yup.string().label("password"),
  });

  const performSignIn = (values, formikBag) => {
    Logger.info("Verifying the login action ", Platform.OS);
    signIn(values).catch((err) => {
      if (err.wrongPasswordAttempts >= 3) {
        setError(
          Localise(
            messages,
            "You’ve attempted to login too many times with an incorrect password.\nYour account has been locked.\nPlease contact your system administrator, or click"
          )
        );
        setUnlockPwd("link");
      } else if (err.wrongPasswordAttempts < 3) {
        navigation.dispatch(
          CommonActions.navigate({
            name: "update-password",
            params: {
              email: values.userId,
            },
          })
        );
      } else if (err === "INVALID_LOGIN_ATTEMPT") {
        setError(
          Localise(
            messages,
            "Incorrect credentials/Unauthorized access!\n\nPlease try again or contact your system administrator."
          )
        );
        setUnlockPwd("hide");
      } else if (err === "REQUEST_TIME_OUT") {
        setError("Request timed out, please try again.");
        setUnlockPwd("hide");
      }

      if (formikBag) formikBag.setSubmitting(false);
    });
  };

  const [isCredentialsStored, setIsCredentialsStored] =
    useStateIfMounted(false);

  const promptForFingerprint = async () => {
    const hasBiometric = await LocalAuthentication.hasHardwareAsync();
    const isBiometricEnrolled = await LocalAuthentication.isEnrolledAsync();

    if (hasBiometric && isBiometricEnrolled) {
      const isCredentialsStored = await Keychain.hasInternetCredentials(
        DeviceInfo.getBundleId()
      );

      // To remove saved credentials from device keychain, we need to call this below method.
      // const userGenCredentials = await Keychain.resetInternetCredentials(
      //   DeviceInfo.getBundleId()
      // );

      if (isCredentialsStored) {
        setIsCredentialsStored(true);

        try {
          const storedCredentials = await Keychain.getInternetCredentials(
            DeviceInfo.getBundleId(),
            {
              authenticationPrompt: {
                title: "Login with Biometric",
              },
            }
          );

          if (storedCredentials) {
            // Doing silent authentication in background with stored credentials which are fetched from device keychain
            performSignIn(
              {
                userId: storedCredentials.username,
                password: storedCredentials.password,
              },
              null
            );
          } else {
            console.log("No credentials found in device keychain.");
          }
        } catch (error) {
          console.log(
            "Unable to fetch saved credentials in device keychain - " +
              error.message
          );
        }
      } else {
        console.log("No credentials saved earlier in device keychain.");
      }
    }
  };

  useEffect(() => {
    if (Platform.OS === "ios" || Platform.OS === "android") {
      if (Environment.get("update_needed", false) !== true)
        promptForFingerprint();
    } else {
      request("get-build-info", { platform: Platform.OS }).then((buildInfo) => {
        const { config: { version = "" } = {} } = buildInfo;
        const currentRelease = UtilStorage.getReleaseVersion("CurrentVersion");
        if (currentRelease !== version) {
          UtilStorage.setReleaseVersion(version);
          window.location.reload();
        }
      });
      props.setCurrentPage("login");
    }
  }, []);

  return (
    <Form
      initialValues={{
        userId: "",
        password: "",
        keepMeSignedIn: true, // isWebBrowser ? false : true,
      }}
      onSubmit={(values, formikBag) => validateLogin(values, formikBag)}
      validationSchema={
        loginType === "MANUAL" ? validationSchema : ssoValidationSchema
      }
      render={(formikData) => {
        return (
          <>
            {!!error && (
              <View style={tw("flex flex-row items-center justify-center")}>
                {isUnlockPwd === "link" ? (
                  <Text style={fonts.error}>
                    {error}{" "}
                    <Text
                      style={fonts.link1}
                      onPress={() => setUnlockPwd("screen")}
                    >
                      {Localise(messages, "here")}
                    </Text>{" "}
                    {Localise(messages, "to reset your password.")}
                  </Text>
                ) : (
                  <Text style={fonts.error}>{error}</Text>
                )}
              </View>
            )}
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              keyboardType="email-address"
              name="userId"
              placeholder={"Email"}
              textContentType="emailAddress"
              isUpdateOnChange={true}
              containerStyle={{
                width: isDesktop ? 360 : 250,
                alignSelf: "center",
              }}
            />
            {(loginType === "MANUAL" || !isSSOEnabled) && (
              <FormField
                fsClass="fs-exclude"
                autoCapitalize="none"
                autoCorrect={false}
                name="password"
                placeholder={"Password"}
                textContentType="password"
                isUpdateOnChange={true}
                containerStyle={{
                  width: isDesktop ? 360 : 250,
                  alignSelf: "center",
                }}
                iconPosition={false}
                secureTextEntry={isPasswordSecure}
                iconType="ionicon"
                iconName={
                  !isEmpty(formikData.values.password)
                    ? isPasswordSecure
                      ? "eye"
                      : "eye-off"
                    : ""
                }
                onIconPress={() => {
                  setIsPasswordSecure(!isPasswordSecure);
                }}
              />
            )}
            <View
              style={[
                tw(
                  `flex flex-row mb-4 self-center justify-${
                    isWebBrowser ? "between " : "end"
                  }`
                ),
                { width: isDesktop ? 360 : 250 },
              ]}
            >
              {isWebBrowser && (
                <FormFieldCheckBox
                  iconRight={false}
                  name="keepMeSignedIn"
                  size={20}
                  title={"Keep me signed in"}
                  containerStyle={{ padding: 0, paddingLeft: 5 }}
                  inputContainerStyle={{ marginTop: 0, marginRight: 0 }}
                />
              )}
              <TouchableOpacity
                style={[tw("flex flex-col"), { paddingRight: 5 }]}
                onPress={() => {
                  setForgotPwd(true);
                  setUserId(formikData.values.userId);
                }}
                testID="forgot_pwd"
                accessibilityLabel="forgot_pwd"
              >
                {isDesktop ? (
                  <HoverableOpacity
                    hoverStyle={{ textDecorationLine: "underline" }}
                    onPress={() => {
                      setForgotPwd(true);
                      setUserId(formikData.values.userId);
                    }}
                  >
                    <Text
                      style={{
                        ...fonts.link1,
                        ...{
                          fontSize: 15,
                          fontWeight: "400",
                          textDecorationLine: "none",
                        },
                        ...tw("text-right"),
                      }}
                    >
                      {Localise(messages, "Forgot Password?")}
                    </Text>
                  </HoverableOpacity>
                ) : (
                  <Text
                    style={{
                      ...fonts.link1,
                      ...tw("text-right"),
                      fontWeight: "400",
                      fontSize: 11,
                    }}
                  >
                    {Localise(messages, "Forgot Password?")}
                  </Text>
                )}
              </TouchableOpacity>
            </View>
            <SubmitButton
              containerStyle={{
                margin: 5,
                alignItems: "center",
              }}
              title={"Login"}
              titleStyle={{
                fontWeight: isDesktop ? "400" : "600",
                fontSize: isDesktop ? 19 : 14,
              }}
              isSubmitOnEnter={true}
              disableOnErrors={true}
              disabledStyle={{
                backgroundColor: backgroundColors.secondary,
                borderWidth: 1,
                borderColor: colors.grayScaleLight,
              }}
            />

            {(Platform.OS === "ios" || Platform.OS === "android") &&
              isCredentialsStored && (
                <>
                  <TouchableOpacity
                    onPress={promptForFingerprint}
                    style={{
                      ...tw("flex items-center"),
                      marginTop: 30,
                      marginBottom: 20,
                    }}
                  >
                    <Image
                      source={IMAGES["fingerprint"]}
                      style={{
                        width: 48,
                        height: 48,
                      }}
                    />
                  </TouchableOpacity>
                </>
              )}
          </>
        );
      }}
    />
  );
};

// Need ro remove this when we modified Logout related logic like with out depending on API reponse if we removed current, completed orders and staff on logout.
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetCurrentOrders,
      resetStaffListing,
      setShopCode,
      setCurrentPage,
      setCreateOrderShopCode,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Login);
