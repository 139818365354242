import React from "react";
import { View, ActivityIndicator } from "react-native";
import styles from "./styles";

const SpinnerComp = ({ size = "small", testID = "loader" }) => (
  <View style={styles.loading}>
    <ActivityIndicator size={size} testID={testID} />
  </View>
);

export default SpinnerComp;
