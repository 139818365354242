/* eslint-disable react-hooks/rules-of-hooks */
import React, { memo, useMemo, useCallback, useContext } from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import tw from "tailwind-rn";
import { useFormikContext } from "formik";

import { Picker, ToasterHandler } from "components/elements";
import { FormFieldAutoComplete } from "components/elements/forms";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import UserProfileStorage from "library/storage/userProfile";

import { areShopLocationsEqual } from "components/views/drawer/delivery/routes-dashboard/upsert-route/helper";
import styles from "../../styles";

// eslint-disable-next-line react/display-name
const RouteShopDetails = memo(
  ({
    shopCode,
    setShopCode,
    filteredShopCodes,
    memberQuery,
    setMemberQuery,
    startLocationCode,
    localPermissions,
    routeData = {},
    isEditRoute,
    ordersList,
    setOrdersList,
    setShowShopRemovedModal,
    setShopRemoved,
    setEraseMap,
    getDriversAndSaveRoute,
    hasPickupStopWithSameAddress,
    disableRouteEdit,
  }) => {
    const shopNames = UserProfileStorage.getAllShopNames();
    const { messages, Localise } = useContext(I18NContext);
    const { isDesktop } = useContext(DeviceContext);
    const isSmallScreen = !isDesktop;
    const { values, setValues } = useFormikContext();
    const { allShopCodes = [], selectedMemberCodes = [] } = values;
    const finalValues = cloneDeep(values);
    const selectedCount = selectedMemberCodes.length;
    const allLabel = Localise(messages, "All");

    const hasOrderWithShop = useCallback(
      (removedShop) => {
        return ordersList?.ordersInRoute?.some(
          ({ receivingMember: { memberCode: siteId = "" } = {} }) =>
            areShopLocationsEqual(removedShop, siteId)
        );
      },
      [ordersList?.ordersInRoute]
    );

    const handleShopChange = (selectedValue) => {
      const { selectedMemberCodes = [] } = finalValues;
      if (selectedValue.label === allLabel) {
        if (selectedMemberCodes?.length !== selectedValue?.value?.length) {
          set(finalValues, "selectedMemberCodes", [...selectedValue.value]);
          if (isEditRoute) {
            setEraseMap(true);
            getDriversAndSaveRoute(finalValues.selectedMemberCodes);
          }
        }
      } else {
        const index = selectedMemberCodes.indexOf(selectedValue.value);
        const isSelected = index >= 0;
        if (isSelected) {
          if (selectedMemberCodes?.length > 1) {
            if (isEditRoute) {
              if (hasOrderWithShop(selectedValue.value)) {
                setShowShopRemovedModal(true);
                setShopRemoved([selectedValue.value]);
              } else {
                selectedMemberCodes.splice(index, 1);

                //Remove pickup stop for shop when startLocation removed
                let updatedOrdersInRoute = [...ordersList.ordersInRoute];
                const isShopStartLocation = areShopLocationsEqual(
                  startLocationCode,
                  selectedValue.value
                );

                if (
                  isShopStartLocation &&
                  hasPickupStopWithSameAddress(selectedMemberCodes[0])
                ) {
                  updatedOrdersInRoute = ordersList.ordersInRoute?.filter(
                    (ord) =>
                      !(
                        ord.stopType === "PICKUP" &&
                        areShopLocationsEqual(
                          selectedMemberCodes[0],
                          get(ord, "receivingMember.memberCode")
                        )
                      )
                  );
                  const message = `${Localise(
                    messages,
                    `Start Location changed.`
                  )} ${Localise(messages, `Removed Pickup stop for`)} ${
                    selectedMemberCodes[0]
                  } ${shopNames[selectedMemberCodes[0]]}. ${Localise(
                    messages,
                    `Please review.`
                  )}`;
                  ToasterHandler("success", Localise(messages, message));
                }

                setOrdersList({
                  ...ordersList,
                  ordersInRoute: updatedOrdersInRoute,
                });
                setEraseMap(true);
                getDriversAndSaveRoute(selectedMemberCodes);
              }
            } else {
              selectedMemberCodes.splice(index, 1);
            }
          }
        } else {
          selectedMemberCodes.push(selectedValue.value);
          if (isEditRoute) {
            setEraseMap(true);
            getDriversAndSaveRoute(selectedMemberCodes);
          }
        }
      }
      setMemberQuery("");
      setValues(finalValues);
    };

    const shopData = useMemo(
      () =>
        allShopCodes.filter((code) =>
          code.label?.toLowerCase().includes(memberQuery?.toLowerCase())
        ),
      [allShopCodes, memberQuery]
    );

    return (
      <>
        {/* Shop Selection */}
        {filteredShopCodes.length > 1 ? (
          <View
            style={{
              ...tw("flex-row items-center"),
              zIndex: 55,
            }}
          >
            <View style={styles.labelTextContainer}>
              <Text style={{ paddingRight: 15, ...styles.labelText }}>
                {Localise(messages, "Shop")}:
              </Text>
            </View>
            <View
              style={styles.valueText}
              pointerEvents={disableRouteEdit ? "none" : "auto"}
            >
              {localPermissions.multiShopRoute ? (
                <FormFieldAutoComplete
                  name="selectedMemberCodes"
                  data={shopData}
                  initialDataLength={allShopCodes.length}
                  showOnFocus={true}
                  setFocusBack={true}
                  placeholder={
                    selectedCount
                      ? `${selectedCount} ${Localise(messages, "Selected")}`
                      : `${Localise(messages, "Select Shop Codes")}`
                  }
                  listDisplayValues={["label"]}
                  outerContainerStyle={{
                    width: isSmallScreen ? "100%" : "80%",
                    ...styles.shop,
                  }}
                  isMultiSelect={true}
                  onSelect={handleShopChange}
                  onChangeText={(text) => {
                    const arr = text.split(",");
                    setMemberQuery(arr[arr.length - 1]);
                  }}
                />
              ) : (
                <>
                  {isEmpty(routeData) ? (
                    <Picker
                      containerStyle={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        paddingLeft: 0,
                        paddingRight: 10,
                      }}
                      innerContainerStyle={{
                        width: isSmallScreen ? "100%" : "80%",
                        maxWidth: 800,
                      }}
                      items={[
                        ...filteredShopCodes.map((code) => ({
                          label: `${code} ${shopNames[code]}`,
                          value: code,
                        })),
                      ]}
                      placeholder={{}}
                      value={shopCode}
                      onValueChange={(val) => {
                        setShopCode(val);
                        UserProfileStorage.setSelectedShopCode(val);
                        UserProfileStorage.setRouteShopList([val]);
                      }}
                      enabled={false}
                    />
                  ) : (
                    <Text>
                      {`${
                        routeData?.memberCodes
                          ?.map(
                            (eachShop) => `${eachShop} ${shopNames[eachShop]}`
                          )
                          ?.join(",\n") || ""
                      }`}
                    </Text>
                  )}
                </>
              )}
            </View>
          </View>
        ) : null}
      </>
    );
  }
);

export default RouteShopDetails;
