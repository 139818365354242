import { createSlice } from "@reduxjs/toolkit";

import { SLICE_NAME as sliceName } from "./constants";
import { SLICE_NAME as parentSlice } from "../constants";

export const initialState = {
  scorecardDate: "",
  ratingDetails: [{}],
  comments: {
    rejectComments: [{}],
    cancelComments: [{}],
    drpComments: [{}],
  },
};

const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setReset: (state) => {
      Object.assign(state, initialState);
    },
    setSelectedDate: (state, action) => {
      state.scorecardDate = action.payload;
    },
    setScorecardRating: (state, action) => {
      state.ratingDetails = action.payload;
    },
    setScorecardComments: (state, action) => {
      state.comments = action.payload;
    },
    fetchScorecardRating: () => {},
    fetchScorecardComments: () => {},
  },
});

export const {
  setReset,
  setSelectedDate,
  setScorecardRating,
  setScorecardComments,
  fetchScorecardRating,
  fetchScorecardComments,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
