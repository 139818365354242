export const UIConfig = [
  { key: "nonRouted", title: "Non-Routed Orders", displayStatus: "open" },
];

export const RoutesUIConfig = [
  { label: "activeRoutes", title: "Active Routes", displayStatus: "open" },
  {
    label: "completedRoutes",
    title: "Completed Routes",
    displayStatus: "close",
  },
  {
    label: "deletedRoutes",
    title: "Deleted Routes",
    displayStatus: "close",
  },
];

export const InitOrdersData = {
  nonRouted: { orders: [] },
};

export const InitViewMoreCounts = {
  routes: 8,
  activeRoutes: 8,
  completedRoutes: 8,
  deletedRoutes: 8,
};

export const ListOfColumns = [
  "displayDeliveryDate",
  "messageNumber",
  "recipientCity",
  "recipientName",
  "productFirstChoiceDescription",
  "zoneName",
];

export const ListOfMobileColumns = [
  "displayPrice",
  "displayDeliveryDate",
  "deliveredDateTime",
  "deliveryInstructions",
  "messageNumber",
  "recipientCity",
  "zip",
  "recipientName",
  "locationType",
  "productFirstChoiceDescription",
  "items",
  "zoneName",
];

export const ListOfDriverMobileColumns = [
  "displayDeliveryDate",
  "deliveredDateTime",
  "deliveryInstructions",
  "stopDescription",
  "recipientName",
  "stopName",
  "recipientAddressLine",
  "recipientCity",
  "zip",
];

export const ListOfSubColumns = {
  displayDeliveryDate: "deliveryInstructions",
  messageNumber: "",
  recipientCity: ["zip", "isAvsVerified"],
  recipientName: "locationType",
  productFirstChoiceDescription: "items",
};

export const ListOfOrderStatus = {
  set1: {
    start: "1w",
    status: [
      "ACKNOWLEDGED",
      "PRINTED",
      "DESIGN",
      "DESIGNED",
      "OUT_FOR_DELIVERY",
      "ACKNOWLEDGE_PRINT",
      "CANCELLED",
      "DELIVERED",
    ],
  },
};

export const ListOfSubActions = {
  // "view-chats": [{ key: "markAsUnread", label: "Mark Unread" }],
};

//Actions in Route Listing(Routes Tab/Delivery Dashboard) 3 dots
export const ListOfRouteActions = {
  DRAFT: [
    { key: "INPROGRESS", label: "Start Route" },
    { key: "DELETED", label: "Delete Route" },
  ],
  PLANNED: [
    { key: "INPROGRESS", label: "Start Route" },
    { key: "DELETED", label: "Delete Route" },
  ],
  INPROGRESS: [{ key: "COMPLETED", label: "End Route" }],
  COMPLETED: [],
  INCOMPLETE: [{ key: "COMPLETED", label: "Complete Route" }],
  DELETED: [],
  DRIVER: [{ key: "EDIT", label: "Edit Route" }],
};

export const ListOfFilters = {
  "Date Range": [
    {
      title: "Today",
      value: "0",
    },
    {
      title: "Tomorrow",
      value: "1",
    },
    {
      title: "This Week",
      value: "7",
    },
    {
      title: "This Month",
      value: "this-month",
    },
    { title: "Custom Range", value: "custom-date-range" },
  ],
};

export const ListOfSortOptions = [
  { key: "Price: Low to High", label: "Price: Low to High" },
  { key: "Price: High to Low", label: "Price: High to Low" },
  { key: "Order Status (A to Z)", label: "Order Status: A-Z" },
  { key: "Order Status (Z to A)", label: "Order Status: Z-A" },
  { key: "Delivery Date: New to Old", label: "Delivery Date: New to Old" },
  { key: "Delivery Date: Old to New", label: "Delivery Date: Old to New" },
  {
    key: "Default: Delivery Date + Rush/Timed",
    label: "Default: Delivery Date + Rush/Timed",
  },
];

export const OrderFetchInterval = 2 * 60 * 1000; // 2 mins

export const RouteStatusImages = {
  DRAFT: "accepted",
  PLANNED: "design",
  INPROGRESS: "delivery",
  COMPLETED: "completed",
  INCOMPLETE: "incomplete",
  DELETED: "cancelled",
};
