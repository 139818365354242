/* eslint-disable react/display-name */
import { setPageActions } from "library/sagas/views/home/drawer/orders-new/slice";

import {
  CustomHeaderComponent,
  StatusCellRenderer,
  OrderCellRenderer,
  RecipientCellRenderer,
  CustomerCellRenderer,
  DateCellRenderer,
  PriceCellRenderer,
  ProductCellRenderer,
  CustomFilterComponent,
  ActionsCellRenderer,
  DesignerCellRenderer,
  DeliveryServiceCellRenderer,
  FullfillmentCellRenderer,
  AssignmentsCellRenderer,
} from "./cells";

export const orderGroupsConfig = [
  {
    category: "all",
    tabs: [{ label: "All", value: "all" }],
  },
  {
    category: "actions",
    tabs: [{ label: "Needs Action", value: true, badge: 4 }],
  },
  {
    category: "date",
    tabs: [
      { label: "Today", value: 0, hidden: false },
      { label: "Tomorrow", value: 1, hidden: false },
      { label: "Future", value: 2, hidden: false },
      { label: "This Week", value: 3, hidden: true },
      { label: "This Month", value: 4, hidden: true },
      { label: "Custom", value: 5, hidden: true },
    ],
  },
  {
    category: "delivery_status",
    tabs: [
      { label: "Undelivered", value: "Accepted" },
      { label: "Outgoing", value: "Sent" },
      { label: "Delivered", value: "Delivered" },
    ],
  },
  {
    category: "order_status",
    tabs: [
      { label: "Cancelled", value: "Cancelled" },
      { label: "Rejected", value: "Rejected" },
      { label: "Forfeited", value: "Forfeited" },
    ],
  },
];

export const columnIDs = {
  status: "fulfillment",
  order_id: "external_id",
  fulfillment_date: "fulfillment_date",
  customer: "contact",
  recipient: "recipient",
  products: "products",
  price: "price",
  actions: "actions",
  designer: "designer",
  sentiment: "sentiment",
  delivery_service: "delivery_service",
  fullfillment: "fullfillment",
  assignments: "assignments",
};

export const columnSortOptions = {
  fulfillment_status_asc: `${columnIDs.status}::status::ASC`,
  fulfillment_status_dsc: `${columnIDs.status}::status::DESC`,
  mhq_number_asc: `${columnIDs.order_id}::external_id::ASC`,
  mhq_number_dsc: `${columnIDs.order_id}::external_id::DESC`,
  mol_number_asc: `${columnIDs.order_id}::mol_number::ASC`,
  mol_number_dsc: `${columnIDs.order_id}::mol_number::DESC`,
  wire_number_asc: `${columnIDs.order_id}::wire_number::ASC`,
  wire_number_dsc: `${columnIDs.order_id}::wire_number::DESC`,
  fulfillment_date_asc: `${columnIDs.fulfillment_date}::fulfillment_date::ASC`,
  fulfillment_date_dsc: `${columnIDs.fulfillment_date}::fulfillment_date::DESC`,
  timed_delivery_asc: `${columnIDs.fulfillment_date}::fulfillment_time::ASC`,
  timed_delivery_dsc: `${columnIDs.fulfillment_date}::fulfillment_time::DESC`,
  customer_first_name_asc: `${columnIDs.customer}::first_name::ASC`,
  customer_first_name_dsc: `${columnIDs.customer}::first_name::DESC`,
  customer_last_name_asc: `${columnIDs.customer}::last_name::ASC`,
  customer_last_name_dsc: `${columnIDs.customer}::last_name::DESC`,
  customer_email_name_asc: `${columnIDs.customer}::email::ASC`,
  customer_email_name_dsc: `${columnIDs.customer}::email::DESC`,
  recipient_first_name_asc: `${columnIDs.recipient}::first_name::ASC`,
  recipient_first_name_dsc: `${columnIDs.recipient}::first_name::DESC`,
  recipient_last_name_asc: `${columnIDs.recipient}::last_name::ASC`,
  recipient_last_name_dsc: `${columnIDs.recipient}::last_name::DESC`,
  recipient_address_asc: `${columnIDs.recipient}::address.address_line1::ASC`,
  recipient_address_dsc: `${columnIDs.recipient}::address.address_line1::DESC`,
  recipient_location_name_asc: `${columnIDs.recipient}::address.city::ASC`,
  recipient_location_name_dsc: `${columnIDs.recipient}::address.city::DESC`,
  amount_asc: `${columnIDs.price}::totals.total::ASC`,
  amount_dsc: `${columnIDs.price}::totals.total::DESC`,
};

const getSortStatus = (sort = "", gridState) => {
  const [colId = "", rowId = "", label = ""] = sort.split("::");
  const filterState =
    gridState?.find((e) => e?.colId === colId)?.appliedSort || "";
  return filterState === `${colId}::${rowId}::${label}`;
};

export const columnParams = {
  resizable: true,
  editable: false,
  hide: false,
  suppressMenu: false,
  appliedSearch: "",
  appliedFilter: {},
  appliedSort: "",
};

export const columnDefs = (dispatch, gridState, showDesigners) => {
  return {
    [columnIDs.status]: {
      ...columnParams,
      colId: columnIDs.status,
      headerName: "Status",
      menuTabs: ["filterMenuTab", "generalMenuTab"],
      width: 190,
      headerComponent: CustomHeaderComponent,
      cellRenderer: StatusCellRenderer,
      filter: CustomFilterComponent,
      filterParams: {
        filterOptions: [
          {
            rowId: "status.in",
            heading: "Order Status",
            filters: [
              {
                label: "Incoming",
                name: "Incoming",
              },
              {
                label: "Accepted",
                name: "Accepted",
              },
              {
                label: "In Design",
                name: "In Design",
              },
              {
                label: "Designed",
                name: "Designed",
              },
              {
                label: "Out for Delivery",
                name: "Out for Delivery",
              },
              {
                label: "Delivered",
                name: "Completed",
              },
            ],
          },
          {
            rowId: "actions.type.in",
            heading: "Needs Action",
            filters: [
              {
                label: "FILLER_REJECT",
                name: "Filler Reject",
              },
              {
                label: "DS_FULFILLMENT_ERROR",
                name: "DS Fulfillment Error",
              },
              {
                label: "ACCEPT",
                name: "Accept",
              },
              {
                label: "SETTLEMENT_ERROR",
                name: "Settlement Error",
              },
              {
                label: "REFUND_ERROR",
                name: "Refund Error",
              },
              {
                label: "PRICE_REQUEST",
                name: "Price Change Request",
              },
              {
                label: "CANCEL_REQUEST",
                name: "Cancel Request",
              },
              {
                label: "DATE_CHANGE_REQUEST",
                name: "Date Change Request",
              },
              {
                label: "CHAT_MESSAGE",
                name: "View Chats",
              },
              {
                label: "RESPONSE_MESSAGE",
                name: "View Responses",
              },
              {
                label: "HURRY_UP",
                name: "Hurry Up",
              },
              {
                label: "PAYMENT_DUE",
                name: "Payment Due",
              },
              {
                label: "FOLLOW_UP",
                name: "Follow Up",
              },
            ],
          },
        ],
        sortOptions: [
          {
            label: columnSortOptions.fulfillment_status_asc,
            name: "Order Status - Ascending",
            checked: getSortStatus(
              columnSortOptions.fulfillment_status_asc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.fulfillment_status_asc,
                })
              );
            },
          },
          {
            label: columnSortOptions.fulfillment_status_dsc,
            name: "Order Status - Descending",
            checked: getSortStatus(
              columnSortOptions.fulfillment_status_dsc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.fulfillment_status_dsc,
                })
              );
            },
          },
        ],
      },
      cellEditorParams: {
        cellRenderer: StatusCellRenderer,
        highlightMatch: true,
        cellHeight: 40,
        values: [
          {
            label: "INCOMING",
            value: "Incoming",
            color: "#31AA92",
          },
          {
            label: "NEW",
            value: "New",
            color: "#31AA92",
          },
          {
            label: "SENT",
            value: "Sent",
            color: "#31AA92",
          },
          {
            label: "ACCEPTED",
            value: "Accepted",
            color: "#31AA92",
          },
          {
            label: "IN_DESIGN",
            value: "In Design",
            color: "#357975",
          },
          {
            label: "DESIGNED",
            value: "Designed",
            color: "$357975",
          },
          {
            label: "OUT_FOR_DELIVERY",
            value: "Out for Delivery",
            color: "#3B818C",
          },
          {
            label: "DELIVERED",
            value: "Delivered",
            color: "#1F4156",
          },
          {
            label: "CANCELLED",
            value: "Cancelled",
            color: "#808080",
          },
          {
            label: "REJECTED",
            value: "Rejected",
            color: "#808080",
          },
          {
            label: "ARCHIVED",
            value: "Archived",
            color: "#808080",
          },
          {
            label: "FORFEITED",
            value: "Forfeited",
            color: "#808080",
          },
          {
            label: "FORWARDED",
            value: "Forwarded",
            color: "#31AA92",
          },
        ],
      },
    },
    [columnIDs.order_id]: {
      ...columnParams,
      colId: columnIDs.order_id,
      headerName: "Order",
      menuTabs: ["filterMenuTab", "generalMenuTab"],
      width: 250,
      headerComponent: CustomHeaderComponent,
      cellRenderer: OrderCellRenderer,
      filter: CustomFilterComponent,
      filterParams: {
        sortOptions: [
          {
            label: columnSortOptions.mhq_number_asc,
            name: "MHQ Number Ascending",
            checked: getSortStatus(columnSortOptions.mhq_number_asc, gridState),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.mhq_number_asc,
                })
              );
            },
          },
          {
            label: columnSortOptions.mhq_number_dsc,
            name: "MHQ Number Descending",
            checked: getSortStatus(columnSortOptions.mhq_number_dsc, gridState),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.mhq_number_dsc,
                })
              );
            },
          },
          {
            label: columnSortOptions.mol_number_asc,
            name: "MOL Number Ascending",
            checked: getSortStatus(columnSortOptions.mol_number_asc, gridState),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.mol_number_asc,
                })
              );
            },
          },
          {
            label: columnSortOptions.mol_number_dsc,
            name: "MOL Number Descending",
            checked: getSortStatus(columnSortOptions.mol_number_dsc, gridState),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.mol_number_dsc,
                })
              );
            },
          },
          {
            label: columnSortOptions.wire_number_asc,
            name: "Wire Number Ascending",
            checked: getSortStatus(
              columnSortOptions.wire_number_asc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.wire_number_asc,
                })
              );
            },
          },
          {
            label: columnSortOptions.wire_number_dsc,
            name: "Wire Number Descending",
            checked: getSortStatus(
              columnSortOptions.wire_number_dsc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.wire_number_dsc,
                })
              );
            },
          },
        ],
      },
    },
    [columnIDs.fulfillment_date]: {
      ...columnParams,
      colId: columnIDs.fulfillment_date,
      headerName: "Delivery",
      menuTabs: ["filterMenuTab", "generalMenuTab"],
      headerComponent: CustomHeaderComponent,
      cellRenderer: DateCellRenderer,
      width: 200,
      filter: CustomFilterComponent,
      filterParams: {
        filterOptions: [
          {
            rowId: "fulfillment_date",
            heading: "Delivery Date Range",
            filters: [
              {
                label: "Today",
                name: "Today",
              },
              {
                label: "Tomorrow",
                name: "Tomorrow",
              },
              {
                label: "Future",
                name: "Future",
              },
              {
                label: "This Week",
                name: "This Week",
              },
              {
                label: "This Month",
                name: "This Month",
              },
              {
                label: "customDatePicker",
                name: "Custom",
              },
            ],
          },
          {
            rowId: "fulfillment_details",
            heading: "Timed Delivery",
            filters: [
              {
                label: "timed**true",
                name: "Timed Deliveries",
              },
              {
                label: "rush**true",
                name: "Rush Deliveries",
              },
            ],
          },
        ],
        sortOptions: [
          {
            label: columnSortOptions.fulfillment_date_asc,
            name: "Delivery Date Ascending",
            checked: getSortStatus(
              columnSortOptions.fulfillment_date_asc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.fulfillment_date_asc,
                })
              );
            },
          },
          {
            label: columnSortOptions.fulfillment_date_dsc,
            name: "Delivery Date Descending",
            checked: getSortStatus(
              columnSortOptions.fulfillment_date_dsc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.fulfillment_date_dsc,
                })
              );
            },
          },
          {
            label: columnSortOptions.timed_delivery_asc,
            name: "Timed Deliveries Ascending",
            checked: getSortStatus(
              columnSortOptions.timed_delivery_asc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.timed_delivery_asc,
                })
              );
            },
          },
          {
            label: columnSortOptions.timed_delivery_dsc,
            name: "Timed Deliveries Descending",
            checked: getSortStatus(
              columnSortOptions.timed_delivery_dsc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.timed_delivery_dsc,
                })
              );
            },
          },
        ],
      },
    },
    [columnIDs.customer]: {
      ...columnParams,
      colId: columnIDs.customer,
      headerName: "Customer",
      menuTabs: ["filterMenuTab", "generalMenuTab"],
      width: 300,
      headerComponent: CustomHeaderComponent,
      cellRenderer: CustomerCellRenderer,
      filter: CustomFilterComponent,
      filterParams: {
        sortOptions: [
          {
            label: columnSortOptions.customer_first_name_asc,
            name: "First Name Ascending",
            checked: getSortStatus(
              columnSortOptions.customer_first_name_asc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.customer_first_name_asc,
                })
              );
            },
          },
          {
            label: columnSortOptions.customer_first_name_dsc,
            name: "First Name Descending",
            checked: getSortStatus(
              columnSortOptions.customer_first_name_dsc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.customer_first_name_dsc,
                })
              );
            },
          },
          {
            label: columnSortOptions.customer_last_name_asc,
            name: "Last Name Ascending",
            checked: getSortStatus(
              columnSortOptions.customer_last_name_asc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.customer_last_name_asc,
                })
              );
            },
          },
          {
            label: columnSortOptions.customer_last_name_dsc,
            name: "Last Name Descending",
            checked: getSortStatus(
              columnSortOptions.customer_last_name_dsc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.customer_last_name_dsc,
                })
              );
            },
          },
          {
            label: columnSortOptions.customer_email_name_asc,
            name: "Email Name Ascending",
            checked: getSortStatus(
              columnSortOptions.customer_email_name_asc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.customer_email_name_asc,
                })
              );
            },
          },
          {
            label: columnSortOptions.customer_email_name_dsc,
            name: "Email Name Descending",
            checked: getSortStatus(
              columnSortOptions.customer_email_name_dsc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.customer_email_name_dsc,
                })
              );
            },
          },
        ],
      },
    },
    [columnIDs.recipient]: {
      ...columnParams,
      colId: columnIDs.recipient,
      headerName: "Recipient",
      menuTabs: ["filterMenuTab", "generalMenuTab"],
      headerComponent: CustomHeaderComponent,
      cellRenderer: RecipientCellRenderer,
      filter: CustomFilterComponent,
      width: 300,
      filterParams: {
        sortOptions: [
          {
            label: columnSortOptions.recipient_first_name_asc,
            name: "First Name Ascending",
            checked: getSortStatus(
              columnSortOptions.recipient_first_name_asc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_first_name_asc,
                })
              );
            },
          },
          {
            label: columnSortOptions.recipient_first_name_dsc,
            name: "First Name Descending",
            checked: getSortStatus(
              columnSortOptions.recipient_first_name_dsc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_first_name_dsc,
                })
              );
            },
          },
          {
            label: columnSortOptions.recipient_last_name_asc,
            name: "Last Name Ascending",
            checked: getSortStatus(
              columnSortOptions.recipient_last_name_asc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_last_name_asc,
                })
              );
            },
          },
          {
            label: columnSortOptions.recipient_last_name_dsc,
            name: "Last Name Descending",
            checked: getSortStatus(
              columnSortOptions.recipient_last_name_dsc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_last_name_dsc,
                })
              );
            },
          },
          {
            label: columnSortOptions.recipient_address_asc,
            name: "Address Ascending",
            checked: getSortStatus(
              columnSortOptions.recipient_address_asc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_address_asc,
                })
              );
            },
          },
          {
            label: columnSortOptions.recipient_address_dsc,
            name: "Address Descending",
            checked: getSortStatus(
              columnSortOptions.recipient_address_dsc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_address_dsc,
                })
              );
            },
          },
          {
            label: columnSortOptions.recipient_location_name_asc,
            name: "Location Name Ascending",
            checked: getSortStatus(
              columnSortOptions.recipient_location_name_asc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_location_name_asc,
                })
              );
            },
          },
          {
            label: columnSortOptions.recipient_location_name_dsc,
            name: "Location Name Descending",
            checked: getSortStatus(
              columnSortOptions.recipient_location_name_dsc,
              gridState
            ),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_location_name_dsc,
                })
              );
            },
          },
        ],
      },
    },
    [columnIDs.products]: {
      ...columnParams,
      colId: columnIDs.products,
      headerName: "Products",
      menuTabs: ["filterMenuTab"],
      headerComponent: CustomHeaderComponent,
      cellRenderer: ProductCellRenderer,
      filter: CustomFilterComponent,
      width: 500,
    },
    [columnIDs.price]: {
      ...columnParams,
      colId: columnIDs.price,
      headerName: "Amount",
      menuTabs: ["generalMenuTab"],
      headerComponent: CustomHeaderComponent,
      cellRenderer: PriceCellRenderer,
      filter: CustomFilterComponent,
      width: 150,
      filterParams: {
        sortOptions: [
          {
            label: columnSortOptions.amount_asc,
            name: "Amount Ascending",
            checked: getSortStatus(columnSortOptions.amount_asc, gridState),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.amount_asc,
                })
              );
            },
          },
          {
            label: columnSortOptions.amount_dsc,
            name: "Amount Descending",
            checked: getSortStatus(columnSortOptions.amount_dsc, gridState),
            action: () => {
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.amount_dsc,
                })
              );
            },
          },
        ],
      },
    },
    [columnIDs.designer]: {
      ...columnParams,
      colId: columnIDs.designer,
      headerName: "Designer",
      field: "designer.id",
      hide: true,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      enableRowGroup: showDesigners,
      rowGroup: showDesigners,
      width: 150,
      cellRenderer: DesignerCellRenderer,
    },
    [columnIDs.delivery_service]: {
      ...columnParams,
      colId: columnIDs.delivery_service,
      headerName: "Delivery Service",
      suppressMenu: true,
      width: 150,
      cellRenderer: DeliveryServiceCellRenderer,
    },
    [columnIDs.fullfillment]: {
      ...columnParams,
      colId: columnIDs.fullfillment,
      headerName: "Fullfillment",
      menuTabs: ["filterMenuTab"],
      headerComponent: CustomHeaderComponent,
      cellRenderer: FullfillmentCellRenderer,
      filter: CustomFilterComponent,
      width: 150,
      filterParams: {
        filterOptions: [
          {
            rowId: "source.in",
            heading: "Order Source",
            filters: [
              {
                label: "Phone",
                name: "Local Orders",
              },
              {
                label: "Mercury Online",
                name: "Mercury Online",
              },
              {
                label: "FTD",
                name: "FTD",
              },
              {
                label: "Teleflora",
                name: "Teleflora",
              },
              {
                label: "Bloomnet",
                name: "Bloomnet",
              },
              {
                label: "Email",
                name: "Email Orders",
              },
              {
                label: "DoorDash",
                name: "DoorDash",
              },
              {
                label: "UberEats",
                name: "UberEats",
              },
              {
                label: "eFlorist",
                name: "TEL eFlorist",
              },
            ],
          },
        ],
      },
    },
    [columnIDs.assignments]: {
      ...columnParams,
      colId: columnIDs.assignments,
      headerName: "Assignments",
      menuTabs: ["filterMenuTab"],
      headerComponent: CustomHeaderComponent,
      cellRenderer: AssignmentsCellRenderer,
      filter: CustomFilterComponent,
      width: 150,
      filterParams: {
        filterOptions: [
          {
            rowId: "fulfillment_details.delivery_zone_id.in",
            heading: "Zone",
            filters: [],
          },
        ],
      },
    },
    [columnIDs.actions]: {
      ...columnParams,
      colId: columnIDs.actions,
      headerName: "Actions",
      menuTabs: ["filterMenuTab"],
      headerComponent: CustomHeaderComponent,
      cellRenderer: ActionsCellRenderer,
      filter: CustomFilterComponent,
      width: 150,
      filterParams: {
        filterOptions: [
          {
            rowId: "print_details.details_printed.in",
            heading: "Printed State",
            filters: [
              {
                label: "true",
                name: "Printed",
              },
              {
                label: "false",
                name: "Unprinted",
              },
            ],
          },
        ],
      },
    },
  };
};

export const needsActionMappings = {
  FILLER_REJECT: {
    image: "replace-filler",
    color: "#622355",
    label: "Fulfillment Error",
  },
  DS_FULFILLMENT_ERROR: {
    image: "replace-filler",
    color: "#622355",
    label: "Fulfillment Error",
  },
  ACCEPT: {
    image: "approved-price",
    color: "#60A17B",
    label: "Price Approved",
  },
  SETTLEMENT_ERROR: {
    image: "payment-error",
    color: "#602454",
    label: "Payment Error",
  },
  REFUND_ERROR: {
    image: "payment-error",
    color: "#602454",
    label: "Payment Error",
  },
  PRICE_REQUEST: {
    image: "price",
    color: "#b50d81",
    label: "Approve Price",
  },
  CANCEL_REQUEST: {
    image: "price",
    color: "#b50d81",
    label: "Approve Price",
  },
  DATE_CHANGE_REQUEST: {
    image: "date",
    color: "#b50d81",
    label: "Approve Date",
  },
  CHAT_MESSAGE: {
    image: "chat",
    color: "#3E5C6D",
    label: "Chat Message",
  },
  RESPONSE_MESSAGE: {
    image: "chat",
    color: "#3E5C6D",
    label: "Chat Message",
  },
  HURRY_UP: {
    image: "chat",
    color: "#3E5C6D",
    label: "Chat Message",
  },
  PAYMENT_DUE: {
    image: "payment-error",
    color: "#602454",
    label: "Payment Error",
  },
  NEW_PICKUP: {
    image: "new-pickups",
    color: "#60A17B",
    label: "New Pickup",
  },
};

export const oldPrintActionKeys = {
  OrderDetail: "print",
  TriFoldCard: "triCardPrint",
  Invoice: "invoice",
  DesignerWorksheet: "designerWorksheetPrint",
  OrderMessages: "orderMessages",
};

export const newPrintActionKeys = {
  OrderDetail: "details_printed",
  TriFoldCard: "trifold_printed",
  Invoice: "invoice_printed",
  DesignerWorksheet: "worksheet_printed",
  OrderMessages: "order_messages",
};

export const meetBallActionKeys = {
  design: "In Design",
  designed: "Designed",
  "out-for-delivery": "Out for Delivery",
};

export const getDeliveryMethod = (source, fulfillmentType, eros_details) => {
  return source === "Mercury Online"
    ? fulfillmentType === "Florist Delivered"
      ? "MOL_FLORIST_DELIVERED"
      : "MOL_CUSTOMER_PICKUP"
    : source === "Phone"
    ? eros_details && eros_details.filler === eros_details.sender
      ? "FLORIST_DELIVERED"
      : fulfillmentType === "Pickup"
      ? "STORE_PICKUP"
      : fulfillmentType === "Phone Out"
      ? "PHONE_OUT"
      : "FLORIST_PARTNER"
    : ["UberEats", "DoorDash"].includes(source)
    ? "PARTNER_STORE_PICKUP"
    : ["GrubHub", "FTD"].includes(source)
    ? "FLORIST_PARTNER"
    : source === "Walk In"
    ? "WALK_IN"
    : "FLORIST_DELIVERED"; // "Teleflora", "Bloomnet", "Email", "eFlorist"
};
