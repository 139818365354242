import moment from "moment";
import IMAGES from "static/assets/images";

const checkBoxPlaceholder = () =>
  `<img src="${IMAGES["checkbox-unchecked-dark"]}" width="13" height="13">`;

export const relatedMessageInfo = (
  Localise,
  messages,
  relatedMessage,
  orderPrice
) => {
  const {
    messageType,
    messageNumber = "",
    comments = "",
    deliveryDate,
    price: messagePrice,
    operator: messageOperator,
    createdOn,
  } = relatedMessage;
  const messageDateAndTime = moment(createdOn).format(
    "ddd MMM DD YYYY hh:mm A"
  );
  return `<div class="table-responsive">
  <table class="table">
    <tbody>
      <tr style="font-size:14px !important;">
        <td>
          <label>${Localise(messages, "Total Order Price")}</label>
        </td>
        <td>$${parseFloat(orderPrice).toFixed(2)}</td>
      </tr>
      <tr style="font-size:14px !important;">
        <td><label>${Localise(messages, "Message Number")}</label></td>
        <td>${messageNumber}</td>
        <td rowspan="1">${checkBoxPlaceholder()}</td>
      </tr>
      <tr style="font-size:14px !important;">
        <td><label>${Localise(messages, "Message")}</label></td>
        <td><div class="dynamic-content">${comments}</div></td>
      </tr>
      ${
        messageType.includes("DeliveryDate") || messageType.includes("Price")
          ? `<tr style="font-size:14px !important;">
        <td><label>${Localise(messages, "New ")}${Localise(
              messages,
              messageType.includes("Price") ? "Price" : "Date"
            )} </label></td>
        <td>${
          messageType.includes("Price")
            ? `$${messagePrice}`
            : moment(deliveryDate).format("MM/DD/YYYY")
        }</td>
      </tr>`
          : ``
      }
      <tr style="font-size:14px !important;">
        <td><label>${Localise(messages, "Operator")}</label></td>
        <td>${messageOperator} ${messageDateAndTime}</td>
      </tr>
    </tbody>
  </table>
  </div>`;
};

export const filterMapping = {
  needsAction: {},
  acceptOrder: { title: "Incoming", value: "regular:incoming" },
  approveRequests: {
    title: "Approve Requests",
    value: "approve-request",
  },
  viewChats: {
    title: "View Chats",
    value: "view-chats",
  },
  viewResponses: {
    title: "View Responses",
    value: "view-responses",
  },
  newPickups: {
    title: "New Pickup",
    value: "new-pickup:incoming",
  },
  hurryPickups: {
    title: "Hurry Pickup!",
    value: "hurry-pickup:incoming",
  },
};

export const checkDateRangeFilterApplied = (data) => {
  // Check if "This Month" or "Custom Range" exists in appliedFilters or filters
  const filterTitles = ["This Month", "Custom Range"];

  const checkDateRange = (filter) => {
    if (filter && filter["Date Range"]) {
      const title = filter["Date Range"].title;
      return filterTitles.includes(title);
    }
    return false;
  };

  // Check in appliedFilters and filters arrays
  const appliedFiltersCheck = data?.appliedFilters.some(checkDateRange);
  const filtersCheck = data?.filters.some(checkDateRange);

  return appliedFiltersCheck || filtersCheck;
};

export const retryFetchOrdersAfterDelay = async ({
  response = [],
  currentTimeMinus30Seconds = "",
  fetchStatuses = "",
  fetchStartDate = "",
  fetchEndDate = "",
  set2 = {},
  actions = {},
  InitOrdersData = [],
  isCustomDateRangeApplied = {},
  fetchData = () => {},
}) => {
  // Third fetchData call with deltaOrders set to true
  if (!isCustomDateRangeApplied.current) {
    fetchData({
      params: {
        startDate: fetchStartDate,
        endDate: fetchEndDate,
        fetchStatuses,
        memberCodes: undefined,
        deltaOrders: true,
        lastSyncTime: currentTimeMinus30Seconds || "",
        dateType: undefined,
        set2,
      },
      actions,
      originalData: response,
      timeStamp: currentTimeMinus30Seconds,
      initData: InitOrdersData,
      listingType: "current",
    });
  }
};
