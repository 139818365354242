import { put, call, all } from "redux-saga/effects";
import { request } from "library/utils/request";
import {
  setRecordId,
  setDeliveryMethod,
  setSourceMemberCode,
} from "library/sagas/ongoing/current-orders/slice";
import { fetchAllOrdersData } from "library/sagas/ongoing/order-details/slice";
import UserProfileStorage from "library/storage/userProfile";
import get from "lodash/get";
import { InitOrdersData } from "components/views/drawer/delivery/routes-dashboard/config";
import {
  processOrderResponse,
  isOrderEligibleforDeliveryDashboard,
  getNonRoutableOrderReason,
} from "library/utils/orderListing";
import {
  clearRouteCitiesZipcodes,
  setZipApiResponse,
  fetchCitiesFeesForShop,
  fetchCitiesZipcodesForShop,
  saveCitiesZipcodesforShop,
  savecitiesplaceIDs,
  setZipCoverageLoading,
} from "library/sagas/views/home/drawer/delivery/slice.js";

//To fetch order detail of order scanned from route
export function* handlegetOrderFromScan(action = {}) {
  const { params = {}, resolve, reject } = get(action, "payload", {});
  const memberCodes = UserProfileStorage.getProfileMemberCodes();

  try {
    const {
      dm: deliveryMethod = "",
      sm: sourceMemberCode = "",
      oid: orderItemId = "",
      ordersList: { ordersInRoute = [], nonRoutedOrders = [] } = {},
    } = params;

    const orderFromRoute = ordersInRoute.find(
      (obj) => obj.orderItemId === orderItemId
    );

    if (!orderFromRoute) {
      const orderFromNonRoute = nonRoutedOrders.find(
        (obj) => obj.orderItemId === orderItemId
      );
      if (orderFromNonRoute) {
        resolve &&
          resolve({
            scannedOrder: orderFromNonRoute,
            action: "add",
            stopAdditionMethod: "MOBILE_APP_QR_SCAN",
          });
      } else {
        if (deliveryMethod && sourceMemberCode && orderItemId) {
          if (memberCodes.includes(sourceMemberCode)) {
            // setting recordID, deliveryMethod and SourceMemberCode from QR code value
            yield put(setRecordId(orderItemId));
            yield put(setDeliveryMethod(deliveryMethod));
            yield put(setSourceMemberCode(sourceMemberCode));
            yield put(
              fetchAllOrdersData({
                recordId: orderItemId,
                deliveryMethod,
                sourceMemberCode,
                resolve: (orderDetails) => {
                  if (!orderDetails?.orderItems[0]?.deliveryInfo?.routeId) {
                    const updatedResp = processOrderResponse(
                      [orderDetails?.orderItems[0]],
                      { scannedOrders: { orders: [] } },
                      "scannedOrders"
                    );
                    const { orderCollection = {} } = updatedResp;

                    const processedOrder =
                      orderCollection?.scannedOrders?.orders[0] || {};

                    const isOrderEligible = isOrderEligibleforDeliveryDashboard(
                      processedOrder,
                      true, //statusCheck
                      true //routeScan
                    );

                    if (isOrderEligible) {
                      const {
                        orderLastModifiedDate,
                        orderDetailsLastPrintedDate,
                      } = orderDetails;
                      resolve &&
                        resolve({
                          action: "add",
                          stopAdditionMethod: "MOBILE_APP_QR_SCAN",
                          scannedOrder: {
                            ...processedOrder,
                            orderLastModifiedDate,
                            orderDetailsLastPrintedDate,
                          },
                          orderDetails: {
                            ...orderDetails.orderItems[0],
                            priceIncludingDelivery:
                              orderDetails.priceIncludingDelivery || 0,
                            orderAmounts: orderDetails.orderAmounts,
                            orderCustomerInfo: orderDetails.customerInfo,
                          },
                        });
                    } else {
                      reject &&
                        reject(getNonRoutableOrderReason(processedOrder));
                    }
                  } else {
                    reject && reject("Order is part of another route");
                  }
                },
              })
            );
            // Redirecting to orders page.
          } else {
            reject && reject("You don’t have access to this order");
          }
        } else {
          reject && reject("QR code is not from MercuryHQ");
        }
      }
    } else {
      reject && reject("Order is already part of route");
    }
  } catch (error) {
    reject && reject("Failed to open the order from scan, Please try again!");
  }
}

//To fetch orders of different deliveryDates in route
export function* handleFetchOrdersInRoute(action = {}) {
  const { params = {}, resolve, reject } = get(action, "payload", {});

  try {
    const { fetchStops = [] } = params;
    if (fetchStops?.length > 0) {
      let orders = [];
      yield all(
        fetchStops.map((stop, i, stops) => {
          const {
            orderItemId,
            deliveryMethod,
            siteId: sourceMemberCode,
          } = stop;
          return put(
            fetchAllOrdersData({
              recordId: orderItemId,
              deliveryMethod,
              sourceMemberCode,
              resolve: (orderDetails) => {
                const orderItem = get(orderDetails, "orderItems.0", {});
                orders.push(orderItem);
                if (orders?.length === stops.length) {
                  const updatedResp = processOrderResponse(
                    orders,
                    InitOrdersData,
                    "delivery-details"
                  );
                  const { orderCollection = {} } = updatedResp;

                  const processedOrders =
                    orderCollection?.nonRouted?.orders || [];
                  resolve(processedOrders);
                }
              },
            })
          );
        })
      );
    }
  } catch (error) {
    reject && reject("Failed to fetch orders, Please try again!");
  }
}

const processCityZipcodesFeesResponse = (response = [], localFee) => {
  let cityFeeData = {};
  const cityFeeDataIndex = response?.findIndex((row) => !row.zipCode);

  if (cityFeeDataIndex < 0) {
    cityFeeData = {
      city: get(response, "0.city", ""),
      countryId: get(response, "0.countryId", ""),
      state: get(response, "0.state", ""),
      fee: localFee,
      status: "N",
      cityFeeAddedManually: true,
    };
  } else {
    cityFeeData = response.splice(cityFeeDataIndex, 1)[0];
  }
  const sortedZipcodes = response.sort((a, b) =>
    b.status?.localeCompare(a.status)
  );
  sortedZipcodes.unshift(cityFeeData);
  return sortedZipcodes.map((data) => ({
    ...data,
    status: data.status || "N",
    fee: roundTheFees(data.fee || localFee),
  }));
};

const processCitiesFeesResponse = (response) => {
  return response
    .filter((cityData) => cityData.city)
    .map((data) => ({
      ...data,
      fee: roundTheFees(data.fee),
    }));
};

export const roundTheFees = (fee, prevText, isOnChange) => {
  const isValidFee = isValidFeeValue(fee);
  const roundedFee = isValidFee
    ? isOnChange
      ? isMaxDecimalsExceeded(fee)
        ? prevText
        : fee
      : fee % 1 !== 0
      ? (+fee).toFixed(2)
      : parseInt(fee).toString()
    : fee.replace(/[^0-9.]/g, "");
  return roundedFee.toString();
};
export const isMaxDecimalsExceeded = (fee) => {
  const maxDecimalsReached = fee.split(".")[1]?.length > 2;
  return maxDecimalsReached;
};
export const isValidFeeValue = (fee) => {
  const isValid = (fee || fee === 0 || fee === "0") && !isNaN(fee);
  return isValid;
};

export function* handleFetchCitiesZipcodesforShop(action = {}) {
  const {
    payload: { city, countryId, state, shopCode, stopLoading = false } = {},
  } = action;

  const cityZipcodesFeesServiceRequest = (params) =>
    request("get-cityzipcodes-fees", params);

  try {
    const response = yield call(cityZipcodesFeesServiceRequest, {
      shopCode,
      city,
      countryId,
      state,
    });
    const content = processCityZipcodesFeesResponse(response, ""); // sending blank localFee as we just need to get active ZipCodes
    let placeIDs = [];

    try {
      for (let i = 0; i < content.length; i++) {
        if (content[i].zipCode && content[i].status === "Y") {
          const googleMapsServiceRequest = (params) =>
            request("google-place-id", params);

          const googleMapsServiceResponse = yield call(
            googleMapsServiceRequest,
            { zip: content[i].zipCode }
          );
          if (googleMapsServiceResponse.results[0]) {
            placeIDs.push(googleMapsServiceResponse.results[0].place_id);
          }
        }
      }
    } catch (error) {
      console.log("error >>", error);
    }

    yield put(savecitiesplaceIDs(placeIDs));
    yield put(saveCitiesZipcodesforShop({ content, shopCode }));
    if (stopLoading) {
      yield put(setZipCoverageLoading(false));
    }
  } catch (error) {
    console.log(error);
    if (stopLoading) {
      yield put(setZipCoverageLoading(false));
    }
  }
}

export function* handleFetchCitiesFeesForShop(action = {}) {
  const { payload: { shopCode, isLastShop = false } = {} } = action;
  const cityFeesServiceRequest = (params) => request("get-cities-fees", params);
  try {
    const response = yield call(cityFeesServiceRequest, { shopCode });
    const content = processCitiesFeesResponse(response);
    if (content && content.length > 0) {
      yield all(
        content.map((_, i) =>
          put(
            fetchCitiesZipcodesForShop({
              city: content[i].city,
              countryId: content[i].countryId,
              state: content[i].state,
              shopCode,
              stopLoading: i + 1 === content?.length && isLastShop,
            })
          )
        )
      );
    }

    yield put(
      setZipApiResponse({
        path: "feesCoverage.citiesFees",
        content,
      })
    );
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchZipCoverage(action = {}) {
  const { payload: { shopList = [] } = {} } = action;
  try {
    if (shopList?.length > 0) {
      yield put(clearRouteCitiesZipcodes());
      yield put(setZipCoverageLoading(true));
      yield all(
        shopList.map((shopCode, i) =>
          put(
            fetchCitiesFeesForShop({
              shopCode,
              isLastShop: i + 1 === shopList?.length,
            })
          )
        )
      );
    }
  } catch (error) {
    console.log(error);
  }
}
