import * as React from "react";
import { useEffect, useRef } from "react";
import { Button } from "react-native-elements";
import { CommonActions, useNavigation } from "@react-navigation/native";

import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { Picker, SignCapture, Divider } from "components/elements";
import tw from "tailwind-rn";
import { IMAGES } from "static/assets/images";
import moment from "moment";
import { request } from "library/utils/request";

import {
  setRecordId,
  setDconStatus,
} from "library/sagas/ongoing/current-orders/slice";
import { resetBasicInfo as resetDeliveryBasicInfo } from "library/sagas/ongoing/current-delivery/slice";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import { fetchNonRoutedOrders } from "library/sagas/views/home/drawer/delivery/slice";
import { selectShowSignatureModal } from "library/sagas/ongoing/global-data/selector";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import UserProfileStorage from "library/storage/userProfile";
import get from "lodash/get";

import { View, Text, Image, Platform, ActivityIndicator } from "react-native";
import SplitScreen from "components/containers/split-screen";
import UserProfileContext from "library/contexts/userProfile";
import SubHeader from "components/containers/header/sub-header";
import useStateIfMounted from "library/utils/useStateIfMounted";
import Environment from "library/utils/environment";
import I18NContext from "library/contexts/i18N";
import RoutesListing from "components/views/drawer/delivery/routes-dashboard/routes-listing";
import RouteDetails from "components/views/drawer/route-details";
import { Entitlements } from "library/utils/entitlements";
import { theme, fonts, colors } from "styles/theme";

const RoutesScreen = ({
  currentOrdersInfo,
  selectedShopCode,
  setRecordId,
  route,
}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { setParams } = navigation;
  const { params: { routeId: paramsRouteId = "", shopCode: paramsShop } = {} } =
    route;
  const showSignatureModal = useSelector(selectShowSignatureModal);
  const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const { proxyUser } = React.useContext(UserProfileContext);
  const shopNames = UserProfileStorage.getAllShopNames();
  const [routes, setRoutes] = useStateIfMounted([]);
  const [shopCode, setShopCode] = useStateIfMounted(
    paramsShop || selectedShopCode
  );
  const [loading, setLoading] = useStateIfMounted(false);
  const { messages, Localise } = React.useContext(I18NContext);
  const [selectedRecordId, setSelectedRecordId] = useStateIfMounted("");
  const [actionTriggered, setActionTriggered] = useStateIfMounted({});
  const [timeStamp, setTimestamp] = useStateIfMounted(false);
  const [nextOrderY, setNextOrderY] = useStateIfMounted(0);
  const [autoScroll, setAutoScroll] = useStateIfMounted(false);
  const routesTimerToClear = useRef(null);

  const { recordId } = currentOrdersInfo;

  const { permissions = {} } = React.useContext(AppSettingsContext);
  const filteredShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.ROUTES in permissions[memberCode])
      filteredShopCodes.push(memberCode);
  });
  const selectedMemberCode =
    filteredShopCodes.length > 1 && shopCode !== "all"
      ? shopCode || filteredShopCodes[0]
      : filteredShopCodes[0];

  const selectedShopPermissions = get(permissions, selectedMemberCode, {});

  let localPermissions = {
    isOptimizeEnabled: false,
    adhocStops: false,
    multiShopRoute: false,
    editRoute: false,
  };

  const isCFROptimiseEnabled = selectedShopPermissions[
    Entitlements.ROUTES
  ]?.includes(Entitlements.ROUTES_PAGE.ROUTE_CFR_OPTIMIZATION);

  localPermissions.isOptimizeEnabled =
    isCFROptimiseEnabled ||
    selectedShopPermissions[Entitlements.ROUTES]?.includes(
      Entitlements.ROUTES_PAGE.ROUTE_OPTIMIZATION
    );

  localPermissions.adhocStops = selectedShopPermissions[
    Entitlements.ROUTES
  ]?.includes(Entitlements.ROUTES_PAGE.ROUTE_ADHOC_STOPS);

  const considerLocalOrders = selectedShopPermissions[
    Entitlements.DELIVERY
  ]?.includes(Entitlements.DELIVERY_PAGE.ROUTE_CONSIDER_LOCAL_ORDERS);

  localPermissions.editRoute =
    selectedShopPermissions[Entitlements.DRIVER_DASHBOARD]?.includes(
      Entitlements.DRIVER_DASHBOARD_PAGE.EDIT_ROUTE
    ) ||
    selectedShopPermissions[Entitlements.ROUTES]?.includes(
      Entitlements.ROUTES_PAGE.EDIT_ROUTE
    );

  localPermissions.multiShopRoute = filteredShopCodes.some((memberCode) =>
    permissions[memberCode][Entitlements.ROUTES]?.includes(
      Entitlements.ROUTES_PAGE.ROUTE_MULTISHOP_OWNERSHIP
    )
  );

  const showCreateRoute =
    filteredShopCodes.some((memberCode) =>
      permissions[memberCode][Entitlements.DRIVER_DASHBOARD]?.includes(
        Entitlements.DRIVER_DASHBOARD_PAGE.CREATE_ROUTE
      )
    ) &&
    (isTablet || isMobile);
  const pollInterval = 2 * 60 * 1000; // 2 mins
  const startRange = parseInt(
    Environment.get("ROUTES_DEFAULT_START_RANGE", "7")
  );
  const endRange = parseInt(Environment.get("ROUTES_DEFAULT_END_RANGE", "3"));

  const startDate = moment().subtract(startRange, "days").format("YYYY-MM-DD");
  const endDate = moment().add(endRange, "days").format("YYYY-MM-DD");

  const getRoutes = () => {
    if (shopCode !== "all") {
      setLoading(true);
      request("get-routes", {
        shopCode: shopCode,
        deliveryDates: moment().format("YYYY-MM-DD"),
      })
        .then((res) => {
          setRoutes(res);
          setLoading(false);
          setTimestamp(moment().utc().format());
        })
        .catch(() => {
          setLoading(false);
          console.log("error");
        });
    }
  };

  useEffect(() => {
    if (!shopCode || shopCode?.toLowerCase() === "all") {
      setShopCode(filteredShopCodes[0]);
      UserProfileStorage.setSelectedShopCode(filteredShopCodes[0]);
      UserProfileStorage.setRouteShopList([filteredShopCodes[0]]);
    }
    const routeIdInStorage = UserProfileStorage.getSelectedRouteId();

    routeIdInStorage.length && UserProfileStorage.setSelectedRouteId("");

    //To clear filters before entering create/edit-route
    dispatch(resetDeliveryBasicInfo());

    //reset delivery status prefill for form
    dispatch(setDconStatus(""));

    //open route details when route edited
    if (paramsRouteId) {
      setSelectedRecordId(paramsRouteId);
      setRecordId();
    }

    setParams({ routeId: undefined, shopCode: undefined });

    return () => {
      setRecordId();
      setSelectedRecordId();
      setParams({ routeId: undefined, shopCode: undefined });
    };
  }, []);

  useEffect(() => {
    clearTimeout(routesTimerToClear.current);
    if (shopCode !== "all") {
      getRoutes();
    } else {
      filteredShopCodes.length === 1 && setShopCode(filteredShopCodes[0]);
    }
  }, [shopCode]);

  useEffect(() => {
    clearTimeout(routesTimerToClear.current);
    if (shopCode !== "all" && timeStamp && pollInterval) {
      routesTimerToClear.current = setTimeout(() => {
        getRoutes();
      }, pollInterval);
    } else {
      filteredShopCodes.length === 1 && setShopCode(filteredShopCodes[0]);
    }
    return () => {
      clearTimeout(routesTimerToClear.current);
    };
  }, [timeStamp]);

  useEffect(() => {
    actionTriggered !== "" && getRoutes();
  }, [actionTriggered]);

  useEffect(() => {
    //get all orders for routeDeliveryDate to get rush order details to show rush icon on route tile
    if (shopCode !== "all") {
      dispatch(
        fetchNonRoutedOrders({
          startDate,
          endDate,
          shopCode,
          deliveryDate: moment().format("YYYY-MM-DD"),
          considerLocalOrders,
        })
      );
    }
  }, [shopCode]);

  const createRoute = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: `create-route`,
        params: { accessByDriver: "true" },
      })
    );

    UserProfileStorage.setAutoRouteId(undefined);
    dispatch(setCurrentPage("create-route"));
  };

  return (
    <SplitScreen
      showTwoScreens={!!selectedRecordId}
      subHeaderExists={isSmallScreen}
      proxyHeaderExists={proxyUser ? true : false}
      screenToScroll={2}
      autoScreenScroll={autoScroll}
      ScrollYCoordinate={nextOrderY}
    >
      <View fsClass="fs-unmask">
        {isSmallScreen && <SubHeader showTwoScreens={!!recordId} />}
        <View>
          <View style={[tw("flex flex-row")]}>
            <Text
              style={[
                fonts.heading1,
                tw("pt-5 pb-3"),
                isTablet && { fontSize: 16, fontWeight: "400" },
              ]}
            >
              {Localise(messages, "Driver Dashboard")}
            </Text>
            {loading && (
              <ActivityIndicator
                style={{ marginLeft: 10, marginTop: 10 }}
                color={colors.activityIndicator}
                testID={"Driver_Dashboard_loader"}
              />
            )}
          </View>
          {!isMobile && <Divider />}
        </View>
        <>
          <View
            style={[
              tw("items-center flex-row flex-wrap justify-between mt-3"),
              { maxWidth: isDesktop ? 840 : "100%" },
            ]}
          >
            {filteredShopCodes.length > 1 && (
              <View
                style={{
                  ...tw("flex-row items-center"),
                  paddingBottom: 10,
                }}
              >
                <Text style={{ paddingRight: 15 }}>
                  {Localise(messages, "Shop")}:
                </Text>
                <Picker
                  containerStyle={{
                    flexDirection: "row",
                    justifyContent: "center",
                    paddingBottom: 0,
                  }}
                  innerContainerStyle={{
                    width: 250,
                  }}
                  items={[
                    {
                      label: "Select Shop",
                      value: "all",
                    },
                    ...filteredShopCodes.map((code) => ({
                      label: `${code} ${shopNames[code]}`,
                      value: code,
                    })),
                  ]}
                  placeholder={{}}
                  value={shopCode}
                  onValueChange={(val) => {
                    setShopCode(val);
                    UserProfileStorage.setSelectedShopCode(val);
                    UserProfileStorage.setRouteShopList([val]);

                    //close route details sidecar when shopCode changed
                    setRecordId();
                    setSelectedRecordId();
                  }}
                ></Picker>
              </View>
            )}
            {showCreateRoute &&
              (localPermissions.multiShopRoute ||
                !shopCode?.toLowerCase().includes("all")) && (
                <Button
                  title={Localise(messages, "Create Route")}
                  titleStyle={theme.Button.secondaryTitleStyle}
                  buttonStyle={{
                    ...theme.Button.secondaryButtonStyle,
                    paddingVertical: 6,
                    paddingHorizontal: 10,
                    marginTop: 0,
                    width: 130,
                    height: 35,
                  }}
                  containerStyle={{
                    margin: 0,
                    marginBottom: 10,
                    marginRight: 5,
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    createRoute();
                  }}
                  testID={"create_route"}
                  accessibilityLabel={"create_route"}
                />
              )}
          </View>
          {(filteredShopCodes.length > 1 && shopCode !== "all") ||
          filteredShopCodes.length === 1 ? (
            <View
              style={{
                minHeight: 500,
                zIndex: -1,
                maxWidth: isDesktop ? 840 : "100%",
              }}
            >
              <RoutesListing
                title={"Routes"}
                label={"routes"}
                defaultOpen={true}
                shopCode={shopCode}
                deliveryDate={moment().format("YYYY-MM-DD")}
                setLoading={setLoading}
                routes={routes}
                showMeatBalls={true}
                variation={"sideCar"}
                setSelectedRecordId={setSelectedRecordId}
                actionTriggered={actionTriggered}
                filteredShopCodes={filteredShopCodes}
              />
            </View>
          ) : (
            <View
              style={{
                flex: 1,
                marginTop: Platform.OS === "web" ? 50 : 20,
                alignItems: "center",
                justifyContent: "center",
                minHeight: Platform.OS === "web" ? 500 : 200,
                zIndex: -1,
              }}
            >
              <Image
                style={{
                  width: 100,
                  height: 90,
                }}
                resizeMode="cover"
                source={IMAGES["select-shop"]}
              />
              <Text
                style={{
                  width: 180,
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: "400",
                  lineHeight: 23,
                  marginTop: 40,
                }}
              >
                {Localise(messages, "Select a shop above to get started")}
              </Text>
            </View>
          )}
        </>
      </View>

      <View>
        {isSmallScreen && (
          <SubHeader
            isOrderDetailView={true}
            title={`Routes`}
            onTrigger={() => {
              setSelectedRecordId("");
            }}
          />
        )}
        <>
          {showSignatureModal && <SignCapture showModal={showSignatureModal} />}
          <RouteDetails
            shopCode={shopCode}
            selectedRecordId={selectedRecordId}
            triggerAction={setActionTriggered}
            showSignatureModal={showSignatureModal}
            getRoutes={getRoutes}
            localPermissions={localPermissions}
            {...{ setAutoScroll, setNextOrderY }}
          />
        </>
      </View>
    </SplitScreen>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    currentOrdersInfo: state.mhq.ongoing.currentOrders,
    selectedShopCode: state.mhq.ongoing.global.shopCode,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setRecordId }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RoutesScreen);
