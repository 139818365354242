import { createSlice, current } from "@reduxjs/toolkit";

import PageStorage from "library/storage/pageStorage";

import isEmpty from "lodash/isEmpty";
import {
  orderGroupsConfig,
  columnIDs,
} from "components/views/drawer/orders-new/config";

import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  loading: false,
  shopCode: "",
  gridInstance: {},
  gridProps: {},
  columnDefination: [],
  api: [],
  data: [],
  designers: [],
  zones: [],
  pageLimit: 10,
  showLimitExceeded: false,
  needsActionCount: 0,
  refreshGrid: false,
  actions: {
    searchQuery: "",
    orderGroups: [
      {
        category: "all",
        values: ["all"],
      },
    ],
    gridState: [],
    showDesigners: false,
  },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setPageInitialise: (state, action) => {
      const pageActions = PageStorage.getNewOrdersPageActions(
        "new_orders_page_actions"
      );
      if (!isEmpty(pageActions)) state.actions = pageActions;

      state.shopCode = action?.payload?.sendingMember ?? "";
      state.gridInstance = action?.payload?.gridInstance ?? {};
      state.gridProps = action?.payload?.gridProps ?? {};
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    resetSlice: (state) => {
      const updatedState = {
        ...initialState,
        shopCode: state.shopCode,
        gridInstance: state.gridInstance,
        gridProps: state.gridProps,
        columnDefination: state.columnDefination,
      };
      Object.assign(state, updatedState);
      PageStorage.setNewOrdersPageActions(
        "new_orders_page_actions",
        updatedState.actions
      );
    },
    setAPIResponse: (state, action) => {
      state.api = action.payload;
    },
    setNeedsActionCount: (state, action) => {
      state.needsActionCount = action.payload;
    },
    setPageData: (state, action) => {
      state.data = action.payload;
    },
    setPageActions: (state, action) => {
      const {
        type = "",
        value = null,
        callback = () => {},
        checked = false,
      } = action.payload;

      // Reset the pagination
      state.api = [];
      state.data = [];

      if (type === "searchQuery") {
        state.actions.searchQuery = value;
        state.refreshGrid = true;
      } else if (type === "columnDefination") {
        state.columnDefination = value;
      } else if (type === "orderGroups") {
        const { category, value: tagValue } = value;

        if (category === "all") {
          state.actions.orderGroups = initialState.actions.orderGroups;
        } else {
          const selectedGroup = current(state).actions.orderGroups.find(
            (e) => e.category === category
          );
          const isValueSelected = selectedGroup?.values.includes(tagValue);
          const remainingGroups = current(state).actions.orderGroups.filter(
            (e) => e.category !== category && e.category !== "all"
          );

          const updatedValues = isValueSelected
            ? selectedGroup.values.filter((e) => e !== tagValue)
            : [...(selectedGroup?.values ?? []), tagValue];

          if (!remainingGroups.length && !updatedValues.length) {
            state.actions.orderGroups = initialState.actions.orderGroups;
          } else {
            if (updatedValues.length) {
              state.actions.orderGroups = [
                ...remainingGroups,
                { category, values: updatedValues },
              ];
            } else {
              state.actions.orderGroups = remainingGroups;
            }
          }

          // Updating the gridState to reflect the same in date filters
          if (category === "date") {
            const gridState = current(state).actions.gridState;
            const dateTabs =
              orderGroupsConfig.find((e) => e.category === "date").tabs || [];
            const dayName =
              dateTabs.find((e) => e.value === tagValue).label || "";

            const existingFilterEntry = gridState.find(
              (e) => e.colId === "fulfillment_date"
            );
            const currentDeliveryDate =
              existingFilterEntry?.appliedFilter?.fulfillment_date || [];

            const updatedFilters = currentDeliveryDate.includes(dayName)
              ? currentDeliveryDate.filter((e) => e !== dayName)
              : [...currentDeliveryDate, dayName];

            const updatedGridState = existingFilterEntry
              ? gridState.map((e) =>
                  e.colId === "fulfillment_date"
                    ? {
                        ...e,
                        ...(updatedFilters.length
                          ? {
                              appliedFilter: {
                                ...e.appliedFilter,
                                fulfillment_date: updatedFilters,
                              },
                            }
                          : { appliedFilter: {} }),
                      }
                    : e
                )
              : [
                  ...gridState,
                  {
                    colId: "fulfillment_date",
                    appliedFilter: {
                      fulfillment_date: [dayName],
                    },
                  },
                ];

            state.actions.gridState = updatedGridState;
          }
        }
        state.refreshGrid = true;
      } else if (type === "saveGridState") {
        const gridCurrentState =
          state?.gridInstance?.columnApi?.getColumnState() ?? [];
        const updatedState = gridCurrentState.map((e) => {
          const colId = e?.colId ?? "";

          return {
            ...(state.actions.gridState.find((j) => j.colId === e.colId) || {}),
            colId,
            hide: e?.hide ?? state?.columnDefination[colId]?.hide,
            width: e?.width ?? state?.columnDefination[colId]?.width,
          };
        });

        state.actions.gridState = updatedState;
      } else if (type === "saveColumnFilter") {
        const gridCurrentState = current(state).actions.gridState;
        const [columnId = "", rowId = "", rowLabel = "", ...restArray] =
          value.split("::");

        /* Start - Updating order group actions to match the date filters */
        const orderGroupDateFilters =
          orderGroupsConfig.find((e) => e.category === "date").tabs || [];
        const dateTabs = orderGroupDateFilters.map((e) => e.label);

        if (dateTabs.includes(rowLabel)) {
          const dayOfTheWeek = orderGroupDateFilters.find(
            (e) => e.label === rowLabel
          ).value;

          slice.caseReducers.setPageActions(state, {
            payload: {
              type: "orderGroups",
              value: { category: "date", value: dayOfTheWeek },
            },
          });
        }
        /* End - Updating order group actions to match the date filters */

        if (gridCurrentState?.length ?? 0) {
          state.actions.gridState = gridCurrentState.map((column) => {
            if (column.colId === columnId) {
              const existingFilters = column.appliedFilter?.[rowId] || [];
              let updatedFilters;
              let appliedFilter;

              if (restArray.length) {
                // To handle date picker selection
                if (columnId === "fulfillment_date") {
                  const [start, end] = restArray;
                  updatedFilters = existingFilters;
                  appliedFilter = {
                    ...column.appliedFilter,
                    [rowId]: updatedFilters,
                    startDate: start,
                    endDate: end,
                  };
                }
              } else {
                updatedFilters = existingFilters.includes(rowLabel)
                  ? existingFilters.filter((value) => value !== rowLabel)
                  : [...existingFilters, rowLabel];

                appliedFilter = updatedFilters.length
                  ? {
                      ...column.appliedFilter,
                      [rowId]: updatedFilters,
                    }
                  : {};
              }

              return {
                ...column,
                appliedFilter,
              };
            }
            return column;
          });
        } else {
          state.actions.gridState = Object.keys(columnIDs).map((key) => {
            const colId = columnIDs[key];
            const appliedFilter =
              colId === columnId
                ? {
                    [rowId]: [`${rowLabel}`],
                  }
                : {};

            return {
              colId,
              appliedFilter,
            };
          });
        }

        const refreshGrid =
          (rowLabel === "customDatePicker" && checked && restArray.length) ||
          (rowLabel === "customDatePicker" && !checked) ||
          rowLabel !== "customDatePicker";
        if (refreshGrid) state.refreshGrid = true;
      } else if (type === "saveColumnSort") {
        const gridCurrentState = current(state).actions.gridState;
        const [columnId = "", rowId = "", sortValue = ""] = value.split("::");

        if (gridCurrentState?.length ?? 0) {
          state.actions.gridState = gridCurrentState?.map((column) => {
            if (column.colId === columnId) {
              const existingSort = column.appliedSort || "";
              const appliedSort =
                existingSort !== `${columnId}::${rowId}::${sortValue}`
                  ? value
                  : "";

              return {
                ...column,
                appliedSort,
              };
            }
            return column;
          });
        } else {
          state.actions.gridState = Object.keys(columnIDs).map((key) => {
            const colId = columnIDs[key];
            const appliedSort = colId === columnId ? value : "";

            return {
              colId,
              appliedSort,
            };
          });
        }
        state.refreshGrid = true;
      } else if (type === "saveColumnSearch") {
        const gridCurrentState = current(state).actions.gridState;
        const [columnId = "", searchValue = ""] = value.split("::");

        if (gridCurrentState?.length ?? 0) {
          state.actions.gridState = gridCurrentState?.map((column) => {
            if (column.colId === columnId) {
              const existingSearch = column.appliedSearch || "";
              const appliedSearch =
                existingSearch === `${columnId}::${searchValue}`
                  ? existingSearch
                  : searchValue.length
                  ? `${columnId}::${searchValue}`
                  : "";

              return {
                ...column,
                appliedSearch,
              };
            }
            return column;
          });
        } else {
          state.actions.gridState = Object.keys(columnIDs).map((key) => {
            const colId = columnIDs[key];
            const appliedSearch = colId === columnId ? value : "";

            return {
              colId,
              appliedSearch,
            };
          });
        }
        callback();
      } else if (type === "showDesigners") {
        state.actions.showDesigners = value?.showDesigners ?? false;
        slice.caseReducers.setPageActions(state, {
          payload: {
            type: "saveGridState",
          },
        });
      }

      !["columnDefination"].includes(type) &&
        PageStorage.setNewOrdersPageActions(
          "new_orders_page_actions",
          current(state).actions
        );
    },
    setPageDesigners: (state, action) => {
      state.designers = action.payload;
    },
    setPageZones: (state, action) => {
      state.zones = action.payload;
    },
    setShowLimitExceeded: (state, action) => {
      state.showLimitExceeded = action.payload;
    },
    setRefreshGrid: (state, action) => {
      state.refreshGrid = action.payload;
    },
    setGridDataSource: () => {},

    triggerRefreshGrid: () => {},
    fetchDesigners: () => {},
    fetchOrders: () => {},
    filterOrders: () => {},
    fetchPrintDetails: () => {},
    triggerMeetBallAction: () => {},
    assignDesigner: () => {},
    followupOrder: () => {},
    orderDetailActions: () => {},
  },
});

export const {
  setPageInitialise,
  setLoading,
  resetSlice,
  setAPIResponse,
  setPageData,
  setPageSearch,
  setOrderGroups,
  setGridState,
  setPageActions,
  setPageDesigners,
  setShowLimitExceeded,
  setRefreshGrid,
  setGridDataSource,
  setNeedsActionCount,
  setPageZones,

  fetchDesigners,
  fetchOrders,
  fetchPrintDetails,
  followupOrder,
  filterOrders,
  assignDesigner,
  triggerMeetBallAction,
  triggerRefreshGrid,
  orderDetailActions,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
