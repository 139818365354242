import { DeviceContext } from "library/contexts/appSettings";
import React, { useContext } from "react";
import { ScrollView, View } from "react-native";
import { Text } from "react-native-elements";
import { backgroundColors, colors, fonts } from "styles/theme";
import tw from "tailwind-rn";

export const DSTermsAndConditionsContent = () => {
  const { isDesktop } = useContext(DeviceContext);

  const customText = (text, style, isBold = true) => {
    return <Text style={[isBold && fonts.heading4, style]}>{text}</Text>;
  };

  return (
    <>
      <View
        style={{
          ...tw("w-full p-3 mb-2"),
          backgroundColor: backgroundColors.primary,
        }}
        fsClass="fs-unmask"
      >
        <Text style={{ ...fonts.heading3, ...tw("text-white text-center") }}>
          FTD DELIVERY SERVICE AGREEMENT
        </Text>
      </View>
      <ScrollView
        style={{
          paddingHorizontal: !isDesktop ? 10 : 0,
          ...fonts.default,
        }}
        fsClass="fs-unmask"
      >
        <View style={tw("py-1")}>
          <Text style={tw("p-2 text-justify")}>
            THIS FTD DELIVERY SERVICE AGREEMENT (this “{customText(`Agreement`)}
            ”), is made and entered into as of the date as accepted in writing
            or electronically by and between FTD, LLC, a Delaware limited
            liability company (“{customText(`FTD`)}”), and the FTD Member
            signing below (“{customText(`Customer`)}). Any use of FTD Delivery
            Service by Customer constitutes acceptance of this Agreement. FTD
            and Customer are sometimes individually referred to herein as a “
            {customText(`Party`)}” and together as the “{customText(`Parties.`)}
            ”
          </Text>
          <Text style={tw("p-2 text-justify")}>
            WHEREAS, Customer desires to participate in a program under which
            FTD will provide a local delivery service (“
            {customText(`FTD Delivery Service`)}”) to facilitate Customer’s
            commercial relationship with Consumers (as defined below) as more
            specifically described in this Agreement and FTD is willing to
            provide such FTD Delivery Service upon the terms and conditions
            herein set forth, and
          </Text>
          <Text style={tw("p-2 text-justify")}>
            WHEREAS, the term “{customText(`Consumer`)}” for purposes of this
            Agreement shall be defined as any person or other legal entity that
            places orders with Customer for local delivery of fresh flowers,
            fresh flower arrangements and/or other products (“
            {customText(`Products`)}”), and “{customText(`Courier Service`)}” is
            the service provided by FTD for local delivery of the Products.
          </Text>
          <Text style={tw("p-2 text-justify")}>
            WHEREAS, Customer is willing to agree to such terms and conditions.
          </Text>
          <Text style={tw("p-2 text-justify")}>
            NOW THEREFORE, in consideration of the mutual covenants herein
            contained, and for good and valuable consideration, the receipt and
            adequacy of which are hereby acknowledged, the Parties agree as
            follows:
          </Text>
          <View style={[tw("flex flex-row"), { width: "100%" }]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("p-2 text-right"), color: colors.primary }}>
                {customText(`1.`)}
              </Text>
            </View>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Status in FTD.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              Upon execution of this Agreement by Customer and acceptance by
              FTD, (i) Customer shall be an FTD Delivery Service Customer and
              (ii) Customer agrees to comply with the FTD Standards. In the
              event that the FTD Standards conflict with the terms of this
              Agreement, the terms of this Agreement shall govern.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("p-2 text-right"), color: colors.primary }}>
                {customText(`2.`)}
              </Text>
            </View>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Fees and Payment Terms.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              Customer agrees to pay all the fees and charges listed on{" "}
              {customText(
                `Exhibit A`,
                { textDecorationLine: "underline" },
                false
              )}{" "}
              attached hereto and made a part hereof for the benefits and
              services provided to it for any of its locations it chooses to
              utilize as a pick-up location. It is further understood and agreed
              however that FTD shall have the right to increase any one or more
              of such fees from time to time during the term of this Agreement
              at its sole discretion. Charges and fees will be included in
              Customer’s monthly clearinghouse statement and paid in accordance
              with FTD clearinghouse rules.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("p-2 text-right"), color: colors.primary }}>
                {customText(`3.`)}
              </Text>
            </View>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`FTD Responsibilities and Services.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              FTD shall provide the following services to Customer during the
              term of this Agreement:
            </Text>
          </View>
          <Text style={{ ...tw("p-2 text-justify ml-10"), width: "90%" }}>
            {customText(`(A)`, {
              marginRight: 5,
            })}
            {customText(`Courier Service.`, {
              textDecorationLine: "underline",
              marginRight: 5,
            })}{" "}
            FTD will provide a Courier Service for local delivery within the zip
            codes designated by FTD for each Customer pick-up location. These
            zip codes shall be updated from time to time and shall be provided
            to Customer in writing and/or via the FTD Delivery Service website.
            The Courier Service shall be performed by a third party not legally
            affiliated with FTD.
          </Text>
          <Text style={{ ...tw("p-2 text-justify ml-10"), width: "90%" }}>
            {customText(`(B)`, {
              marginRight: 5,
            })}
            {customText(`FTD Delivery Service Website.`, {
              textDecorationLine: "underline",
              marginRight: 5,
            })}{" "}
            FTD will provide a method for Customer to enter the necessary order
            information and delivery details via a website (“
            {customText(`FTD Delivery Service Website`)}”) and host such site.
            Such website will display a record of all orders placed and will
            provide updates to delivery order status as is updated by the
            Courier Service. Order updates shall include: order confirmation,
            delivery confirmation and/or order rejection. Additional updates may
            be provided as the platform is enhanced over time.
          </Text>
          <View style={[tw("flex flex-row")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("p-2 text-right"), color: colors.primary }}>
                {customText(`4.`)}
              </Text>
            </View>
            <Text style={{ ...tw("p-2"), color: colors.primary }}>
              {customText(`Responsibilities of Customer.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-1 ml-10")]}>
            <Text style={{ ...tw("mr-3"), color: colors.primary }}>
              {customText(`(A)`)}
            </Text>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              Customer shall abide by the FTD Standards, including clearinghouse
              rules, as may be amended from time to time by FTD.
            </Text>
          </View>
          <View style={[tw("flex flex-row p-2 ml-10")]}>
            <Text style={{ ...tw("mr-3"), color: colors.primary }}>
              {customText(`(B)`)}
            </Text>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              Customer shall use best efforts to enter all information required
              in FTD Delivery Service Website accurately so that Courier Service
              will be able to successfully deliver the order to its intended
              recipient.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("p-2 text-right"), color: colors.primary }}>
                {customText(`5.`)}
              </Text>
            </View>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Term and Termination.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              This Agreement shall extend for a period of one (1) year from the
              date hereof and shall automatically renew from year to year
              thereafter subject to termination effective at the end of the
              first year or at the end of any renewal year thereafter by either
              Party providing written notice of such termination to the other at
              least thirty (30) days prior to the expiration of the first year
              term or any renewal year. Either Party may terminate this
              Agreement by written notice thereof effective immediately in the
              event of a material breach by the other Party of any of its
              obligations hereunder which is not cured within thirty (30) days
              of the receipt by the defaulting Party of written notice thereof.
              FTD may terminate this Agreement at any time upon the delivery of
              written notice to Customer.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("p-2 text-right"), color: colors.primary }}>
                {customText(`6.`)}
              </Text>
            </View>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Severability.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              The Parties agree that, if any term or provision of the Agreement
              shall be found to be void or unenforceable as written, in whole or
              in part, the remainder of this Agreement shall remain in full
              force and effect. The Parties further agree that, in the event of
              such finding of unenforceability, it is their intent that the
              offending term or provision be interpreted and construed in such a
              manner as will carry out the purpose of the clause to the extent
              permitted by law.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("p-2 text-right"), color: colors.primary }}>
                {customText(`7.`)}
              </Text>
            </View>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Waiver of Obligations.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              FTD shall not be deemed to have waived or impaired any right,
              power or option reserved by this Agreement by virtue of any custom
              or practice of FTD at variance with the terms hereof, or by any
              failure, refusal or neglect to exercise any right under this
              Agreement or to insist upon exact compliance by Customer with its
              obligations hereunder.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("p-2 text-right"), color: colors.primary }}>
                {customText(`8.`)}
              </Text>
            </View>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Assignment.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              Neither this Agreement nor any rights under it may be assigned or
              otherwise be transferred by Customer in whole or in part, whether
              voluntarily or by operation of law, including by way of sale of
              assets, merger or consolidation, or any change in control of the
              ownership of Customer, without the prior written consent of FTD,
              and any assignment or attempt to assign this Agreement without the
              prior written consent of FTD shall be null and void.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("p-2 text-right"), color: colors.primary }}>
                {customText(`9.`)}
              </Text>
            </View>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Binding Effect.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              The Agreement is binding upon the Parties hereto and their
              permitted assigns and successors in interest, and shall not be
              modified except by written agreement signed by both Parties.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("p-2"), color: colors.primary }}>
                {customText(`10.`)}
              </Text>
            </View>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Choice of Law and Venue.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              The Parties agree that the terms of this Agreement and
              interpretation hereof shall be interpreted, enforced and governed
              by the laws of the State of Illinois, without regard to its
              conflict of laws rules, and further, the Parties consent to the
              exclusive and continuous jurisdiction of the U.S. District Court
              for the Northern District of Illinois and the DuPage County
              Circuit Court, to enforce the terms of this Agreement.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <Text style={{ ...tw("p-2"), color: colors.primary, width: 40 }}>
              {customText(`11.`)}
            </Text>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Notices.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              For purposes of this Agreement, notices required to be made from
              one Party to another shall be deemed made when such notice is sent
              via certified mail return receipt requested, Federal Express or
              other overnight delivery service that provides a written
              confirmation of delivery at the address of the respective Party
              listed below or via facsimile transmission with a confirmed
              delivery or completed transmission receipt for facsimile number
              listed below for each Party hereto:
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <Text
              style={{ ...tw("p-2"), color: colors.primary, width: 40 }}
            ></Text>
            <Text
              style={{ ...tw("p-2"), color: colors.primary, width: "25%" }}
            ></Text>
            <Text style={{ ...tw("p-2"), color: colors.primary }}>
              FTD, LLC{`\n`}Attn: President – Florist Division{`\n`}200 N
              Lasalle St{`\n`}Chicago, Il 60601
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <Text
              style={{ ...tw("p-2"), color: colors.primary, width: 40 }}
            ></Text>
            <Text style={{ ...tw("p-2"), color: colors.primary, width: "25%" }}>
              And with copy to:
            </Text>
            <Text style={{ ...tw("p-2"), color: colors.primary }}>
              FTD, LLC{`\n`}Attn.: Legal Department{`\n`}200 N Lasalle St{`\n`}
              Chicago, Il 60601
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <Text
              style={{ ...tw("p-2"), color: colors.primary, width: 40 }}
            ></Text>
            <Text style={{ ...tw("p-2"), color: colors.primary, width: "25%" }}>
              Customer:
            </Text>
            <Text style={{ ...tw("p-2"), color: colors.primary, width: "65%" }}>
              All notices shall be sent to physical and/or email address FTD has
              on file for the Customer.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <Text style={{ ...tw("p-2"), color: colors.primary, width: 40 }}>
              {customText(`12.`)}
            </Text>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Relationship.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              The Parties agree that each of them is an independent Party with
              no authority to act for or on behalf of, or to bind, the other
              except as expressly provided in this Agreement.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <Text style={{ ...tw("p-2"), color: colors.primary, width: 40 }}>
              {customText(`13.`)}
            </Text>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Confidentiality.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              By virtue of this Agreement, the Parties may have access to
              information that is confidential to one another (“
              {customText(`Confidential Information`)}”). Confidential
              Information shall include the format, monetary terms, pricing
              under this Agreement, the volume of service provided under this
              Agreement, the amounts of net sales or profits made under this
              Agreement, Customer’s Consumer sales list, and any other
              information that is clearly designated by a Party as confidential,
              but shall not preclude any Party from acknowledging the existence
              of this Agreement. The Parties agree to hold each other’s
              Confidential Information in confidence at all times. Unless
              required by law (after notice to the other Party) or unless the
              other Party has consented in writing, no Party will make each
              other’s Confidential Information available in any form to any
              third party (other than to the officers, agents, employees,
              directors, attorneys, accountants, other advisors or
              representatives of Customer or FTD who need to know the
              Confidential Information and agree to comply with this
              Confidentiality provision). No Party shall use the other’s
              Confidential Information for any purposes other than carrying out
              this Agreement. Each Party Agrees to take reasonable steps to
              insure that Confidential Information is not disclosed or
              distributed by its employees or agents in violation of this
              Agreement. In the event of a breach by either Party in this
              Section 13 (Confidentiality), the non-breaching Party shall be
              entitled to seek injunctive relief without the necessity of
              posting any bond.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <Text style={{ ...tw("p-2"), color: colors.primary, width: 40 }}>
              {customText(`14.`)}
            </Text>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Indemnification.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              Customer agrees to indemnify, defend and hold harmless FTD, and
              its parents, subsidiaries, agents, affiliates, employees,
              directors and officers, from any and all liability, claim, loss,
              damage, demand or expense (including reasonable attorneys’ fees
              and costs) asserted by any third party due to, arising from, or in
              connection with: (i) any breach by Customer of this Agreement;
              (ii) any failure by Customer to comply with any applicable laws or
              regulations; (iii) Customer’s actual or alleged infringement of
              any other party’s intellectual property rights, or (iv) Customer’s
              use of the FTD Delivery Service, except to the extent of FTD’s
              gross negligence or willful misconduct in providing the FTD
              Delivery Service.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <Text style={{ ...tw("p-2"), color: colors.primary, width: 40 }}>
              {customText(`15.`)}
            </Text>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Force Majeure.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              Neither Party will be responsible or liable in any way for its
              failure to perform its obligations under this Agreement during any
              period in which performance is prevented or hindered by conditions
              reasonably beyond its control, acts of God, fire, flood, war,
              insurrection, embargo, strikes, labor disturbances, explosions,
              riots, civil disturbances, acts of terrorism, power failures or
              interruption, epidemics, pandemics, laws, rules, regulations or
              orders of any governmental authority or any other cause which is
              beyond the reasonable control of such nonperforming Party. In the
              event of such force majeure, the nonperforming Party will use best
              efforts to recommence its full duties hereunder as soon as it is
              possible to do so, provided that in the event of nonperformance by
              reason of force majeure lasting for more than thirty (30) days,
              the other Party may, but is not obligated to, immediately
              terminate this Agreement upon written notice to the nonperforming
              Party.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <Text style={{ ...tw("p-2"), color: colors.primary, width: 40 }}>
              {customText(`16.`)}
            </Text>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Limitation of Liability.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              Under no circumstances will FTD or its parent and respective
              affiliates be liable to Customer for any indirect, incidental,
              consequential, special, punitive or exemplary damages arising from
              the subject matter of this Agreement, regardless of the type of
              claim and even if FTD has been advised of the possibility of such
              damages, such as, but not limited to, loss of revenue or
              anticipated profits or lost business. In the event of a breach of
              any or the terms of this Agreement by FTD or its parent or
              affiliates in carrying out any of the terms of this Agreement,
              Customer’s sole and exclusive remedy shall be recovery of proven
              direct damages not to exceed the lesser of (1) the fees paid by
              Customer to FTD under this Agreement during the three months
              immediately preceding the date of such claim arose, or (2)
              $1,000.00.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <Text style={{ ...tw("p-2"), color: colors.primary, width: 40 }}>
              {customText(`17.`)}
            </Text>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Warranty Disclaimer.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              EXCEPT AS OTHERWISE SET FORTH HEREIN, FTD PROVIDES THE FTD
              DELIVERY SERVICE ON AN “AS IS” BASIS, AND FTD MAKES NO
              REPRESENTATIONS AND GRANTS NO WARRANTIES, EXPRESS OR IMPLIED,
              EITHER IN FACT OR BY OPERATION OF LAW, BY STATUTE OR OTHERWISE, IN
              CONNECTION WITH THE FTD DELIVERY SERVICE. FTD SPECIFICALLY
              DISCLAIMS ANY OTHER WARRANTIES, WHETHER WRITTEN OR ORAL, EXPRESS
              OR IMPLIED, INCLUDING ANY WARRANTY OF QUALITY, MERCHANTABILITY OR
              FITNESS FOR A PARTICULAR USE OR PURPOSE, OR ANY WARRANTY AS TO
              NON-INFRINGEMENT OF ANY INTELLECTUAL PROPERTY RIGHTS OF THIRD
              PARTIES. CUSTOMER ASSUMES ALL RISKS OF USING THE FTD DELIVERY
              SERVICE. THIS WARRANTY DISCLAIMER CONSTITUTES AN ESSENTIAL AND
              MATERIAL PART OF THIS AGREEMENT.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <Text style={{ ...tw("p-2"), color: colors.primary, width: 40 }}>
              {customText(`18.`)}
            </Text>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Entire Agreement.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              This Agreement reflects the entire Agreement between the Parties
              regarding the subject matter of this Agreement. Except as may be
              expressly stated in this Agreement, it supersedes and cancels all
              prior Agreements, statements, representations, understandings,
              negotiations and discussions, whether oral or written, between the
              Parties. Each of the Parties acknowledges and agrees that in
              entering into this Agreement it does not rely on (nor has it been
              induced to enter this Agreement based on) any statement,
              representation, warranty or understanding made prior to this
              Agreement save to the extent that such statement, representation,
              warranty or understanding is incorporated into this Agreement.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <Text style={{ ...tw("p-2"), color: colors.primary, width: 40 }}>
              {customText(`19.`)}
            </Text>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Authority.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              Each Party represents that this Agreement has been duly
              authorized, executed and delivered by such Party and constitutes a
              valid and binding obligation of such Party enforceable in
              accordance with its terms.
            </Text>
          </View>
          <View style={[tw("flex flex-row")]}>
            <Text style={{ ...tw("p-2"), color: colors.primary, width: 40 }}>
              {customText(`20.`)}
            </Text>
            <Text
              style={{
                ...tw("p-2 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              {customText(`Credit Information and Financial Statements.`, {
                textDecorationLine: "underline",
                marginRight: 5,
              })}{" "}
              By signing below, Customer hereby authorizes FTD, its employees
              and agents to request and obtain credit bureau reports of the
              Customer for the purpose of establishing , maintaining and
              enforcing a credit relationship with the Customer in accordance
              with FTD Standards, as well as policies as amended from time to
              time, and shall furnish FTD along with this signed Agreement or
              promptly thereafter, a current copy of its financial statements,
              unless this requirement is expressly waived by FTD in writing.
            </Text>
          </View>
          <Text
            style={{ ...tw("px-4 py-2 text-center"), color: colors.primary }}
          >
            {customText(`EXHIBIT A`)}
            {"\n"}
            {customText(`PRICING`)}
            {"\n\n"}
            {customText(`FTD DELIVERY SERVICE PRICING`)}
            {"\n\n"}
            $11.99 (Less than 7 miles){"\n"}$14.99 (7-15 miles)
            {"\n"}
            Distance is determined by driving miles to the delivery location.
            {"\n\n"}
            {customText("Cancellations")}: Customer shall be charged $10.00 for
            (a) deliveries cancelled by the recipient or Customer if the driver
            is already en route to the location, or (b) any of the reasons set
            forth in Schedule A.{"\n\n"}
            Return Delivery Fee: Deliveries under 7 miles $18.00, Deliveries 7-15 miles $20.00 Return
            Delivery Fee can occur if the recipient is not available to receive the delivery or refuses the
            delivery.
            {"\n\n\n"}SCHEDULE A
          </Text>
          <Text
            style={{ ...tw("px-4 py-2 text-justify"), color: colors.primary }}
          >
            The Cancellation Fee set forth in Exhibit A shall be charged in the
            event of any of the following:
          </Text>
          <View style={[tw("flex flex-row pr-2 pb-1")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                1.
              </Text>
            </View>
            <Text
              style={{
                ...tw("px-1 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              Force Majeure Event (defined below).
            </Text>
          </View>
          <View style={[tw("flex flex-row pr-2 pb-1")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                2.
              </Text>
            </View>
            <Text
              style={{
                ...tw("px-1 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              Customer failed to notify FTD Delivery Service of delivery order
              at least one (1) hour prior to requested pickup time (“
              {customText(`Pickup Time`)}”).
            </Text>
          </View>
          <View style={[tw("flex flex-row pr-2 pb-1")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                3.
              </Text>
            </View>
            <Text
              style={{
                ...tw("px-1 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              The delivery order is not properly tagged, assembled and/or
              available for pick-up at the designated pick-up point at the
              requested Pickup Time.
            </Text>
          </View>
          <View style={[tw("flex flex-row pr-2 pb-1")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                4.
              </Text>
            </View>
            <Text
              style={{
                ...tw("px-1 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              Customer did not provide an accurate delivery address.
            </Text>
          </View>
          <View style={[tw("flex flex-row pr-2 pb-1")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                5.
              </Text>
            </View>
            <Text
              style={{
                ...tw("px-1 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              Driver has not been able to contact the delivery order recipient
              as necessary to complete the delivery.
            </Text>
          </View>
          <View style={[tw("flex flex-row pr-2 pb-1")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                6.
              </Text>
            </View>
            <Text
              style={{
                ...tw("px-1 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              The delivery order recipient (i) is not available at the
              designated location to receive the delivery order at the time of
              arrival by the driver; (ii) has requested to change the delivery
              time or location; or (iii) has refused the order (excluding
              refusal due to damage to delivery order at the fault of the
              driver).
            </Text>
          </View>
          <View style={[tw("flex flex-row pr-2 pb-1")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                7.
              </Text>
            </View>
            <Text
              style={{
                ...tw("px-1 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              The delivery order is an “{customText(`Over-Sized Item`)}”
            </Text>
          </View>
          <View style={[tw("flex flex-row pr-2 pb-1")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                8.
              </Text>
            </View>
            <Text
              style={{
                ...tw("px-1 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              To the extent applicable, Customer has requested the wrong size
              vehicle or input the wrong dimensions and/or weight of items in
              the delivery order and the Items do not fit in the vehicle
              assigned.
            </Text>
          </View>
          <View style={[tw("flex flex-row pr-2 pb-1")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                9.
              </Text>
            </View>
            <Text
              style={{
                ...tw("px-1 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              The delivery order contains a Prohibited Item (defined below)
            </Text>
          </View>
          <View style={[tw("flex flex-row pr-2 pb-1")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                10.
              </Text>
            </View>
            <Text
              style={{
                ...tw("px-1 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              Customer has notified FTD Delivery Service of further actions, or
              requested further actions outside of the original delivery order,
              including requesting (i) delivery outside of the targeted delivery
              timeline, (ii) driver to return delivery order to pick-up location
              or other location; (iii) delivery to alternate address; or (iv)
              further actions outside of the geographic markets agreed upon
              between FTD and the delivery vendor.
            </Text>
          </View>
          <Text style={{ ...tw("p-2"), color: colors.primary }}>
            DEFINITIONS.
          </Text>
          <View style={[tw("flex flex-row pr-2 pb-1")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                A.
              </Text>
            </View>
            <Text
              style={{
                ...tw("px-1 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              “{customText(`Force Majeure Event`)}” means the failure to
              complete a delivery due to fires, floods, hurricanes, earthquakes,
              explosions, extreme weather, or other elements of nature, natural
              disasters, pandemics/epidemics, or other “acts of God”, strikes,
              labor disputes, or picketing lockouts (or labor shortages due to
              such events), transportation embargoes, civil riots, insurrection,
              acts of any government or agency thereof and judicial action, or
              infrastructure disasters, or orders or commands of properly
              constituted authorities preventing, or significantly impairing,
              drivers from accessing a location or delivery location and/or
              otherwise completing the delivery services.
            </Text>
          </View>
          <View style={[tw("flex flex-row pr-2 pb-1")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                B.
              </Text>
            </View>
            <Text
              style={{
                ...tw("px-1 text-justify"),
                color: colors.primary,
                width: "90%",
              }}
            >
              “{customText(`Prohibited Item`)}” means:
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-1 ml-5")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                i.
              </Text>
            </View>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "88%",
              }}
            >
              People
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-1 ml-5")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                ii.
              </Text>
            </View>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "88%",
              }}
            >
              Anything illegal including, without limitation, recreational drugs
              and other contraband.
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-1 ml-5")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                iii.
              </Text>
            </View>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "88%",
              }}
            >
              Any Hazardous Waste, defined as a “solid waste” that meets any of
              the criteria of the hazardous waste as described in 40 C.F.R. §
              261.3.
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-1 ml-5")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                iv.
              </Text>
            </View>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "88%",
              }}
            >
              Unset precious stones, industrial diamonds, any article that
              contains more than fifty percent by weight of gold or platinum or
              any combination thereof in raw form, including, but not limited
              to, bullion, bars, or scraps of these metals.
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-1 ml-5")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                v.
              </Text>
            </View>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "88%",
              }}
            >
              Any hazardous material in any quantity.
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-1 ml-5")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                vi.
              </Text>
            </View>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "88%",
              }}
            >
              Cremated remains, human remains or componentsthereof.
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-1 ml-5")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                vii.
              </Text>
            </View>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "88%",
              }}
            >
              Any delivery order to be transported over state lines (i.e.,
              interstate) or over fifty (50) miles if being delivered to and
              from a location in the State of California.
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-1 ml-5")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                viii.
              </Text>
            </View>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "88%",
              }}
            >
              Beer, wine, spirits and other alcohol and liquor products and
              traditional cigarettes, cigars and other any product made or
              derived from tobacco that is intended for human consumption, and
              E-cigarettes, vape pens, tank systems and other electronic
              nicotine delivery systems or any other product regulated and
              controlled by the United States Alcohol and Tobacco Tax and Trade
              Bureau (TTB).
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-1 ml-5")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                ix.
              </Text>
            </View>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "88%",
              }}
            >
              Firearms or weapons of any kind, any firearm or weaponparts, or
              ammunition in any amount.
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-1 ml-5")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                x.
              </Text>
            </View>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "88%",
              }}
            >
              Common fireworks.
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-1 ml-5")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                xi.
              </Text>
            </View>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "88%",
              }}
            >
              Replica or inert explosives or weapons that bear an appearance to
              actual explosives or weapons.
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-1 ml-5")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                xii.
              </Text>
            </View>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "88%",
              }}
            >
              Prescription drugs and medicines or regulated over the counter
              medicine.
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-1 ml-5")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                xiii.
              </Text>
            </View>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "88%",
              }}
            >
              Oversized Items.
            </Text>
          </View>
          <View style={[tw("flex flex-row px-2 pb-4 ml-5")]}>
            <View style={{ width: 35 }}>
              <Text style={{ ...tw("px-2 text-right"), color: colors.primary }}>
                xiv.
              </Text>
            </View>
            <Text
              style={{
                ...tw("text-justify"),
                color: colors.primary,
                width: "88%",
              }}
            >
              All live animals.
            </Text>
          </View>
        </View>
      </ScrollView>
    </>
  );
};
