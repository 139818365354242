import * as Yup from "yup";

export const getValidationSchemaPrice = (Localise, messages) => {
  return Yup.object().shape({
    catalogPriceMinimumPrice: Yup.string()
      .required(Localise(messages, "Please enter Price"))
      .matches(
        /^[1-9]\d*(\.\d+)?$/,
        Localise(messages, "Please enter valid Price")
      ),
  });
};

export const getValidationSchema = (Localise, messages) => {
  return Yup.object().shape({
    operation: Yup.string().required(
      Localise(messages, "Please select operation name")
    ),

    action: Yup.string().required(Localise(messages, "Please select action")),

    fieldValue: Yup.string()
      .required(Localise(messages, "Please enter price"))
      .matches(
        /^[1-9]\d*(\.\d+)?$/,
        Localise(messages, "Please enter non-zero price in decimals")
      ),
    endDate: Yup.string().required(
      Localise(messages, "Please select end date")
    ),
  });
};
