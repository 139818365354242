import {
  selectNoop,
  selectCustomerOrders,
  selectOrderHistoryLimit,
  selectCustomerSubscriptions,
} from "library/sagas/views/home/drawer/customer-directory/selector";

import {
  setCurrentCustomer,
  setOrderMeatBallAction,
} from "library/sagas/views/home/drawer/customer-directory/slice";

import CustomerInfo from "./customer/customer-info";
import Activity from "./customer/activity";
import Payment from "./customer/payment";
import HouseAccount from "./customer/house-account";
import Subscriptions from "./customer/subscriptions";
import CreditDebitMemo from "./customer/credit-debit-memo";
import Statements from "./customer/statements";
import Statement from "./customer/statements/statement";
import ReceivePayment from "./customer/statements/receive-payment";
import CurrentBalance from "./customer/statements/current-balance";
import Listing from "components/containers/listing-new";
import { OrderListItem } from "components/views/drawer/order-listing/orders-list";
import {
  ListOfColumns,
  ListOfMobileColumns,
  ListOfSubColumns,
  ListOfSubActions,
} from "components/views/drawer/order-listing/current-orders/config";
import { ListOfOrderActions } from "components/views/drawer/order-listing/actionsConfig";
import {
  FormFieldPicker,
  FormField,
  FormFieldSwitch,
} from "components/elements/forms";
import SubscriptionItem from "components/views/drawer/customer-directory/upsert-screen/customer/subscriptions/item";
import Environment from "library/utils/environment";
import { useDispatch } from "react-redux";
import { setShopCode as setSelectedShopCode } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import { getFormattedCreditCardExpiry } from "library/utils/formatter";
const accountingTypeOptions = (Localise, messages) => [
  {
    label: Localise(messages, "Balance Forward"),
    value: "Balance_Forward",
  },
  {
    label: Localise(messages, "Open Item Accounting"),
    value: "Open_Item",
  },
];

const creditDebitMemoOptions = (Localise, messages) => [
  {
    label: Localise(messages, "Credit"),
    value: "CREDIT_MEMO",
  },
  {
    label: Localise(messages, "Debit"),
    value: "DEBIT_MEMO",
  },
];

const TextFieldProps = {
  autoCapitalize: "none",
  autoCorrect: false,
  editable: true,
  multiline: false,
  numberOfLines: 1,
};

const customerTypeOptions = (Localise, messages) => [
  {
    label: Localise(messages, "Individual"),
    value: "false",
  },
  {
    label: Localise(messages, "Business"),
    value: "true",
  },
];

const labelStyle = { fontWeight: "normal" };

export const AddressField = (
  Localise,
  messages,
  showFieldLabel = false,
  showAddressText = true
) => {
  return {
    name: "address",
    title: Localise(messages, "Address"),
    formFieldType: "CustomAddress",
    formInputFields: [
      {
        name: "addressLine1",
        formFieldType: "AutoComplete",
        formFieldProps: {
          ...TextFieldProps,
          fsClass: "fs-exclude",
          placeholder: Localise(messages, "Street Address"),
          label: showFieldLabel ? Localise(messages, "Street Address") : "",
          labelStyle: { fontWeight: "normal" },
        },
      },
      {
        name: "addressLine2",
        formFieldType: "Text",
        formFieldProps: {
          ...TextFieldProps,
          fsClass: "fs-exclude",
          placeholder: Localise(messages, "Apt/Suite/Unit"),
          label: showFieldLabel ? Localise(messages, "Apt/Suite/Unit") : "",
          labelStyle,
        },
      },
      {
        name: "country",
        title: Localise(messages, "Country"),
        formFieldType: "Picker",
        formFieldProps: {
          ...TextFieldProps,
          fsClass: "fs-exclude",
          placeholder: {
            label: Localise(messages, "Select Country"),
            value: "",
          },
          label: showFieldLabel ? Localise(messages, "Country") : "",
          labelStyle,
          options: [
            { value: "CA", label: "Canada" },
            { value: "US", label: "United States" },
          ],
        },
      },
      {
        name: "state",
        title: Localise(messages, "State"),
        formFieldType: "Picker",
        formFieldProps: {
          ...TextFieldProps,
          fsClass: "fs-exclude",
          placeholder: {
            label: Localise(messages, "Select State"),
            value: "",
          },
          label: showFieldLabel ? Localise(messages, "State") : "",
          labelStyle,
          options: [],
        },
      },
      {
        name: "city",
        formFieldType: "Text",
        formFieldProps: {
          ...TextFieldProps,
          fsClass: "fs-exclude",
          placeholder: Localise(messages, "City"),
          label: showFieldLabel ? Localise(messages, "City") : "",
          labelStyle,
        },
      },
      {
        name: "zipcode",
        formFieldType: "Text",
        formFieldProps: {
          ...TextFieldProps,
          fsClass: "fs-exclude",
          placeholder: Localise(messages, "Zip Code"),
          label: showFieldLabel ? Localise(messages, "Zip Code") : "",
          labelStyle,
        },
      },
    ],
    showAddressText,
  };
};

export const PhoneField = (Localise, messages) => {
  return {
    name: "phone",
    title: Localise(messages, "Phone"),
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      fsClass: "fs-exclude",
      placeholder: "123-456-7890",
      maxLength: 18,
    },
  };
};

export const EmailField = (Localise, messages) => {
  return {
    name: "email",
    title: Localise(messages, "Email"),
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      fsClass: "fs-exclude",
      placeholder: Localise(messages, "Enter Email"),
    },
  };
};

const CustomerContacts = (Localise, messages) => ({
  name: "customerContacts",
  title: Localise(messages, "Contacts"),
  formFieldType: "customerContacts",
  formInputFields: [
    {
      name: "firstName",
      title: Localise(messages, "First Name"),
      formFieldProps: {
        ...TextFieldProps,
        placeholder: Localise(messages, "Enter First Name"),
      },
      customerType: "false",
    },
    {
      name: "lastName",
      title: Localise(messages, "Last Name"),
      formFieldProps: {
        ...TextFieldProps,
        placeholder: Localise(messages, "Enter Last Name"),
      },
      customerType: "false",
    },
    {
      name: "phone",
      title: Localise(messages, "Phone"),
      formFieldProps: {
        ...TextFieldProps,
        fsClass: "fs-exclude",
        placeholder: "123-456-7890",
        maxLength: 18,
      },
    },
    {
      name: "email",
      title: Localise(messages, "Email"),
      formFieldProps: {
        ...TextFieldProps,
        fsClass: "fs-exclude",
        placeholder: Localise(messages, "Enter Email"),
      },
    },
  ],
});

export const CustomerInfoFields = (
  Localise,
  messages,
  showEmailMarketingToggle = false
) => [
  {
    name: "customerId",
    title: Localise(messages, "Customer Number"),
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      editable: false,
      placeholder: "",
    },
  },
  {
    name: "houseAccountInfo.houseAccountId",
    title: Localise(messages, "House Account Number"),
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      editable: false,
      placeholder: "",
    },
  },
  {
    name: "customerType",
    title: Localise(messages, "Customer Type"),
    formFieldType: "Picker",
    formFieldProps: {
      placeholder: {
        label: Localise(messages, "Choose Customer Type"),
        value: "",
      },
      options: customerTypeOptions(Localise, messages),
    },
  },
  {
    formFieldType: "customerName",
    formInputFields: [
      {
        name: "firstName",
        title: Localise(messages, "First Name"),
        formFieldProps: {
          ...TextFieldProps,
          placeholder: Localise(messages, "Enter First Name"),
        },
        customerType: "false",
      },
      {
        name: "lastName",
        title: Localise(messages, "Last Name"),
        formFieldProps: {
          ...TextFieldProps,
          placeholder: Localise(messages, "Enter Last Name"),
        },
        customerType: "false",
      },
      {
        name: "businessName",
        title: Localise(messages, "Business Name"),
        formFieldProps: {
          ...TextFieldProps,
          placeholder: Localise(messages, "Enter Business Name"),
        },
        customerType: "true",
      },
    ],
  },
  PhoneField(Localise, messages),
  EmailField(Localise, messages),
  AddressField(Localise, messages),
  {
    name: "taxExemptCode",
    title: Localise(messages, "Tax Exempt Code"),
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: Localise(messages, "Enter Tax Exempt Code"),
    },
  },
  {
    name: "deliveryFeeOverride",
    title: Localise(messages, "Delivery Fee Override"),
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: Localise(messages, "Enter Delivery Fee Override"),
      isPrice: true,
      keyboardType: "numeric",
      iconName: "currency-usd",
      iconPosition: true,
      iconSize: 12,
      iconType: "material-community",
      showHelpIcon: true,
      helpText:
        "This field allows you to set a custom delivery fee for this customer. The entered value will override the standard delivery fee for all future orders they place. If set to '$0.00,' the customer will not be charged a delivery fee by default. Leave this field blank to use the shop's standard delivery fee.",
    },
  },
  {
    name: "discountType",
    title: Localise(messages, "Employee / Corporate Discount"),
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: Localise(messages, "Enter Discount Type"),
    },
  },
  {
    name: "discountPercentage",
    title: Localise(messages, "Discount %"),
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: Localise(messages, "0"),
      keyboardType: "numeric",
      iconName: "percent",
      iconPosition: false,
      iconSize: 12,
      iconType: "font-awesome",
    },
  },
  // {
  //   name: "totalAverageSpend",
  //   title: Localise(messages, "Total Average Spend"),
  //   formFieldType: "Text",
  //   formFieldProps: {
  //     ...TextFieldProps,
  //     placeholder: "",
  //     editable: false,
  //   },
  // },
  // {
  //   name: "averageSpendByOccasion",
  //   title: Localise(messages, "Average Spend by Occasion"),
  //   formFieldType: "Text",
  //   formFieldProps: {
  //     ...TextFieldProps,
  //     placeholder: "",
  //     editable: false,
  //     inputContainerStyle: { height: 75 },
  //     multiline: true,
  //     numberOfLines: 3,
  //   },
  // },
  {
    name: "referralType",
    title: Localise(messages, "Referral Type"),
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: Localise(messages, "Enter referral type"),
      editable: false,
    },
  },
  {
    name: "Transactional Notifications",
    type: "group",
    fields: [
      {
        name: "emailOptIn",
        disabled: true,
        title: Localise(messages, "Email"),
        formFieldType: "ToggleField",
        showField: Environment.get("IS_EMAIL_ENABLED", true),
        containerStyles: { width: "50%" },
      },
      {
        name: "smsOptIn",
        title: Localise(messages, "SMS"),
        formFieldType: "ToggleField",
        showField: Environment.get("IS_SMS_ENABLED", true),
        containerStyles: { width: "50%" },
      },
    ],
  },
  showEmailMarketingToggle && {
    name: "Marketing Notifications",
    type: "group",
    fields: [
      {
        name: "emailMarketingOptIn",
        disabled: true,
        title: Localise(messages, "Email"),
        formFieldType: "ToggleField",
        showField: showEmailMarketingToggle,
        containerStyles: { width: "50%" },
      },
      {
        // name: "smsMarketingOptIn",
        // title: Localise(messages, "SMS"),
        // formFieldType: "ToggleField",
        // showField: Environment.get("ENABLE_OCCASION_REMINDER", true),
        // containerStyles: { width: "50%" },
      },
    ],
  },
  CustomerContacts(Localise, messages),
  {
    name: "customerNotes",
    title: Localise(messages, "Customer Notes"),
    formFieldType: "TextArea",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: Localise(messages, "Enter Customer Notes"),
      inputContainerStyle: { height: 100 },
      multiline: true,
      numberOfLines: 5,
    },
  },
];

const CustomerHistory = (Localise, messages) => [
  {
    label: Localise(messages, "Store Origin"),
    key: "storeOrigin",
  },
  {
    label: Localise(messages, "Created On"),
    key: "createdOn",
  },
  {
    label: Localise(messages, "Created By"),
    key: "createdBy",
  },
  {
    label: Localise(messages, "Updated On"),
    key: "updatedOn",
  },
  {
    label: Localise(messages, "Updated By"),
    key: "updatedBy",
  },
];

// const CustomerNotificationFields = (Localise, messages) => [
//   {
//     section: Localise(messages, "Order Notifications"),
//     path: "orderNotifications",
//     fields: [
//       { name: "email", title: Localise(messages, "Email") },
//       { name: "sms", title: Localise(messages, "SMS") },
//     ],
//   },
//   {
//     section: Localise(messages, "Marketing Notifications"),
//     path: "marketingNotifications",
//     fields: [
//       { name: "email", title: Localise(messages, "Email") },
//       { name: "sms", title: Localise(messages, "SMS") },
//     ],
//   },
// ];

const CustomerPaymentFields = (Localise, messages) => {
  return {
    section: Localise(messages, "Credit Card"),
    path: "paymentInfo",
    fields: [
      {
        name: "nameOnCard",
        title: Localise(messages, "Name On Card"),
        formFieldType: "Text",
        formFieldProps: {
          ...TextFieldProps,
          placeholder: Localise(messages, "Enter the name on card"),
        },
      },
      {
        name: "cardNumber",
        title: Localise(messages, "Card Number"),
        formFieldType: "Text",
        formFieldProps: {
          ...TextFieldProps,
          fsClass: "fs-exclude",
          placeholder: Localise(messages, "Enter Card Number"),
          iconName: "credit-card",
          iconPosition: false,
          maxLength: 19,
          transformText: (text = "") => {
            let trimmed = text.split(" ").join("");
            if (trimmed.length < 4 || isNaN(trimmed)) return text;
            let ccnumspaced = trimmed.match(/.{1,4}/g);
            return ccnumspaced.join(" ");
          },
        },
      },
      {
        name: "cvvNumber",
        title: Localise(messages, "CVV"),
        formFieldType: "Text",
        formFieldProps: {
          ...TextFieldProps,
          fsClass: "fs-exclude",
          placeholder: Localise(messages, "Enter Code"),
          maxLength: 4,
        },
      },
      {
        name: "expiration",
        title: Localise(messages, "Expiration"),
        formFieldType: "Text",
        formFieldProps: {
          ...TextFieldProps,
          fsClass: "fs-exclude",
          placeholder: "MM/YYYY",
          maxLength: 7,
          transformText: (text) => getFormattedCreditCardExpiry(text),
        },
      },
      {
        name: "billingAddress",
        title: Localise(messages, "Billing Address"),
        formFieldType: "BillingAddress",
        formCheckbox: {
          name: "sameAsMailingAddress",
          title: Localise(messages, "Same as Mailing Address"),
        },
        formInputFields: [
          {
            name: "addressLine1",
            formFieldType: "Text",
            formFieldProps: {
              ...TextFieldProps,
              fsClass: "fs-exclude",
              placeholder: Localise(messages, "Street Address"),
            },
          },
          {
            name: "addressLine2",
            formFieldType: "Text",
            formFieldProps: {
              ...TextFieldProps,
              fsClass: "fs-exclude",
              placeholder: Localise(messages, "Apt/Suite/Unit"),
            },
          },
          {
            name: "country",
            title: Localise(messages, "Country"),
            formFieldType: "Picker",
            formFieldProps: {
              ...TextFieldProps,
              fsClass: "fs-exclude",
              placeholder: {
                label: Localise(messages, "Select Country"),
                value: "",
              },
              options: [
                { value: "CA", label: "Canada" },
                { value: "US", label: "United States" },
              ],
            },
          },
          {
            name: "state",
            title: Localise(messages, "State"),
            formFieldType: "Picker",
            formFieldProps: {
              ...TextFieldProps,
              fsClass: "fs-exclude",
              placeholder: {
                label: Localise(messages, "Select State"),
                value: "",
              },
              options: [],
            },
          },
          {
            name: "city",
            formFieldType: "Text",
            formFieldProps: {
              ...TextFieldProps,
              fsClass: "fs-exclude",
              placeholder: Localise(messages, "City"),
            },
          },
          {
            name: "zipcode",
            formFieldType: "Text",
            formFieldProps: {
              ...TextFieldProps,
              fsClass: "fs-exclude",
              placeholder: Localise(messages, "Zip Code"),
            },
          },
        ],
      },
    ],
  };
};

export const HouseAccountCreditFields = (Localise, messages) => {
  return {
    section: Localise(messages, "House Account"),
    path: "houseAccount",
    fields: [
      {
        title: Localise(messages, "Credit Limit"),
        formFieldElement: FormField,
        formFieldProps: {
          ...TextFieldProps,
          name: "creditLimit",
          isPrice: true,
          iconName: "currency-usd",
          iconSize: 14,
          iconType: "material-community",
          inputContainerStyle: { padding: 6 },
          leftIconContainerStyle: { paddingRight: 0 },
          keyboardType: "numeric",
          placeholder: Localise(messages, "Enter the Credit Limit"),
        },
      },
    ],
  };
};

export const HouseAccountFields = (Localise, messages) => {
  return {
    section: Localise(messages, "House Account"),
    path: "houseAccount",
    fields: [
      {
        title: Localise(messages, "Credit Limit"),
        formFieldElement: FormField,
        formFieldProps: {
          ...TextFieldProps,
          name: "creditLimit",
          isPrice: true,
          iconName: "currency-usd",
          iconSize: 14,
          iconType: "material-community",
          inputContainerStyle: { padding: 6 },
          leftIconContainerStyle: { paddingRight: 0 },
          keyboardType: "numeric",
          placeholder: Localise(messages, "Enter the Credit Limit"),
        },
      },
      {
        title: Localise(messages, "Accounting Type"),
        formFieldElement: FormFieldPicker,
        formFieldProps: {
          name: "accountType",
          disabled: true,
          placeholder: {
            label: Localise(messages, "Select Accounting Type"),
            value: "",
          },
          data: accountingTypeOptions(Localise, messages),
        },
      },
      {
        title: Localise(messages, "Auto Email Invoices"),
        formFieldElement: FormFieldSwitch,
        formFieldProps: {
          name: "autoEmailInvoices",
          value: "isEnabled",
        },
      },
      {
        title: Localise(messages, "Auto Email Statements"),
        formFieldElement: FormFieldSwitch,
        formFieldProps: {
          name: "autoEmailStatements",
          value: "isEnabled",
        },
      },
    ],
  };
};

const CreditDebitMemoFields = (Localise, messages, allShopCodes = []) => {
  return {
    section: Localise(messages, "Receive Payment"),
    path: "receivePayment",
    fields: [
      {
        title: Localise(messages, "Shop"),
        formFieldElement: FormFieldPicker,
        formFieldProps: {
          name: "memberCode",
          placeholder: {
            label: Localise(messages, "Select Shop"),
            value: "",
          },
          data: allShopCodes.map((code) => {
            return { label: code, value: code };
          }),
        },
      },
      {
        title: Localise(messages, "Type"),
        formFieldElement: FormFieldPicker,
        formFieldProps: {
          name: "creditDebitMemoType",
          placeholder: {
            label: Localise(messages, "Select Memo Type"),
            value: "",
          },
          data: creditDebitMemoOptions(Localise, messages),
        },
      },
      {
        title: Localise(messages, "Amount"),
        formFieldElement: FormField,
        formFieldProps: {
          ...TextFieldProps,
          name: "amount",
          isPrice: true,
          iconName: "currency-usd",
          iconSize: 14,
          iconType: "material-community",
          inputContainerStyle: { padding: 6 },
          leftIconContainerStyle: { paddingRight: 0 },
          keyboardType: "numeric",
          placeholder: Localise(messages, "Enter amount"),
        },
      },
      {
        title: Localise(messages, "Notes"),
        formFieldElement: FormField,
        formFieldProps: {
          ...TextFieldProps,
          name: "optionalNotes",
          placeholder: Localise(messages, "Optional Notes"),
        },
      },
    ],
  };
};

const ReceivePaymentFields = (Localise, messages, allShopCodes = []) => {
  const dispatch = useDispatch();
  return {
    section: Localise(messages, "Receive Payment"),
    path: "receivePayment",
    fields: [
      {
        title: Localise(messages, "Shop"),
        formFieldElement: FormFieldPicker,
        formFieldProps: {
          name: "memberCode",
          placeholder: {
            label: Localise(messages, "Select Shop"),
            value: "",
          },
          data: allShopCodes.map((code) => {
            return { label: code, value: code };
          }),
          onChange: (value) => {
            dispatch(setSelectedShopCode(value));
          },
        },
      },
      {
        title: Localise(messages, "Amount"),
        formFieldElement: FormField,
        formFieldProps: {
          ...TextFieldProps,
          name: "amount",
          isPrice: true,
          iconName: "currency-usd",
          iconSize: 14,
          iconType: "material-community",
          inputContainerStyle: { padding: 6 },
          leftIconContainerStyle: { paddingRight: 0 },
          keyboardType: "numeric",
          placeholder: Localise(messages, "Enter amount"),
        },
      },
      {
        title: Localise(messages, "Notes"),
        formFieldElement: FormField,
        formFieldProps: {
          ...TextFieldProps,
          name: "optionalNotes",
          placeholder: Localise(messages, "Optional Notes"),
        },
      },
    ],
  };
};

export const getUIConfig = (
  Localise,
  messages,
  isDesktop,
  isMobile,
  allShopCodes = [],
  showEmailMarketingToggle
) => ({
  sections: [
    {
      section: Localise(messages, "Customer Info"),
      component: {
        name: CustomerInfo,
        props: {
          InputFields: CustomerInfoFields(
            Localise,
            messages,
            showEmailMarketingToggle
          ),
          CustomerHistory: CustomerHistory(Localise, messages),
        },
      },
    },
    // {
    //   section: Localise(messages,"Notifications"),
    //   component: {
    //     name: Notifications,
    //     props: {
    //       InputFields: CustomerNotificationFields(Localise, messages),
    //     },
    //   },
    // },
    {
      section: Localise(messages, "Card On File"),
      component: {
        name: Payment,
        props: {
          InputFields: CustomerPaymentFields(Localise, messages),
        },
      },
    },
    {
      section: Localise(messages, "Order History"),
      component: {
        name: Listing,
        props: {
          showHeader: false,
          scrollContainerStyles: {
            padding: 0,
          },
          sections: [
            {
              component: {
                props: {
                  noRecordsMessage: Localise(messages, "No Orders Yet!"),
                  viewMore: {
                    label: Localise(messages, "View More"),
                    value: 5,
                    initial: 5,
                  },
                  selectors: {
                    dataSelector: selectCustomerOrders,
                    limitSelector: selectOrderHistoryLimit,
                    accordionSelector: selectNoop,
                  },
                  actions: { setPageAction: setCurrentCustomer },
                  itemComponent: {
                    name: OrderListItem,
                    props: {
                      countDownTimer: false,
                      setOrderMeatBallAction,
                      columnsList: isMobile
                        ? ListOfMobileColumns
                        : ListOfColumns,
                      subColumnsList: ListOfSubColumns,
                      actionsList: ListOfOrderActions(),
                      subActionsList: ListOfSubActions,
                      isSmallScreen: !isDesktop,
                      isMobile,
                      listingType: "customerOrders",
                      setCurrentCustomer,
                    },
                  },
                },
              },
            },
          ],
        },
      },
    },
    {
      section: Localise(messages, "Subscriptions"),
      component: {
        name: Subscriptions,
        props: {
          showHeader: false,
          scrollContainerStyles: {
            padding: 0,
          },
          sections: [
            {
              section: "subscriptions",
              component: {
                props: {
                  noRecordsMessage: Localise(messages, "No Subscriptions Yet!"),
                  viewMore: {
                    label: Localise(messages, "View More"),
                    value: 5,
                    initial: 5,
                  },
                  selectors: {
                    dataSelector: selectCustomerSubscriptions,
                    limitSelector: selectOrderHistoryLimit,
                    accordionSelector: selectNoop,
                  },
                  actions: { setPageAction: setCurrentCustomer },
                  itemComponent: {
                    name: SubscriptionItem,
                    props: {
                      listingType: "customerSubscriptions",
                      setCurrentCustomer,
                    },
                  },
                },
              },
            },
          ],
        },
      },
    },
    {
      section: Localise(messages, "House Account"),
      component: {
        name: HouseAccount,
        props: {
          InputFields: HouseAccountFields(Localise, messages),
        },
      },
    },
    {
      section: Localise(messages, "Credit/Debit Memo"),
      component: {
        name: CreditDebitMemo,
        props: {
          InputFields: CreditDebitMemoFields(Localise, messages, allShopCodes),
        },
      },
    },
    {
      section: Localise(messages, "Statements"),
      component: {
        name: Statements,
        props: {
          sections: [
            {
              section: Localise(messages, "Statement"),
              component: {
                name: Statement,
                props: {},
              },
            },
            {
              section: Localise(messages, "Receive Payment"),
              component: {
                name: ReceivePayment,
                props: {
                  InputFields: ReceivePaymentFields(
                    Localise,
                    messages,
                    allShopCodes
                  ),
                },
              },
            },
            {
              section: Localise(messages, "Current Balance"),
              component: {
                name: CurrentBalance,
                props: {},
              },
            },
          ],
        },
      },
    },
    {
      section: Localise(messages, "Activity"),
      component: {
        name: Activity,
        props: {
          InputFields: CustomerInfoFields(
            Localise,
            messages,
            showEmailMarketingToggle
          ),
          CustomerHistory: CustomerHistory(Localise, messages),
        },
      },
    },
  ],
});
