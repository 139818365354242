import toLower from "lodash/toLower";

export const getCitiesByZipSearch = (cityZipcodes = [], searchText = "") => {
  if (!searchText) return cityZipcodes;
  return cityZipcodes.filter((coverageArea) => {
    const { zipCode = "" } = coverageArea;
    return isMatchingZip(searchText, zipCode);
  });
};

export const getCitiesByNameOrZipSearch = (
  cities = [],
  cityZipCodes = [],
  searchText = ""
) => {
  if (!searchText) return cities;

  return cities?.filter((coverageArea) =>
    isMatchingCityOrZip(coverageArea, searchText, cityZipCodes)
  );
};

export const isMatchingZip = (searchText = "", zipCode = "") => {
  if (!searchText) return true;
  return toLower(zipCode).startsWith(toLower(searchText));
};

export const isMatchingCityOrZip = (
  coverageArea = {},
  searchText = "",
  cityZipCodes = []
) => {
  if (!searchText) return true;
  const { city = "", status } = coverageArea;
  const cityInLower = toLower(city);
  return (
    status &&
    (cityInLower.startsWith(toLower(searchText)) ||
      getCitiesByZipSearch(cityZipCodes, searchText)
        .map((x) => toLower(x.city))
        .includes(cityInLower))
  );
};
