import {
  select,
  put,
  call,
  fork,
  delay,
  all,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import get from "lodash/get";
import uniq from "lodash/uniq";
import isEqual from "lodash/isEqual";
import flatten from "lodash/flatten";
import toLower from "lodash/toLower";
import capitalize from "lodash/capitalize";
import difference from "lodash/difference";
import intersection from "lodash/intersection";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import Environment from "library/utils/environment";
import { Currency } from "components/wrappers";

import {
  fetchProduct,
  createProduct,
  updateProduct,
  patchProduct,
  deleteProduct,
  restoreProduct,
  setData,
  setApiResponse,
  setAPIError,
  setQuickAction,
  fetchActivity,
  revertToGlobal,
} from "./slice";

import {
  setAPIResponse as setCommonAPIResponse,
  navigateScreen,
  setBulkUpdates,
  applyPriceAdjustmentRule,
  setProductExtraFilters,
} from "../common/slice";

import {
  fetchShopSettings,
  setShopCode as setShopSettingsCode,
} from "../../shop-settings/common/slice";

import { processCatalogResponse, calcPrice } from "../common/index.js";

import {
  selectAllCollections,
  selectEnableProductActivity,
  selectScheduledPriceAdjustment,
  selectShopCode,
  selectSharedCatalogIds,
  selectScreen,
} from "../common/selector";
import Logger from "library/utils/logger";
import { selectApiResponse, selectData } from "./selector";
import request from "../request";
import UserProfileStorage from "library/storage/userProfile";
import { memberEntitlements } from "library/utils/entitlements";
import { createRecipeList } from "library/utils/productDetails";

function* handleFetchProduct(action = {}) {
  const pwsProductRequest = (params) => request("get-product", params);
  const prsProductRequest = (params) => request("get-product-prs", params);

  const productId = get(action, "payload", "");
  const memberCodes = UserProfileStorage.getProfileMemberCodes();
  const code = yield select(selectShopCode);
  const shopCode = code === "all" ? memberCodes[0] : code;

  try {
    const pwsResponse = yield call(pwsProductRequest, { productId, shopCode });

    const collections = yield select(selectAllCollections);
    const sharedCatalogs = yield select(selectSharedCatalogIds);

    const priceAdjustmentRules = yield select(selectScheduledPriceAdjustment);
    const isPriceAdjustmentApplied = priceAdjustmentRules.length > 0;
    const matchingRule =
      priceAdjustmentRules?.find((rule) => {
        const {
          startDate = "",
          endDate = "",
          active = true,
          products = [],
        } = rule;
        return (
          active &&
          products.includes(productId) &&
          moment().isSameOrAfter(moment(startDate, "MM/DD/YYYY")) &&
          moment().isSameOrBefore(moment(endDate, "MM/DD/YYYY"))
        );
      }) || {};

    const { action = "", fieldValue = "", operation = "" } = matchingRule || {};

    const isMOLProduct =
      pwsResponse.siteSellable.includes("mol") ||
      (pwsResponse.siteSellable.includes(shopCode) &&
        pwsResponse.siteId === shopCode);

    const extraData = {
      collections,
      sharedCatalogs,
    };

    if (isPriceAdjustmentApplied) {
      extraData.adjustedPriceRuleObject = {
        action,
        fieldValue,
        operation,
      };
    }

    if (isMOLProduct && !productId.includes("GIFT_CARD")) {
      const molResponse = yield call(prsProductRequest, {
        productId,
        shopCode,
        queryByGlobal: true,
      });
      extraData.prsResponse = get(molResponse, "products.0", []);
      extraData.srpPricesObject = {
        regionalPrices: get(molResponse, "products.0.regionalPrices", []),
        variations: get(molResponse, "products.0.variations.items", []),
      };
    }

    const content = processProductResponse(pwsResponse, extraData, shopCode);

    yield put(setShopSettingsCode(shopCode));
    yield put(fetchShopSettings());
    yield put(setApiResponse(pwsResponse));
    yield put(setData({ content }));

    const enableProductActivity = yield select(selectEnableProductActivity);
    if (enableProductActivity) {
      const addonId = get(pwsResponse, "addonId", "");
      const addonType = get(pwsResponse.classification, "addonType", "");
      const isAddon = addonId !== "" || addonType !== "";
      const variationIds = get(pwsResponse, "variationProductIds", "");
      yield put(fetchActivity({ productId, isAddon, variationIds }));
    }

    return content;
  } catch (error) {
    Logger.error("Unable to fetch product details.", productId);
    const productError = error
      .toLowerCase()
      .includes("no product found with id")
      ? `There is no product "${productId}" available for member "${shopCode}"`
      : "Something went wrong, please try again";
    yield put(setAPIError(productError));
  }
}

function* handleCreateProduct(action = {}) {
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (requestPayload) =>
    request("create-product", requestPayload);

  const { resolve, reject, params = {} } = get(action, "payload", {});

  const { productId } = params;

  try {
    const imageUpdates = yield call(handleProductImagesUpload, params);
    const requestPayload = prepareSaveProductPayload({
      ...params,
      variants: params.variants.map((variant, index) => ({
        ...variant,
        ...imageUpdates[index],
      })),
      shopCode,
    });
    const { regionalPrices = [], ...createProductPayload } = requestPayload;
    yield call(serviceRequest, createProductPayload);

    yield put(fetchProduct(productId));

    const { catalogType, ...data } = prepareCatalogPayload(requestPayload);
    const collections = get(
      requestPayload,
      "collections.floristCollections",
      []
    );
    yield put(
      setCommonAPIResponse({
        section: catalogType,
        patch: {
          id: productId,
          todo: "create",
          data: get(
            processCatalogResponse(
              { productsList: [data] },
              catalogType,
              shopCode
            ),
            "0",
            ""
          ),
        },
      })
    );

    if (catalogType === "local") {
      yield put(
        setProductExtraFilters({
          productExtraFilters: {
            flowerTypes: processFilters("flowerType", params),
            colorTypes: processFilters("productColor", params),
          },
        })
      );
    }
    // trigger bulk update action for addonToCollection Mapping
    if (params.productType?.includes("addon") && params.collections.length) {
      const addonToCollectionsMapping = params.collections.filter(
        (val) => val !== "addons"
      );
      yield call(handleBulkUpdates, {
        collectionIds: addonToCollectionsMapping,
        property: "addons",
        operation: "add",
        value: [productId],
      });
    }

    if (collections.length) {
      yield fork(function* () {
        yield delay(5000);
        yield call(handleBulkUpdates, {
          productIds: [productId],
          property: "collections",
          operation: "add",
          value: collections,
        });
      });
    }

    resolve && resolve();
  } catch (error) {
    if (get(error, "errors.0.message", "").includes("exists already")) {
      reject && reject("ID already exists.");
    } else {
      yield put(setAPIError("Something went wrong, please try again"));
      reject && reject();
    }
  }
}

function* handleUpdateProduct(action = {}) {
  const shopCode = yield select(selectShopCode);
  const updateServiceRequest = (requestPayload) =>
    request("update-product", requestPayload);

  const { resolve, reject, params } = get(action, "payload", {});

  const { productId } = params;
  const { prevCollections = [], bulkOperation = "", ...payload } = params;

  try {
    const original = yield select(selectData);

    const imageUpdates = yield call(handleProductImagesUpload, params);

    const updates = {
      ...payload,
      variants: payload.variants.map((variant, index) => ({
        ...variant,
        ...imageUpdates[index],
      })),
      collections: prevCollections, // update collection mappings in bulk update api only (below)
      shopCode,
    };
    const updatedAddonData = {
      availabilityObject: updates.availabilityObject,
      activeStartDate: updates.activeStartDate,
      activeEndDate: updates.activeEndDate,
      compareAtPrice: updates.compareAtPrice,
      price: updates.price,
      variants: updates.variants,
    };

    const originalPwsData = {
      availabilityObject: original.availabilityObject,
      activeStartDate: original.activeStartDate,
      activeEndDate: original.activeEndDate,
      compareAtPrice: original.compareAtPrice,
      price: original.price,
      variants: original.variants,
    };
    //Skip product update api calls for global addon when only collections are touched.
    const skipProductUpdateApi =
      original.productType?.includes("addon") &&
      original.group === "mol" &&
      bulkOperation !== "" &&
      isEqual(originalPwsData, updatedAddonData);

    if (!isEqual(original, updates)) {
      const requestPayload = prepareSaveProductPayload(updates);
      const originalData = yield select(selectApiResponse);
      const diffPayload = prepareDiffPayload(requestPayload, originalData);

      if (!skipProductUpdateApi) {
        yield call(updateServiceRequest, diffPayload);
      }

      const { catalogType, ...data } = prepareCatalogPayload({
        ...requestPayload,
        defaultVariationId: params.defaultVariantId,
      });

      yield put(
        setCommonAPIResponse({
          section: catalogType,
          patch: {
            id: productId,
            todo: "update",
            data: get(
              processCatalogResponse(
                { productsList: [data] },
                catalogType,
                shopCode
              ),
              "0",
              ""
            ),
          },
        })
      );

      if (catalogType === "local") {
        yield put(
          setProductExtraFilters({
            productExtraFilters: {
              flowerTypes: processFilters("flowerType", payload),
              colorTypes: processFilters("productColor", payload),
            },
          })
        );
      }
    }

    // status change
    if (original.availability !== params.availability) {
      yield call(handleBulkUpdates, {
        productIds: [productId],
        property: "active",
        operation: "",
        value: params.availability,
        skipApi: true,
      });
    }

    // collection -> product mappings
    const previousCollections = uniq(params?.prevCollections) || [];
    const currentCollections = uniq(params?.collections) || [];

    const addProductToCollections = difference(
      currentCollections,
      previousCollections
    );

    if (params.productType?.includes("addon") && params.collections.length) {
      const addonToCollectionsMapping = params.collections.filter(
        (val) => val !== "addons"
      );
      if (addonToCollectionsMapping.length > 0) {
        yield call(handleBulkUpdates, {
          collectionIds: addonToCollectionsMapping,
          property: "addons",
          operation: bulkOperation,
          value: [productId],
        });
      }
    } else {
      if (addProductToCollections.length) {
        yield call(handleBulkUpdates, {
          productIds: [productId],
          property: "collections",
          operation: "add",
          value: addProductToCollections,
        });
      }

      const removeProductFromCollections = difference(
        prevCollections,
        currentCollections
      );
      if (removeProductFromCollections.length) {
        yield call(handleBulkUpdates, {
          productIds: [productId],
          property: "collections",
          operation: "remove",
          value: removeProductFromCollections,
        });
      }
    }
    yield put(fetchProduct(productId));

    resolve && resolve();

    yield put(applyPriceAdjustmentRule("local"));
    yield put(applyPriceAdjustmentRule("global"));
  } catch (error) {
    Logger.error(
      `Failed to update the product- ${productId}`,
      JSON.stringify({ error, payload: params })
    );
    yield put(setAPIError("Something went wrong, please try again"));
    reject && reject();
  }
}
const processFilters = (path, payload = []) => {
  const type = payload.variants.reduce((arr, product) => {
    if (product[path]) arr.push(product[path]);
    return [...new Set(arr.flat())];
  }, []);

  return type;
};

function* handleBulkUpdates({
  productIds = [],
  collectionIds = [],
  property,
  operation,
  value,
  skipApi,
}) {
  const shopCode = yield select(selectShopCode);
  const bulkUpdateRequest = (params) => request("bulk-products-update", params);

  if (!skipApi)
    yield call(bulkUpdateRequest, {
      ...(property === "addons"
        ? {
            ...(collectionIds.includes("*")
              ? { productIds: ["*"] }
              : { collectionIds }),
          }
        : { productIds }),
      property,
      operation,
      value,
      shopCode,
    });

  yield put(
    setBulkUpdates({
      productIds,
      property,
      operation,
      value,
    })
  );
}

function* handlePatchProduct(action = {}) {
  const { resolve, reject, params } = get(action, "payload", {});

  const { productId, status } = params;
  const { name, statusAsOnDate } = yield select(selectData);

  if (!productId) return;

  try {
    yield call(handleBulkUpdates, {
      productIds: [productId],
      property: "active",
      operation: "",
      value: status === "active",
      type: "",
    });

    if (name)
      yield put(
        setData({
          patch: {
            availability: statusAsOnDate && status === "active",
          },
        })
      );

    resolve && resolve();
  } catch (error) {
    const { params: { handle: screen } = {} } = yield select(selectScreen);
    if (screen === "product")
      yield put(setAPIError("Something went wrong, please try again"));
    reject && reject();
  }
}

function* handleDeleteProduct(action = {}) {
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (productId) =>
    request("delete-product", { productId, shopCode });
  const { catalogType } = yield select(selectData);
  const {
    resolve,
    reject,
    params: { productId, section },
  } = get(action, "payload", {});

  try {
    const payload = {
      params: {
        status: "inactive",
        productId,
      },
    };

    yield call(handlePatchProduct, { payload });

    yield call(serviceRequest, productId);

    yield put(
      setCommonAPIResponse({
        section: section || catalogType,
        patch: {
          id: productId,
          todo: "delete",
        },
      })
    );

    resolve && resolve();
  } catch (error) {
    yield put(setAPIError("Something went wrong, please try again"));
    reject && reject();
  }
}

function* handleRestoreProduct(action = {}) {
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (productId) =>
    request("restore-product", { productId, shopCode });

  const {
    resolve,
    reject,
    params: { productId },
  } = get(action, "payload", {});

  try {
    yield call(serviceRequest, productId);

    const product = yield call(handleFetchProduct, { payload: productId });

    const requestPayload = prepareSaveProductPayload(product);

    const { catalogType, ...data } = prepareCatalogPayload(requestPayload);

    yield put(
      setCommonAPIResponse({
        section: catalogType,
        patch: {
          id: productId,
          todo: "create",
          data: get(
            processCatalogResponse(
              { productsList: [data] },
              catalogType,
              shopCode
            ),
            "0",
            ""
          ),
        },
      })
    );

    if (product.collections.length) {
      yield fork(function* () {
        yield delay(5000);
        yield call(handleBulkUpdates, {
          productIds: [productId],
          property: "collections",
          operation: "add",
          value: product.collections,
        });
      });
    }

    const payload = {
      params: {
        status: "inactive",
        productId,
      },
    };

    yield call(handlePatchProduct, { payload });

    resolve && resolve();
  } catch (error) {
    yield put(setAPIError("Something went wrong, please try again"));
    reject && reject();
  }
}

function* handleRevertProductToGlobal(action = {}) {
  const shopCode = yield select(selectShopCode);
  const { resolve, reject, params } = get(action, "payload", {});
  const { productId } = params;

  const serviceRequest = (params) =>
    request("product-revert-to-global", params);

  try {
    yield call(serviceRequest, { productId, shopCode });

    const product = yield call(handleFetchProduct, { payload: productId });
    const requestPayload = prepareSaveProductPayload(product);
    const { catalogType, ...data } = prepareCatalogPayload(requestPayload);

    yield put(
      setCommonAPIResponse({
        section: catalogType,
        patch: {
          id: productId,
          todo: "update",
          data: get(
            processCatalogResponse(
              { productsList: [data] },
              catalogType,
              shopCode
            ),
            "0",
            ""
          ),
        },
      })
    );

    resolve && resolve();
  } catch (error) {
    yield put(setAPIError("Something went wrong, please try again"));
    reject && reject();
  }
}

function* handleQuickAction(action = {}) {
  const {
    resolve,
    reject,
    params: {
      id,
      section,
      patch: { todo, data },
    },
  } = get(action, "payload", {});

  if (!id) return;

  if (todo === "delete") {
    yield put(
      deleteProduct({ params: { productId: id, section }, resolve, reject })
    );
  } else if (todo === "duplicate") {
    yield put(
      navigateScreen({
        name: "product",
        params: {
          handle: `clone_${id}`,
        },
      })
    );
    // status update
  } else {
    yield put(
      patchProduct({
        params: { productId: id, ...data },
        resolve,
        reject,
      })
    );
  }
}

/** Helpers */
function* handleProductImagesUpload(params) {
  const { variants = [] } = params;
  const memberCodes = UserProfileStorage.getProfileMemberCodes();
  const shopCode = yield select(selectShopCode);
  const memberCode = shopCode === "all" ? memberCodes[0] : shopCode;
  const imageUploadRequest = (productId, image) => {
    let contentType = "text/plain";
    let fileExt = "";

    if (image.startsWith("data:image")) {
      fileExt = image.substring("data:image/".length, image.indexOf(";base64"));
      contentType = `image/${fileExt}`;
    }

    return request("upload-MOL-image", {
      imageContent: image.split(",")[1],
      imageName: `${productId}_Image.${fileExt}`,
      imageCategory: "product",
      imageContentType: contentType,
      shopCode: memberCode,
    }).then((response) => {
      return get(response, "imageDetails.GCPImageURL", "");
    });
  };

  try {
    const images = yield all(
      variants.map((variant) => {
        const { productId, image = "" } = variant;
        if (productId && image && image.startsWith("data:image")) {
          return call(imageUploadRequest, productId, image);
        }
        return image;
      })
    );

    return images.map((image) => ({
      image: image.startsWith("data:image") ? "" : image,
    }));
  } catch {
    setAPIError("Something went wrong, please try again");

    return variants.map((variant) => ({
      ...variant,
      image: variant.image.startsWith("data:image") ? "" : variant.image,
    }));
  }
}

function* handleFetchActivity(action = {}) {
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (productId, shopCode) =>
    request("product-activity", { productId, shopCode });

  const { resolve, reject, productId, isAddon, variationIds } = get(
    action,
    "payload",
    {}
  );

  try {
    const response = yield call(serviceRequest, productId, shopCode);
    const activity = prepareProductActivityResponse(
      response,
      isAddon,
      variationIds
    );

    yield put(
      setData({
        patch: { activity },
      })
    );

    resolve && resolve();
  } catch (error) {
    yield put(setAPIError("Something went wrong, please try again"));
    reject && reject();
  }
}
const prepareProductActivityResponse = (
  response = [],
  isAddon,
  variationIds
) => {
  const filteredResponse = response
    .filter((val) => val.diff.length > 0)
    .map((val) => {
      const productId = get(val.product, "productId", "");
      const isVariationExists =
        get(val.product, "variations", "") && variationIds.includes(productId);

      return {
        diff: val.diff,
        productId: val.product.productId,
        trigger_type: val.trigger_type,
        ...(isVariationExists
          ? { isVariationProduct: !!val.product.variations }
          : {}),
        variationIds: variationIds,
        isAddon: isAddon,
        authorName: val.authorName,
        modifiedProperty: val.modifiedPropertyGroups,
        lastUpdatedDate: moment(val.lastUpdatedDate),
      };
    })
    .sort(
      (a, b) =>
        moment(b.lastUpdatedDate.format()) - moment(a.lastUpdatedDate.format())
    );

  return filteredResponse;
};

const processProductResponse = (response = {}, extraData = {}, shopCode) => {
  const getPrice = (regionalPrices = []) => {
    const country = Currency(shopCode, "COUNTRY");
    const { price = "", salePrice = "" } =
      regionalPrices.find((o) => o.country === country) || {};
    return {
      price: price ? parseFloat(price).toFixed(2) : "",
      salePrice: salePrice ? parseFloat(salePrice).toFixed(2) : "",
    };
  };

  const shopifyWebsiteUrl = getShopifyWebsiteUrl(shopCode);

  const seoDisplayUrl = get(response, "seo.url", "").replace(
    "/product/",
    "/products/"
  );
  const productId = get(response, "productId", "");
  const siteSellable = get(response, "siteSellable", []);
  const siteId = get(response, "siteId", "");
  const collections = get(response, "collections.floristCollections", []);
  const productGroup = get(response, "productGroup", []);
  const classification = get(response, "classification", {});
  const addonId = get(response, "addonId", "");
  const addonType = get(classification, "addonType", "");
  const subType = get(classification, "subType", "");

  const addonSubType = [
    "standard-addon",
    "personal-touch-addon",
    "standard-and-personal-touch",
  ].includes(subType)
    ? subType === "standard-and-personal-touch"
      ? ["standard-addon", "personal-touch-addon"]
      : [subType]
    : ["standard-addon"];

  const masterImage =
    get(response, `media.${shopCode}.0.url`, "") ||
    get(response, "media.mol.0.url", "");
  const memberModifiedCollections = get(
    response,
    "memberModifiedCollections",
    []
  );
  const isVariantsActive =
    get(response, "variations", []).filter((val) => val.active).length > 0;

  const dropShippingProduct = get(
    response,
    "memberAttributes.dropShippingProduct",
    false
  );
  const todayDate = moment().format("YYYY-MM-DD");

  const activeStartDate = get(
    response,
    "availability.activeStartDate",
    todayDate
  );
  //availability toggle check
  const activeEndDate = get(response, "availability.activeEndDate", todayDate);
  const activeStatus = get(response, "availability.active", false);
  const isBeforeToday = moment(todayDate).isSameOrAfter(activeStartDate);
  const isAfterToday = moment(todayDate).isSameOrBefore(activeEndDate);
  const availability = isBeforeToday && isAfterToday && activeStatus;

  //availability optional dates
  const productActiveStartDate =
    get(response, "availability.activeStartDate", "") === "1900-01-01"
      ? ""
      : get(response, "availability.activeStartDate", "");

  const productActiveEndDate =
    get(response, "availability.activeEndDate", "") === "2100-01-01"
      ? ""
      : get(response, "availability.activeEndDate", "");

  const activeDateAvailability = !!(
    productActiveEndDate || productActiveStartDate
  );

  const fulfillmentStartDate =
    get(response, "memberAttributes.fulfillmentStartDate", "") === "1900-01-01"
      ? ""
      : get(response, "memberAttributes.fulfillmentStartDate", "");

  const fulfillmentEndDate =
    get(response, "memberAttributes.fulfillmentEndDate", "") === "2100-01-01"
      ? ""
      : get(response, "memberAttributes.fulfillmentEndDate", "");
  const fulfillmentDateAvailability = !!(
    fulfillmentEndDate || fulfillmentStartDate
  );
  // prices
  const regionalPrices = get(response, "regionalPrices", []);

  const { price: masterPrice, salePrice: masterSalePrice } =
    getPrice(regionalPrices);

  const masterRegularPrice = masterSalePrice ? masterSalePrice : masterPrice;
  const masterCompareAtPrice =
    masterSalePrice && masterPrice ? masterPrice : "";

  const {
    adjustedPriceRuleObject = {},
    srpPricesObject = {},
    collections: availableCollections = [],
    sharedCatalogs: availableSharedCatalogs = [],
    prsResponse = [],
  } = extraData;

  const {
    regionalPrices: srpRegionalPrices = [],
    variations: srpVariantPrices = [],
  } = srpPricesObject; // SRP prices
  const maxDepreciationAllowed = Environment.get(
    "MAXIMUM_SRP_DEPRECIATION_ALLOWED",
    10
  );
  const { price: masterSrpMinPrice, salePrice: masterSrpSalePrice } =
    getPrice(srpRegionalPrices);

  const masterSrpMinimumFloorValue =
    parseFloat(masterSrpSalePrice || masterSrpMinPrice) -
    parseFloat(maxDepreciationAllowed);

  const masterSrpPriceMinimum =
    parseFloat(masterSrpMinimumFloorValue) <= 0
      ? parseFloat(masterSrpSalePrice || masterSrpMinPrice)
      : masterSrpMinimumFloorValue;

  const {
    action = "",
    fieldValue = "",
    operation = "",
  } = adjustedPriceRuleObject;

  const adjustedMasterPrice = calcPrice(
    masterRegularPrice,
    masterSrpPriceMinimum,
    fieldValue,
    operation,
    action
  );
  const adjustedCompareAtPrice = calcPrice(
    masterCompareAtPrice,
    masterSrpPriceMinimum,
    fieldValue,
    operation,
    action
  );

  const isMOLProduct =
    siteSellable.includes("mol") ||
    (siteSellable.includes(shopCode) && siteId === shopCode);
  const isAddon = addonId !== "" || addonType !== "";

  const productType = isAddon
    ? "addon"
    : dropShippingProduct
    ? "dropship"
    : "product";

  //master recipes
  const productRecipe = get(prsResponse, "legacy.standardRecipe", "");
  const masterRecipeList = isMOLProduct
    ? createRecipeList(productRecipe)
    : createRecipeList(get(response, "memberAttributes.recipe", ""));

  //taxonomy categories
  const taxonomyMap = {
    CKJ: { id: "fb-2-3-2", name: "Chocolate" },
    RMCF: { id: "fb-2-3-2", name: "Chocolate" },
    A: { id: "ae-3-2-3", name: "Balloons" },
    GC: {
      id: "ae-3-1-6-2",
      name: "Greeting Cards",
    },
    BKJ: {
      id: "tg-5-8-11",
      name: "Stuffed Animals",
    },
  };

  let taxonomyCategoryId, taxonomyCategoryName;

  if (isMOLProduct && !isAddon) {
    const hasPlants = Object.values(classification).some(
      (value) => value === "Plants"
    );
    if (hasPlants) {
      taxonomyCategoryId = "hg-17";
      taxonomyCategoryName = "Plants";
    } else {
      taxonomyCategoryId = "ae-3-1-3";
      taxonomyCategoryName = "Fresh Cut Flowers";
    }
  } else if (taxonomyMap[productId]) {
    taxonomyCategoryId = taxonomyMap[productId].id;
    taxonomyCategoryName = taxonomyMap[productId].name;
  } else {
    taxonomyCategoryId = get(response, "memberAttributes.taxonomy.gid", "");
    taxonomyCategoryName = get(response, "memberAttributes.taxonomy.name", "");
  }

  const defaultVariantId = get(response, "defaultVariationId", "");
  let defaultVariantName = "",
    defaultVariantDescription = "";

  const variants = get(response, "variations", [])
    .filter((e) => isAddon || !!capitalize(e.upsellProductName))
    .map((e, index) => {
      const variantId = e.productId;
      const variantType = e.upsellProductName?.replace(/(<([^>]+)>)/gi, "");
      const image = get(e, "image");

      if (defaultVariantId === variantId || index === 0) {
        defaultVariantName = variantType;
        defaultVariantDescription = get(e, "descriptions.long.en-US", "");
      }

      const variantRegionalPrices = get(
        srpVariantPrices.find((o) => o.productId === variantId),
        "regionalPrices",
        []
      );
      const { price: variantSrpMinPrice, salePrice: variantSrpSalePrice } =
        getPrice(variantRegionalPrices);

      const variantSrpMinimumFloorValue =
        parseFloat(variantSrpSalePrice || variantSrpMinPrice) -
        parseFloat(maxDepreciationAllowed);
      const variantSrpPriceMinimum =
        parseFloat(variantSrpMinimumFloorValue) <= 0
          ? parseFloat(variantSrpSalePrice || variantSrpMinPrice)
          : variantSrpMinimumFloorValue;

      const { price: variantPrice = "", salePrice: variantSalePrice } =
        getPrice(e.regionalPrices);

      const variantRegularPrice = variantSalePrice
        ? variantSalePrice
        : variantPrice;
      const variantCompareAtPrice =
        variantSalePrice && variantPrice ? variantPrice : "";

      const adjustedVariantPrice = calcPrice(
        variantRegularPrice,
        variantSrpPriceMinimum,
        fieldValue,
        operation,
        action
      );
      const adjustedVariantCompareAtPrice = calcPrice(
        variantCompareAtPrice,
        variantSrpPriceMinimum,
        fieldValue,
        operation,
        action
      );

      //variant recipes
      const prsVariations = get(prsResponse, "variations.items", []);
      const globalVariantRecipe =
        prsVariations.find((o) => o.productId === variantId)?.legacy
          ?.standardRecipe || "";

      const recipe = isMOLProduct
        ? globalVariantRecipe
        : get(e, "memberAttributes.recipe", "");
      const variantRecipeList = createRecipeList(recipe);

      return {
        variantType,
        call_for_price: get(e, "callForPrice", false),
        inStorePickUp: get(e, "fulfillment.inStore", false),
        descriptions: e.descriptions,
        active: e.active,
        productId: variantId,
        dimensions: e.dimensions,
        adjustedPrice: adjustedVariantPrice || "",
        adjustedCompareAtPrice: adjustedVariantCompareAtPrice || "",
        ...(isMOLProduct
          ? {
              srpMinPrice: variantSrpPriceMinimum,
              originalSrpMin: variantSrpMinimumFloorValue,
            }
          : {}),
        price: variantSalePrice ? variantSalePrice : variantPrice,
        classification: get(e, "classification", {}),
        flowerType: get(e, "classification.flowerType", []),
        productColor: get(e, "color", []),
        originalColor: get(e, "color", []),
        compareAtPrice: variantSalePrice && variantPrice ? variantPrice : "",
        image: image?.includes("cdn.shopify.com")
          ? image
              .replace(".jpg", "_preset_mol-mx-tile-wide-sv-new_160x.jpg")
              .replace(".jpeg", "_preset_mol-mx-tile-wide-sv-new_160x.jpeg")
          : image,
        originalImage: image,
        isExists: true,
        seoUrl: isAddon ? get(e, "seo.url", "") : "",
        addonType: isAddon ? addonType || addonId : "",
        recipe: variantRecipeList,
        upsellProductName: e.upsellProductName || variantType,
        variationDisplaySequence: e.variationDisplaySequence,
        variantBackup: {
          regionalPrices: get(e, "regionalPrices", []),
          prices: get(e, "prices", {}),
        },
      };
    })
    .sort((v1, v2) => {
      if (isAddon) {
        const v1PidSuffix = v1.productId.charAt(v1.productId.length - 1);
        const v2PidSuffix = v2.productId.charAt(v2.productId.length - 1);
        return v1PidSuffix < v2PidSuffix ? -1 : 1;
      } else {
        return parseInt(v1.variationDisplaySequence) <
          parseInt(v2.variationDisplaySequence)
          ? -1
          : 1;
      }
    });

  return {
    name: get(response, "name.en-US", ""),
    group: isMOLProduct ? "mol" : "florist",
    seoUrl: get(response, "seo.url", ""),
    seoDisplayUrl: shopifyWebsiteUrl
      ? `${shopifyWebsiteUrl}${seoDisplayUrl}`
      : "",
    productId,
    description: get(
      response,
      "descriptions.long.en-US",
      defaultVariantDescription
    ),
    flowerType: get(response, "classification.flowerType", []),
    originalColor: get(response, "color", []),
    productColor: get(response, "color", []),
    adjustedPrice: adjustedMasterPrice || "",
    adjustedCompareAtPrice: adjustedCompareAtPrice || "",
    ...(isMOLProduct
      ? {
          srpMinPrice: masterSrpPriceMinimum,
          originalSrpMin: masterSrpMinimumFloorValue,
        }
      : {}),
    price: masterSalePrice ? masterSalePrice : masterPrice,
    compareAtPrice: masterSalePrice && masterPrice ? masterPrice : "",
    localDelivery: get(response, "fulfillment.localDelOnly", false),
    inStorePickUp: get(response, "fulfillment.inStore", false),
    excludeFromRushDelivery: get(
      response,
      "memberAttributes.excludeFromRushDelivery",
      false
    ),
    showOnWebsite: !get(response, "memberAttributes.hideFromWebsite", false),
    dropShippingProduct,
    fulfillmentStartDate,
    fulfillmentEndDate,
    fulfillmentDateAvailability,
    recipe: masterRecipeList,
    activeDateAvailability,
    promoEligible: get(response, "promoEligible", false),
    availability:
      variants.length > 0 ? availability && isVariantsActive : availability,
    statusAsOnDate: isBeforeToday && isAfterToday,
    availabilityObject: get(response, "availability", {}),
    activeStartDate: productActiveStartDate,
    activeEndDate: productActiveEndDate,
    memberModifiedCollections,
    productType,
    ...(isAddon ? { addonSubType: addonSubType || [] } : {}),
    catalogType: isAddon ? "addons" : isMOLProduct ? "global" : "local",
    standalone:
      isMOLProduct &&
      get(response, "variations", []).length === 0 &&
      !toLower(get(response, "taxonomy")).includes("variation"),
    variants,
    defaultVariantId,
    defaultVariantName,
    collections: availableCollections.length
      ? intersection(
          collections,
          availableCollections.map((e) => e.handle)
        )
      : collections,
    productGroup: availableSharedCatalogs.length
      ? intersection(productGroup, availableSharedCatalogs)
      : productGroup,
    addons: get(response, "addons", []),
    personalTouchAddons: get(
      response,
      "memberAttributes.personalTouchAddons",
      []
    ),
    call_for_price: get(response, "callForPrice", false),
    dimensions: get(response, "fulfillment.shipping.size"),
    soldOut: get(response, "availability.soldOut", false),
    excludeFromPriceMinimum: get(
      response,
      "availability.excludeFromPriceMinimum",
      false
    ),
    image: masterImage.includes("cdn.shopify.com")
      ? masterImage
          .replace(".jpg", "_preset_mol-mx-tile-wide-sv-new_160x.jpg")
          .replace(".jpeg", "_preset_mol-mx-tile-wide-sv-new_160x.jpeg")
      : masterImage,
    originalImage: masterImage,
    siteId: get(response, "siteId"),
    taxonomy: get(response, "taxonomy"),
    siteSellable: get(response, "siteSellable", []),
    isAddonSorted: get(response, "memberAttributes.isAddonSorted", false),
    taxonomyCategoryId,
    taxonomyCategoryName,
    classification,
    shopCode,
    taxExempt: get(response, "taxExempt", false),
    masterBackup: {
      regionalPrices,
      prices: get(response, "prices", {}),
    },
  };
};

const prepareSaveProductPayload = (params) => {
  const {
    name,
    group,
    seoUrl,
    productId,
    description,
    localDelivery = false,
    promoEligible = false,
    availability = false,
    showOnWebsite = true,
    excludeFromPriceMinimum = false,
    excludeFromRushDelivery = false,
    isAddonSorted = false,
    soldOut = false,
    productType,
    standalone = false,
    variants = [],
    collections: floristCollections = [],
    defaultVariantId,
    defaultVariantName,
    addons = [],
    personalTouchAddons = [],
    shopCode,
    originalImage,
    originalColor,
    siteId,
    call_for_price,
    inStorePickUp,
    taxonomy,
    siteSellable = [],
    statusAsOnDate = true,
    classification = {},
    availabilityObject = {},
    memberModifiedCollections,
    activeEndDate,
    activeStartDate,
    fulfillmentStartDate,
    fulfillmentEndDate,
    productGroup,
    taxExempt = false,
    masterBackup = {},
    taxonomyCategoryName = "",
    taxonomyCategoryId = "",
    addonSubType = [],
  } = params;

  const memberCodes = UserProfileStorage.getProfileMemberCodes();
  const code = shopCode === "all" ? memberCodes[0] : shopCode;
  const isAddon = productType?.includes("addon");
  const baseActiveVariant =
    !standalone && variants.find((e) => e.productId && e.price);

  const productActiveStartDate =
    activeStartDate === "" ? "1900-01-01" : activeStartDate;

  const productActiveEndDate =
    activeEndDate === "" ? "2100-01-01" : activeEndDate;

  const productFulfillmentStartDate =
    fulfillmentStartDate === "" ? "1900-01-01" : fulfillmentStartDate;
  const productFulfillmentEndDate =
    fulfillmentEndDate === "" ? "2100-01-01" : fulfillmentEndDate;
  const {
    price,
    compareAtPrice,
    dimensions,
    flowerType = [],
    productColor = [],
  } = baseActiveVariant || params;

  const getSEO = (string) =>
    `/products/${toLower(string.trim())
      .replace(/[^a-zA-Z0-9- ]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-")}`;

  const country = Currency(shopCode, "COUNTRY");
  const currencyCode = Currency(shopCode, "CODE");
  let newDefaultVariantId = "";
  const variations = variants
    .filter((e) => e.productId && e.price)
    .map(
      ({
        variantType,
        active,
        productId,
        dimensions,
        price,
        compareAtPrice,
        flowerType,
        productColor,
        image,
        originalColor,
        originalImage,
        isExists = false,
        seoUrl,
        classification,
        upsellProductName,
        variationDisplaySequence,
        recipe: recipeList = [],
        variantBackup = {},
      }) => {
        if (variantType === defaultVariantName) newDefaultVariantId = productId;

        const recipe =
          recipeList.length > 0
            ? recipeList
                .filter(({ quantity, item }) => quantity && item)
                .map(({ quantity, item }) => `${quantity} ${item}`)
                .join(", ")
            : "";

        return {
          variantType,
          name: {
            "en-US": `${name} (${variantType})`,
          },
          callForPrice: call_for_price,
          active,
          productId,
          dimensions,
          excludeFromPriceMinimum,
          soldOut,
          prices: {
            ...(variantBackup?.prices ?? {}),
            [currencyCode]: parseFloat(parseFloat(price).toFixed(2)),
          },
          ...(group !== "mol"
            ? { color: productColor }
            : { color: originalColor }),
          classification: {
            ...classification,
            ...(group !== "mol"
              ? {
                  flowerType,
                  ...(isAddon
                    ? {
                        addonType: classification?.addonType || "Other",
                        subType:
                          addonSubType.length > 1
                            ? "standard-and-personal-touch"
                            : addonSubType[0] || "",
                      }
                    : {}),
                  category: taxonomyCategoryName,
                }
              : {}),
          },
          regionalPrices: (variantBackup?.regionalPrices ?? [])
            .filter((e) => e.country !== country)
            .concat({
              country,
              price: parseFloat(
                parseFloat(compareAtPrice ? compareAtPrice : price).toFixed(2)
              ),
              ...(compareAtPrice
                ? { salePrice: parseFloat(parseFloat(price).toFixed(2)) }
                : {}),
            }),
          image: image.includes("_preset_mol-mx-tile-wide-sv-new_160x")
            ? originalImage
            : image,
          seo: {
            url: seoUrl || getSEO(`${name}-prd-${productId}`),
          },
          isExists,
          ...(isAddon ? { addonId: productId } : {}),
          upsellProductName:
            group === "mol" ? upsellProductName || variantType : variantType,
          fulfillment: {
            localDelOnly: productType === "dropship" ? false : localDelivery,
            inStore: productType === "dropship" ? false : inStorePickUp,
            shipping: {
              size: dimensions,
              methods: ["ND"],
            },
            floristAttributes: {
              mercuryDescription: `${name} (${variantType})`,
            },
          },
          taxonomy: taxonomy ? taxonomy : "Variation Florist",
          memberAttributes: {
            excludeFromRushDelivery,
            fulfillmentStartDate,
            fulfillmentEndDate,
            hideFromWebsite: !showOnWebsite,
            dropShippingProduct: productType === "dropship",
            personalTouchAddons:
              productType !== "dropship" ? personalTouchAddons : [],
            isAddonSorted,
            ...(group !== "mol"
              ? {
                  recipe,
                  ...(taxonomyCategoryId
                    ? {
                        taxonomy: {
                          gid: taxonomyCategoryId,
                          name: taxonomyCategoryName,
                        },
                      }
                    : {}),
                }
              : {}),
          },
          taxExempt,
          variationDisplaySequence,
        };
      }
    );

  const descriptions = {
    long: {
      "en-US": description || "",
    },
  };

  const addDefaultVariationId =
    group === "mol"
      ? newDefaultVariantId && newDefaultVariantId != defaultVariantId
      : true;
  const baseDefaultVariantId =
    defaultVariantId || baseActiveVariant?.productId || "";
  const defaultVariationObject = addDefaultVariationId
    ? {
        defaultVariationId: newDefaultVariantId || baseDefaultVariantId,
      }
    : {};

  const payload = {
    productId: productId,
    ...(isAddon ? { addonId: productId } : {}),
    taxonomy: taxonomy
      ? taxonomy
      : standalone
      ? "Standalone Florist"
      : "Variation Florist",
    audit: {
      source: "FOL",
      createdBy: "Mercury HQ",
    },
    descriptions,
    name: {
      "en-US": name,
    },
    seo: {
      url: seoUrl || getSEO(`${name}-prd-${productId}`),
    },
    prices: {
      ...(masterBackup?.prices ?? {}),
      [currencyCode]: parseFloat(parseFloat(price).toFixed(2)),
    },
    regionalPrices: (masterBackup?.regionalPrices ?? [])
      .filter((e) => e.country !== country)
      .concat({
        country,
        price: parseFloat(
          parseFloat(compareAtPrice ? compareAtPrice : price).toFixed(2)
        ),
        ...(compareAtPrice
          ? { salePrice: parseFloat(parseFloat(price).toFixed(2)) }
          : {}),
      }),
    callForPrice: call_for_price,
    availability: {
      ...availabilityObject,
      ...(group === "mol" ? {} : { state: "Active" }),
      activeEndDate: productActiveEndDate,
      activeStartDate: productActiveStartDate,
      active: availability,
      excludeFromPriceMinimum,
      soldOut,
    },
    memberAttributes: {
      excludeFromRushDelivery,
      fulfillmentStartDate: productFulfillmentStartDate,
      fulfillmentEndDate: productFulfillmentEndDate,
      hideFromWebsite: !showOnWebsite,
      dropShippingProduct: productType === "dropship",
      personalTouchAddons:
        productType !== "dropship" ? personalTouchAddons : [],
      isAddonSorted,
      ...(group !== "mol"
        ? {
            ...(taxonomyCategoryId
              ? {
                  taxonomy: {
                    gid: taxonomyCategoryId,
                    name: taxonomyCategoryName,
                  },
                }
              : {}),
          }
        : {}),
    },
    fulfillment: {
      localDelOnly: productType === "dropship" ? false : localDelivery,
      inStore: productType === "dropship" ? false : inStorePickUp,
      ...(dimensions && {
        shipping: {
          size: dimensions,
          methods: ["ND"],
        },
      }),
      floristAttributes: {
        mercuryDescription: name,
      },
    },
    classification: {
      ...classification,
      ...(group !== "mol"
        ? {
            flowerType,
            ...(isAddon
              ? {
                  addonType: classification?.addonType || "Other",
                  subType:
                    addonSubType.length > 1
                      ? "standard-and-personal-touch"
                      : addonSubType[0] || "standard-addon",
                }
              : {}),
            category: taxonomyCategoryName,
          }
        : {}),
    },
    promoEligible,
    ...(group === "mol" ? { memberModifiedCollections } : {}),
    variations,
    ...defaultVariationObject,
    collections: {
      floristCollections: isAddon ? ["addons"] : floristCollections,
    },
    ...(isAddon
      ? {}
      : {
          addons: productType !== "dropship" ? addons : [],
        }),
    siteId,
    statusAsOnDate,
    siteSellable: siteSellable.length ? siteSellable : [code],
    channel: [],
    ...(group !== "mol" ? { color: productColor } : { color: originalColor }),
    media: {
      [code]: [
        {
          url: standalone ? originalImage : "",
          presetType: "",
        },
      ],
    },
    shopCode,
    taxExempt,
    ...(!isAddon ? { productGroup } : {}),
  };
  return payload;
};

const getProductType = (product) => {
  const { shopCode, siteId, siteSellable } = product;
  const memberCodes = UserProfileStorage.getProfileMemberCodes();
  const code = shopCode === "all" ? memberCodes[0] : shopCode;
  const isMOLProduct =
    siteSellable.includes("mol") ||
    (siteSellable.includes(code) && siteId === code);

  return isMOLProduct ? "mol" : "florist";
};

const prepareDiffPayload = (newData = {}, originalData = {}) => {
  const updatedData = cloneDeep(newData);
  const group = getProductType(updatedData);
  const isGlobal = group === "mol";
  let payload = updatedData;
  const isAddon = !!originalData.addonId;

  if (!isGlobal) {
    // Mandatory fields while doing a product update in PWS
    payload = {
      productId: updatedData?.productId ?? "",
      ...(updatedData?.addonId ? { addonId: updatedData.addonId } : {}),
      ...(updatedData?.productGroup
        ? {
            productGroup:
              updatedData.productGroup.length > 0 || updatedData?.addonId
                ? updatedData.productGroup
                : ["my_catalog"],
          }
        : {}),
      siteSellable: updatedData?.siteSellable ?? [],
      audit: updatedData?.audit ?? {},
      classification: {
        ...(isAddon
          ? {
              addonType: originalData.classification.addonType || "Other",
            }
          : {}),
      },
    };

    delete updatedData.statusAsOnDate;
    delete updatedData.productGroup;
    delete updatedData.regionalPrices;

    if (!originalData?.color && !updatedData?.color?.length)
      delete updatedData.color;
    Object.keys(updatedData?.fulfillment).forEach((key) => {
      if (
        isEqual(originalData?.fulfillment[key], updatedData?.fulfillment[key])
      )
        delete updatedData?.fulfillment[key];
    });
    Object.keys(updatedData?.availability).forEach((key) => {
      if (
        isEqual(originalData?.availability[key], updatedData?.availability[key])
      )
        delete updatedData?.availability[key];
    });

    Object.keys(updatedData).forEach((item) => {
      if (item === "variations") {
        payload[item] = updatedData[item]
          .map((updatedVariant) => {
            const orginalVariant =
              originalData[item].find(
                (e) => e?.productId === updatedVariant?.productId
              ) || {};

            if (!isEqual(updatedVariant, orginalVariant)) {
              // Mandatory fields while doing a product update in PWS
              const variantPayload = {
                productId: updatedVariant?.productId ?? "",
                upsellProductName: updatedVariant?.upsellProductName ?? "",
                variantType: updatedVariant?.variantType ?? "",
                variationDisplaySequence:
                  updatedVariant?.variationDisplaySequence ?? "",
                seo: updatedVariant?.seo ?? "",
                active: updatedVariant?.active ?? false,
                image: updatedVariant?.image ?? "",
                classification: {
                  ...(isAddon
                    ? {
                        addonType:
                          originalData.classification.addonType || "Other",
                      }
                    : {}),
                },
              };

              if (!orginalVariant?.color && !updatedVariant?.color?.length)
                delete updatedVariant.color;

              Object.keys(updatedVariant).forEach((key) => {
                if (!isEqual(updatedVariant[key], orginalVariant[key])) {
                  variantPayload[key] = updatedVariant[key];
                }
              });
              return variantPayload;
            } else {
              return false;
            }
          })
          .filter(Boolean);
      } else if (!isEqual(updatedData[item], originalData[item])) {
        payload[item] = updatedData[item];
      }
    });
  }
  return payload;
};

const prepareCatalogPayload = (params) => {
  const {
    prices,
    regionalPrices,
    classification,
    descriptions,
    collections,
    availability,
    name,
    productId,
    addonId,
    siteId,
    siteSellable = [],
    variations,
    media,
    statusAsOnDate = true,
    defaultVariationId,
    productGroup = [],
    shopCode,
    seo = {},
  } = params;

  const { active, excludeFromPriceMinimum } = availability;

  const addonType = get(classification, "addonType", "");
  const isAddon = addonId || addonType !== "";
  const status = statusAsOnDate && active;
  const group = getProductType({ shopCode, siteId, siteSellable });
  const catalogType = isAddon ? "addons" : group === "mol" ? "global" : "local";

  const productImage = variations.length
    ? get(
        variations.find((v) => v.productId === defaultVariationId) ||
          variations[0],
        "image",
        ""
      )
    : get(media, `${siteId}.0.url`, "");

  return {
    classification,
    descriptions,
    collections,
    status,
    excludeFromPriceMinimum,
    excludeFromRushDelivery: get(
      params,
      "memberAttributes.excludeFromRushDelivery",
      false
    ),
    dropShippingProduct: get(
      params,
      "memberAttributes.dropShippingProduct",
      false
    ),
    name: get(name, "en-US", ""),
    image: productImage,
    siteId,
    siteSellable,
    productId,
    soldOut: get(params, "availability.soldOut", ""),
    callForPrice: get(params, "callForPrice", false),
    inStore: get(params, "fulfillment.inStore", false),
    localDelOnly: get(params, "fulfillment.localDelOnly", false),
    price: variations.length ? variations.map((v) => v.prices) : [prices],
    regionalPrices: variations.length
      ? flatten(variations.map((v) => v.regionalPrices))
      : regionalPrices,
    catalogType,
    availability,
    productGroup,
    seo,
  };
};

const getShopifyWebsiteUrl = (shopCode) => {
  const memberCodes = UserProfileStorage.getProfileMemberCodes();
  const code = shopCode === "all" ? memberCodes[0] : shopCode;
  const entitlement = UserProfileStorage.getMemberEntitlement(code);

  const showSeoDisplayUrl =
    entitlement &&
    entitlement
      .toLowerCase()
      .includes(memberEntitlements.MERCURY_ONLINE.toLowerCase());

  if (showSeoDisplayUrl) {
    const { shopify_store_url } = UserProfileStorage.getShopPreferences(code);

    return shopify_store_url;
  }

  return "";
};

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(fetchProduct.type, handleFetchProduct);
  yield takeEvery(createProduct.type, handleCreateProduct);
  yield takeEvery(updateProduct.type, handleUpdateProduct);
  yield takeEvery(patchProduct.type, handlePatchProduct);
  yield takeEvery(deleteProduct.type, handleDeleteProduct);
  yield takeEvery(restoreProduct.type, handleRestoreProduct);
  yield takeEvery(setQuickAction.type, handleQuickAction);
  yield takeEvery(fetchActivity.type, handleFetchActivity);
  yield takeLatest(revertToGlobal.type, handleRevertProductToGlobal);
}

export default watchSaga;
