/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { View, TouchableOpacity, ActivityIndicator } from "react-native";
import { Text, Button } from "react-native-elements";
import { useFormikContext } from "formik";
import * as DocumentPicker from "expo-document-picker";
import kebabCase from "lodash/kebabCase";
import startCase from "lodash/startCase";
import moment from "moment";

import { ToasterHandler } from "components/elements";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";
import Environment from "library/utils/environment";
import { setSideCar } from "library/sagas/views/home/drawer/ftd-admin/member-settings/common/slice";
import {
  setJobId,
  setMemberCode,
} from "library/sagas/views/home/drawer/ftd-admin/member-settings/manage-groups/slice";

import { fonts, colors, theme } from "styles/theme";
import tw from "tailwind-rn";

const GenerateUIForResults = React.memo(({ results = [] }) => {
  const dispatch = useDispatch();

  return (
    <View>
      {results.map((record, index) => {
        return (
          <View
            key={index}
            style={[
              {
                borderWidth: 1,
                borderColor: colors.grayScaleLight,
                padding: 8,
                marginBottom: 8,
                height: "auto",
                width: "100%",
              },
            ]}
          >
            <TouchableOpacity
              testID="data_migration_job_details"
              accessibilityLabel="data_migration_job_details"
              onPress={() => {
                dispatch(setSideCar("data-migration-details"));
                dispatch(setJobId(record.id));
                dispatch(setMemberCode(record.memberCode));
              }}
            >
              <View
                key={index}
                style={[
                  {
                    borderLeftWidth: 5,
                    borderLeftColor: record.colorCode,
                  },
                  tw("flex flex-row w-full items-center"),
                ]}
              >
                <Text
                  style={{
                    ...fonts.heading5,
                    width: "33%",
                    paddingLeft: 10,
                  }}
                >
                  {record.remarks}
                </Text>
                <Text
                  style={{
                    ...fonts.heading5,
                    width: "27%",
                  }}
                >
                  Started:{" "}
                  {moment
                    .utc(record.startDate)
                    .tz("America/Chicago")
                    .format("MM/DD/YYYY hh:mm A")}{" "}
                  CST
                </Text>
                <Text
                  style={{
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  User: {record.createdBy}
                </Text>
                <Text
                  style={{
                    width: "15%",
                    textAlign: "right",
                    ...fonts.link1,
                  }}
                  onPress={() => {
                    dispatch(setSideCar("data-migration-details"));
                    dispatch(setJobId(record.id));
                    dispatch(setMemberCode(record.memberCode));
                  }}
                >
                  View Status
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        );
      })}
    </View>
  );
});

const DataMigration = () => {
  const dmFileKeys = {
    customers: "customers",
    customerContacts: "customerContacts",
    customerRecipients: "customerRecipients",
    orders: "orders",
    products: "bulk product create",
  };

  const [dmFile, setDMFile] = useStateIfMounted({
    [dmFileKeys.customers]: "",
    [dmFileKeys.customerContacts]: "",
    [dmFileKeys.customerRecipients]: "",
    [dmFileKeys.orders]: "",
    [dmFileKeys.products]: "",
  });

  const [dmError, setDMError] = useStateIfMounted("");
  const [showDMLoading, setShowDMLoading] = useStateIfMounted("");
  const [dmResults, setDMResults] = useStateIfMounted([]);
  const [showDMSampleFileLoading, setShowDMSampleFileLoading] =
    useStateIfMounted(false);

  const {
    values: { apiMemberCode },
  } = useFormikContext();

  const dataMigrationFileSizeLimit = Environment.get(
    "DATA_MIGRATION_FILE_SIZE_LIMIT",
    10485760
  );

  const getJobsList = () => {
    request("get-data-migration-jobs", { memberCode: apiMemberCode })
      .then((res) => {
        processMHQReponse(res);
      })
      .catch((err) => {
        setShowDMLoading("done");
      });
  };

  useEffect(() => {
    getJobsList();
  }, []);

  const dmErrorMessages = {
    allFilesRequired: "Please choose all files to start data migration job.",
    errorOccured:
      "Failed to create data migration job, please try again later.",
    internalErrorOccured:
      "Some internal error occurred, please try again later.",
    fileSize: `file size should be less than ${
      dataMigrationFileSizeLimit / (1024 * 1024)
    } MB.`,
    bulkCreateProductsError:
      "Failed to create bulk products, please try again later.",
  };

  const chooseFile = async (fileKey) => {
    const fileType = fileKey === "bulk product create" ? "zip" : "csv";
    let result = await DocumentPicker.getDocumentAsync({
      type: `text/${fileType}`,
    });

    if (result.type === "success") {
      if (result.size < dataMigrationFileSizeLimit) {
        setDMFile({ ...dmFile, [fileKey]: result });
        setDMError("");
      } else {
        setDMFile({ ...dmFile, [fileKey]: "" });
        setDMError(`${startCase(fileKey)} ${dmErrorMessages.fileSize}`);
      }
    }
  };

  const processMHQReponse = (response = []) => {
    try {
      response.map((record) => {
        if (record.status !== "COMPLETED") {
          record.colorCode = colors.error;
          record.remarks = "Data Migration in progress";
        } else {
          record.colorCode = colors.success;
          record.remarks = "Data Migration Completed";
        }
      });

      setDMResults(response);
    } catch (err) {
      setDMError(dmErrorMessages.internalErrorOccured);
      setDMResults([]);
    }
    setShowDMLoading("done");
  };

  const handleSubmit = () => {
    if (dmFile[dmFileKeys.products]?.name) {
      setDMError("");
      setShowDMLoading("load");
      const dmPayloadWithFiles = new FormData();
      dmPayloadWithFiles.append("file", dmFile[dmFileKeys.products]?.file);

      request("bulk-create-products", dmPayloadWithFiles)
        .then(() => {
          setDMFile({
            customers: "",
            contacts: "",
            recipients: "",
            orders: "",
            products: "",
          });
          setShowDMLoading("done");
        })
        .catch((error) => {
          console.log("error:", error);
          setShowDMLoading("");
          setDMError(dmErrorMessages.bulkCreateProductsError);
        });
    } else if (
      dmFile[dmFileKeys.customers]?.name &&
      dmFile[dmFileKeys.customerContacts]?.name &&
      dmFile[dmFileKeys.customerRecipients]?.name &&
      dmFile[dmFileKeys.orders]?.name
    ) {
      setDMError("");
      setShowDMLoading("load");

      const dmPayloadWithFiles = new FormData();
      dmPayloadWithFiles.append("memberCode", apiMemberCode);
      dmPayloadWithFiles.append(
        dmFileKeys.customers,
        dmFile[dmFileKeys.customers]?.file
      );
      dmPayloadWithFiles.append(
        dmFileKeys.customerContacts,
        dmFile[dmFileKeys.customerContacts]?.file
      );
      dmPayloadWithFiles.append(
        dmFileKeys.customerRecipients,
        dmFile[dmFileKeys.customerRecipients]?.file
      );
      dmPayloadWithFiles.append(
        dmFileKeys.orders,
        dmFile[dmFileKeys.orders]?.file
      );

      request("upload-migration-data", dmPayloadWithFiles)
        .then(() => {
          setDMFile({
            customers: "",
            contacts: "",
            recipients: "",
            orders: "",
          });
          getJobsList();
        })
        .catch(() => {
          setShowDMLoading("");
          setDMError(dmErrorMessages.errorOccured);
        });
    } else {
      setDMError(dmErrorMessages.allFilesRequired);
    }
  };

  const downloadSampleFile = (fileKey) => {
    setShowDMSampleFileLoading(fileKey);
    const fileType = fileKey === "bulk product create" ? "zip" : "csv";
    request("download-image", {
      imageName: `${fileKey}.${fileType}`,
      memberCode: "FTD",
    })
      .then((res) => {
        var tempAnchorTag = document.createElement("a"); // creating temp anchor tag
        tempAnchorTag.href = `data:text/${fileType};base64,` + res.source;
        tempAnchorTag.download = `${kebabCase(
          fileKey
        )}-sample-file.${fileType}`;
        tempAnchorTag.click(); // triggering click event in order to download sample file automatically as we alreday clicked on sample file link

        setShowDMSampleFileLoading("");
      })
      .catch(() => {
        setShowDMSampleFileLoading("");
        ToasterHandler(
          "error",
          "Failed to download sample file, please try again later."
        );
      });
  };

  return (
    <View style={[{ zIndex: -1 }]}>
      <View style={[tw("flex justify-center py-2")]}>
        <Text style={[fonts.heading3, tw("mb-2")]}>Data Migration</Text>
      </View>
      <View style={tw("pb-2")}>
        <View>
          <View style={tw("flex flex-row items-center mt-1")}>
            <View style={[tw("flex flex-row"), { width: "45%" }]}>
              <Text style={[fonts.heading5, tw("mr-1")]}>Customers</Text>

              {showDMSampleFileLoading === dmFileKeys.customers ? (
                <ActivityIndicator
                  color={colors.activityIndicator}
                  style={tw("ml-2")}
                />
              ) : (
                <>
                  <Text>(</Text>
                  <TouchableOpacity
                    onPress={() => {
                      downloadSampleFile(dmFileKeys.customers);
                    }}
                  >
                    <Text style={[fonts.link1]}>Sample File</Text>
                  </TouchableOpacity>
                  <Text>)</Text>
                </>
              )}
            </View>

            <Button
              testID={"choose_mhq_file"}
              accessibilityLabel="choose_mhq_file"
              titleStyle={{
                ...theme.Button.secondaryTitleStyle,
                ...fonts.heading5,
              }}
              buttonStyle={{
                ...theme.Button.secondaryButtonStyle,
                paddingVertical: 2,
              }}
              containerStyle={{ marginLeft: 0 }}
              title="Choose File"
              onPress={() => {
                chooseFile(dmFileKeys.customers);
              }}
            />

            <Text numberOfLines={2}>
              {dmFile[dmFileKeys.customers]?.name || "No file chosen"}
            </Text>
          </View>

          <View style={tw("flex flex-row items-center mt-1")}>
            <View style={[tw("flex flex-row"), { width: "45%" }]}>
              <Text style={[fonts.heading5, tw("mr-1")]}>
                Customer Contacts
              </Text>

              {showDMSampleFileLoading === dmFileKeys.customerContacts ? (
                <ActivityIndicator
                  color={colors.activityIndicator}
                  style={tw("ml-2")}
                />
              ) : (
                <>
                  <Text>(</Text>
                  <TouchableOpacity
                    onPress={() => {
                      downloadSampleFile(dmFileKeys.customerContacts);
                    }}
                  >
                    <Text style={[fonts.link1]}>Sample File</Text>
                  </TouchableOpacity>
                  <Text>)</Text>
                </>
              )}
            </View>

            <Button
              testID={"choose_mhq_file"}
              accessibilityLabel="choose_mhq_file"
              titleStyle={{
                ...theme.Button.secondaryTitleStyle,
                ...fonts.heading5,
              }}
              buttonStyle={{
                ...theme.Button.secondaryButtonStyle,
                paddingVertical: 2,
              }}
              containerStyle={{ marginLeft: 0 }}
              title="Choose File"
              onPress={() => {
                chooseFile(dmFileKeys.customerContacts);
              }}
            />

            <Text numberOfLines={2}>
              {dmFile[dmFileKeys.customerContacts]?.name || "No file chosen"}
            </Text>
          </View>

          <View style={tw("flex flex-row items-center mt-1")}>
            <View style={[tw("flex flex-row"), { width: "45%" }]}>
              <Text style={[fonts.heading5, tw("mr-1")]}>
                Customer Recipients
              </Text>

              {showDMSampleFileLoading === dmFileKeys.customerRecipients ? (
                <ActivityIndicator
                  color={colors.activityIndicator}
                  style={tw("ml-2")}
                />
              ) : (
                <>
                  <Text>(</Text>
                  <TouchableOpacity
                    onPress={() => {
                      downloadSampleFile(dmFileKeys.customerRecipients);
                    }}
                  >
                    <Text style={[fonts.link1]}>Sample File</Text>
                  </TouchableOpacity>
                  <Text>)</Text>
                </>
              )}
            </View>

            <Button
              testID={"choose_mhq_file"}
              accessibilityLabel="choose_mhq_file"
              titleStyle={{
                ...theme.Button.secondaryTitleStyle,
                ...fonts.heading5,
              }}
              buttonStyle={{
                ...theme.Button.secondaryButtonStyle,
                paddingVertical: 2,
              }}
              containerStyle={{ marginLeft: 0 }}
              title="Choose File"
              onPress={() => {
                chooseFile(dmFileKeys.customerRecipients);
              }}
            />

            <Text numberOfLines={2}>
              {dmFile[dmFileKeys.customerRecipients]?.name || "No file chosen"}
            </Text>
          </View>

          <View style={tw("flex flex-row items-center mt-1")}>
            <View style={[tw("flex flex-row"), { width: "45%" }]}>
              <Text style={[fonts.heading5, tw("mr-1")]}>Orders</Text>

              {showDMSampleFileLoading === dmFileKeys.orders ? (
                <ActivityIndicator
                  color={colors.activityIndicator}
                  style={tw("ml-2")}
                />
              ) : (
                <>
                  <Text>(</Text>
                  <TouchableOpacity
                    onPress={() => {
                      downloadSampleFile(dmFileKeys.orders);
                    }}
                  >
                    <Text style={[fonts.link1]}>Sample File</Text>
                  </TouchableOpacity>
                  <Text>)</Text>
                </>
              )}
            </View>

            <Button
              testID={"choose_mhq_file"}
              accessibilityLabel="choose_mhq_file"
              titleStyle={{
                ...theme.Button.secondaryTitleStyle,
                ...fonts.heading5,
              }}
              buttonStyle={{
                ...theme.Button.secondaryButtonStyle,
                paddingVertical: 2,
              }}
              containerStyle={{ marginLeft: 0 }}
              title="Choose File"
              onPress={() => {
                chooseFile(dmFileKeys.orders);
              }}
            />

            <Text numberOfLines={2}>
              {dmFile[dmFileKeys.orders]?.name || "No file chosen"}
            </Text>
          </View>
          <View style={tw("flex flex-row items-center mt-1")}>
            <View style={[tw("flex flex-row"), { width: "45%" }]}>
              <Text style={[fonts.heading5, tw("mr-1")]}>
                Products and Images
              </Text>

              {showDMSampleFileLoading === dmFileKeys.orders ? (
                <ActivityIndicator
                  color={colors.activityIndicator}
                  style={tw("ml-2")}
                />
              ) : (
                <>
                  <Text>(</Text>
                  <TouchableOpacity
                    onPress={() => {
                      downloadSampleFile(dmFileKeys.products);
                    }}
                  >
                    <Text style={[fonts.link1]}>Sample File</Text>
                  </TouchableOpacity>
                  <Text>)</Text>
                </>
              )}
            </View>

            <Button
              testID={"choose_mhq_file"}
              accessibilityLabel="choose_mhq_file"
              titleStyle={{
                ...theme.Button.secondaryTitleStyle,
                ...fonts.heading5,
              }}
              buttonStyle={{
                ...theme.Button.secondaryButtonStyle,
                paddingVertical: 2,
              }}
              containerStyle={{ marginLeft: 0 }}
              title="Choose File"
              onPress={() => {
                chooseFile(dmFileKeys.products);
              }}
            />

            <Text numberOfLines={2}>
              {dmFile[dmFileKeys.products]?.name || "No file chosen"}
            </Text>
          </View>

          <View style={tw("flex flex-row items-center my-2")}>
            <Button
              containerStyle={{
                margin: 2,
              }}
              onPress={() => {
                handleSubmit();
              }}
              title={"Submit"}
              disabled={
                dmFile[dmFileKeys.products]?.name ||
                (dmFile[dmFileKeys.customers]?.name &&
                  dmFile[dmFileKeys.customerContacts]?.name &&
                  dmFile[dmFileKeys.customerRecipients]?.name &&
                  dmFile[dmFileKeys.orders]?.name)
                  ? false
                  : true
              }
            />

            <Text style={[theme.SubmitButton.errorStyle, tw("ml-2 mt-2")]}>
              {dmError}
            </Text>

            {showDMLoading === "load" && (
              <ActivityIndicator color={colors.activityIndicator} />
            )}
          </View>

          <View style={{ marginTop: 15 }}>
            <GenerateUIForResults
              results={dmResults}
              apiMemberCode={apiMemberCode}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default DataMigration;
