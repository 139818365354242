import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Platform, ScrollView, View } from "react-native";
import { Text } from "react-native-elements";

import get from "lodash/get";

import { FormField, FormFieldCheckBox, Form } from "components/elements/forms";
import { Picker, ToasterHandler, CustomCheckbox } from "components/elements";
import { SaveCancelButtons, Currency } from "components/wrappers";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { setSideCar } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import { selectShopCode } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import {
  fetchCityZipcodesFees,
  fetchCities,
  saveCityZipcodesFees,
  clearCityZipcodesFees,
  saveCitiesFees,
  setSearchZipcode,
} from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/slice";
import {
  selectFeesCoverage,
  selectSearchZipcodeText,
} from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/selector";
import {
  isValidFeeValue,
  roundTheFees,
} from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/sections/fee-coverage";

import states from "static/data/states.json";

import { FormFieldProps, NewCoverageAreaConfig } from "../ui-config";
import { getNewCoverageAreaValidationSchema } from "../yup";
import SearchFilter from "./searchFilter";
import FeeCoverageError from "../errors";
import FeesCoverageModal from "../input/alert";
import { getCitiesByZipSearch, isMatchingZip } from "../input/helper";

import { fonts, backgroundColors, colors } from "styles/theme";
import tw from "tailwind-rn";

const NewCoverageArea = () => {
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);
  const { messages, Localise } = React.useContext(I18NContext);
  const { isMobile } = React.useContext(DeviceContext);
  const currency = Currency(shopCode);
  const {
    cityZipcodesFees: { content: newCoverageArea = [] },
    cities: { content: cities = [] },
    citiesFees: { content: existingCityData = [] },
  } = useSelector(selectFeesCoverage);
  const searchZip = useSelector(selectSearchZipcodeText);

  const setAddressStates = (countryId, state, city) => ({
    countryId,
    state,
    city,
  });

  const [showModal, setShowModal] = useState({});
  const [address, setAddress] = useState(setAddressStates("US", "", ""));
  const [addressErrors, setAddressError] = useState(
    setAddressStates("", "", "")
  );

  //Show the status as active by default.
  const initialValues = {
    cityZipcodesCoverage: newCoverageArea?.map((x) => {
      return { ...x, status: "Y" };
    }),
  };

  useEffect(() => {
    dispatch(clearCityZipcodesFees());
  }, []);

  useEffect(() => {
    address.state &&
      dispatch(
        fetchCities({
          params: { countryId: address.countryId, state: address.state },
        })
      );
  }, [address.state]);

  useEffect(() => {
    const isCityFeeConfigured = existingCityData.some(
      ({ city }) => city === address.city
    );

    address.city &&
      !isCityFeeConfigured &&
      dispatch(
        fetchCityZipcodesFees({
          params: {
            city: address.city,
            countryId: address.countryId,
            state: address.state,
          },
        })
      );
  }, [address.city]);

  const handleCityDataSubmit = (cityZipcodesCoverage, formikBag) => {
    const cityData = { ...cityZipcodesCoverage[0] };
    delete cityData.cityFeeAddedManually;
    dispatch(
      saveCitiesFees({
        params: {
          values: [cityData],
          initialValues: [],
          skipGetCityFeeCall: true,
        },
        resolve: () => {
          dispatch(setSideCar());
          dispatch(clearCityZipcodesFees());
          handleZipCodesFeeSubmit(
            [cityData, ...cityZipcodesCoverage.slice(1)],
            formikBag,
            []
          );
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  const onSubmit = ({ cityZipcodesCoverage }, formikBag) => {
    if (!cityZipcodesCoverage.length) return;

    const cityFeeAddedManually = get(
      cityZipcodesCoverage,
      "0.cityFeeAddedManually",
      false
    );

    if (cityFeeAddedManually) {
      return handleCityDataSubmit(cityZipcodesCoverage, formikBag);
    }
    handleZipCodesFeeSubmit(
      cityZipcodesCoverage,
      formikBag,
      initialValues.cityZipcodesCoverage
    );
  };

  const handleZipCodesFeeSubmit = (values, formikBag) => {
    dispatch(
      saveCityZipcodesFees({
        params: {
          values,
          initialValues,
        },

        resolve: () => {
          ToasterHandler(
            "success",
            Localise(
              messages,
              "Your Fee and Coverage Settings have been updated"
            )
          );
          dispatch(setSideCar());
          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          dispatch(setSideCar());
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  const onLocationChange = (name, value, zipCodesFeeCoverage) => {
    if (name === "countryId") {
      setAddress(setAddressStates(value, "", ""));
      setAddressError(
        setAddressStates(
          value === "" ? "Select Country" : "",
          "Select State",
          "Select City"
        )
      );
      zipCodesFeeCoverage.length && dispatch(clearCityZipcodesFees());
    }
    if (name === "state") {
      setAddress(setAddressStates(address.countryId, value, ""));
      setAddressError(
        setAddressStates(
          address.countryId ? "" : "Select Country",
          value ? "" : "Select State",
          "Select City"
        )
      );
      zipCodesFeeCoverage.length && dispatch(clearCityZipcodesFees());
    }
    if (name === "city") {
      setAddress(setAddressStates(address.countryId, address.state, value));
      setAddressError(
        setAddressStates(
          address.countryId ? "" : "Select Country",
          address.state ? "" : "Select State",
          value ? "" : "Select City"
        )
      );
    }
  };

  const onLocationDataLoad = (name, countryId) => {
    if (name === "countryId")
      return [
        { label: "Canada", value: "CA" },
        { label: "United States", value: "US" },
      ];
    else if (name === "state") return get(states, countryId, []);
    else if (name === "city")
      return cities.filter(
        ({ value: cityName }) =>
          !existingCityData.some(({ city }) => city === cityName)
      );
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      validationSchema={getNewCoverageAreaValidationSchema(messages, Localise)}
      render={({
        setFieldValue,
        values = {},
        errors: { cityZipcodesCoverage = [] } = {},
      }) => {
        const showInputFields = !!address.city;
        const [{ fee: cityFee = "" } = {}, ...cityZipcodes] =
          values.cityZipcodesCoverage;
        const errorsArrayIndexes = Object.keys(cityZipcodesCoverage);
        const errorsArray = errorsArrayIndexes.map((errorIndex) =>
          get(
            values,
            `${
              errorIndex === "0"
                ? `cityZipcodesCoverage.${errorIndex}.city`
                : `cityZipcodesCoverage.${errorIndex}.zipCode`
            }`
          )
        );
        const noZipCodesFlag = values.cityZipcodesCoverage.length === 0;
        const isAllZipsSelected = cityZipcodes.every((x) => x.status === "Y");

        const matchedCitiesAndZipCodes = getCitiesByZipSearch(
          cityZipcodes,
          searchZip
        );

        return (
          <>
            {NewCoverageAreaConfig.map(
              ({ formFieldType, name, title, label }, i) => {
                if (formFieldType === "Picker") {
                  return (
                    <View
                      key={i}
                      style={[tw("flex-row items-center"), { marginBottom: 5 }]}
                    >
                      <View style={{ width: 100 }}>
                        <Text style={fonts.heading4}>
                          {Localise(messages, title)}
                        </Text>
                      </View>
                      <View style={{ width: 180 }}>
                        <Picker
                          containerStyle={{
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                          innerContainerStyle={{
                            width: isMobile ? 120 : 150,
                          }}
                          items={onLocationDataLoad(name, address.countryId)}
                          placeholder={{
                            label: Localise(messages, "Please Select"),
                            value: "",
                          }}
                          value={address[name]}
                          onValueChange={(val) => {
                            onLocationChange(
                              name,
                              val,
                              values.cityZipcodesCoverage
                            );
                          }}
                        ></Picker>
                        {!!addressErrors[name] && (
                          <View>
                            <Text
                              style={[
                                fonts.error,
                                {
                                  paddingVertical: 5,
                                  textAlign: "left",
                                  marginLeft: isMobile ? 30 : 15,
                                },
                              ]}
                            >
                              {Localise(messages, addressErrors[name])}
                            </Text>
                          </View>
                        )}
                      </View>
                    </View>
                  );
                } else if (formFieldType === "Text") {
                  return (
                    <>
                      {showModal.title && (
                        <FeesCoverageModal
                          title={Localise(messages, showModal.title)}
                          primaryAction={showModal.primaryAction}
                          secondaryAction={showModal.secondaryAction}
                        />
                      )}
                      {showInputFields && (
                        <View key={i} style={tw("flex-row items-center pb-3")}>
                          <View style={{ width: 100 }}>
                            <Text style={fonts.heading4}>
                              {Localise(messages, title)}
                            </Text>
                          </View>
                          <View
                            style={{
                              marginLeft: isMobile ? 25 : 10,
                            }}
                          >
                            <FormField
                              name="cityZipcodesCoverage.0.fee"
                              {...FormFieldProps}
                              handleOnBlur={(val) => {
                                const fee = roundTheFees(val);
                                isValidFeeValue(fee) &&
                                  fee !== cityFee &&
                                  setShowModal({
                                    title: `${address.city} ${Localise(
                                      messages,
                                      "Zip Codes"
                                    )}`,
                                    primaryAction: () => {
                                      setFieldValue(
                                        "cityZipcodesCoverage.0.fee",
                                        fee
                                      );
                                      cityZipcodes.map((data, i) => {
                                        setFieldValue(
                                          `cityZipcodesCoverage.${i + 1}.fee`,
                                          fee
                                        );
                                      });
                                      setShowModal({});
                                    },
                                    secondaryAction: () => {
                                      setFieldValue(
                                        "cityZipcodesCoverage.0.fee",
                                        cityFee
                                      );
                                      setShowModal({});
                                    },
                                  });
                              }}
                              editable={!noZipCodesFlag}
                              inputContainerStyle={{
                                ...FormFieldProps.inputContainerStyle,
                                backgroundColor: noZipCodesFlag
                                  ? backgroundColors.greyColor
                                  : backgroundColors.secondary,
                              }}
                              iconType={"material-community"}
                              iconName={"currency-usd"}
                              iconSize={14}
                              currency={currency}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  );
                } else {
                  return (
                    <View key={i}>
                      <SearchFilter
                        searchSelector={selectSearchZipcodeText}
                        searchAction={setSearchZipcode}
                        placeholder={"Search Zip Codes"}
                        containerStyle={{
                          paddingLeft: 0,
                          paddingRight: 15,
                        }}
                        searchOn="Zip Code(s)"
                        resultsCount={matchedCitiesAndZipCodes.length}
                      />
                      {!searchZip && cityZipcodes.length > 1 ? (
                        <CustomCheckbox
                          title={"Select All"}
                          checked={isAllZipsSelected}
                          inputContainerStyle={{
                            marginBottom: 10,
                            paddingRight: 10,
                            flexDirection: "row-reverse",
                          }}
                          onPress={() => {
                            const updatedCityZipcodes =
                              values?.cityZipcodesCoverage?.map((x) => {
                                return {
                                  ...x,
                                  status: isAllZipsSelected ? "N" : "Y",
                                };
                              });
                            setFieldValue(
                              `cityZipcodesCoverage`,
                              updatedCityZipcodes
                            );
                          }}
                        />
                      ) : null}
                      <View style={[{ flexGrow: 1 }]}>
                        <ScrollView
                          contentContainerStyle={tw(
                            "flex flex-row flex-wrap items-start"
                          )}
                          {...(Platform.OS === "android" && {
                            nestedScrollEnabled: true,
                          })}
                        >
                          <View style={tw("w-full flex-row flex-wrap pt-3")}>
                            {showInputFields &&
                              cityZipcodes?.map(
                                ({ zipCode, status = "", fee }, i) => {
                                  const index = i + 1;
                                  const isFieldChecked = status === "Y";

                                  if (!isMatchingZip(searchZip, zipCode))
                                    return null;

                                  return (
                                    <View
                                      key={index}
                                      style={[
                                        tw(
                                          `flex-row items-center mb-3 border py-1 ${
                                            isMobile ? "mr-1" : "mr-3"
                                          }`
                                        ),
                                        {
                                          backgroundColor:
                                            backgroundColors.navBar,
                                          borderColor: colors.light,
                                        },
                                      ]}
                                    >
                                      <View
                                        style={{
                                          maxHeight: 40,
                                          overflow: "hidden",
                                        }}
                                      >
                                        <FormFieldCheckBox
                                          iconRight={false}
                                          name={`cityZipcodesCoverage.${index}.status`}
                                          size={20}
                                          title={""}
                                          isChecked={isFieldChecked}
                                          onChangehandler={() => {
                                            !isFieldChecked &&
                                              !isValidFeeValue(fee) &&
                                              setFieldValue(
                                                `cityZipcodesCoverage.${index}.fee`,
                                                cityFee
                                              );
                                            setTimeout(() => {
                                              setFieldValue(
                                                `cityZipcodesCoverage.${index}.status`,
                                                isFieldChecked ? "N" : "Y"
                                              );
                                            }, 0);
                                          }}
                                          inputContainerStyle={{
                                            marginRight: 0,
                                          }}
                                        />
                                      </View>
                                      <Text
                                        style={{
                                          backgroundColor:
                                            backgroundColors.navBar,
                                          ...fonts.heading4,
                                        }}
                                      >
                                        {zipCode}
                                      </Text>
                                      <FormField
                                        name={`cityZipcodesCoverage.${index}.fee`}
                                        {...FormFieldProps}
                                        editable={isFieldChecked}
                                        inputContainerStyle={{
                                          ...FormFieldProps.inputContainerStyle,
                                          backgroundColor: isFieldChecked
                                            ? backgroundColors.secondary
                                            : backgroundColors.greyColor,
                                        }}
                                        iconType={"material-community"}
                                        iconName={"currency-usd"}
                                        iconSize={14}
                                        currency={currency}
                                      />
                                    </View>
                                  );
                                }
                              )}
                          </View>
                        </ScrollView>
                      </View>
                    </View>
                  );
                }
              }
            )}
            <FeeCoverageError
              title={Localise(
                messages,
                "Please enter valid fees for fields below"
              )}
              errorsArray={errorsArray}
            />
            <SaveCancelButtons
              disableOnDirty={noZipCodesFlag}
              buttonTitle={Localise(messages, "Save")}
              customContainerStyles={{
                width: 100,
                marginLeft: 0,
              }}
            />
          </>
        );
      }}
    />
  );
};

export default NewCoverageArea;
