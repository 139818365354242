import { getDateWithSuffix } from "library/utils/formatter";

const REPORTS = Object.freeze({
  SCORECARD_UPDATE: (scorecardUpdateDate) =>
    `Scorecard updates the ${getDateWithSuffix(
      scorecardUpdateDate
    )} of every month`,
});

export default REPORTS;
