import React, { useEffect, useContext } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { createStackNavigator } from "@react-navigation/stack";

import SplitScreen from "components/containers/split-screen-new";
import ScreenHeader from "components/containers/split-screen-new/header";
import { TabBar } from "components/elements";

import ManageGroups from "./manage-groups";
import BulkAccounts from "./bulk-accounts";
import InvalidateSessions from "./invalidate-sessions";
import SharedCatalogs from "./shared-catalogs";
import ShopPreferences from "./manage-groups/shop-preferences";
import DataMigrationJobDetails from "./manage-groups/data-migration/job-details";
import SharedCatalogDetails from "./shared-catalogs/details";
import Notifications from "./notifications";
import NotificationHistory from "./notifications/notification-history";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";

import {
  setInit,
  setReset,
  setSideCar,
  setActiveTab,
} from "library/sagas/views/home/drawer/ftd-admin/member-settings/common/slice";
import {
  selectSideCar,
  selectActiveTab,
  selectTabNames,
} from "library/sagas/views/home/drawer/ftd-admin/member-settings/common/selector";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";

import tw from "tailwind-rn";

const Stack = createStackNavigator();

const FTDMemberSettings = ({ navigation }) => {
  const dispatch = useDispatch();
  const showSideCar = useSelector(selectSideCar);
  const tabNames = useSelector(selectTabNames);
  const { isDesktop } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const { userRole } = useContext(UserProfileContext);

  useEffect(() => {
    dispatch(setCurrentPage("ftd-member-settings"));
    dispatch(setInit({ userRole }));

    return () => {
      dispatch(setReset());
    };
  }, []);

  return (
    <Stack.Navigator headerMode="none">
      {tabNames.map(({ key, title }) => {
        return (
          <Stack.Screen key={key} name={key} options={{ title }}>
            {() => (
              <SplitScreen
                ScreenOne={<MainScreen defaultTab={key} />}
                ScreenTwo={<SideScreen />}
                showSecondScreen={!!showSideCar}
                headerProps={{
                  title: Localise(messages, "Mercury Member Settings"),
                  style: { flexDirection: isDesktop ? "row" : "column" },
                }}
                rootNavigation={navigation}
                navHeaderProps={{
                  title,
                  onTrigger: () => dispatch(setSideCar("")),
                }}
                CustomHeader={() => <CustomHeader defaultTab={key} />}
              />
            )}
          </Stack.Screen>
        );
      })}
    </Stack.Navigator>
  );
};

const CustomHeader = ({ defaultTab }) => {
  const dispatch = useDispatch();
  const tabNames = useSelector(selectTabNames);
  const currentTab = useSelector(selectActiveTab);
  const { isDesktop } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);

  const setCurrentTab = (name) => {
    dispatch(setActiveTab(name));
  };

  if (currentTab && defaultTab != currentTab) return null;

  return (
    <>
      <ScreenHeader
        title={Localise(messages, "Mercury Member Settings")}
        style={{ flexDirection: isDesktop ? "row" : "column" }}
      />
      <TabBar
        containerStyle={tw("w-full")}
        tabNames={tabNames}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        style={{
          marginTop: -10,
        }}
      />
    </>
  );
};

const MainScreen = ({ defaultTab }) => {
  const dispatch = useDispatch();
  const currentTab = useSelector(selectActiveTab);
  const { isDesktop, isTablet } = React.useContext(DeviceContext);

  const setCurrentTab = (name) => {
    dispatch(setActiveTab(name));
  };

  useEffect(() => {
    if (!currentTab) setCurrentTab(defaultTab);
  }, []);

  if (currentTab && defaultTab != currentTab) return null;

  return (
    <View
      fsClass="fs-unmask"
      style={{
        maxWidth: isDesktop ? 996 : isTablet ? 676 : 334,
        minHeight: 500,
      }}
    >
      {currentTab === "manage-groups" && <ManageGroups />}
      {currentTab === "bulk-accounts" && <BulkAccounts />}
      {currentTab === "invalidate-sessions" && <InvalidateSessions />}
      {currentTab === "shared-catalogs" && <SharedCatalogs />}
      {currentTab === "notifications" && <Notifications />}
    </View>
  );
};

const SideScreen = () => {
  const sideCar = useSelector(selectSideCar);

  if (!sideCar) return null;

  return (
    <>
      {sideCar === "shop-preferences" && <ShopPreferences />}
      {sideCar === "data-migration-details" && <DataMigrationJobDetails />}
      {sideCar === "shared-catalog-details" && <SharedCatalogDetails />}
      {sideCar === "notification-details" && <NotificationHistory />}
    </>
  );
};

export default FTDMemberSettings;
