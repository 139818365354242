import React from "react";
import SplitScreen from "components/containers/split-screen-new";
import NotificationCenter from "./notification-center";

const Notifications = () => {
  return (
    <SplitScreen
      ScreenOne={<NotificationCenter />}
      headerProps={{
        title: "Notification Center",
      }}
    />
  );
};

export default Notifications;
