import React from "react";
import { ScrollView, View } from "react-native";
import { Divider, Image, Text } from "react-native-elements";

import tw from "tailwind-rn";

import { colors, fonts, shapes } from "styles/theme";
import IMAGES from "static/assets/images";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import isEmpty from "lodash/isEmpty";
import { TouchableOpacity } from "react-native";
import {
  getOrderCurrentStatus,
  prepareOrderStatusesData,
  statusesBGColors,
} from "../helper";
import Environment from "library/utils/environment";

const OrderStatus = ({ trackingDetails, setModalName }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);

  const {
    order: orderObj = {},
    events = [],
    designedImageUrls = [],
  } = trackingDetails;
  const designedImageData = designedImageUrls[0];

  const orderItemDetails = orderObj?.orderItems?.[0] || {};

  const orderCurrentStatus = getOrderCurrentStatus(orderItemDetails);

  const orderStatusesData = prepareOrderStatusesData(
    orderObj?.orderType,
    orderItemDetails,
    events
  );
  const showUploadDesignedImage = Environment.get(
    "SHOW_UPLOAD_DESIGNED_IMAGE",
    false
  );

  return (
    <View
      style={[
        tw("flex flex-col"),
        {
          ...shapes.sectionBorder,
          width: isDesktop ? 328 : "100%",
          height: 540,
          padding: 0,
          marginHorizontal: isDesktop ? 11 : 0,
          marginVertical: 8,
        },
      ]}
    >
      <View
        style={{
          padding: 15,
          backgroundColor: statusesBGColors[orderCurrentStatus],
        }}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: colors.highlighter,
            marginLeft: 15,
          }}
        >
          {Localise(messages, "Status")}: {orderCurrentStatus}
        </Text>
      </View>

      <Divider style={{ marginVertical: 0 }} />

      <ScrollView
        style={{ padding: 15, marginLeft: 15 }}
        nativeID="order_status_scrollbar"
      >
        {orderStatusesData.map((statusData, index) => {
          const {
            status,
            active = false,
            date = "",
            comments = "",
          } = statusData;

          const getHeight = (index) =>
            index < 3
              ? index === 1 && !isEmpty(designedImageData)
                ? 170
                : 65
              : 90;

          const getDesignedImageHeight = (index) =>
            index < 3
              ? index === 1 && !isEmpty(designedImageData)
                ? 155
                : 55
              : 70;

          return (
            <View
              key={`order_status_${index}`}
              style={[
                tw("flex flex-row"),
                {
                  height: getHeight(index, designedImageData),
                },
              ]}
            >
              <View>
                <Image
                  style={{
                    width: 23,
                    height: 23,
                  }}
                  resizeMode="cover"
                  source={
                    IMAGES[
                      active
                        ? "check-mark-filled-in-green"
                        : "grey-filled-in-circle"
                    ]
                  }
                />

                {index < orderStatusesData.length - 1 && (
                  <View
                    style={{
                      height: getDesignedImageHeight(index, designedImageData),
                      borderLeftWidth: 1,
                      borderStyle: "solid",
                      borderColor: colors.grayScaleLight,
                      marginLeft: 11,
                      position: "absolute",
                      top: 22,
                    }}
                  />
                )}
              </View>

              <View style={[{ marginLeft: 9, marginTop: 5, width: "90%" }]}>
                <Text
                  style={{
                    ...fonts.default,
                    fontSize: 15,
                    fontWeight: "bold",
                    color: active ? colors.primary : colors.disabled,
                  }}
                >
                  {status}
                </Text>

                {active && (
                  <Text
                    style={{ ...fonts.default, fontSize: 14, marginTop: 5 }}
                  >
                    {date}
                  </Text>
                )}

                {index === 1 &&
                !isEmpty(designedImageData) &&
                showUploadDesignedImage ? (
                  <View style={{ marginBottom: 20 }}>
                    <View style={{ width: 104 }}>
                      <Image
                        style={{
                          width: 104,
                          height: 104,
                          marginTop: 10,
                        }}
                        source={{
                          uri: designedImageData,
                        }}
                      />

                      <TouchableOpacity
                        onPress={() => setModalName("designed_photo")}
                        testID="zoom_designed_photo"
                        accessibilityLabel="zoom_designed_photo"
                        style={{
                          position: "absolute",
                          top: 81,
                          right: 3,
                        }}
                      >
                        <Image
                          style={{
                            width: 30,
                            height: 30,
                          }}
                          resizeMode="cover"
                          source={IMAGES["zoom-in"]}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                ) : null}

                {index >= 3 && active && comments.length > 0 && (
                  <Text
                    style={{
                      ...fonts.default,
                      fontSize: 14,
                      marginTop: 5,
                    }}
                    flexWrap="wrap"
                    numberOfLines={2}
                  >
                    {comments}
                  </Text>
                )}
              </View>
            </View>
          );
        })}
      </ScrollView>
    </View>
  );
};

export default OrderStatus;
