import React from "react";
import { Text } from "react-native-elements";
import { View, Platform } from "react-native";
import { CustomModal } from "components/elements";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { colors } from "styles/theme";

const CancelDSModal = ({
  isOrderReject,
  isOrderModify,
  isOrderCancel,
  isCancelDSForOrderTransfer = false,
  isOrderCancelApproval,
  isLocalDSOrderCancel,
  isCancelDSModal,
  setShowCancelDSModal,
  setShowReasonForm,
  setSelectedAction,
  triggerAction,
  cancelApprovalInfo = {},
  setCancelPayload,
  customMessage = "",
  secondaryhandler = () => {},
}) => {
  const { messages, Localise } = React.useContext(I18NContext);

  const modalContent = {
    content: (
      <View style={{ textAlign: "center" }}>
        <Text
          style={{
            ...tw("px-4 pt-4"),
            fontSize: 15,
            color: colors.highlighter,
          }}
        >
          {Localise(
            messages,
            "This order has been scheduled with Delivery Service."
          )}
        </Text>
        <Text
          style={{
            ...tw("px-4 pb-4"),
            fontSize: 15,
            color: colors.highlighter,
          }}
        >
          {Localise(
            messages,
            customMessage?.length
              ? customMessage
              : isOrderReject
              ? "First cancel the Delivery Service Request, then Reject this order."
              : isOrderCancel
              ? "First cancel the Delivery Service Request, then Cancel this order."
              : isOrderCancelApproval || isLocalDSOrderCancel
              ? "Click Continue to approve Mercury HQ and Delivery Service cancelation."
              : isOrderModify
              ? "First cancel the Delivery Service Request, then Modify this order."
              : isCancelDSForOrderTransfer
              ? "First cancel the Delivery Service Request, then Transfer this order."
              : "Click Continue to approve Mercury HQ cancellation."
          )}
        </Text>
      </View>
    ),
    buttons: isOrderModify
      ? [{ type: "secondary", title: Localise(messages, "Close") }]
      : isCancelDSForOrderTransfer
      ? [
          { type: "secondary", title: Localise(messages, "Nevermind") },
          {
            type: "primary",
            title: Localise(messages, "Cancel Delivery Request"),
          },
        ]
      : [
          { type: "secondary", title: Localise(messages, "Nevermind") },
          { type: "primary", title: Localise(messages, "Continue") },
        ],
  };

  return (
    <CustomModal
      modalVisible={isCancelDSModal}
      modalContent={modalContent}
      primaryhandler={() => {
        setShowCancelDSModal(false);

        if (isLocalDSOrderCancel) {
          triggerAction();
        } else if (isOrderReject || isOrderCancel) {
          setShowReasonForm(true);
        } else {
          triggerAction({
            action: "cancel-approval",
            askId: cancelApprovalInfo.askId,
          });
        }
      }}
      secondaryhandler={() => {
        setShowCancelDSModal(false);
        if (isLocalDSOrderCancel) {
          setCancelPayload({});
        }
        if (
          (isOrderReject || isOrderCancel || isLocalDSOrderCancel) &&
          !isOrderModify
        ) {
          setShowReasonForm(false);
          setSelectedAction("");
        }
        secondaryhandler();
      }}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: "white" },
      ]}
      modalStyle={
        Platform.OS !== "web"
          ? {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: "#FFFFFF",
            }
          : {
              width: "35%",
            }
      }
    ></CustomModal>
  );
};

export default CancelDSModal;
