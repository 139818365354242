import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";
import { isCustomDateRangeToggle } from "library/utils/formatter";

export const initialState = {
  actions: {
    appliedFilters: [],
    filters: [],
    selectedShops: [],
    shops: [],
    search: {},
    sort: {},
  },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setActions: (state, action) => {
      const currentFilters = action.payload.appliedFilters;
      const prevFilters = state.actions.appliedFilters;

      const changeInDateRange = currentFilters
        ? isCustomDateRangeToggle(prevFilters, currentFilters)
        : false;

      if (changeInDateRange) {
        state.timeStamp = null;
        state.data = [];
        state.originalData = [];
      }

      state.actions = { ...state.actions, ...action.payload };
    },
    resetBasicInfo: (state) => {
      // Preserve "Individual Filters" if it exists in the current state
      const preservedIndividualFilter = state?.actions?.appliedFilters?.find(
        (filter) =>
          filter?.["Individual Filters"]?.value === "hideFlaggedOrders"
      );

      Object.assign(state, {
        ...state,
        actions: {
          appliedFilters: preservedIndividualFilter
            ? [preservedIndividualFilter]
            : [],
          filters: preservedIndividualFilter ? [preservedIndividualFilter] : [],
          selectedShops: [],
          shops: [],
          search: {},
          sort: {},
        },
      });
    },
  },
});

export const { setActions, resetBasicInfo } = slice.actions;

export default { [sliceName]: slice.reducer };
