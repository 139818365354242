/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { View } from "react-native";
import { Button } from "react-native-elements";
import tw from "tailwind-rn";
import { theme, backgroundColors } from "styles/theme";
import Modal from "modal-enhanced-react-native-web";

export const CustomModal = ({
  modalVisible,
  modalContent,
  primaryhandler,
  secondaryhandler,
  contentStyle = {},
  modalStyle = {},
  customPrimaryButtonStyle = {},
  customSecondaryButtonStyle = {},
  isReduceBtnSpacing = false,
  modalRef = React.useRef(),
  testID = "modal",
}) => {
  const { content, buttons = [] } = modalContent;
  if (!modalVisible) return null;

  return (
    <View ref={modalRef}>
      <Modal isVisible={modalVisible} testID={testID}>
        <View
          style={[
            tw("flex flex-col justify-center items-center"),
            styles.modalContent,
            modalStyle,
          ]}
        >
          <View style={contentStyle}>
            {!!content && content}
            {!!buttons.length && (
              <View
                style={
                  isReduceBtnSpacing
                    ? tw("flex flex-row justify-center mt-4")
                    : tw("flex flex-row justify-around mt-4")
                }
              >
                {buttons.map(({ type, title, testID, loader = false }) => {
                  const id = testID || title.replace(" ", "_");
                  return (
                    <View key={title}>
                      {type === "primary" && (
                        <Button
                          title={title}
                          buttonStyle={customPrimaryButtonStyle}
                          onPress={primaryhandler}
                          testID={id}
                          accessibilityLabel={id}
                          loading={loader}
                        />
                      )}
                      {type === "secondary" && (
                        <Button
                          titleStyle={theme.Button.secondaryTitleStyle}
                          buttonStyle={{
                            ...theme.Button.secondaryButtonStyle,
                            ...customSecondaryButtonStyle,
                          }}
                          title={title}
                          onPress={secondaryhandler}
                          testID={id}
                          accessibilityLabel={id}
                        />
                      )}
                    </View>
                  );
                })}
              </View>
            )}
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = {
  modalContent: {
    margin: "auto",
    backgroundColor: backgroundColors.secondary,
    padding: 22,
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
};
