/* eslint-disable react/display-name */
import React, { useContext, useEffect, useRef } from "react";
import { View, Platform, TouchableOpacity } from "react-native";
import { Text, Badge, Image } from "react-native-elements";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
} from "@react-navigation/drawer";
import { CustomModal, PrintIframeRenderer } from "components/elements";
import {
  CommonActions,
  DrawerActions,
  useLinkBuilder,
  useNavigation,
} from "@react-navigation/native";
import { connect, useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import useStateIfMounted from "library/utils/useStateIfMounted";

import UserAuthContext from "library/contexts/userAuth";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import I18NContext from "library/contexts/i18N";

import UserAuthStorage from "library/storage/userAuth";
import UserProfileStorage from "library/storage/userProfile";
import AppSettingsStorage from "library/storage/appSettings";

import { fonts, colors, backgroundColors } from "styles/theme";
import { IMAGES, DIMENSIONS } from "static/assets/images";
import {
  setCurrentPage,
  setIsRouteEditing,
  setIsAdhocStopEditing,
  setIsRouteSave,
  setIsRouteSaveSuccess,
  setIsBulkDSRequestUploadInProgress,
  setIsRouteOptimised,
  setOptimiseRoute,
  setIsRouteOptimiseDone,
} from "library/sagas/ongoing/global-data/slice";
import { request } from "library/utils/request";
import { selectDashboardShopCode } from "library/sagas/ongoing/global-data/selector";
import { setPaymentDetails } from "library/sagas/views/home/drawer/create-order/slice";
import { selectPaymentDetails } from "library/sagas/views/home/drawer/create-order/selector";
import { fetchData } from "library/sagas/views/home/drawer/delivery-tasks/slice";
import { setSelectScreenKey } from "library/sagas/views/home/drawer/delivery/slice";
import {
  InitDSRequestsData,
  ListOfApproachingDriversStates,
  ListOfDeliveryTaskStates,
  ListOfDeliveryServiceSources,
  AutoPrintDSFetchInterval,
} from "components/views/drawer/delivery/delivery-service-dashboard/config.js";
import moment from "moment";

import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import every from "lodash/every";
import startsWith from "lodash/startsWith";
import some from "lodash/some";
import toLower from "lodash/toLower";
import isEmpty from "lodash/isEmpty";
import tw from "tailwind-rn";

import Environment from "library/utils/environment";
import { Entitlements, memberEntitlements } from "library/utils/entitlements";
import EnvironmentStorage from "library/storage/environment";
import { setActions as setDSListingActions } from "library/sagas/views/home/drawer/delivery-tasks/slice";
import { fetchNotifications } from "library/sagas/views/home/drawer/notifications/slice";
import {
  printPickupManifest,
  defaultDeliveryProvider,
} from "library/utils/deliveryService";
import { voidPayments } from "library/utils/createOrder";
import { ToasterHandler } from "components/elements";
import Logger from "library/utils/logger";
import { DELIVERY } from "library/constants";

import DrawerItem from "./item";
import AutoPrint from "./auto-print";
import LoadNewBundle from "./load-new-bundle";

//service requests
const getPickupManifestPrintStatus = (params) =>
  request("get-pickup-manifest-print-status", params);

let fcmService;

if (Platform.OS !== "web") {
  fcmService = require("library/utils/PushNotifications/FCMService").fcmService;
}
const Drawer = createDrawerNavigator();

const DrawerLabel = ({ title, badge, focused }) => (
  <View
    style={[
      tw("flex flex-row justify-start items-center"),
      { width: Platform.OS === "web" ? 140 : 170, paddingRight: 5 },
    ]}
    fsClass="fs-unmask"
  >
    <Text style={[focused ? fonts.heading5 : fonts.default, { fontSize: 14 }]}>
      {title}
    </Text>
    {badge > 0 && (
      <Badge
        containerStyle={tw("ml-1")}
        badgeStyle={{ backgroundColor: "#e5175e" }}
        value={badge}
      />
    )}
  </View>
);

const Menu = React.memo(
  ({
    initialRouteName,
    options,
    screenRef,
    currentPage,
    setCurrentPage,
    setLogoutModal,
    isRouteEditing,
    isRouteSaveSuccess,
    setIsRouteEditing,
    isAdhocStopEditing,
    setIsAdhocStopEditing,
    setIsRouteSave,
    setIsRouteSaveSuccess,
    isBulkDSRequestUploadInProgress,
    setIsBulkDSRequestUploadInProgress,
    isRouteOptimised,
    setIsRouteOptimised,
    setOptimiseRoute,
    isOptimiseDone,
    setIsRouteOptimiseDone,
    setDSListingActions,
    deliveryTasksList,
    fetchData,
    layoutMode,
  }) => {
    const { isMobile, isTablet, isDesktop } = React.useContext(DeviceContext);
    const { signOut } = useContext(UserAuthContext);
    const { isPasswordAboutToExpire } = useContext(UserProfileContext);
    const { messages, Localise } = React.useContext(I18NContext);
    const isEmbedded = layoutMode === "embedded";
    const [showMiniMenu, setMiniMenu] = useStateIfMounted(
      isMobile ? false : true
    ); //Desktop & Tablet only
    const [menuOptions, setMenuOptions] = useStateIfMounted(
      generateMenuOptions(options, isMobile)
    );
    const navigation = useNavigation();
    const ManifestAutoPrintTimer = useRef(null);
    const dispatch = useDispatch();

    const {
      AutoPrintData: shipmentsData = [],
      pickupManifestTimeStamp,
      actions = {},
    } = deliveryTasksList;
    const isQuickFiltersVisible = Environment.get(
      "SHOW_DELIVERY_QUICK_FILTERS",
      false
    );
    const isGiftCardsEnabled = Environment.get("GIFT_CARDS_ENABLED", false);
    const isElectronApp =
      Platform.OS === "web" &&
      document.getElementById("isElectronApp").value === "true";
    const macAddress =
      isElectronApp && document.getElementById("macAddress").value;
    const shopNames = UserProfileStorage.getAllShopNames();

    const pollInterval = 2 * 1000; //every 2 seconds

    const dashboardSelectedShopCode = useSelector(selectDashboardShopCode);

    const { permissions } = React.useContext(AppSettingsContext);

    let isDeliveryEntEnabled = false;
    let isRoutesEntEnabled = false;
    let isReportsEntEnabled = false;
    let isMercuryOnlineEntEnabled = false;
    let isProductCatalogEntEnabled = false;
    let isCustomerDirectoryEntEnabled = false;
    let isGiftCardsEntEnabled = false;
    let isMOLRefundsEntEnabled = false;
    let isDeliveryTasksEntEnabled = false;

    let isDeliveryServiceOnlyUser = false;

    if (dashboardSelectedShopCode === "all") {
      const allPermissions = Object.values(permissions);
      const ShopGroupEntitlements = Object.keys(permissions).map((code) =>
        UserProfileStorage.getMemberEntitlement(code).toLowerCase()
      );

      isDeliveryServiceOnlyUser =
        every(
          ShopGroupEntitlements,
          (each) => each === memberEntitlements.DELIVERY_SERVICE.toLowerCase()
        ) || false;

      isDeliveryEntEnabled =
        some(allPermissions, Entitlements.DELIVERY) || false;
      isRoutesEntEnabled = some(allPermissions, Entitlements.ROUTES) || false;
      isReportsEntEnabled = some(allPermissions, Entitlements.REPORTS) || false;
      isMercuryOnlineEntEnabled =
        some(allPermissions, Entitlements.MERCURY_ONLINE) || false;
      isProductCatalogEntEnabled =
        some(allPermissions, Entitlements.PRODUCT_CATALOG) || false;
      isCustomerDirectoryEntEnabled =
        some(allPermissions, Entitlements.CUSTOMER_DIRECTORY) || false;
      isGiftCardsEntEnabled =
        some(allPermissions, Entitlements.GIFT_CARDS) || false;
      isMOLRefundsEntEnabled =
        some(allPermissions, Entitlements.MOL_REFUNDS) || false;
      isDeliveryTasksEntEnabled =
        some(allPermissions, Entitlements.DELIVERY_TASKS) || false;
    } else {
      const selectedShopPermissions = get(
        permissions,
        dashboardSelectedShopCode,
        {}
      );

      isDeliveryEntEnabled = Entitlements.DELIVERY in selectedShopPermissions;
      isRoutesEntEnabled = Entitlements.ROUTES in selectedShopPermissions;
      isReportsEntEnabled = Entitlements.REPORTS in selectedShopPermissions;
      isMercuryOnlineEntEnabled =
        Entitlements.MERCURY_ONLINE in selectedShopPermissions;
      isProductCatalogEntEnabled =
        Entitlements.PRODUCT_CATALOG in selectedShopPermissions;
      isCustomerDirectoryEntEnabled =
        Entitlements.CUSTOMER_DIRECTORY in selectedShopPermissions;
      isGiftCardsEntEnabled =
        Entitlements.GIFT_CARDS in selectedShopPermissions;
      isMOLRefundsEntEnabled =
        Entitlements.MOL_REFUNDS in selectedShopPermissions;
      isDeliveryTasksEntEnabled =
        Entitlements.DELIVERY_TASKS in selectedShopPermissions;
    }

    const isReportsTrunedOn = Environment.get("SHOW_REPORTS_MENU", false);
    let showReportsMenuItem = isReportsTrunedOn && isReportsEntEnabled;

    const loadForesee = () => {
      if (Platform.OS === "web") {
        const isForeseeEnabled = Environment.get("ENABLE_FORESEE", true);

        if (isForeseeEnabled) {
          const isProd = EnvironmentStorage.getName() === "prod";
          // ForeSee Staging Embed Script v2.01
          // DO NOT MODIFY BELOW THIS LINE *****************************************
          (function (g) {
            var d = document,
              am = d.createElement("script"),
              h = d.head || d.getElementsByTagName("head")[0],
              fsr = "fsReady",
              aex = {
                src: `//gateway.foresee.com/sites/mercuryhq/${
                  isProd ? "production" : "staging"
                }/gateway.min.js`,
                type: "text/javascript",
                async: "true",
                "data-vendor": "fs",
                "data-role": "gateway",
              };
            for (var attr in aex) {
              am.setAttribute(attr, aex[attr]);
            }
            h.appendChild(am);
            g[fsr] ||
              (g[fsr] = function () {
                var aT = "__" + fsr + "_stk__";
                g[aT] = g[aT] || [];
                g[aT].push(arguments);
              });
          })(window);
        }
      }
    };

    const handleDriverApproachingPN = (data) => {
      UserProfileStorage.setCurrentTab("DS");
      setCurrentPage("lmds-delivery");
      const { driverName, pickupTime, shopCode } = data;
      let quickFilters = [];
      let driverValues = [
        {
          title: "Courier Name",
          value: driverName,
        },
        {
          title: "Pickup Time",
          value: moment(pickupTime).format("h:mm A"),
        },
        {
          title: "Display Date",
          value: moment(pickupTime).format("MM/DD/YYYY"),
        },
        {
          title: "ShopCode",
          value: shopCode,
        },
      ];
      driverValues.map((each) => {
        quickFilters.push({
          ["ApproachingDrivers"]: {
            value: [
              {
                title: each.title,
                value: each.value,
              },
            ],
          },
        });
      });

      /* If user already logged in previously and on click of push notification 
      we are redirecting user to the delivery screen in case of driver approaching notifivation and applying filters*/
      navigation.dispatch(
        CommonActions.navigate({
          name: "lmds-delivery",
        })
      );

      setDSListingActions({
        appliedFilters: cloneDeep(quickFilters),
        filters: cloneDeep(quickFilters),
      });
    };

    const onOpenNotification = (payload = {}) => {
      //title => "Driver is approaching" or "Hurry! Driver is approaching"
      if (payload?.notification.title.includes("Driver is approaching")) {
        //handle separately for Driver Approaching push notification
        handleDriverApproachingPN(payload.data);
      } else if (payload?.notification.title.includes("Fulfillment Errors")) {
        dispatch(setSelectScreenKey("DS"));
        UserProfileStorage.setCurrentTab("DS");
        setCurrentPage("lmds-delivery");
        navigation.dispatch(
          CommonActions.navigate({
            name: "lmds-delivery",
          })
        );
      } else {
        /* If user already logged in previously and on click of push notification 
        we are redirecting user to the dashboard screen in case of mobiles / tablets */
        navigation.dispatch(
          CommonActions.navigate({
            name: "dashboard",
          })
        );
      }
    };

    React.useEffect(() => {
      // Clearing all notifications, when user opened or resumed app from any state (background/killed/foreground) by click on notification in notification center.
      Platform.OS !== "web" &&
        fcmService.onNotificationOpenedListener(onOpenNotification);
    }, []);

    const getShipments = (isCallback = false) => {
      const filteredShopCodes = [];
      Object.keys(permissions).map((memberCode) => {
        if (Entitlements.DELIVERY_TASKS in permissions[memberCode])
          filteredShopCodes.push(memberCode);
      });

      const updatedShopCodes = filteredShopCodes.filter((shopCode) => {
        const {
          pickup_manifest_print_settings: pickupManifestPreferencesArray = [],
        } = UserProfileStorage.getShopPreferences(shopCode);

        const pickupManifestPreferences =
          (pickupManifestPreferencesArray &&
            pickupManifestPreferencesArray?.find(
              (each) => each.macAddress === macAddress
            )) ||
          {};

        return pickupManifestPreferences?.auto_print;
      });

      //Do not Auto-Print if no eligible shops with auto_print enabled
      if (!updatedShopCodes.length) return;

      const { sources = [] } = ListOfDeliveryServiceSources;
      const { set1 = {} } = ListOfDeliveryTaskStates;

      const deliveryStartDate = moment()
        .subtract(1, "week")
        .utc()
        .startOf("day")
        .toISOString();
      const deliveryEndDate = moment()
        .add(1, "week")
        .utc()
        .endOf("day")
        .toISOString();

      fetchData({
        actions: isCallback ? actions : {},
        listingType: "deliveryTasks",
        initData: InitDSRequestsData,
        params: {
          deliveryStartDate,
          deliveryEndDate,
          fetchStates: isCallback
            ? set1.status.join(",")
            : ListOfApproachingDriversStates.join(","),
          fetchMemberCodes: isCallback
            ? filteredShopCodes
            : updatedShopCodes.join(","),
          sources: sources.join(","),
        },
        setLoading: (e) => {},
        auto_print: !isCallback,
      });
    };

    //get shipments to auto print pickup manifests
    useEffect(() => {
      if (macAddress && isQuickFiltersVisible) {
        clearTimeout(ManifestAutoPrintTimer.current);
        getShipments();
      }
    }, [macAddress]);

    //get shipments to auto print pickup manifests after every 2 min
    useEffect(() => {
      if (macAddress && isQuickFiltersVisible) {
        ManifestAutoPrintTimer.current = setTimeout(() => {
          getShipments();
        }, AutoPrintDSFetchInterval);
      }
      return () => {
        clearTimeout(ManifestAutoPrintTimer.current);
      };
    }, [pickupManifestTimeStamp]);

    useEffect(() => {
      if (
        shipmentsData["approachingDrivers"]?.list.length &&
        macAddress &&
        isQuickFiltersVisible
      ) {
        shipmentsData["approachingDrivers"]?.list?.map(
          (pickupDriverBatch, index) => {
            const { shipments = [], reqParams } = pickupDriverBatch;
            const { siteId: shopCode } = shipments[0];

            //getting print status for all ApproachingDrivers
            getPickupManifestPrintStatus({
              shopCode,
              ...reqParams,
            }).then((printStatusResp) => {
              if (printStatusResp) {
                const { printed: isPickupManifestPrinted = false } =
                  printStatusResp;

                if (!isPickupManifestPrinted) {
                  setTimeout(() => {
                    printPickupManifest(
                      pickupDriverBatch,
                      "AUTO",
                      false,
                      getShipments,
                      Localise,
                      messages
                    );
                  }, (index + 1) * 500);
                }
              } else {
                setTimeout(() => {
                  printPickupManifest(
                    pickupDriverBatch,
                    "AUTO",
                    true,
                    getShipments,
                    Localise,
                    messages
                  );
                }, (index + 1) * 500);
              }
            });
          }
        );
      }
    }, [shipmentsData]);

    useEffect(() => {
      loadForesee();
      const updatedMenuOptions = menuOptions.map((option) => {
        if (
          ["profile", "ftd-profile"].includes(toLower(option.name)) &&
          isMobile &&
          isPasswordAboutToExpire
        ) {
          return { ...option, badge: 1 };
        }

        if (["reports"].includes(toLower(option.name))) {
          return { ...option, hidden: !showReportsMenuItem };
        }

        if (["mercury-online"].includes(toLower(option.name))) {
          return { ...option, hidden: !isMercuryOnlineEntEnabled };
        }

        if (["product-catalog"].includes(toLower(option.name))) {
          return { ...option, hidden: !isProductCatalogEntEnabled };
        }

        if (["customer-directory"].includes(toLower(option.name))) {
          return { ...option, hidden: !isCustomerDirectoryEntEnabled };
        }

        if (["gift-cards"].includes(toLower(option.name))) {
          return {
            ...option,
            hidden: !(isGiftCardsEntEnabled && isGiftCardsEnabled),
          };
        }

        if (["mercury-online-refunds"].includes(toLower(option.name))) {
          return { ...option, hidden: !isMOLRefundsEntEnabled };
        }

        return option;
      });

      // apply current orders count as badge
      const pollOrderCount = () => {
        if (
          (!UserAuthStorage.getAccessToken() &&
            !UserAuthStorage.getRefreshToken()) ||
          isEmpty(UserProfileStorage.getAuthGroup())
        )
          signOut();

        const allShopsPreferences = UserProfileStorage.getAllShopPreferences();
        const eligibleProvidersList = Environment.get(
          "ELIGIBLE_DELIVERY_PROVIDERS",
          "roadie,doordash"
        ).split(",");

        let showDeliveryModuleMenuItem = false;
        let showDeliveryService = false;

        if (dashboardSelectedShopCode === "all") {
          showDeliveryModuleMenuItem =
            some(Object.values(allShopsPreferences), {
              delivery_module_access: true,
            }) || false;

          showDeliveryService =
            (some(Object.values(allShopsPreferences), {
              delivery_service: "true",
            }) &&
              Object.values(allShopsPreferences).some((shop) => {
                return !eligibleProvidersList.includes(
                  shop.delivery_provider || defaultDeliveryProvider
                );
              })) ||
            false;
        } else {
          const selectedShopPreferences = UserProfileStorage.getShopPreferences(
            dashboardSelectedShopCode
          );

          showDeliveryModuleMenuItem = get(
            selectedShopPreferences,
            "delivery_module_access",
            false
          );

          showDeliveryService =
            get(selectedShopPreferences, "delivery_service", "false") ===
              "true" &&
            !eligibleProvidersList.includes(
              get(
                selectedShopPreferences,
                "delivery_provider",
                defaultDeliveryProvider
              ) || defaultDeliveryProvider
            );
        }

        const badgeMenus = updatedMenuOptions.map(
          //changed to generateMenuOptions map as menuOptions state is not getting updated
          (option) => {
            if (option.name === "orders") {
              const badgeCount = AppSettingsStorage.getMenuBadge();
              // Platform.OS !== "web" &&
              //   fcmService.setApplicationBadge(badgeCount);
              return { ...option, badge: badgeCount };
            }

            if (option.name === "delivery-service") {
              return { ...option, hidden: !showDeliveryService };
            }

            if (option.name === "lmds-delivery") {
              return {
                ...option,
                hidden: !(showDeliveryModuleMenuItem && isDeliveryEntEnabled),
              };
            }

            if (option.name === "lmds-routes") {
              return {
                ...option,
                hidden: !(showDeliveryModuleMenuItem && isRoutesEntEnabled),
              };
            }

            if (option.name === "delivery-tasks") {
              return {
                ...option,
                hidden: !isDeliveryTasksEntEnabled,
              };
            }

            return option;
          }
        );

        setMenuOptions(badgeMenus);
      };

      const pollTimer = setInterval(pollOrderCount, pollInterval);
      pollOrderCount();

      return () => {
        clearInterval(pollTimer);
      };
    }, [dashboardSelectedShopCode]);

    return (
      <>
        <Drawer.Navigator
          initialRouteName={
            isDeliveryServiceOnlyUser ? "lmds-delivery" : initialRouteName
          }
          drawerPosition={isTablet ? "right" : "left"}
          drawerContent={(props) => (
            <DrawerContentScrollView
              testID={"left-nav-menu"}
              {...props}
              contentContainerStyle={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 0,
              }}
            >
              {isElectronApp &&
                Object.keys(shopNames).map((memberCode, index) => (
                  <View key={index}>
                    <PrintIframeRenderer
                      id={`iframeForPickupManifest_${memberCode}`}
                    />
                  </View>
                ))}
              {isTablet && (
                <TouchableOpacity
                  style={[
                    tw(
                      `flex ${showMiniMenu ? "items-center" : "items-end pr-2"}`
                    ),
                    { backgroundColor: "#E8F8FA", paddingVertical: 10 },
                  ]}
                  onPress={() => setMiniMenu(!showMiniMenu)}
                  fsClass="fs-unmask"
                >
                  <Image
                    style={{
                      width: 30,
                      height: 30,
                    }}
                    containerStyle={{}}
                    source={IMAGES[showMiniMenu ? "swipe-right" : "swipe-left"]}
                    fsClass="fs-unmask"
                  />
                </TouchableOpacity>
              )}
              {isMobile ? (
                <CustomDrawerItemList
                  {...props}
                  isMobile={isMobile}
                  setMiniMenu={setMiniMenu}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  setLogoutModal={setLogoutModal}
                  isRouteEditing={isRouteEditing}
                  setIsRouteEditing={setIsRouteEditing}
                  isAdhocStopEditing={isAdhocStopEditing}
                  setIsAdhocStopEditing={setIsAdhocStopEditing}
                  setIsRouteSave={setIsRouteSave}
                  isRouteSaveSuccess={isRouteSaveSuccess}
                  setIsRouteSaveSuccess={setIsRouteSaveSuccess}
                  isBulkDSRequestUploadInProgress={
                    isBulkDSRequestUploadInProgress
                  }
                  setIsBulkDSRequestUploadInProgress={
                    setIsBulkDSRequestUploadInProgress
                  }
                  isRouteOptimised={isRouteOptimised}
                  setIsRouteOptimised={setIsRouteOptimised}
                  isOptimiseDone={isOptimiseDone}
                  setOptimiseRoute={setOptimiseRoute}
                  setIsRouteOptimiseDone={setIsRouteOptimiseDone}
                />
              ) : (
                <TouchableOpacity
                  activeOpacity={1}
                  onMouseEnter={() => isDesktop && setMiniMenu(false)}
                  onMouseLeave={() => isDesktop && setMiniMenu(true)}
                >
                  <CustomDrawerItemList
                    {...props}
                    setMiniMenu={setMiniMenu}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isMobile={isMobile}
                    setLogoutModal={setLogoutModal}
                    isRouteEditing={isRouteEditing}
                    setIsRouteEditing={setIsRouteEditing}
                    isAdhocStopEditing={isAdhocStopEditing}
                    setIsAdhocStopEditing={setIsAdhocStopEditing}
                    setIsRouteSave={setIsRouteSave}
                    isRouteSaveSuccess={isRouteSaveSuccess}
                    setIsRouteSaveSuccess={setIsRouteSaveSuccess}
                    isBulkDSRequestUploadInProgress={
                      isBulkDSRequestUploadInProgress
                    }
                    setIsBulkDSRequestUploadInProgress={
                      setIsBulkDSRequestUploadInProgress
                    }
                    isRouteOptimised={isRouteOptimised}
                    setIsRouteOptimised={setIsRouteOptimised}
                    setOptimiseRoute={setOptimiseRoute}
                    isOptimiseDone={isOptimiseDone}
                    setIsRouteOptimiseDone={setIsRouteOptimiseDone}
                  />
                </TouchableOpacity>
              )}
            </DrawerContentScrollView>
          )}
          drawerType={isMobile ? "front" : "permanent"}
          sceneContainerStyle={{
            marginLeft: isEmbedded ? 0 : isMobile ? 0 : 54,
          }}
          drawerStyle={
            isMobile
              ? { width: 250, backgroundColor: backgroundColors.navBar }
              : {
                  width: showMiniMenu ? 54 : 200,
                  height: "100%",
                  backgroundColor: backgroundColors.navBar,
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.4,
                  shadowRadius: 7,
                  position: "absolute",
                  left: 0,
                  ...(isEmbedded ? { borderRightWidth: 0, width: 0 } : {}),
                }
          }
          drawerContentOptions={{
            itemStyle: {
              marginVertical: 0,
              marginHorizontal: 0,
              paddingVertical: 0,
              paddingHorizontal: 0,
              borderRadius: 0,
            },
          }}
        >
          {menuOptions?.map((option, index) => {
            const {
              name,
              label,
              badge,
              hidden,
              url,
              unmountOnBlur = true,
              id,
              ...iconProps
            } = option;

            let displayLabel = label;
            if (label === "LMDS Routes") {
              displayLabel = "Driver Dashboard";
            } else if (label.includes("LMDS ")) {
              displayLabel = label.replace("LMDS ", "");
            }

            const isExternalLink =
              Platform.OS === "web" && startsWith(url, "http");

            return screenRef[name] ? (
              <Drawer.Screen
                key={id || index}
                name={name}
                component={isExternalLink ? () => {} : screenRef[name]}
                options={{
                  unmountOnBlur,
                  title: Localise(messages, displayLabel),
                  hidden,
                  url,
                  drawerLabel: ({ focused }) => (
                    <DrawerLabel
                      title={Localise(messages, displayLabel)}
                      badge={badge}
                      focused={focused}
                    />
                  ),
                  drawerIcon: ({ focused }) => (
                    <MenuIcon
                      focused={focused}
                      showMiniMenu={showMiniMenu}
                      name={name}
                      badge={badge}
                      {...iconProps}
                    />
                  ),
                }}
              />
            ) : null;
          })}
        </Drawer.Navigator>
        <AutoPrint />
        <LoadNewBundle />
      </>
    );
  }
);

const generateMenuOptions = (menus, isMobile) => {
  if (!menus.length) return [];

  return [
    ...menus.map((menu) => ({
      ...menu,
      ...(menu.mobileMenu && {
        hidden: !isMobile && menu.mobileMenu ? true : false,
      }),
      ...(menu.name === "delivery-service" && { hidden: true }),
    })),
    {
      id: "5fb4fe47711abc423f2bad2c",
      label: "Notifications",
      url: "/notifications",
      displayOrder: 5,
      icon: "flower-outline",
      name: "notifications",
      hidden: true,
    },
  ];
};

const MenuIcon = ({ showMiniMenu, badge, name }) => {
  let menuName = name;
  let dimensions = {};
  if (menuName === "lmds-delivery") {
    dimensions = DIMENSIONS["deliveryDashboard"];
    menuName = "deliveryDashboard";
  } else if (menuName === "lmds-routes") {
    menuName = "delivery-black";
  } else if (menuName === "delivery-tasks") {
    menuName = "delivery-service";
  } else if (menuName === "create-order") {
    menuName = "create-new-order";
  }
  return (
    <>
      {IMAGES[menuName] && (
        <Image
          fsClass="fs-unmask"
          style={{
            width: dimensions.imageWidth ? dimensions.imageWidth : 30,
            height: dimensions.imageHeight ? dimensions.imageHeight : 30,
          }}
          containerStyle={{ marginRight: -22, paddingRight: 5 }}
          source={IMAGES[menuName]}
        />
      )}
      {showMiniMenu && badge > 0 && (
        <Badge
          containerStyle={{ position: "absolute", top: 0, right: 0 }}
          badgeStyle={{ backgroundColor: "#e5175e" }}
          value={badge}
        />
      )}
    </>
  );
};

const CustomDrawerItemList = ({
  state,
  navigation,
  descriptors,
  itemStyle,
  isMobile,
  setMiniMenu,
  currentPage,
  setCurrentPage,
  setLogoutModal,
  isRouteEditing,
  setIsRouteEditing,
  isAdhocStopEditing,
  setIsAdhocStopEditing,
  setIsRouteSave,
  isRouteSaveSuccess,
  setIsRouteSaveSuccess,
  isBulkDSRequestUploadInProgress,
  setIsBulkDSRequestUploadInProgress,
  isRouteOptimised,
  setIsRouteOptimised,
  setOptimiseRoute,
  isOptimiseDone,
  setIsRouteOptimiseDone,
}) => {
  const buildLink = useLinkBuilder();
  const { signOut } = useContext(UserAuthContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const dispatch = useDispatch();

  const [modalVisible, setModalVisibile] = useStateIfMounted(false);
  const [modalVisibleForBulkDS, setModalVisibileForBulkDS] =
    useStateIfMounted(false);
  const [modalVisibleForOptimise, setModalVisibleForOptimise] =
    useStateIfMounted(false);
  const [modalData, setModalData] = useStateIfMounted({});
  const { status: orderPaymentStatus, values: orderValues = {} } =
    useSelector(selectPaymentDetails);
  const dashboardSelectedShopCode = useSelector(selectDashboardShopCode);

  const isSplitPaymentEnabled = Environment.get("ENABLE_SPLIT_PAYMENT", false);

  const modalContentForBulkImportDS = {
    content: (
      <Text
        style={{
          ...tw("p-4"),
          fontSize: 15,
          color: colors.highlighter,
          textAlign: "center",
        }}
      >
        {Localise(messages, "File upload in progress")}
      </Text>
    ),
    buttons: [
      {
        type: "primary",
        title: Localise(messages, "OK"),
      },
    ],
  };
  const modalContent = {
    content: (
      <Text style={{ ...tw("p-4"), fontSize: 15, color: colors.highlighter }}>
        {Localise(messages, DELIVERY.SAVE_ROUTE_CONFIRM)}
      </Text>
    ),
    buttons: [
      { type: "secondary", title: Localise(messages, "Cancel") },
      { type: "primary", title: Localise(messages, "Continue") },
    ],
  };
  const modalContentForOptimise = {
    content: (
      <Text style={{ ...tw("p-4"), fontSize: 15, color: colors.highlighter }}>
        {Localise(messages, DELIVERY.OPTIMISE_SUGGEST)}
      </Text>
    ),
    buttons: [
      { type: "secondary", title: Localise(messages, "Cancel") },
      { type: "primary", title: Localise(messages, "Continue") },
    ],
  };

  useEffect(() => {
    if (isRouteSaveSuccess) {
      const { focused, name, key, params } = modalData;
      name && key && setIsRouteSaveSuccess(false);
      name && key && redirectToSelectedMenu(focused, name, key, params);
    }
  }, [isRouteSaveSuccess]);

  useEffect(() => {
    if (isOptimiseDone) {
      const { focused, name, key, params } = modalData;
      name && key && setIsRouteOptimiseDone(false);
      name && key && redirectToSelectedMenu(focused, name, key, params);
    }
  }, [isOptimiseDone]);

  const redirectToSelectedMenu = (focused, name, key, params) => {
    navigation.dispatch({
      ...(focused
        ? DrawerActions.closeDrawer()
        : CommonActions.navigate(name, params)),
      target: key,
    });
    setMiniMenu && !isMobile && setMiniMenu(true);
    setCurrentPage && setCurrentPage(name);
  };

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dashboardSelectedShopCode]);

  const handleSave = () => {
    setIsRouteSave(true);
    setModalVisibile(false);
  };

  const handleCloseBulkDS = () => {
    const { focused, name, key, params } = modalData;
    setIsBulkDSRequestUploadInProgress(false);
    setModalVisibileForBulkDS(false);
    redirectToSelectedMenu(focused, name, key, params);
  };

  const handleClose = () => {
    const { focused, name, key, params } = modalData;
    setIsRouteEditing(false);
    setIsAdhocStopEditing(false);
    setModalVisibile(false);
    if (!isRouteOptimised) {
      setModalVisibleForOptimise(true);
    } else redirectToSelectedMenu(focused, name, key, params);
  };

  const handleCloseForOptimise = () => {
    const { focused, name, key, params } = modalData;
    setOptimiseRoute(false);
    setIsRouteOptimised(true);
    setModalVisibleForOptimise(false);
    redirectToSelectedMenu(focused, name, key, params);
  };

  const handleOptimise = () => {
    setOptimiseRoute(true);
    setModalVisibleForOptimise(false);
  };

  const onLogout = () => {
    navigation.dispatch(DrawerActions.toggleDrawer());
    setLogoutModal(true);
    signOut().then(() => {
      setLogoutModal(false);
    });
  };

  const DrawerItems = state.routes.map((route, i) => {
    const focused = i === state.index;
    const { drawerLabel, drawerIcon, hidden, url } =
      descriptors[route.key].options;
    const params = Platform.OS === "web" ? {} : { url };
    const isExternalLink = Platform.OS === "web" && startsWith(url, "http");
    const handleMenuClick = (key, name) => {
      if (isRouteEditing || isAdhocStopEditing) {
        setModalVisibile(true);
        setModalData({ focused, name, key, params });
      } else if (isBulkDSRequestUploadInProgress) {
        setModalVisibileForBulkDS(true);
        setModalData({ focused, name, key, params });
      } else if (!isRouteOptimised) {
        setModalVisibleForOptimise(true);
        setModalData({ focused, name, key, params });
      } else if (isSplitPaymentEnabled && orderPaymentStatus === "pending") {
        voidPayments({
          values: orderValues,
          resolve: () => {
            dispatch(setPaymentDetails({ status: "", values: {} }));
            UserProfileStorage.setCurrentTab("");
            redirectToSelectedMenu(focused, name, key, params);
            ToasterHandler(
              "nice",
              Localise(
                messages,
                `Refund initiated for successful transaction(s)`
              )
            );
          },
          reject: () => {
            Logger.info("reject of voidPayments in menu :>> ");
            ToasterHandler(
              "uh oh",
              Localise(messages, "Something went wrong. Please try again!")
            );
          },
        });
      } else {
        UserProfileStorage.setCurrentTab("");
        redirectToSelectedMenu(focused, name, key, params);
      }
    };
    const allowRedirect = ![
      "shop-settings",
      "product-catalog",
      "help",
      "customer-directory",
    ].includes(currentPage);

    if (hidden) return null;

    return (
      <DrawerItem
        key={route.key}
        label={drawerLabel}
        icon={drawerIcon}
        focused={focused}
        activeBackgroundColor={colors.light}
        inactiveBackgroundColor="transparent"
        style={itemStyle}
        testID={route.name}
        accessibilityLabel={route.name}
        to={buildLink(route.name, route.params)}
        onPress={() => {
          if (route.name === currentPage && allowRedirect) return;
          route.name === "logout" || route.name === "ftd-logout"
            ? onLogout()
            : isExternalLink
            ? window.open(url)
            : handleMenuClick(state.key, route.name);
        }}
      />
    );
  });

  return (
    <>
      <CustomModal
        modalVisible={modalVisible}
        modalContent={modalContent}
        primaryhandler={handleSave}
        secondaryhandler={handleClose}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: backgroundColors.secondary, textAlign: "center" },
        ]}
        modalStyle={
          Platform.OS !== "web" && {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            backgroundColor: "#00000070",
            color: colors.secondary,
          }
        }
      ></CustomModal>
      <CustomModal
        modalVisible={modalVisibleForBulkDS}
        modalContent={modalContentForBulkImportDS}
        primaryhandler={handleCloseBulkDS}
        secondaryhandler={handleClose}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: backgroundColors.secondary, textAlign: "center" },
        ]}
        modalStyle={
          Platform.OS !== "web" && {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            backgroundColor: "#00000070",
            color: colors.secondary,
          }
        }
      ></CustomModal>
      <CustomModal
        modalVisible={modalVisibleForOptimise}
        modalContent={modalContentForOptimise}
        primaryhandler={handleOptimise}
        secondaryhandler={handleCloseForOptimise}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: backgroundColors.secondary, textAlign: "center" },
        ]}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: colors.secondary,
              }
            : { width: "35%" }
        }
      ></CustomModal>
      {DrawerItems}
    </>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    currentPage: state.mhq.ongoing.global.currentPage,
    isRouteEditing: state.mhq.ongoing.global.isRouteEditing,
    isAdhocStopEditing: state.mhq.ongoing.global.isAdhocStopEditing,
    isRouteSaveSuccess: state.mhq.ongoing.global.isRouteSaveSuccess,
    isBulkDSRequestUploadInProgress:
      state.mhq.ongoing.global.isBulkDSRequestUploadInProgress,
    isRouteOptimised: state.mhq.ongoing.global.isRouteOptimised,
    isOptimiseDone: state.mhq.ongoing.global.isOptimiseDone,
    deliveryTasksList: state.mhq.views.home.drawer.deliveryTasks,
    layoutMode: state.mhq.ongoing.global.layoutMode,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentPage,
      setIsRouteEditing,
      setIsAdhocStopEditing,
      setIsRouteSave,
      setIsRouteSaveSuccess,
      setIsBulkDSRequestUploadInProgress,
      setIsRouteOptimised,
      setOptimiseRoute,
      setIsRouteOptimiseDone,
      setDSListingActions,
      fetchData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
