import React, { useEffect, useContext, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createStackNavigator } from "@react-navigation/stack";
import SplitScreen from "components/containers/split-screen-new";

import {
  setInit,
  setReset,
  setShopCode,
} from "library/sagas/views/home/drawer/reports/common/slice";
import {
  selectTabNames,
  selectShopCode,
  selectToastDetails,
} from "library/sagas/views/home/drawer/reports/common/selector";
import { AppSettingsContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { Entitlements } from "library/utils/entitlements";

import { ToasterHandler } from "components/elements";
import { MainScreen, SideScreen, CustomHeader } from "./helper";

const Stack = createStackNavigator();

const Reports = ({ navigation }) => {
  const dispatch = useDispatch();

  const { permissions } = useContext(AppSettingsContext);
  const { messages, Localise } = useContext(I18NContext);

  const shopCode = useSelector(selectShopCode);
  const tabNames = useSelector(selectTabNames);
  const toastMessage = useSelector(selectToastDetails);

  const screenOneRef = createRef();
  const screenTwoRef = createRef();

  const shopCodes = Object.keys(permissions)
    .map(
      (memberCode) =>
        Entitlements.SCORECARD in permissions[memberCode] && memberCode // adding only scorecard as we have shop picker only for scorecard tab
    )
    .filter(Boolean);

  useEffect(() => {
    dispatch(setShopCode(shopCode.length > 0 ? shopCode : shopCodes[0]));
    dispatch(setInit({ permissions }));
    return () => dispatch(setReset());
  }, []);

  useEffect(() => {
    if (toastMessage) {
      ToasterHandler(
        toastMessage.type,
        Localise(messages, toastMessage.message)
      );
    }
  }, [toastMessage]);

  useEffect(() => {
    shopCode && dispatch(setInit({ permissions }));
  }, [shopCode]);

  return (
    <Stack.Navigator headerMode="none">
      {tabNames.map(({ key, title }) => (
        <Stack.Screen key={key} name={key} options={{ title }}>
          {() => (
            <SplitScreen
              ScreenOne={
                <MainScreen defaultTab={key} screenOneRef={screenOneRef} />
              }
              ScreenTwo={<SideScreen screenTwoRef={screenTwoRef} />}
              showSecondScreen={false}
              rootNavigation={navigation}
              CustomHeader={() => (
                <CustomHeader defaultTab={key} shopCodes={shopCodes} />
              )}
              {...{ screenOneRef, screenTwoRef }}
            />
          )}
        </Stack.Screen>
      ))}
    </Stack.Navigator>
  );
};

export default Reports;
