import React from "react";
import { View, Platform } from "react-native";
import { Text, Button } from "react-native-elements";

import { useSelector } from "react-redux";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { fonts, theme } from "styles/theme";
import tw from "tailwind-rn";
import { request } from "library/utils/request";
import { ToasterHandler } from "components/elements";
import { SaveFile } from "components/views/drawer/reports/reports-tab/helper";

const ListingHeader = ({ selectors }) => {
  const {
    dataSelector,
    limitSelector,
    selectTotalNumberOfRecords,
    selectPageActions,
  } = selectors;
  const itemData = useSelector(dataSelector);
  const currentLimit = useSelector(limitSelector);
  const pageActions = useSelector(selectPageActions);
  const totalRecords = useSelector(selectTotalNumberOfRecords);
  const totalLimit = itemData?.length ?? 0;
  const showingItems = currentLimit >= totalLimit ? totalLimit : currentLimit;

  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);

  const DownloadCustomers = () => {
    request("customer-report", { search: pageActions?.search })
      .then((resp) => {
        const { data, fileName, fileType } = resp;
        const file = `${fileName}.${fileType}`;
        if (Platform.OS === "web") {
          // Creating blob link to download
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file);

          // Force download
          link.click();
        } else {
          SaveFile(data, file, null, messages, Localise);
        }
      })
      .catch(() => {
        ToasterHandler(
          "error",
          Localise(messages, "Download failed, Please try again.")
        );
      });
  };

  return (
    <View
      style={[
        tw("flex flex-row justify-between"),
        {
          paddingVertical: 5,
          paddingHorizontal: isDesktop ? 27 : 5,
          backgroundColor: "#fff",
        },
      ]}
    >
      <View style={{ justifyContent: "center" }}>
        <Text>
          <Text style={fonts.heading4}>{Localise(messages, "Showing")}:</Text>{" "}
          {pageActions?.search?.length > 0
            ? `${showingItems} ${
                totalRecords > 10
                  ? `${Localise(messages, "of")} ${totalRecords}`
                  : ``
              } ${Localise(messages, "results for")} ${pageActions?.search}`
            : `${showingItems} ${Localise(
                messages,
                "of"
              )} ${totalRecords} ${Localise(messages, "Customers")}`}
        </Text>
      </View>
      <Button
        testID={"Download_Results"}
        accessibilityLabel="Download_Results"
        titleStyle={theme.Button.secondaryTitleStyle}
        buttonStyle={{
          ...theme.Button.secondaryButtonStyle,
          paddingVertical: 5,
          paddingHorizontal: 5,
        }}
        containerStyle={{
          marginVertical: 0,
          marginHorizontal: 0,
          justifyContent: "center",
        }}
        title={Localise(messages, "Download Results")}
        onPress={DownloadCustomers}
      />
    </View>
  );
};

export default ListingHeader;
