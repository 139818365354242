import React, { useEffect } from "react";
import { View, TouchableOpacity, Image } from "react-native";
import { Text } from "react-native-elements";
import { useSelector, useDispatch } from "react-redux";

import tw from "tailwind-rn";
import get from "lodash/get";
import isArray from "lodash/isArray";

import { PrintIframeAction, Tooltip, InfoLabel } from "components/elements";
import { FormField } from "components/elements/forms";

import { fonts, colors, shapes, fontWeights } from "styles/theme";
import IMAGES from "static/assets/images";
import I18NContext from "library/contexts/i18N";
import AppSettingsContext from "library/contexts/appSettings";

import useStateIfMounted from "library/utils/useStateIfMounted";
import { trimSpecialChars, formatPrice } from "library/utils/formatter";
import { request } from "library/utils/request";

import ChargesComponent from "components/views/drawer/order-details/payment-details/order-details/charges-summary";
import {
  selectIsEditOrder,
  selectOrderItemTabs,
  selectActiveItemTab,
  selectIsSubscription,
  selectIsEditSubscription,
} from "library/sagas/views/home/drawer/create-order/selector";
import { setActiveOrderItemTab } from "library/sagas/views/home/drawer/create-order/slice";
import { selectSelectedProducts } from "library/sagas/ongoing/global-data/selector";
import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";

import {
  getRecipeInfo,
  getOccasionLabel,
} from "../../order-details/product-details/helper";
import { isMHQNonCoreMember } from "library/utils/entitlements";
import SubscriptionPauses from "./subscription-pauses";
import { hasOrderNotesFeature } from "library/utils/featureAvailability";

const AdditionalInfoArea = ({
  index,
  formIKPath,
  formValues,
  enableCPS = false,
  sendingMemberCode,
  canModifyOrder = true,
  isSmallScreen,
}) => {
  const dispatch = useDispatch();

  const { messages, Localise } = React.useContext(I18NContext);
  const { permissions } = React.useContext(AppSettingsContext);

  const [occasionsList, setOccasionsList] = useStateIfMounted([]);

  const orderItem = get(formValues, `${formIKPath}`, {});

  const { deliveryInfo = {}, lineItems = [] } = orderItem;
  const { occasion = "" } = deliveryInfo;

  const productsCount = (isArray(lineItems) && lineItems.length) || 0;
  const orderHasProducts = productsCount > 0;

  const selectedProducts = useSelector(selectSelectedProducts);
  const activeItemTab = useSelector(selectActiveItemTab);
  const isMHQNonCoreShop = isMHQNonCoreMember(sendingMemberCode);
  const tabNames = useSelector(selectOrderItemTabs);

  const orderDetailsResponse = useSelector(selectApiResponse);
  const isEditOrder = useSelector(selectIsEditOrder);
  const isSubscription = useSelector(selectIsSubscription);
  const isEditSubscription = useSelector(selectIsEditSubscription);
  const isMultiOrderEligible =
    isMHQNonCoreShop && !isEditOrder && !formValues?.isQuickSaleEnabled;

  const orderDirection = get(
    orderDetailsResponse,
    `orderItems.${index}.direction`,
    ""
  );

  const originalSendingMember = get(
    orderDetailsResponse,
    `orderItems.${index}.sendingMember.memberCode`,
    ""
  );

  const selfOutGoingOrder =
    isEditOrder &&
    originalSendingMember === sendingMemberCode &&
    orderDirection === "OUTBOUND";

  const hasOrderNotesEligibility = hasOrderNotesFeature(
    permissions,
    sendingMemberCode
  );

  const canShowCustomerNotes = enableCPS && !formValues?.isQuickSaleEnabled;

  const canShowNextArrow = tabNames.length > 1;
  const orderItemTitle = tabNames[activeItemTab].title;
  const canShowWrapper = hasOrderNotesEligibility || canShowCustomerNotes;
  const allowModification = canModifyOrder || selfOutGoingOrder;

  // Config to handle both Notes and Customer Notes sections
  const sectionConfig = [
    {
      id: "customerNotes",
      title: "Customer Notes",
      fieldName: "customerNotes", // Field name for formik
      fieldPath: `customerInfo`,
      placeholder: "Enter notes related to the customer.",
      show: canShowCustomerNotes, // Based on cps enabled and not a quick sale transaction
      showInfoIcon: false, // Info icon not needed,
      updateOnChange: isSmallScreen ? formValues?.hasCustomerInfo : false,
      sectionOpacity: allowModification ? 1 : 0.7,
      sectionPointerEvents: allowModification ? "auto" : "none",
    },
    {
      id: "notes",
      title: "Order Notes",
      fieldName: "notes", // Field name for formik
      fieldPath: `orderItems.${index}`,
      placeholder: "Enter notes related to the order.",
      show: hasOrderNotesEligibility, // Based on permissions check
      other: { maxLength: 400, showRemainingChars: true }, // show remaining characters for order notes
      showInfoIcon: true, // Info icon needed
      tooltipText: `${Localise(
        messages,
        "Order Notes (Internal Use Only)"
      )}\n\n${Localise(
        messages,
        "Please note that any comments added here are for internal use only.  These notes are private, designed to assist your team in managing orders effectively.  Notes entered in this section will not be visible to customers or recipients and will not appear on any printed documents."
      )}`,
      updateOnChange: isSmallScreen ? true : false,
      sectionOpacity: 1,
      sectionPointerEvents: "auto",
    },
  ];

  useEffect(() => {
    request("get-occasions", {}, undefined, true).then((res) => {
      setOccasionsList((res && res.occasions) || []);
    });
  }, []);

  return (
    <View>
      <View
        style={[
          isMultiOrderEligible && {
            ...shapes.sectionBorder,
            paddingHorizontal: 23,
            paddingVertical: 15,
          },
        ]}
      >
        {isMultiOrderEligible && !isSubscription && (
          <View style={tw("flex-row items-center justify-between mb-2")}>
            <Text
              style={{
                ...fonts.sectionHeading,
                color: colors.highlighter,
              }}
            >
              {orderItemTitle}
            </Text>
            {canShowNextArrow && (
              <TouchableOpacity
                style={{ paddingHorizontal: 5 }}
                onPress={() => {
                  dispatch(
                    setActiveOrderItemTab(
                      tabNames.length !== activeItemTab + 1 ? index + 1 : 0
                    )
                  );
                }}
                testID="forward-order-item-arrow"
                accessibilityLabel="forward-order-item-arrow"
              >
                <Image
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  resizeMode="contain"
                  source={IMAGES[`back_right_nav_arrow`]}
                />
              </TouchableOpacity>
            )}
          </View>
        )}

        {canShowWrapper && (
          <View
            style={[
              !isMultiOrderEligible && {
                ...shapes.sectionBorder,
                paddingHorizontal: 23,
                paddingVertical: 15,
              },
            ]}
          >
            {sectionConfig.map(
              ({
                id,
                title,
                fieldName,
                fieldPath,
                placeholder,
                show,
                showInfoIcon,
                other = {},
                tooltipText = "",
                updateOnChange,
                sectionOpacity,
                sectionPointerEvents,
              }) => {
                if (!show) return null;
                return (
                  <View
                    style={[
                      tw(`flex flex-col mt-2`),
                      { opacity: sectionOpacity },
                    ]}
                    key={id}
                    pointerEvents={sectionPointerEvents}
                  >
                    <View style={tw("flex flex-row mb-2 mr-3")}>
                      <Text
                        style={{
                          ...fonts.sectionHeading,
                          color: colors.highlighter,
                        }}
                      >
                        {Localise(messages, title)}
                      </Text>
                      {showInfoIcon && (
                        <InfoLabel
                          toolTipProps={{
                            showToolTip: true,
                            toolTipText: tooltipText,
                          }}
                          iconProps={{ iconStyle: { marginLeft: 5 } }}
                        />
                      )}
                    </View>

                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      autoComplete="new-password"
                      name={fieldName}
                      path={fieldPath}
                      containerStyle={{
                        width: "100%",
                        paddingLeft: 0,
                      }}
                      placeholder={Localise(messages, placeholder)}
                      multiline={true}
                      numberOfLines={5}
                      maxNumberOfLines={5}
                      spellCheck={true}
                      isUpdateOnChange={updateOnChange}
                      {...other}
                    />
                  </View>
                );
              }
            )}
          </View>
        )}

        {orderHasProducts && (
          <View>
            {lineItems.map((product, idx) => (
              <ProductDetails
                key={idx}
                index={idx}
                product={product}
                Localise={Localise}
                messages={messages}
                selectedProducts={selectedProducts}
                occasion={occasion}
                occasionsList={occasionsList}
              />
            ))}
          </View>
        )}
      </View>
      {isEditOrder && <ChargesComponent />}
      {isEditSubscription && <SubscriptionPauses />}
    </View>
  );
};

const ProductDetails = ({
  index,
  product,
  Localise,
  messages,
  selectedProducts,
  occasion,
  occasionsList,
}) => {
  const [showSubstitution, setShowSubstitution] = useStateIfMounted(false);

  const {
    productFirstChoiceDescription = "",
    productFirstChoiceRefNumberId = "",
    productFirstChoiceCode,
    productSecondChoiceCode,
    productSecondChoiceDescription,
    img,
    type,
  } = product || {};

  const isProduct = type === "Product";

  const currentProductInfo = selectedProducts.find(
    (product) => product.productId === productFirstChoiceCode
  );

  const {
    dimension = "",
    actualPrice,
    recipeList = [],
    isFTDOrder = false,
  } = currentProductInfo || {};

  return (
    <View
      key={index}
      style={{
        ...shapes.sectionBorder,
        marginRight: 10,
        marginTop: 15,
        width: "100%",
      }}
    >
      <View style={tw("flex")}>
        <View style={tw("flex flex-row justify-between")}>
          <Text style={[tw("mb-4"), fonts.sectionHeading]}>
            {Localise(messages, `${isProduct ? "Product" : "Add-on"} Details`)}
          </Text>

          {isProduct && recipeList?.length ? (
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() => {
                PrintIframeAction(
                  getRecipeInfo(
                    {
                      productFirstChoiceCode,
                      occasion: getOccasionLabel(occasionsList, occasion),
                      formattedPrice: formatPrice(actualPrice),
                      productFirstChoiceDescription,
                      img,
                      actualPrice,
                      ...currentProductInfo,
                    },
                    Localise,
                    messages,
                    isFTDOrder
                  )
                );
              }}
              testID="print_recipe"
              accessibilityLabel="print_recipe"
            >
              <Tooltip
                text={Localise(messages, "Print Recipe")}
                renderForWebOnly={true}
              >
                <Image
                  style={{
                    width: 26,
                    height: 26,
                  }}
                  source={IMAGES["print"]}
                />
              </Tooltip>
            </TouchableOpacity>
          ) : null}
        </View>

        <Text
          style={[
            tw("mb-1"),
            fonts.default,
            {
              color: colors.primary,
              fontWeight: fontWeights.bold,
            },
          ]}
          numberOfLines={2}
        >
          {productFirstChoiceRefNumberId + " " + productFirstChoiceDescription}
        </Text>

        <Text
          style={[
            tw("mb-1"),
            fonts.default,
            {
              color: colors.primary,
              fontWeight: "normal",
            },
          ]}
        >
          {Localise(messages, "Product Code")}
          {": "}
          {trimSpecialChars(productFirstChoiceCode, ".")}
        </Text>

        {dimension.length > 0 && (
          <Text
            style={[
              tw("mb-1"),
              fonts.default,
              {
                color: colors.primary,
                fontWeight: "normal",
              },
            ]}
          >
            {Localise(messages, "Size / Style")}: {dimension}
          </Text>
        )}

        {productSecondChoiceCode && productSecondChoiceCode !== "NONE" && (
          <View style={[tw("my-2")]}>
            <View style={tw("flex flex-row")}>
              <TouchableOpacity
                onPress={() => setShowSubstitution(!showSubstitution)}
                testID="substitution"
                accessibilityLabel="substitution"
              >
                <Text style={[fonts.link]}>
                  {Localise(messages, "Substitution")}
                </Text>
              </TouchableOpacity>
            </View>

            {showSubstitution && (
              <View style={tw("flex flex-wrap items-baseline ml-2 my-1")}>
                <View style={tw("mb-1")}>
                  <Text>
                    {productSecondChoiceDescription.split("\\n").join("\n")}
                  </Text>
                </View>
                <View style={tw("my-1")}>
                  <Text>
                    {Localise(messages, "Product Code:")}{" "}
                    {trimSpecialChars(productSecondChoiceCode, ".")}
                  </Text>
                </View>
              </View>
            )}
          </View>
        )}

        {isProduct && recipeList?.length > 0 && (
          <View style={[tw("mt-3")]}>
            <Text>{Localise(messages, "Recipe")}</Text>
            <View
              style={[
                tw("flex flex-col mt-1"),
                {
                  borderColor: colors.grayScaleLight,
                  borderWidth: 1,
                  borderRadius: 2,
                  paddingTop: 11,
                  paddingRight: 11,
                  paddingBottom: 8,
                  paddingLeft: 14,
                },
              ]}
            >
              {recipeList.map((ele, index) => (
                <View key={index} style={tw("flex flex-row p-1")}>
                  <Text style={tw(`w-1/4 ${ele.css || ""}`)}>
                    {Localise(messages, ele.quantity)}
                  </Text>
                  {!isFTDOrder && (
                    <Text
                      style={{
                        ...tw(`w-1/4 ${ele.css || ""}`),
                        paddingLeft: 2,
                      }}
                    >
                      {Localise(messages, ele.color) || "-"}
                    </Text>
                  )}
                  <Text style={tw(`w-2/4 ${ele.css || ""}`)}>
                    {Localise(messages, ele.item)}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

export default AdditionalInfoArea;
