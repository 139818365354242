import React, { useContext, useEffect, useRef } from "react";
import { ScrollView, View, Dimensions } from "react-native";
import { DeviceContext } from "library/contexts/appSettings";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { theme, colors, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";
import SplitPane from "components/elements/split-pane";
import UserProfileContext from "library/contexts/userProfile";
import { setYOffSetScroll } from "library/sagas/ongoing/global-data/slice";
import { selectIsEmbeddedMode } from "library/sagas/ongoing/global-data/selector";

import { macAddressBannerExists } from "library/utils/formatter";

const SplitTabView = ({
  showTwoScreens,
  isFormSubmitting,
  screenOne,
  screenTwo,
  subHeaderExists,
  isAutoFill,
  setYOffSetScroll,
  hasStickyHeader,
  scrollEnabled,
  autoScreenScroll,
  ScrollYCoordinate,
  screenToScroll,
  tabHeaderExists,
  removePaddingHorizontal = false,
  customSecondScreenStyle = {},
}) => {
  const { isDesktop, isTablet } = useContext(DeviceContext);
  const screenOneRef = useRef();
  const screenTwoRef = useRef();
  const screenOneChildren = screenOne.props.children;
  const screenTwoChildren = screenTwo.props.children;

  useEffect(() => {
    if (ScrollYCoordinate && autoScreenScroll) {
      if (screenToScroll === 1) {
        screenOneRef.current.scrollTo({
          x: 0,
          y: ScrollYCoordinate,
          animated: true,
        });
      } else if (screenToScroll === 2) {
        screenTwoRef.current.scrollTo({
          x: 0,
          y: ScrollYCoordinate,
          animated: true,
        });
      }
    }
  }, [autoScreenScroll]);

  return (
    <View style={tw("flex flex-row h-full")} fsClass="fs-unmask">
      <View
        style={[
          showTwoScreens ? tw("p-0 w-0 h-0 opacity-0") : tw("w-full"),
          { height: "93%" },
        ]}
      >
        {subHeaderExists &&
          screenOneChildren?.length > 1 &&
          React.cloneElement(screenOne.props.children[0], {
            scrollViewRef: screenOneRef,
            key: "subheader",
          })}
        <ScrollView
          stickyHeaderIndices={hasStickyHeader ? [1] : null}
          contentContainerStyle={{
            opacity: isFormSubmitting ? 0.25 : 1,
            paddingHorizontal: removePaddingHorizontal
              ? 0
              : isDesktop
              ? 30
              : 10,
          }}
          style={[
            showTwoScreens ? tw("p-0 w-0 h-0 opacity-0") : tw("w-full"),
            tabHeaderExists && { maxHeight: `83%` },
          ]}
          ref={screenOneRef}
          keyboardShouldPersistTaps={isAutoFill ? "always" : "handled"}
          onScroll={(e) => {
            isAutoFill && setYOffSetScroll(e.nativeEvent.contentOffset.y);
          }}
          scrollEnabled={scrollEnabled}
        >
          {screenOneChildren?.length > 3
            ? [
                React.cloneElement(screenOne.props.children[1], {
                  scrollViewRef: screenOneRef,
                  key: "contentHeader",
                }),
                React.cloneElement(screenOne.props.children[2], {
                  scrollViewRef: screenOneRef,
                  key: "stickycontentHeader",
                }),
                React.cloneElement(screenOne.props.children[3], {
                  scrollViewRef: screenOneRef,
                  key: "content",
                }),
              ]
            : screenOneChildren?.length > 2
            ? [
                React.cloneElement(screenOne.props.children[1], {
                  scrollViewRef: screenOneRef,
                  key: "contentHeader",
                }),
                React.cloneElement(screenOne.props.children[2], {
                  scrollViewRef: screenOneRef,
                  key: "content",
                }),
              ]
            : screenOneChildren?.length > 1
            ? [
                React.cloneElement(screenOne.props.children[1], {
                  scrollViewRef: screenOneRef,
                  key: "content",
                }),
              ]
            : React.cloneElement(screenOne, {
                scrollViewRef: screenOneRef,
              })}
        </ScrollView>
      </View>
      <View
        style={
          showTwoScreens
            ? [tw("w-full"), { height: "96%" }]
            : tw("p-0 w-0 h-0 opacity-0")
        }
      >
        {subHeaderExists &&
          screenTwoChildren?.length > 1 &&
          React.cloneElement(screenTwo.props.children[0], {
            scrollViewRef: screenTwoRef,
            key: "subheader",
          })}
        <ScrollView
          contentContainerStyle={{
            opacity: isFormSubmitting ? 0.25 : 1,
            padding: isDesktop ? 30 : isTablet ? 0 : 10,
            ...customSecondScreenStyle,
          }}
          style={[
            {
              backgroundColor: backgroundColors.sideCar,
            },
            showTwoScreens ? tw("w-full") : tw("p-0 w-0 h-0 opacity-0"),
            tabHeaderExists && { maxHeight: `83%` },
          ]}
          ref={screenTwoRef}
          keyboardShouldPersistTaps={isAutoFill ? "always" : "handled"}
        >
          <View style={{ backgroundColor: colors.secondary }}>
            {screenTwoChildren?.length > 1
              ? React.cloneElement(screenTwo.props.children[1], {
                  scrollViewRef: screenTwoRef,
                  key: "content",
                })
              : React.cloneElement(screenTwo, {
                  scrollViewRef: screenTwoRef,
                })}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const SplitDesktopView = ({
  showTwoScreens,
  isFormSubmitting,
  screenOne,
  screenTwo,
  proxyHeaderExists,
  suspendedHeaderExists,
  isAutoFill,
  setYOffSetScroll,
  hasStickyHeader,
  tabHeaderExists,
  sessionBannerExists = false,
  screenHeaderExists,
  autoScreenScroll,
  ScrollYCoordinate,
  screenToScroll,
  removePaddingHorizontal = false,
  customDefaultPrimaryWidth,
  customMaxWidthForPrimary,
  customSecondScreenStyle = {},
}) => {
  const { isDesktop } = useContext(DeviceContext);
  const isEmbedded = useSelector(selectIsEmbeddedMode);
  const screenOneRef = useRef();
  const screenTwoRef = useRef();
  const screenHeaderHeight = screenHeaderExists ? 70 : 0;
  const headerHeight =
    theme?.Header?.containerStyle?.height + screenHeaderHeight;
  const screenOneChildren = screenOne.props.children;
  const screenTwoChildren = screenTwo.props.children;
  const { width: windowWidth } = Dimensions.get("window");
  const sessionBannerHeight = sessionBannerExists ? 50 : 0;
  const macAddressBannerHeight = macAddressBannerExists() ? 50 : 0;

  const minWidthForPrimary = windowWidth * 0.3;
  const maxWidthForPrimary = customMaxWidthForPrimary || windowWidth * 0.5;

  useEffect(() => {
    if (autoScreenScroll) {
      if (screenToScroll === 1) {
        screenOneRef.current.scrollTo({
          x: 0,
          y: ScrollYCoordinate,
          animated: true,
        });
      } else if (screenToScroll === 2) {
        screenTwoRef.current.scrollTo({
          x: 0,
          y: ScrollYCoordinate,
          animated: true,
        });
      }
    }
  }, [autoScreenScroll]);

  return (
    <View style={[tw("flex flex-row h-full")]}>
      <SplitPane
        style={[
          tw("flex flex-row h-full"),
          isEmbedded ? { width: "100%" } : {},
        ]}
        separatorStyle={{ width: isDesktop ? 5 : 10, cursor: "col-resize" }}
        split="h"
        primary={"second"}
        min={minWidthForPrimary}
        max={maxWidthForPrimary}
        defaultValue={
          showTwoScreens ? customDefaultPrimaryWidth || "40vw" : "0%"
        }
        onChange={() => {}}
      >
        <View
          style={{
            overflow: "hidden",
            paddingRight: 10,
          }}
        >
          <View
            style={[
              isDesktop && {
                maxHeight:
                  proxyHeaderExists || suspendedHeaderExists
                    ? tabHeaderExists
                      ? `calc(100vh - ${headerHeight}px - 200px - ${sessionBannerHeight}px - ${macAddressBannerHeight}px)`
                      : `calc(100vh - ${headerHeight}px - 50px - ${sessionBannerHeight}px - ${macAddressBannerHeight}px)`
                    : tabHeaderExists
                    ? `calc(100vh - ${headerHeight}px - 150px - ${sessionBannerHeight}px - ${macAddressBannerHeight}px)`
                    : `calc(100vh - ${headerHeight}px - ${sessionBannerHeight}px - ${macAddressBannerHeight}px)`,
                marginRight: 5,
              },
              showTwoScreens
                ? isDesktop
                  ? tw("w-full")
                  : tw("p-0 w-0 h-0 opacity-0")
                : tw("w-full"),
              {
                opacity: isFormSubmitting ? 0.25 : 1,
                paddingHorizontal: removePaddingHorizontal
                  ? 0
                  : isDesktop
                  ? 20
                  : 10,
                shadowColor: backgroundColors.black,
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.4,
                shadowRadius: 7,
                elevation: 3,
              },
            ]}
          >
            {screenOneChildren?.length > 1 &&
              React.cloneElement(screenOne.props.children[1], {
                scrollViewRef: screenOneRef,
                key: "header",
              })}

            {screenOneChildren?.length > 3 &&
              hasStickyHeader &&
              React.cloneElement(screenOne.props.children[2], {
                scrollViewRef: screenOneRef,
                key: "stickyContentHeader",
              })}

            <ScrollView
              style={{ paddingHorizontal: 15 }}
              ref={screenOneRef}
              onScroll={(e) => {
                isAutoFill && setYOffSetScroll(e.nativeEvent.contentOffset.y);
              }}
            >
              {screenOneChildren?.length > 3
                ? React.cloneElement(screenOne.props.children[3], {
                    scrollViewRef: screenOneRef,
                    key: "content",
                  })
                : screenOneChildren?.length > 2
                ? React.cloneElement(screenOne.props.children[2], {
                    scrollViewRef: screenOneRef,
                    key: "content",
                  })
                : React.cloneElement(screenOne, {
                    scrollViewRef: screenOneRef,
                  })}
            </ScrollView>
          </View>
        </View>
        <ScrollView
          contentContainerStyle={{
            opacity: isFormSubmitting ? 0.25 : 1,
            padding: isDesktop ? 30 : 10,
            ...customSecondScreenStyle,
          }}
          style={[
            isDesktop &&
              tabHeaderExists && {
                maxHeight:
                  proxyHeaderExists || suspendedHeaderExists
                    ? `calc(100vh - 265px - ${macAddressBannerHeight}px)`
                    : `calc(100vh - 215px - ${macAddressBannerHeight}px)`,
              },
            {
              backgroundColor: backgroundColors.sideCar,
            },
            showTwoScreens ? tw("w-full") : tw("p-0 w-0 h-0 opacity-0"),
          ]}
          ref={screenTwoRef}
        >
          <View style={{ backgroundColor: colors.secondary }}>
            {screenTwoChildren?.length > 1
              ? React.cloneElement(screenTwo.props.children[1], {
                  scrollViewRef: screenTwoRef,
                  key: "sidecar",
                })
              : screenTwoChildren?.length === 1
              ? React.cloneElement(screenTwo.props.children[0], {
                  scrollViewRef: screenTwoRef,
                  key: "sidecar",
                })
              : React.cloneElement(screenTwo, {
                  scrollViewRef: screenTwoRef,
                  key: "sidecar",
                })}
          </View>
        </ScrollView>
      </SplitPane>
    </View>
  );
};

const SplitScreen = ({
  showTwoScreens = false,
  isFormSubmitting = false,
  children: [screenOne, screenTwo],
  subHeaderExists = false,
  proxyHeaderExists = false,
  isAutoFill,
  setYOffSetScroll,
  hasStickyHeader = false,
  scrollEnabled = true,
  autoScreenScroll = false,
  ScrollYCoordinate = 0,
  screenToScroll = 1,
  tabHeaderExists = false,
  sessionBannerExists = false,
  screenHeaderExists = false,
  removePaddingHorizontal = false,
  defaultPrimaryWidth,
  maxWidthForPrimary,
  customSecondScreenStyle,
}) => {
  const { isTablet, isMobile } = useContext(DeviceContext);
  const { suspendedMembers = [] } = useContext(UserProfileContext);

  return isTablet || isMobile ? (
    <SplitTabView
      showTwoScreens={showTwoScreens}
      isFormSubmitting={isFormSubmitting}
      screenOne={screenOne}
      screenTwo={screenTwo}
      subHeaderExists={subHeaderExists}
      isAutoFill={isAutoFill}
      setYOffSetScroll={setYOffSetScroll}
      hasStickyHeader={hasStickyHeader}
      scrollEnabled={scrollEnabled}
      autoScreenScroll={autoScreenScroll}
      ScrollYCoordinate={ScrollYCoordinate}
      screenToScroll={screenToScroll}
      tabHeaderExists={tabHeaderExists}
      removePaddingHorizontal={removePaddingHorizontal}
      customSecondScreenStyle={customSecondScreenStyle}
    />
  ) : (
    <SplitDesktopView
      showTwoScreens={showTwoScreens}
      isFormSubmitting={isFormSubmitting}
      screenOne={screenOne}
      screenTwo={screenTwo}
      proxyHeaderExists={proxyHeaderExists}
      suspendedHeaderExists={suspendedMembers.length > 0}
      isAutoFill={isAutoFill}
      setYOffSetScroll={setYOffSetScroll}
      hasStickyHeader={hasStickyHeader}
      tabHeaderExists={tabHeaderExists}
      sessionBannerExists={sessionBannerExists}
      screenHeaderExists={screenHeaderExists}
      autoScreenScroll={autoScreenScroll}
      screenToScroll={screenToScroll}
      ScrollYCoordinate={ScrollYCoordinate}
      removePaddingHorizontal={removePaddingHorizontal}
      customDefaultPrimaryWidth={defaultPrimaryWidth}
      customMaxWidthForPrimary={maxWidthForPrimary}
      customSecondScreenStyle={customSecondScreenStyle}
    />
  );
};

const mapStateToProps = (state = {}) => {
  return {
    isAutoFill: state.mhq.ongoing.global.isAutoFill,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setYOffSetScroll }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SplitScreen);
