// Constants for filter values to remove
const FILTERS_TO_REMOVE = {
  designerFilters: ["designed", "assignedOrders", "unassignedOrders"], // These should be removed for "showDesignerFilters"
  followUpFilters: ["flaggedOrders"], // These should be removed for "showFollowUpFilters"
};

export const updateFilters = ({
  filterOptions,
  showDesignerFilters,
  showFollowUpFilters,
}) => {
  let coreActionsList = { ...filterOptions }; // Create a shallow copy of filterOptions

  const applyFilters = (filterList, filtersToExclude) => {
    return filterList.filter((item) => !filtersToExclude.includes(item.value));
  };

  if (!showDesignerFilters || !showFollowUpFilters) {
    Object.keys(coreActionsList).forEach((key) => {
      let updatedArray = [...coreActionsList[key]]; // Shallow copy to avoid mutation

      // Apply the designer filters if needed
      if (!showDesignerFilters && key === "Order Status") {
        updatedArray = applyFilters(
          updatedArray,
          FILTERS_TO_REMOVE.designerFilters
        );
      }

      // Apply the follow-up filters if needed
      if (!showFollowUpFilters && key === "Individual Filters") {
        updatedArray = applyFilters(
          updatedArray,
          FILTERS_TO_REMOVE.followUpFilters
        );
      }

      // Update the coreActionsList with the filtered result
      coreActionsList[key] = updatedArray;
    });
  }

  return coreActionsList;
};
