export const getUIConfig = (apiData) => {
  const initialValues = {
    memberCodes: [],
    senderType: "",
    startDate: "",
    startTime: "",
    messageTitle: "",
    messageBody: "<div><br></div>",
    url: "",
    entitlements: [],
    fileName: "",
    ...apiData,
  };
  return initialValues;
};

export const notificationSenderTypes = [
  {
    label: "Alert",
    value: "Alert",
  },
  {
    label: "Did You Know",
    value: "Did_You_Know",
  },
  {
    label: "Marketing",
    value: "Marketing",
  },
  {
    label: "Merchandising",
    value: "Merchandising",
  },
  {
    label: "Other",
    value: "Other",
  },
  {
    label: "Quick Tips",
    value: "Quick_Tips",
  },
  {
    label: "Release Notes",
    value: "Release_Notes",
  },
];
