import { createSlice } from "@reduxjs/toolkit";

import { SLICE_NAME as sliceName } from "./constants";
import { SLICE_NAME as parentSlice } from "../constants";

import { Entitlements } from "library/utils/entitlements";

import get from "lodash/get";
import some from "lodash/some";

export const initialState = {
  activeTab: "",
  tabs: [
    { key: "reports-tab", title: "Reports" },
    { key: "score-card", title: "Scorecard" },
  ],
  shopCode: "",
  status: { ratingLoading: false, commentsLoading: false },
  toast: null,
};

const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setInit: (state, action) => {
      state.tabs = initialState.tabs;

      const permissions = get(action, "payload.permissions", {});
      const allPermissions = Object.values(permissions);
      const selectedShopPermissions = get(permissions, state.shopCode, {});

      if (
        (state.shopCode &&
          !(Entitlements.REPORTS_TAB in selectedShopPermissions)) ||
        !some(allPermissions, Entitlements.REPORTS_TAB)
      ) {
        state.tabs = state.tabs.filter((tab) => tab.key !== "reports-tab");
      }

      if (
        (state.shopCode &&
          !(Entitlements.SCORECARD in selectedShopPermissions)) ||
        !some(allPermissions, Entitlements.SCORECARD)
      ) {
        state.tabs = state.tabs.filter((tab) => tab.key !== "score-card");
      }

      if (!state.tabs.find((e) => e.key === state.activeTab)) {
        state.activeTab = state.tabs[0].key;
      }
    },
    setReset: (state) => {
      Object.assign(state, initialState);
    },
    setStatus: (state, action) => {
      state.status = { ...state.status, ...action.payload };
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setShopCode: (state, action) => {
      state.shopCode = action.payload;
    },
    setToast: (state, action) => {
      state.toast = action.payload;
    },
  },
});

export const {
  setInit,
  setReset,
  setStatus,
  setActiveTab,
  setShopCode,
  setToast,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
