import React from "react";
import { PopupMenu, Tooltip } from "components/elements";
import { Image } from "react-native-elements";
import I18NContext from "library/contexts/i18N";
import { IMAGES } from "static/assets/images";
import tw from "tailwind-rn";
import { useNavigation, CommonActions } from "@react-navigation/native";

export const CreateMenu = ({
  menuOptions,
  helpText = "",
  dataParams = {},
  imageSize = 20,
}) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const navigation = useNavigation();

  const handleOnSelect = (key) => {
    const {
      route: { name, params },
    } = menuOptions.find((e) => e.key === key);

    navigation.dispatch(
      CommonActions.navigate({
        name,
        params: { ...params, ...dataParams },
      })
    );
  };

  return (
    <PopupMenu
      containerStyle={tw("mx-3 items-end")}
      optionsContainerStyle={tw("p-1 m-8")}
      menuOptions={menuOptions}
      onSelect={handleOnSelect}
      testID="create_menu"
      accessibilityLabel="create_menu"
    >
      <Tooltip text={Localise(messages, helpText)} renderForWebOnly={true}>
        <Image
          style={{
            width: imageSize,
            height: imageSize,
          }}
          resizeMode="contain"
          source={IMAGES[`create-order`]}
        />
      </Tooltip>
    </PopupMenu>
  );
};
