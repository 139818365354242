import React, { useState } from "react";
import { useSelector } from "react-redux";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { FieldArray, useFormikContext } from "formik";
import {
  Picker,
  DraggableFlatList as DraggableFlatListForDesktop,
  Tooltip,
} from "components/elements";
import DraggableFlatList from "react-native-draggable-flatlist";
import { Currency, LabelField } from "components/wrappers";
import {
  selectAllAddons,
  selectShopCode,
  selectAllCollections,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import startCase from "lodash/startCase";
import { colors, fonts, backgroundColors } from "styles/theme";
import IMAGES from "static/assets/images";
import tw from "tailwind-rn";

const SuggestedAddons = () => {
  const allAddons = useSelector(selectAllAddons);
  const { isMobile, isDesktop } = React.useContext(DeviceContext);
  const [scrollEnable, setScrollEnable] = useState(true);
  const collectionsData = useSelector(selectAllCollections);
  const addonsCollection = collectionsData?.find(
    (collection) => collection.handle === "addons"
  );
  const { manualSortProductOrder = [] } = addonsCollection || {};
  const {
    values: {
      addons = [],
      personalTouchAddons: personalAddons = [],
      isAddonSorted = false,
    },
    setFieldValue,
  } = useFormikContext();

  const getSelectedAddons = (isPersonal) => {
    const selectedAddons = isPersonal ? personalAddons : addons;
    return !isAddonSorted
      ? selectedAddons.sort(
          (a, b) =>
            manualSortProductOrder.indexOf(a) -
            manualSortProductOrder.indexOf(b)
        )
      : selectedAddons;
  };
  const getPersonalTouchAddons = (isPersonalTouch) => {
    return getSelectedAddons(isPersonalTouch).filter((val) =>
      allAddons.some((addon) => {
        if (addon["handle"] === val) {
          return isPersonalTouch === addon.isPersonalTouchAddon;
        }
      })
    );
  };

  const personalTouchAddons = getPersonalTouchAddons(true);
  const standardAddons = getSelectedAddons(false);

  return (
    <View
      style={tw(
        `flex ${isMobile ? "flex-col" : "flex-row items-start justify-between"}`
      )}
    >
      {["standardAddons", "personalTouchAddons"].map((addonType, index) => {
        return (
          <View
            style={[
              tw(`flex ${isMobile ? "flex-col" : "flex-row"} `),
              { maxWidth: 400, width: "100%" },
            ]}
            key={index}
          >
            <FieldArray
              name={
                addonType === "personalTouchAddons"
                  ? "personalTouchAddons"
                  : "addons"
              }
              render={(arrayHelpers) => {
                const remainingAddons = allAddons.reduce(
                  (acc, cur) => {
                    const { addonSubType = "" } = cur;
                    const { standardAddons = [], personalTouchAddons = [] } =
                      acc;
                    if (addonSubType === "personal-touch-addon") {
                      personalTouchAddons.push(cur);
                    } else {
                      standardAddons.push(cur);
                      if (addonSubType === "standard-and-personal-touch") {
                        personalTouchAddons.push(cur);
                      }
                    }
                    return acc;
                  },
                  {
                    standardAddons: [],
                    personalTouchAddons: [],
                  }
                );
                const getRemainingAddons = (type) => {
                  const addons =
                    type === "standardAddons"
                      ? standardAddons
                      : personalTouchAddons;
                  const property =
                    type === "standardAddons" ? "productId" : "handle";
                  return remainingAddons[type]?.filter(
                    (addon) => !addons?.find((e) => e === addon[property])
                  );
                };

                const DraggableComponent = isDesktop
                  ? DraggableFlatListForDesktop
                  : DraggableFlatList;
                const isAddonExists =
                  addonType === "personalTouchAddons"
                    ? personalAddons.length > 0
                    : addons.length > 0;

                const data =
                  addonType === "personalTouchAddons"
                    ? personalTouchAddons
                    : standardAddons;

                return (
                  <>
                    <View
                      key={index}
                      style={[
                        { maxWidth: 400, width: "100%" },
                        tw(
                          `flex ${
                            isMobile ? "flex-col" : "flex-row items-start"
                          }  justify-between`
                        ),
                      ]}
                    >
                      <View
                        style={[
                          tw("flex flex-col"),
                          { maxWidth: 400, width: "100%" },
                        ]}
                        key={index}
                      >
                        <LabelField
                          text={
                            addonType === "standardAddons"
                              ? `Standard Add-ons`
                              : `Personal Touch Add-ons`
                          }
                          style={{
                            ...fonts.heading4,
                            marginVertical: 10,
                            marginLeft: 7,
                            marginRight: 7,
                            paddingBottom: 0,
                          }}
                          numberOfLines={2}
                        />
                        {getRemainingAddons(addonType).length > 0 ? (
                          <View
                            style={[
                              tw("flex flex-col items-center justify-center"),
                              {
                                width: "100%",
                                height: 93,
                              },
                            ]}
                          >
                            <Picker
                              placeholder={{
                                label: "Select Item",
                              }}
                              containerStyle={{
                                width: "100%",
                                height: "auto",
                                paddingHorizontal: 0,
                                paddingBottom: 0,
                              }}
                              items={getRemainingAddons(addonType).map(
                                (e, index) => {
                                  const property =
                                    addonType === "personalTouchAddons"
                                      ? "handle"
                                      : "productId";

                                  return {
                                    label: e.name,
                                    value: e[property],
                                    key: `${e[property]}-${index}`,
                                  };
                                }
                              )}
                              name={"newAddon"}
                              value={""}
                              onValueChange={(val) => {
                                if (val) {
                                  arrayHelpers.push(val);
                                }
                              }}
                            />
                          </View>
                        ) : getSelectedAddons(
                            addonType === "personalTouchAddons" ? true : false
                          )?.length == 0 ? (
                          <View>
                            <Text>No Add-ons available at this moment</Text>
                          </View>
                        ) : null}
                        {isAddonExists ? (
                          <DraggableComponent
                            containerStyle={{
                              flex: 1,
                              height:
                                !isDesktop && data.length > 3 ? 400 : "100%",
                            }}
                            data={data}
                            onDragEnd={(prop) => {
                              const data = isDesktop ? prop : prop.data;
                              const fieldName =
                                addonType === "personalTouchAddons"
                                  ? "personalTouchAddons"
                                  : "addons";

                              setFieldValue(fieldName, data);
                              setFieldValue("isAddonSorted", true);
                              setScrollEnable(true);
                            }}
                            keyExtractor={(item) => item}
                            onDragBegin={() => {
                              setScrollEnable(false);
                            }}
                            autoscrollThreshold={100}
                            dragDropIcon={true}
                            activationDistance={scrollEnable ? 10 : 1}
                            renderItem={(data, i) => {
                              const addon = isDesktop ? data : data.item;
                              const index = isDesktop ? i : data.index;
                              const property =
                                addonType === "personalTouchAddons"
                                  ? "handle"
                                  : "productId";
                              const addonData = allAddons.find(
                                (e) => e[property] === addon
                              );
                              return (
                                <View style={tw("flex flex-row items-center")}>
                                  {!isDesktop && (
                                    <View
                                      style={tw(
                                        "flex flex-col items-center justify-between"
                                      )}
                                    >
                                      <Image
                                        style={{ width: 18, height: 10 }}
                                        source={IMAGES["drag-drop-icon"]}
                                      />
                                      <View
                                        style={{
                                          backgroundColor:
                                            backgroundColors.primary,
                                          width: 15,
                                          height: 15,
                                          borderRadius: 15,
                                          margin: 5,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            color: "#fff",
                                            textAlign: "center",
                                            fontWeight: "700",
                                            fontSize: 10,
                                          }}
                                        >
                                          {index + 1}
                                        </Text>
                                      </View>
                                    </View>
                                  )}
                                  <Addon
                                    key={index}
                                    index={index}
                                    value={addonData}
                                    data={
                                      addonType === "standardAddons"
                                        ? standardAddons
                                        : personalTouchAddons
                                    }
                                    standardAddons={standardAddons}
                                    personalTouchAddons={personalTouchAddons}
                                    addonType={addonType}
                                    arrayHelpers={arrayHelpers}
                                    drag={!isDesktop && data.drag}
                                  />
                                </View>
                              );
                            }}
                          />
                        ) : null}
                      </View>
                    </View>
                  </>
                );
              }}
            />
          </View>
        );
      })}
    </View>
  );
};

const Addon = ({ value, index, arrayHelpers, drag }) => {
  const shopCode = useSelector(selectShopCode);
  const currency = Currency(shopCode);
  const { isDesktop } = React.useContext(DeviceContext);
  const { name, image, price, status, productId } = value;
  const { Localise, messages } = React.useContext(I18NContext);

  return (
    <TouchableOpacity
      style={[
        tw(`flex flex-col mb-3`),
        {
          borderWidth: 1,
          borderColor: colors.light,
          width: isDesktop ? 350 : "100%",
          cursor: "grab",
        },
      ]}
      onLongPress={drag && drag}
      disabled={isDesktop && true}
    >
      <View
        key={index}
        style={tw("flex flex-row items-center justify-between p-2 w-full")}
      >
        <View style={[tw("flex flex-col w-1/2")]}>
          <Text
            style={[
              fonts.heading4,
              { marginLeft: 10, maxWidth: !isDesktop ? 150 : 250 },
            ]}
          >
            {Localise(messages, name)}
          </Text>
          <Text style={[fonts.style2, { marginLeft: 10 }]}>
            {Localise(messages, `Price : $${price} ${currency}`)}
          </Text>
          <Text style={[fonts.style2, { marginLeft: 10 }]}>
            {Localise(messages, `ID : ${productId}`)}
          </Text>
          <Text style={[fonts.style2, { marginLeft: 10 }]}>
            {Localise(messages, `Status : ${startCase(status)}`)}
          </Text>
        </View>

        <View style={tw("flex flex-row items-start mr-4")}>
          <Image
            style={{
              width: 75,
              height: 75,
              borderWidth: 1,
              borderColor: colors.light,
            }}
            resizeMode="cover"
            source={{ uri: image }}
          />
          <TouchableOpacity
            onPress={() => arrayHelpers.remove(index)}
            testID={"Remove Add-on"}
            style={tw("pl-1")}
          >
            <Tooltip
              text={Localise(messages, "Remove")}
              renderForWebOnly={true}
            >
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode="cover"
                source={IMAGES["close"]}
              />
            </Tooltip>
          </TouchableOpacity>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default SuggestedAddons;
