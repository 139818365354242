import React from "react";
import { useSelector } from "react-redux";
import { ScrollView, View, Dimensions } from "react-native";

import ScreenHeader from "./header";
import SplitPane from "components/elements/split-pane";

import { setYOffSetScroll } from "library/sagas/ongoing/global-data/slice";
import {
  selectIsAutoFill,
  selectIsEmbeddedMode,
} from "library/sagas/ongoing/global-data/selector";
import { selectActiveTab } from "library/sagas/views/home/drawer/shop-settings/common/selector";

import { theme, colors, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";
import { macAddressBannerExists } from "library/utils/formatter";

const Desktop = ({
  ScreenOne,
  ScreenTwo,
  CustomHeader,
  headerProps,
  hasBanner,
  screenOneRef,
  screenTwoRef,
  showSecondScreen,
}) => {
  const isAutoFill = useSelector(selectIsAutoFill);
  const currentTab = useSelector(selectActiveTab);
  const isEmbedded = useSelector(selectIsEmbeddedMode);

  const headerHeight = theme?.Header?.containerStyle?.height;
  const { width: windowWidth } = Dimensions.get("window");

  const minWidthForPrimary = windowWidth * 0.3;
  const maxWidthForPrimary = windowWidth * 0.5;
  const macAddressBannerHeight = macAddressBannerExists() ? 50 : 0;

  return (
    <SplitPane
      style={[tw("flex flex-row h-full"), isEmbedded ? { width: "100%" } : {}]}
      separatorStyle={{ width: 5, cursor: "col-resize" }}
      split="h"
      primary={"second"}
      min={minWidthForPrimary}
      max={maxWidthForPrimary}
      defaultValue={showSecondScreen ? `40vw` : "0%"}
      onChange={() => {}}
    >
      <View style={{ overflow: "hidden", paddingRight: 10 }}>
        <View
          style={[
            {
              maxHeight: hasBanner
                ? `calc(100vh - ${headerHeight}px - 50px - ${macAddressBannerHeight}px)`
                : `calc(100vh - ${headerHeight}px - ${macAddressBannerHeight}px)`,
              marginRight: 5,
            },
            tw("w-full"),
            {
              paddingHorizontal: currentTab === "notifications" ? 0 : 20,
              paddingBottom: 50,
              shadowColor: backgroundColors.black,
              shadowOffset: { width: 0, height: 2 },
              shadowOpacity: 0.4,
              shadowRadius: 7,
              elevation: 3,
            },
          ]}
        >
          {CustomHeader ? <CustomHeader /> : <ScreenHeader {...headerProps} />}
          <ScrollView
            style={{
              paddingHorizontal: currentTab === "notifications" ? 0 : 15,
            }}
            ref={screenOneRef}
            onScroll={(e) => {
              isAutoFill && setYOffSetScroll(e.nativeEvent.contentOffset.y);
            }}
          >
            {ScreenOne}
          </ScrollView>
        </View>
      </View>
      <ScrollView
        contentContainerStyle={{
          padding: isEmbedded ? 0 : 30,
          paddingBottom: isEmbedded ? 0 : 50,
        }}
        style={[
          {
            overflowY: "auto",
            height: `calc(100vh - ${headerHeight}px)`,
            backgroundColor: backgroundColors.sideCar,
          },
          showSecondScreen ? tw("w-full") : tw("p-0 w-0 h-0 opacity-0"),
        ]}
        ref={screenTwoRef}
      >
        {showSecondScreen && (
          <View style={{ backgroundColor: colors.secondary }}>{ScreenTwo}</View>
        )}
      </ScrollView>
    </SplitPane>
  );
};

export default Desktop;
