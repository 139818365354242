import * as Yup from "yup";
import moment from "moment";

const startYear = 2022; //Keeping 2022 as starting Year.

export const months = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];

export const initialValues = () => {
  return {
    allShopCodes: [],
    selectedMemberCodes: [],
    startDate: "",
    endDate: "",
    comparisonStartDate: "",
    comparisonEndDate: "",
    reportType: "",
    rangeType: "",
    minDate: moment("2021-08-01", "YYYY-MM-DD").toDate(),
    maxDate: moment().subtract(1, "days").toDate(),
    month: "",
    year: "",
    detailOrSummaryReport: "",
    businessAnalysisType: "",
    printBy: "",
    filterBy: "",
    occasions: [],
    selectedOccasions: [],
    reportFormat: "",
  };
};

export const getEligibleMonths = () => {
  const eligibleMonths = months.map((monthName, index) => {
    return { label: monthName, value: index };
  });
  return eligibleMonths;
};

export const getYears = (currentYear) => {
  let years = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push({ label: year.toString(), value: year.toString() });
  }
  return years;
};

export const rangeTypes = [
  { label: "Sale Date", value: "orderDate" },
  { label: "Delivery Date", value: "deliveryDate" },
];
export const salesByPromoReportTypes = [
  { label: "Detail Report", value: "detail_report" },
  { label: "Summary Report", value: "summary_report" },
];
export const businessSnapshotReportTypes = [
  { label: "Sales Analysis", value: "sales_analysis" },
  { label: "Delivery Analysis", value: "delivery_analysis" },
  { label: "Product Analysis", value: "product_analysis" },
  { label: "Wire Analysis", value: "wire_analysis" },
];
export const filterTypes = [
  { label: "Order Date", value: "orderDate" },
  { label: "Delivery Date", value: "deliveryDate" },
];

export const printBy = [
  { label: "Accrual Basis", value: "Accrual" },
  { label: "Cash Basis", value: "Cash" },
];

export const supportedFileFormats = [
  { label: "CSV", value: "csv" },
  { label: "Excel", value: "xlsx" },
];

Yup.addMethod(Yup.string, "invalidStartDate", function (errorMessage) {
  return this.test(`invalid-start-date`, errorMessage, function (value) {
    const { path, createError } = this;
    const { startDate, endDate } = this.parent;

    if (!startDate || !endDate) return true;

    return (
      moment(startDate).isSameOrBefore(moment(endDate)) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(
  Yup.string,
  "invalidNoOfDays",
  function (errorMessage, salesTaxErrorMessage) {
    return this.test(`invalid-no-of-days`, errorMessage, function (value) {
      const { path, createError } = this;
      const { startDate, endDate, reportType } = this.parent;
      if (!startDate || !endDate) return true;
      if (reportType === "occasion-reminder") return true;

      let minDays = 31;
      let message = errorMessage;
      if (
        reportType === "sales-tax-report" ||
        reportType === "product-comparison-report"
      ) {
        minDays = 365;
        message = salesTaxErrorMessage;
      }

      const startDateObj = moment(startDate, "YYYY-MM-DD");
      const endDateObj = moment(endDate, "YYYY-MM-DD");

      //Difference in number of days
      const noOfDays = moment.duration(endDateObj.diff(startDateObj)).asDays();

      return noOfDays < minDays || createError({ path, message: message });
    });
  }
);

Yup.addMethod(Yup.string, "invalidNoOfComparisonDays", function (errorMessage) {
  return this.test(
    `invalid-no-of-comparison-days`,
    errorMessage,
    function (value) {
      const { path, createError } = this;
      const { comparisonStartDate, comparisonEndDate } = this.parent;
      if (!comparisonStartDate || !comparisonEndDate) return true;

      let minDays = 365;
      let message = errorMessage;

      const startDateObj = moment(comparisonStartDate, "YYYY-MM-DD");
      const endDateObj = moment(comparisonEndDate, "YYYY-MM-DD");

      //Difference in number of days
      const noOfDays = moment.duration(endDateObj.diff(startDateObj)).asDays();

      return noOfDays < minDays || createError({ path, message: message });
    }
  );
});

Yup.addMethod(Yup.string, "invalidMonth", function (errorMessage) {
  return this.test("invalid-month", errorMessage, function (value) {
    const { path, createError } = this;
    const { month = "", year = "" } = this.parent;

    if (!month || !year) return true;

    const currentYear = Number(moment().format("YYYY"));
    const currentMonth = Number(moment().format("MM")) - 1;

    const selectedYear = Number(year);
    const selectedMonth = Number(month);

    let validMonth = true;
    if (
      (selectedYear === startYear && selectedMonth < 10) ||
      (selectedYear === currentYear && selectedMonth >= currentMonth)
    ) {
      validMonth = false;
    }

    return validMonth || createError({ path, message: errorMessage });
  });
});

export const validationSchema = (messages, Localise) => {
  return Yup.object().shape({
    selectedMemberCodes: Yup.string().when("allShopCodes", {
      is: (value) => value.length > 1,
      then: Yup.string().when("reportType", {
        is: (value) =>
          !["customer-report", "occasion-reminder"].includes(value),
        then: Yup.string().required(Localise(messages, "Select a Shop")),
      }),
    }),
    selectedOccasions: Yup.string().when("reportType", {
      is: (value) => ["occasion-reminder"].includes(value),
      then: Yup.string().required(Localise(messages, "Select an Occasion")),
    }),
    month: Yup.string().when("reportType", {
      is: (value) => ["house-account-statements"].includes(value),
      then: Yup.string()
        .required(Localise(messages, "Please select a Month"))
        .invalidMonth(Localise(messages, "No data available for this Month")),
    }),
    year: Yup.string().when("reportType", {
      is: (value) => ["house-account-statements"].includes(value),
      then: Yup.string().required(Localise(messages, "Please select a Year")),
    }),
    startDate: Yup.string().when("reportType", {
      is: (value) =>
        ![
          "customer-report",
          "house-account-statements",
          "aged-analysis-report",
        ].includes(value),
      then: Yup.string()
        .required(Localise(messages, "Please select start date"))
        .invalidStartDate(
          Localise(messages, "Make sure Start Date is before End Date")
        ),
    }),
    endDate: Yup.string().when("reportType", {
      is: (value) =>
        ![
          "customer-report",
          "house-account-statements",
          "aged-analysis-report",
        ].includes(value),
      then: Yup.string()
        .required(Localise(messages, "Please select end date"))
        .invalidNoOfDays(
          Localise(messages, "Please select a 31 day or less range"),
          Localise(messages, "Please select the date range with in an year")
        ),
    }),
    comparisonStartDate: Yup.string().when("reportType", {
      is: (value) => ["product-comparison-report"].includes(value),
      then: Yup.string()
        .required(Localise(messages, "Please select start date"))
        .invalidStartDate(
          Localise(messages, "Make sure Start Date is before End Date")
        ),
    }),
    comparisonEndDate: Yup.string().when("reportType", {
      is: (value) => ["product-comparison-report"].includes(value),
      then: Yup.string()
        .required(Localise(messages, "Please select end date"))
        .invalidNoOfComparisonDays(
          Localise(messages, "Please select the date range with in an year")
        ),
    }),
    reportType: Yup.string().required(
      Localise(messages, "Please select report type")
    ),
    rangeType: Yup.string().when("reportType", {
      is: (value) =>
        [
          "order-detail-report",
          "product-sales-report",
          "sales-summary-report",
          "product-comparison-report",
        ].includes(value),
      then: Yup.string().required(
        Localise(messages, "Please select range type")
      ),
    }),
    filterBy: Yup.string().when("reportType", {
      is: (value) => ["sales-tax-report"].includes(value),
      then: Yup.string().required(
        Localise(messages, "Please select filter by")
      ),
    }),
    printBy: Yup.string().when("reportType", {
      is: (value) => ["sales-tax-report"].includes(value),
      then: Yup.string().required(Localise(messages, "Please select print by")),
    }),
    detailOrSummaryReport: Yup.string().when("reportType", {
      is: (value) =>
        [
          "sales-by-promo-code",
          "sales-tax-report",
          "credit-card-settlement-report",
          "aged-analysis-report",
        ].includes(value),
      then: Yup.string().required(
        Localise(messages, "Please select detail or summary report")
      ),
    }),
    businessAnalysisType: Yup.string().when("reportType", {
      is: (value) => ["business-snapshot-report-by-type"].includes(value),
      then: Yup.string().required(
        Localise(messages, "Please select Analysis type")
      ),
    }),
    reportFormat: Yup.string().when("reportType", {
      is: (value) =>
        [
          "credit-card-settlement-report",
          "aged-analysis-report",
          "business-snapshot-report-by-type",
        ].includes(value),
      then: Yup.string().required(
        Localise(messages, "Please select report format")
      ),
    }),
  });
};
