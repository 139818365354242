import React from "react";
import { Image, Icon } from "react-native-elements";
import { View } from "react-native";
import LabelField from "../label-field";
import { Tooltip } from "components/elements";
import tw from "tailwind-rn";
import { fonts } from "styles/theme";

const defaultLabelStyles = {
  ...fonts.heading4,
  marginRight: 8,
  paddingLeft: 5,
  paddingBottom: 0,
};

const LabelWithHelpIcon = (props) => {
  const {
    labelText = "",
    tooltipText = "",
    tooltipHeight = 40,
    labelStyles = {},
    viewStyles = {},
    imageSize = 15,
    iconType = "help",
  } = props;
  return (
    <View
      style={[
        tw("flex flex-row items-center"),
        { marginBottom: 6 },
        viewStyles,
      ]}
    >
      <LabelField
        text={labelText}
        style={{ ...defaultLabelStyles, ...labelStyles }}
        numberOfLines={2}
      />
      {tooltipText?.length ? (
        <Tooltip text={tooltipText} height={tooltipHeight}>
          {iconType === "info" ? (
            <Icon
              name="info-outline"
              type="material"
              size={imageSize}
              color="blue"
              containerStyle={{ cursor: "pointer" }}
            />
          ) : (
            <Image
              style={{
                width: imageSize,
                height: imageSize,
              }}
              source={require("static/assets/help.png")}
            />
          )}
        </Tooltip>
      ) : null}
    </View>
  );
};

export default LabelWithHelpIcon;
