import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";
import { SLICE_NAME as parentSlice } from "../constants";

export const initialState = {
  memberCodes: [],
  entitlements: {},
  apiData: {},
  sideCar: {
    data: {},
  },
  status: "",
};

const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setInit: (state) => {
      state.status = "progress";
    },
    setMemberCodes: (state, action) => {
      const { memberCodes, response } = action.payload;
      state.memberCodes = memberCodes;
      state.entitlements = response;
      state.status = "done";
    },
    saveNotifications: () => {},
    fetchMemberCodes: () => {},
    fetchNotificationHistory: () => {},
    setSideCarData: (state, action) => {
      state.sideCar.data = action.payload;
    },
    setApiData: (state, action) => {
      state.apiData = action.payload;
    },
  },
});

export const {
  setMemberCodes,
  saveNotifications,
  fetchMemberCodes,
  setInit,
  fetchNotificationHistory,
  setApiData,
  setSideCarData,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
