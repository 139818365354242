import React, { useRef } from "react";
import { View, TouchableOpacity, Image, Platform } from "react-native";
import { Text } from "react-native-elements";
import { useFormikContext } from "formik";

import { colors } from "styles/theme";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import tw from "tailwind-rn";

import { Tooltip } from "components/elements";
import { Distance } from "components/elements/distance";
import I18NContext from "library/contexts/i18N";
import AddressVerificationIndicator from "./component/address-indicator";
import { generateDetailContent } from "../helper";
import Clipboard from "@react-native-community/clipboard";
import UserProfileContext from "library/contexts/userProfile";

import countryList from "static/data/countries.json";
import IMAGES from "static/assets/images";

import { phoneNumberFormatter } from "library/utils/formatter";
import ORDERS from "library/constants/orders";

const RecipientInfo = ({
  index,
  recordId,
  isSmallScreen,
  orderDetails,
  mapAccordionStatus,
  setMapAccordionStatus = () => {},
}) => {
  const { values } = useFormikContext();
  const isAddressCorrected = useRef(false);
  const isAddressModified = useRef(false);
  const recipientInfoPath = `orderItems.${index}.deliveryInfo.recipientInfo`;
  const formikRecipientInfo = get(values, recipientInfoPath, {});
  const { userProfile: { firstName: operator } = {} } =
    React.useContext(UserProfileContext);
  const {
    receivingMember: {
      latitude: fillerLatitude = "",
      longitude: fillerLongitude = "",
      country: fillerCountry = "",
    } = {},
    addressVerificationInfo: {
      isAvsPerformed = "",
      avsConfidence = "",
      isAvsSuggestedAddress = "",
    } = {},
    distanceInMiles,
    recipientInfo,
    messages = [],
  } = orderDetails;

  const {
    latitude: recipientLatitude = "",
    longitude: recipientLongitude = "",
    originalRecipientAddress,
  } = recipientInfo;

  isAddressCorrected.current =
    messages.filter(
      (e) =>
        e.messageType === "OLC" &&
        e.comments?.includes("Address has been reverted")
    ).length > 0;

  isAddressModified.current =
    messages.filter(
      (e) =>
        e.messageType === "OrderCommunication" &&
        e.comments.includes("Recipient Address updated")
    ).length > 0;

  const {
    firstName,
    lastName,
    phone,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    zip,
    locationType,
    locationName,
  } = formikRecipientInfo;

  const fillerLocation = {
    locations: [
      {
        latitude: parseFloat(fillerLatitude || 0),
        longitude: parseFloat(fillerLongitude || 0),
        isFillingFlorist: true,
      },
    ],
  };

  const isAvsVerified =
    isAvsPerformed === "Y" &&
    isAvsSuggestedAddress === "Y" &&
    avsConfidence === "HIGH";

  const isValidLocation =
    !isEmpty(fillerLocation) &&
    get(fillerLocation, "locations", []).length === 1 &&
    get(fillerLocation, "locations.0.latitude", "") === 0;

  const isShowMap =
    !!recordId &&
    isAvsVerified &&
    !isValidLocation &&
    !!recipientLatitude &&
    !!recipientLongitude;

  const deliveryDistance = isNil(distanceInMiles)
    ? ""
    : `${distanceInMiles} miles`;

  const addressInfo = addressLine1 && city && state && zip;

  const { messages: locMessages, Localise } = React.useContext(I18NContext);

  const hasRecipientInfo = firstName.length > 0 || lastName.length > 0;

  const recipientCountry =
    countryList.find((obj) => obj.value === country)?.label || country;

  const fillingCountry =
    countryList.find((obj) => obj.value === fillerCountry)?.label ||
    fillerCountry;

  const content = {
    Name: { label: "", value: `${firstName} ${lastName}` },
    AddressLine1: { label: "", value: addressLine1 },
    AddressLine2: { label: "", value: addressLine2 },
    CityStateZip: { label: "", value: `${city}, ${state} ${zip}` },
    Country: {
      label: "",
      value: recipientCountry === fillingCountry ? "" : recipientCountry,
    },
    Phone: {
      label: "",
      value: phoneNumberFormatter(phone),
    },
    LocationType: { label: "Type", value: locationType },
    LocationName: { label: "Name", value: locationName },
  };

  const copyContent = `${firstName} ${lastName}, ${addressLine1}, ${city}, ${state} ${zip}`;

  const verifiedMessage = originalRecipientAddress
    ? ORDERS.ADDRESS_COORECTED
    : isAddressModified.current
    ? ORDERS.ADDRESS_VERIFIED
    : isAddressCorrected.current
    ? `${ORDERS.ADDRESS_REVERTED_MEESAGE_WITH_OPERATOR} ${operator}`
    : ORDERS.ADDRESS_VERIFIED;

  const copyFunctionality = () => {
    if (Platform.OS === "web") {
      navigator.clipboard.writeText(copyContent);
    } else {
      Clipboard.setString(copyContent);
    }
  };

  return (
    <View style={{ zIndex: -1, paddingBottom: 5 }}>
      <View style={{ minHeight: 100 }}>
        <Text
          style={{
            fontSize: 15,
            color: colors.primary,
            fontWeight: "bold",
            paddingVertical: 5,
          }}
        >
          {Localise(locMessages, "Recipient")}
        </Text>

        {hasRecipientInfo && generateDetailContent(content, true)}
      </View>

      <View style={tw(`flex flex-row flex-wrap items-center`)}>
        {/* Delivery Distance Display */}
        <Distance
          distanceText={deliveryDistance}
          isAvsVerified={isAvsVerified}
        />

        <View style={tw(`flex flex-row flex-wrap items-center`)}>
          {isShowMap && (
            <TouchableOpacity
              onPress={() => setMapAccordionStatus(!mapAccordionStatus)}
              testID="close"
              accessibilityLabel="close"
              style={tw("pr-3")}
            >
              <Tooltip
                text={Localise(locMessages, "Delivery Location")}
                renderForWebOnly={true}
              >
                <Image
                  style={{ width: 30, height: 30 }}
                  resizeMode="cover"
                  source={IMAGES["mapFolded"]}
                />
              </Tooltip>
            </TouchableOpacity>
          )}
          {hasRecipientInfo && (
            <TouchableOpacity
              onPress={copyFunctionality}
              testID="close"
              accessibilityLabel="close"
            >
              <Tooltip
                text={Localise(locMessages, "Copy Recipient Address")}
                renderForWebOnly={true}
              >
                <Image
                  style={{ width: 20, height: 30 }}
                  resizeMode="cover"
                  source={IMAGES["copy-content"]}
                />
              </Tooltip>
            </TouchableOpacity>
          )}
        </View>
      </View>
      {addressInfo.length > 0 && (
        <AddressVerificationIndicator
          containerStyle={{ paddingTop: 5 }}
          isVerified={isAvsVerified}
          isSmallScreen={isSmallScreen}
          verifiedText={verifiedMessage}
          unVerfifiedText={ORDERS.ADDRESS_NOT_VERIFIED}
        />
      )}
    </View>
  );
};

export default RecipientInfo;
