import { put, select, takeLatest, call } from "redux-saga/effects";
import {
  setPageInitialize,
  setPageInitProgress,
  setPageInitFailure,
  setPageInitSuccess,
  setNavParams,
  fetchCustomerOrders,
  setCustomerOrders,
  setApiError,
  setRushDeliveryFee,
  fetchGlobalFees,
  setGiftCardsData,
  fetchGiftCardsData,
  fetchOrderSummary,
  setOrderSummary,
  setOrderSummaryProgress,
  setOrderSummarySuccess,
  setOrderSummaryFail,
  saveDraftOrder,
  fetchDraftOrders,
  setDraftOrders,
  modifyDraftOrder,
  deleteDraftOrder,
  setDraftsToDelete,
  validateOrder,
  saveCustomerCreditCard,
  upsertSubscription,
  fetchZoneDetailsByLatLng,
  fetchLatLngAndZoneDetailsById,
  setZoneDetails,
  setGeoCodeDetails,
  revertOriginalAddress,
} from "./slice";
import { fetchOrderData } from "library/sagas/ongoing/order-details/slice";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { request } from "library/utils/request";
import { formatPrice } from "library/utils/formatter";

import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";
import { selectDraftsToDelete } from "./selector";
import common from "library/utils/common";
import { ERROR_MESSAGES, ORDERS } from "library/constants";
import { getCreateSubscriptionReqObj } from "components/views/drawer/create-subscription/helper.js";

const getLatLng = (params) => request("get-lat-long", params);

function* handlePageIntialize(action = {}) {
  try {
    yield put(setPageInitProgress());
    const { params } = action.payload;
    const { id, dm, smc } = params;
    yield put(setNavParams({ ...params }));

    if (!id || !dm || !smc) return;

    const orderDetailsRes = yield select(selectApiResponse);
    if (!isEmpty(orderDetailsRes)) {
      const { orderItemId } = get(orderDetailsRes, "orderItems.0", "");
      if (orderItemId !== params.id) {
        yield put(
          fetchOrderData({
            recordId: id,
            deliveryMethod: dm,
            sourceMemberCode: smc,
          })
        );
      }
    } else {
      yield put(
        fetchOrderData({
          recordId: id,
          deliveryMethod: dm,
          sourceMemberCode: smc,
        })
      );
    }
    yield put(setPageInitSuccess());
  } catch (error) {
    yield put(setPageInitFailure());
  }
}

export function* handleFetchCustomerOrders({ payload }) {
  const { resolve, reject, customerId, storeOrigin } = payload;
  const serviceRequest = (customerId, storeOrigin) =>
    request("get-customer-orders", {
      customerId: customerId,
      storeOrigin: storeOrigin,
    });

  try {
    let orders = [];
    if (customerId && storeOrigin) {
      orders = yield call(serviceRequest, customerId, storeOrigin);
    }
    yield put(setCustomerOrders(orders));
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        section: "customers",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject(error);
  }
}

export function* handleFetchGlobalFees(action = {}) {
  const serviceRequest = (params) => request("get-site-fees", params);

  try {
    const response = yield call(serviceRequest, { shopCode: action.payload });
    const rushDeliveryFee =
      response?.siteMappingFees?.rushDeliveryInfos[0]?.rushFee ?? "0";

    yield put(setRushDeliveryFee({ rushDeliveryFee }));
  } catch (error) {
    console.log("Error: ", error);
  }
}

export function* handleValidateGiftCards({ payload }) {
  const { resolve, reject, giftCardId, shopCode } = payload;

  const serviceRequest = (params) => request("validate-gift-card", params);

  try {
    const response = yield call(serviceRequest, {
      giftCodeId: giftCardId,
      shopCode,
    });

    yield put(setGiftCardsData({ response, actionName: "store" }));
    resolve && resolve();
  } catch (error) {
    console.log("Error: ", error);
    reject && reject(error);
  }
}

export function* handleFetchOrderSummary(action = {}) {
  const { params, resolve, reject } = get(action, "payload", {});

  const orderSummaryServiceRequest = (params) =>
    request("order-summary", params);

  try {
    yield put(setOrderSummaryProgress());
    const res = yield call(orderSummaryServiceRequest, params);
    const refundTransactions =
      res?.summary?.refundTransactionDetails?.map((refundDetails) => {
        return {
          ...refundDetails,
          availableAmount: formatPrice(refundDetails.availableAmount),
          refundAmount: formatPrice(refundDetails.refundAmount),
        };
      }) || [];

    // Create formatted response object
    const formattedResponse = {
      order: { ...res.order }, // Copy order details as-is
      summary: {
        ...res.summary,
        refundTransactionDetails: refundTransactions,
      },
    };

    yield put(setOrderSummary(formattedResponse));
    yield put(setOrderSummarySuccess());

    resolve && resolve(formattedResponse);
  } catch (error) {
    yield put(setOrderSummaryFail());
    console.log("failed to fetch order summary");
    reject && reject(error);
  }
}

export function* handleSaveDraftOrder(action = {}) {
  const { params, resolve, reject } = get(action, "payload", {});

  const serviceRequest = (draftOrder) =>
    request("save-draft-order", draftOrder);
  try {
    const response = yield call(serviceRequest, params);
    yield put(
      fetchDraftOrders({
        resolve: () => {
          resolve && resolve(response);
        },
        reject: () => {
          // Resolving the save operation, even if fetching latest draft orders fails
          resolve && resolve(response);
        },
      })
    );
  } catch (error) {
    reject && reject(error);
  }
}

export function* handleFetchDraftOrders(action = {}) {
  const { params, resolve, reject } = get(action, "payload", {});
  const serviceRequest = (params) => request("get-draft-orders", params);
  try {
    const response = yield call(serviceRequest, params);
    const draftOrders = common.groupByMemberCode(response);
    yield put(setDraftOrders(draftOrders));
    resolve && resolve();
  } catch (error) {
    reject && reject(error);
  }
}

export function* handleModifyDraftOrder(action = {}) {
  const { params, resolve, reject } = get(action, "payload", {});
  const serviceRequest = (params) => request("modify-draft-order", params);
  try {
    const response = yield call(serviceRequest, params);
    yield put(
      fetchDraftOrders({
        resolve: () => {
          resolve && resolve(response);
        },
        reject: () => {
          // Resolving the modify operation, even if fetching latest draft orders fails
          resolve && resolve(response);
        },
      })
    );
  } catch (error) {
    reject && reject(error);
  }
}

export function* handleDeleteDraftOrder(action = {}) {
  const { params, resolve, reject } = get(action, "payload", {});
  const serviceRequest = (params) => request("delete-draft-order", params);

  try {
    const draftsToDelete = yield select(selectDraftsToDelete);
    yield put(setDraftsToDelete([...draftsToDelete, params.draftOrderID]));

    yield call(serviceRequest, params);

    yield put(
      fetchDraftOrders({
        resolve: () => {
          resolve && resolve();
        },
        reject: () => {
          // Resolving the delete operation, even if fetching latest draft orders fails
          resolve && resolve();
        },
      })
    );
  } catch (error) {
    // Handling the case where the user tries to delete already deleted draft
    if (error === ERROR_MESSAGES.NOT_FOUND) {
      yield put(
        fetchDraftOrders({
          resolve: () => {
            resolve &&
              resolve({ message: ORDERS.DRAFT_ALREADY_DELETED_MESSAGE });
          },
          reject: () => {
            // Resolving the delete operation, even if fetching latest draft orders fails
            resolve &&
              resolve({ message: ORDERS.DRAFT_ALREADY_DELETED_MESSAGE });
          },
        })
      );
    } else {
      reject && reject(error);
    }
  }
}

export function* handleValidateOrder(action = {}) {
  const { params, resolve, reject } = get(action, "payload", {});
  console.log("params :>> ", params);
  const serviceRequest = (params) => request("validate-order", params);
  try {
    const response = yield call(serviceRequest, params);
    resolve && resolve(response);
  } catch (error) {
    console.log("error :>> ", error);
    reject && reject(error);
  }
}

export function* handleSaveCustomerCreditCard(action = {}) {
  const { params, resolve, reject } = get(action, "payload", {});
  console.log("params :>> ", params);
  const serviceRequest = (params) =>
    request("save-customer-credit-card", params);
  try {
    const response = yield call(serviceRequest, params);
    if (response.success) {
      resolve && resolve(response);
    } else {
      // if we don't receive success reject it.
      reject && reject(response);
    }
  } catch (error) {
    console.log("error :>> ", error);
    reject && reject(error);
  }
}

export function* handleUpsertSubscription(action = {}) {
  const { params = {}, resolve, reject } = get(action, "payload", {});
  const createSubscriptionRequest = (params) =>
    request("create-subscription", params);

  const modifySubscriptionRequest = (params) =>
    request("modify-subscription", params);

  const { createOrderReqObj, subscriptionData = {}, creditCardId } = params;

  try {
    let subscriptionResponse = {};

    const createSubscriptionReqObj = getCreateSubscriptionReqObj(
      createOrderReqObj,
      creditCardId ? creditCardId : "",
      subscriptionData
    );

    const isModifyFlow = subscriptionData?.isModify;
    subscriptionResponse = yield call(
      isModifyFlow ? modifySubscriptionRequest : createSubscriptionRequest,
      createSubscriptionReqObj
    );
    resolve && resolve(subscriptionResponse);
  } catch (error) {
    console.log("error :>> ", error);
    reject && reject(error);
  }
}

function* handleFetchZoneDetailsByLatLng(action = {}) {
  const { params: { shopCode, latlngParams = {} } = {} } = get(
    action,
    "payload",
    {}
  );
  const getZoneDetailsRequest = (params) => request("get-zone-details", params);
  try {
    const geoCodeDetails = yield call(getLatLng, latlngParams);
    yield put(setGeoCodeDetails(geoCodeDetails || {}));
    const { latitude = "", longitude = "" } = geoCodeDetails || {};
    if (latitude && longitude) {
      const zoneDetails = yield call(getZoneDetailsRequest, {
        latitude,
        longitude,
        shopCode,
      });
      yield put(setZoneDetails(zoneDetails));
    }
  } catch (error) {
    console.log("Zone Details By lat/lng Error", error);
  }
}

function* handleFetchLatLngAndZoneDetailsById(action = {}) {
  const { params: { shopCode, deliveryZoneId, latlngParams } = {} } = get(
    action,
    "payload",
    {}
  );
  const getZoneDetailsByIdRequest = (params) =>
    request("get-autoroutes-data", params);

  try {
    const geoCodeDetails = yield call(getLatLng, latlngParams);
    yield put(setGeoCodeDetails(geoCodeDetails));
    const zoneDetails = yield call(getZoneDetailsByIdRequest, {
      shopCode,
      autoRouteId: deliveryZoneId,
    });
    yield put(setZoneDetails([zoneDetails]));
  } catch (error) {
    console.log("Zone Details by zoneId Error", error);
  }
}
function* handleRevertOriginalAddress(action) {
  const { params = {}, resolve, reject } = get(action, "payload", {});
  const serviceRequest = (params) => request("update-address-status", params);
  try {
    const response = yield call(serviceRequest, params);
    resolve && resolve(response);
  } catch (error) {
    reject && reject(error);
  }
}

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(setPageInitialize.type, handlePageIntialize);
  yield takeLatest(fetchCustomerOrders.type, handleFetchCustomerOrders);
  yield takeLatest(fetchGlobalFees.type, handleFetchGlobalFees);
  yield takeLatest(fetchGiftCardsData.type, handleValidateGiftCards);
  yield takeLatest(fetchOrderSummary.type, handleFetchOrderSummary);
  yield takeLatest(saveDraftOrder.type, handleSaveDraftOrder);
  yield takeLatest(fetchDraftOrders.type, handleFetchDraftOrders);
  yield takeLatest(modifyDraftOrder.type, handleModifyDraftOrder);
  yield takeLatest(deleteDraftOrder.type, handleDeleteDraftOrder);
  yield takeLatest(validateOrder.type, handleValidateOrder);
  yield takeLatest(saveCustomerCreditCard.type, handleSaveCustomerCreditCard);
  yield takeLatest(upsertSubscription.type, handleUpsertSubscription);
  yield takeLatest(
    fetchZoneDetailsByLatLng.type,
    handleFetchZoneDetailsByLatLng
  );
  yield takeLatest(
    fetchLatLngAndZoneDetailsById.type,
    handleFetchLatLngAndZoneDetailsById
  );
  yield takeLatest(revertOriginalAddress.type, handleRevertOriginalAddress);
}

export default watchSaga;
