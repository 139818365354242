import { createStyles, minWidth } from "react-native-media-queries";
import { fonts, colors, backgroundColors } from "styles/theme";

const base = {
  mainContainer: {
    flexDirection: "column",
  },
  leftContainer: {
    width: "100%",
    padding: 0,
  },
  rightContainer: {
    width: "100%",
    padding: 0,
    justifyContent: "center",
  },
  eachBlock: {
    width: "100%",
    height: 100,
    flexDirection: "column",
    borderLeftWidth: 1,
    borderLeftColor: "#c4c4c4",
  },
  eachBlockPremium: {
    width: "100%",
    height: 100,
    flexDirection: "column",
    borderLeftWidth: 1,
    borderLeftColor: "#c4c4c4",
  },
  stripContainer: {
    height: 25,
  },
  titleHeader: {
    height: 50,
  },
  titleHeaderStrip: {
    height: 0,
  },
  titleHeaderContent: {
    justifyContent: "flex-start",
  },
  eachBlockContent: {
    flex: 1,
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 20,
    // width: "100%",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  eachBlockIcon: {
    marginTop: 0,
  },
  bottomStripHeader: {
    width: "100%",
    height: 55,
    backgroundColor: "#E2E7EA",
    padding: 20,
  },
  premiumBottomStripHeader: {
    width: "100%",
    height: 55,
    backgroundColor: "#E2E7EA",
    padding: 20,
  },
  bottomStripContent: {
    width: "100%",
    height: 40,
    borderLeftWidth: 1,
    borderLeftColor: "#EEE",
  },
  countText: {
    fontSize: 28,
    lineHeight: 30,
    fontFamily: fonts.fontFamily.bold,
    fontWeight: "600",
    color: "#fff",
    paddingBottom: 0,
    paddingLeft: 5,
  },
  titleHeaderText: {
    fontSize: 28,
  },
  orderActionCountText: {
    fontSize: 20,
    lineHeight: 20,
    fontFamily: fonts.fontFamily.bold,
    fontWeight: "600",
    paddingBottom: 5,
  },
  orderActionCountLabel: {
    paddingBottom: 5,
    fontSize: 16,
  },
  orderActionContainer: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    width: "100%",
    flexDirection: "column",
  },
  needsActionTitle: {
    fontSize: 16,
    color: "#fff",
    paddingLeft: 5,
  },
  needsActionCountBlockContainer: {
    width: "100%",
  },
  needsActionCountBlock: {
    width: "100%",
    height: 50,
    backgroundColor: "#6C0057",
    borderLeftWidth: 1,
    borderLeftColor: "#c4c4c4",
    borderBottomWidth: 0,
    borderBottomColor: "#6C0057",
    justifyContent: "center",
  },
  countBlockContent: {
    padding: 5,
    flexDirection: "row",
    paddingHorizontal: 20,
    alignItems: "center",
  },
  needsActionContent: {
    flexDirection: "row",
    // alignItems: "center",
    marginRight: 0,
    paddingVertical: 25,
    //flexWrap: "wrap",
    //justifyContent: "flex-start",
    width: "100%",
  },
  needsActionContentContainer: {
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  orderActionEachBlock: {
    width: 125,
    height: 125,
    marginLeft: 0,
    //marginBottom: 10,
    borderRadius: 8,
    borderWidth: 1,
  },
  orderActionBlockContainer: {
    width: "auto",
    marginVertical: 5,
    marginHorizontal: 15,
  },
  orderTodayContainer: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    width: "100%",
    flexDirection: "column",
  },
  premiumOrderTodayContainer: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    width: "100%",
    flexDirection: "column",
  },
  orderTodayBottomStrip: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    backgroundColor: "#E2E7EA",
    width: "100%",
    flexDirection: "column",
  },
  orderStatus: {
    paddingLeft: 5,
  },
  calendar: {
    width: "100%",
  },
  zeroOrdersText: {
    fontSize: 16,
    paddingLeft: 10,
  },
  mainLinks: {
    fontSize: 16,
    textDecorationLine: "underline",
  },
  subLinks: {
    fontSize: 14,
    textDecorationLine: "underline",
  },
  timer: {
    color: "red",
    fontSize: 14,
    paddingLeft: 15,
    fontWeight: "600",
  },
  contentContainer: {
    flexDirection: "column",
    flexWrap: "wrap",
  },
  calendarItem: {
    width: "98%",
    height: 73,
    borderWidth: 1,
    borderColor: colors.grayScaleLight,
    padding: 8,
  },
  contentItem: {
    marginHorizontal: 3,
  },
  aovPrice: {
    minWidth: 55,
  },
  salesPrice: {
    minWidth: 45,
  },
  sideStrip: {
    width: 7,
    height: 55,
    marginRight: 10,
  },
  datesContainer: {
    fontSize: 14,
    color: colors.secondary,
    minWidth: 225,
  },
  headContainer: {
    backgroundColor: backgroundColors.primary,
    flexDirection: "column",
    paddingBottom: 10,
    alignItems: "flex-start",
    //height: 80,
  },
  horizontalDivider: {
    width: 2,
    backgroundColor: backgroundColors.light,
    height: 40,
  },
  notifications: {
    width: "100%",
  },
};

const tabletStyles = {
  mainContainer: {
    flexDirection: "column",
  },
  leftContainer: {
    width: "100%",
    padding: 0,
  },
  rightContainer: {
    width: "100%",
    padding: 0,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  contentContainer: {
    flexDirection: "row",
  },
  calendar: {
    marginRight: 10,
    width: "52%",
  },
  calendarItem: {
    width: "95%",
    height: 55,
  },
  sideStrip: {
    height: 45,
  },
  notifications: {
    width: "45%",
  },
  contentItem: {
    marginHorizontal: 5,
  },
  datesContainer: {
    fontSize: 16,
    minWidth: 290,
  },
  headContainer: {
    paddingBottom: 0,
    height: "auto",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  eachBlock: {
    width: "16.4%",
    height: 180,
  },
  eachBlockPremium: {
    width: "16.4%",
    height: 180,
  },
  titleHeader: {
    height: 180,
    width: "18%",
  },
  stripContainer: {
    height: 25,
  },
  eachBlockContent: {
    flexDirection: "column",
    paddingVertical: 10,
    paddingHorizontal: 14,
    alignItems: "baseline",
  },
  eachBlockIcon: {
    marginTop: 25,
  },
  bottomStripHeader: {
    //maxWidth: 150,
    height: 45,
    width: "18%",
    backgroundColor: "#E2E7EA",
    padding: 10,
    justifyContent: "center",
  },
  premiumBottomStripHeader: {
    height: 45,
    width: "15.5%",
    backgroundColor: "#E2E7EA",
    padding: 10,
    justifyContent: "center",
  },
  bottomStripContent: {
    //flexGrow: 1,
    width: "auto",
    height: 45,
    borderLeftWidth: 1,
    borderLeftColor: "#EEE",
    justifyContent: "center",
  },
  countText: {
    fontSize: 30,
    lineHeight: 50,
    fontFamily: fonts.fontFamily.bold,
    fontWeight: "600",
    color: "#fff",
    paddingBottom: 10,
    paddingLeft: 0,
  },
  titleHeaderText: {
    fontSize: 35,
  },
  orderActionCountText: {
    fontSize: 30,
    lineHeight: 30,
    fontFamily: fonts.fontFamily.bold,
    fontWeight: "600",
    paddingBottom: 10,
  },
  orderActionCountLabel: {
    paddingBottom: 10,
    fontSize: 14,
    maxWidth: 125,
    fontWeight: "400",
  },
  orderActionContainer: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    height: 250,
    width: "100%",
    flexDirection: "row",
  },
  orderActionBlockContainer: {
    width: 130,
  },
  needsActionTitle: {
    fontSize: 16,
    color: "#fff",
    paddingLeft: 0,
  },
  needsActionCountBlockContainer: {
    width: "15.5%", //"17%",
  },
  needsActionCountBlock: {
    width: "auto",
    height: 250,
    borderBottomWidth: 25,
    justifyContent: "flex-start",
  },
  countBlockContent: {
    padding: 25,
    flexDirection: "column",
    alignItems: "baseline",
  },
  needsActionContent: {
    flexDirection: "row",
    //alignItems: "center",
    marginRight: 5,
    padding: 5,
    width: "82%",
    //justifyContent: "space-around",
  },
  needsActionContentContainer: {
    paddingHorizontal: 15,
    paddingVertical: 5,
    height: "100%",
    justifyContent: "space-around",
  },
  orderActionEachBlock: {
    height: 175,
    width: "auto",
    marginLeft: 0,
    marginBottom: 0,
    borderRadius: 8,
    borderWidth: 1,
  },
  orderTodayContainer: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    width: "100%",
    flexDirection: "row",
  },
  premiumOrderTodayContainer: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    width: "100%",
    flexDirection: "row",
  },
  orderTodayBottomStrip: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    height: 45,
    backgroundColor: "#fafafa",
    width: "100%",
    flexDirection: "row",
  },
  orderStatus: {
    paddingLeft: 0,
  },
};
const laptopStyles = {
  mainContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftContainer: {
    width: "68%",
    padding: 0,
  },
  rightContainer: {
    width: "31%",
    padding: 0,
    justifyContent: "flex-end",
  },
  contentItem: {
    marginHorizontal: 6,
  },
  calendar: {
    //marginRight: 5,
    width: "100%",
  },
  notifications: {
    width: "100%",
  },
  sideStrip: {
    width: 7,
    height: 55,
    marginRight: 10,
  },
  eachBlock: {
    width: "16.4%",
    height: 180,
  },
  eachBlockPremium: {
    width: "16.4%",
    height: 180,
  },
  stripContainer: {
    height: 25,
  },
  titleHeader: {
    height: 180,
    width: "18%",
  },
  eachBlockContent: {
    flexDirection: "column",
    padding: 20,
    alignItems: "baseline",
  },
  eachBlockIcon: {
    marginTop: 25,
  },
  bottomStripHeader: {
    width: "18%",
    height: 45,
    backgroundColor: "#E2E7EA",
    paddingHorizontal: 10,
    paddingVertical: 10,
    justifyContent: "center",
  },
  premiumBottomStripHeader: {
    width: "15.5%",
    height: 45,
    backgroundColor: "#E2E7EA",
    paddingHorizontal: 10,
    paddingVertical: 10,
    justifyContent: "center",
  },
  bottomStripContent: {
    width: "auto",
    height: 45,
    borderLeftWidth: 1,
    borderLeftColor: "#EEE",
    justifyContent: "center",
  },
  countText: {
    fontSize: 40,
    lineHeight: 50,
    fontFamily: fonts.fontFamily.bold,
    fontWeight: "600",
    paddingLeft: 0,
    paddingBottom: 10,
  },
  titleHeaderText: {
    fontSize: 40,
  },
  needsActionCountBlockContainer: {
    width: "15.5%", //18%
  },
  needsActionContent: {
    width: "83%",
  },
  orderActionCountText: {
    fontSize: 26,
    lineHeight: 25,
    fontFamily: fonts.fontFamily.bold,
    fontWeight: "600",
    paddingBottom: 10,
  },
  orderActionCountLabel: {
    paddingBottom: 10,
    fontSize: 16,
  },
  orderActionContainer: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    height: 250,
    width: "100%",
    flexDirection: "row",
  },
  orderActionBlockContainer: {
    width: 130,
    marginHorizontal: 5,
  },
  needsActionCountBlock: {
    width: "auto",
    height: 250,
    justifyContent: "flex-start",
  },
  orderActionEachBlock: {
    width: "auto",
    height: 140,
    marginLeft: 0,
    borderRadius: 8,
    borderWidth: 1,
  },
  needsActionTitle: {
    fontSize: 20,
    paddingLeft: 0,
  },
  orderTodayContainer: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    width: "100%",
    flexDirection: "row",
  },
  premiumOrderTodayContainer: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    width: "86%",
    flexDirection: "row",
  },
  orderTodayBottomStrip: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    height: 45,
    backgroundColor: "#fafafa",
    width: "100%",
    flexDirection: "row",
  },
  orderStatus: {
    paddingLeft: 0,
  },
  datesContainer: {
    fontSize: 13,
    minWidth: 260,
  },
  headContainer: {
    flexDirection: "row",
    paddingBottom: 0,
    alignItems: "center",
    flexWrap: "wrap",
  },
  calendarItem: {
    width: "90%",
    height: 73,
  },
};

const largeDevices = {
  leftContainer: {
    width: "65%",
    padding: 0,
  },
  rightContainer: {
    width: "35%",
    padding: 0,
    justifyContent: "center",
  },
  contentItem: {
    marginHorizontal: 6,
  },
  calendar: {
    marginRight: 5,
    width: 500,
  },
  notifications: {
    marginRight: 5,
    width: 500,
  },
  eachBlock: {
    width: 175,
    height: 180,
  },
  eachBlockPremium: {
    width: 150,
    height: 180,
  },
  stripContainer: {
    height: 25,
  },
  titleHeader: {
    height: 180,
  },
  eachBlockContent: {
    flexDirection: "column",
    padding: 20,
    alignItems: "baseline",
  },
  orderActionBlockContainer: {
    width: 130,
    padding: 5,
    marginHorizontal: 5,
  },
  eachBlockIcon: {
    marginTop: 25,
  },
  bottomStripHeader: {
    width: 175,
    height: 45,
    backgroundColor: "#E2E7EA",
    padding: 15,
  },
  premiumBottomStripHeader: {
    width: 150,
    height: 45,
    backgroundColor: "#E2E7EA",
    padding: 15,
  },
  bottomStripContent: {
    width: 175,
    height: 45,
    borderLeftWidth: 1,
    borderLeftColor: "#EEE",
    justifyContent: "center",
  },
  countText: {
    fontSize: 40,
    lineHeight: 50,
    fontFamily: fonts.fontFamily.bold,
    fontWeight: "600",
    paddingLeft: 0,
    paddingBottom: 10,
  },
  titleHeaderText: {
    fontSize: 40,
  },
  orderActionCountText: {
    fontSize: 26,
    lineHeight: 25,
    fontFamily: fonts.fontFamily.bold,
    fontWeight: "600",
    paddingBottom: 10,
  },
  orderActionCountLabel: {
    paddingBottom: 10,
    fontSize: 16,
  },
  orderActionContainer: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    height: 230,
    width: 1050,
    flexDirection: "row",
  },
  needsActionCountBlock: {
    width: 175,
    height: 230,
    justifyContent: "flex-start",
  },
  orderActionEachBlock: {
    width: "auto",
    height: 175,
    marginLeft: 0,
    borderRadius: 8,
    borderWidth: 1,
  },
  needsActionTitle: {
    fontSize: 20,
    paddingLeft: 0,
  },
  orderTodayContainer: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    width: 1050,
    flexDirection: "row",
  },
  premiumOrderTodayContainer: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    width: 980,
    flexDirection: "row",
  },
  orderTodayBottomStrip: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    height: 45,
    backgroundColor: "#fafafa",
    width: 1050,
    flexDirection: "row",
  },
  orderStatus: {
    paddingLeft: 0,
  },
  datesContainer: {
    fontSize: 14,
    minWidth: 260,
  },
};

export default createStyles(
  base,
  minWidth(767, tabletStyles),
  minWidth(1300, laptopStyles),
  minWidth(1700, largeDevices)
);
