import { put, takeLatest, call, select, delay } from "redux-saga/effects";

import { request } from "library/utils/request";
import { processSelectedProductResponse } from "library/utils/productDetails";
import { getDeliveryMethod } from "components/views/drawer/orders-new/config";

import moment from "moment";
import isEmpty from "lodash/isEmpty";

import store from "../../../../store";
import {
  setPageDesigners,
  setAPIResponse,
  setPageData,
  fetchDesigners,
  fetchOrders,
  filterOrders,
  fetchPrintDetails,
  triggerMeetBallAction,
  assignDesigner,
  setShowLimitExceeded,
  setGridDataSource,
  setPageInitialise,
  triggerRefreshGrid,
  followupOrder,
  setLoading,
  setNeedsActionCount,
  orderDetailActions,
  setPageActions,
  setPageZones,
} from "./slice";
import {
  selectActions,
  selectAPIResponse,
  selectPageLimit,
  selectShopCode,
  selectGridInstance,
  selectGridProps,
  selectNeedActionCount,
  selectZones,
} from "./selector";

function* handleRefreshGrid() {
  try {
    yield delay(1000);

    const gridInstance = yield select(selectGridInstance);

    gridInstance?.api?.deselectAll();
    gridInstance?.api?.paginationGoToFirstPage();
    gridInstance?.api?.setServerSideDatasource(null);

    yield put(setGridDataSource());
  } catch (error) {
    console.error("Error refreshing grid:", error);
  }
}

function* handleSetGridDataSource() {
  try {
    const shopCode = yield select(selectShopCode);
    const gridInstance = yield select(selectGridInstance);
    const gridProps = yield select(selectGridProps);

    const dataSource = {
      getRows: async function (params) {
        try {
          const rowGroupCols = params.request.rowGroupCols;
          const groupKeys = params.request.groupKeys;
          gridProps?.togglePaginationControls(false);

          await store.dispatch(
            fetchOrders({
              sendingMember: shopCode,
              offset: gridInstance.api.paginationGetCurrentPage() * 10,
              group: !!rowGroupCols.length,
              callback: async ({ data = [], totalRows = 0 }) => {
                if (rowGroupCols.length) {
                  data.length
                    ? gridInstance.api.hideOverlay()
                    : gridInstance.api.showNoRowsOverlay();
                  await store.dispatch(setShowLimitExceeded(totalRows > 1000));

                  if (groupKeys.length) {
                    data = data
                      .map((e) => e.designer.id === groupKeys[0] && e)
                      .filter(Boolean);
                  } else {
                    const rowGroups = [];

                    data.forEach((e) => {
                      !rowGroups.map((d) => d.id).includes(e.designer.id) &&
                        rowGroups.push(e.designer);
                    });
                    data = rowGroups.map((rec) => {
                      const childOrders = data.filter(
                        (i) => i.designer.id === rec.id
                      );

                      return {
                        ...rec,
                        designer: rec,
                        group: true,
                        childOrders,
                        childCount: childOrders.length,
                      };
                    });
                  }

                  params.successCallback(data, data.length);
                } else {
                  Number(totalRows)
                    ? gridInstance.api.hideOverlay()
                    : gridInstance.api.showNoRowsOverlay();

                  await store.dispatch(setShowLimitExceeded(false));
                  params.successCallback(
                    data,
                    isEmpty(data.find((e) => e.id.includes("invalid")))
                      ? Number(totalRows)
                      : null
                  );
                }

                const totalRowCount =
                  gridInstance?.api?.paginationGetRowCount() ?? 0;
                const paginationPageSize =
                  gridInstance?.api?.paginationGetPageSize() ?? 0;
                const paginationCurrentPage =
                  gridInstance?.api?.paginationGetCurrentPage() ?? 0;
                const endRow = Math.min(
                  (paginationCurrentPage + 1) * paginationPageSize,
                  totalRowCount
                );

                gridProps?.setLastRowOnPage(endRow);
                gridProps?.togglePaginationControls(true);
              },
            })
          );
        } catch (error) {
          console.error("Error fetching rows:", error);
          params.failCallback();
        }
      },
    };

    gridInstance.api.setServerSideDatasource(dataSource);
  } catch (error) {
    console.error("Error setting grid data source:", error);
  }
}

function* handleFetchDesigners(action = {}) {
  const designersRequest = (members) =>
    request("get-designer-suggestions", {
      members,
    });
  const { memberCodes, callback = () => {} } = action?.payload ?? {};

  try {
    const designers = yield call(designersRequest, memberCodes);
    yield put(setPageDesigners(designers));
    callback();
  } catch (error) {
    callback();
    console.log(`Error fetching designers - ${error}`);
  }
}

function* handleFetchOrders(action = {}) {
  const ordersRequest = (params) => request("get-orders-new", params);
  const needsActionCountRequest = (params) => request("get-orders-new", params);
  const zonesRequest = (params) => request("get-autoroutes-data", params);

  try {
    const {
      sendingMember,
      offset,
      group = false,
      callback = () => {},
    } = action?.payload ?? {};

    const apiResponse = yield select(selectAPIResponse);
    const limit = yield select(selectPageLimit);
    const { gridState, searchQuery, orderGroups, showDesigners } = yield select(
      selectActions
    );
    const zones = yield select(selectZones);

    let filterQuery = "";

    if (orderGroups.length) {
      const selectedCategories = orderGroups.map((e) => e.category);

      // Group - Action Filters
      if (selectedCategories.includes("actions")) {
        filterQuery += `&required=actions`;
      }

      // Group - Date Filters
      if (selectedCategories.includes("date")) {
        const dateFilters = orderGroups.find(
          (e) => e.category === "date"
        ).values;

        const today = moment().add(0, "days").format("YYYY-MM-DD");
        const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
        const future = moment().add(2, "days").format("YYYY-MM-DD");

        if (dateFilters.length === 1) {
          if (dateFilters.includes(0)) {
            filterQuery += `&fulfillment_date=${today}`;
          } else if (dateFilters.includes(1)) {
            filterQuery += `&fulfillment_date=${tomorrow}`;
          } else if (dateFilters.includes(2)) {
            filterQuery += `&fulfillment_date.gte=${future}`;
          }
        } else if (dateFilters.length === 2) {
          if (dateFilters.includes(0) && dateFilters.includes(1)) {
            filterQuery += `&fulfillment_date.in=${today},${tomorrow}`;
          } else if (dateFilters.includes(0) && dateFilters.includes(2)) {
            filterQuery += `&fulfillment_date.gte=${today}&fulfillment_date.ne=${tomorrow}`;
          } else if (dateFilters.includes(1) && dateFilters.includes(2)) {
            filterQuery += `&fulfillment_date.gte=${tomorrow}`;
          }
        } else {
          filterQuery += `&fulfillment_date.gte=${today}`;
        }
      }

      // Group - Delivery Status Filters OR Order Status Filters
      if (
        selectedCategories.some((cat) =>
          ["delivery_status", "order_status"].includes(cat)
        )
      ) {
        const statusFilters = ["delivery_status", "order_status"].flatMap(
          (category) =>
            orderGroups.find((e) => e.category === category)?.values || []
        );
        const isIncludesUndelivered =
          statusFilters.includes("Accepted") &&
          !statusFilters.includes("Delivered");

        filterQuery += `${
          isIncludesUndelivered ? "&status.ne=Delivered" : ""
        }&status.in=${statusFilters.join(",")}`;
      }
    }

    if (gridState.length) {
      const appliedSearch = gridState
        .map((e) => (!isEmpty(e.appliedSearch) ? e.appliedSearch : false))
        .filter(Boolean);
      const selectedFilters = gridState
        .map((e) => (!isEmpty(e.appliedFilter) ? e.appliedFilter : false))
        .filter(Boolean);

      // Column - Search
      appliedSearch.forEach((key) => {
        const filterKeys = key.split("::");
        const searchColumn = filterKeys[0] || "";
        const searchValue = filterKeys[1] || "";

        if (
          !["external_id", "contact", "products", "recipient"].includes(
            searchColumn
          )
        )
          return;

        const searchKey =
          searchColumn === "external_id"
            ? `search:order_ids_index=${searchValue}`
            : searchColumn === "contact"
            ? `search:customer_index=${searchValue}`
            : searchColumn === "recipient"
            ? `search:recipient_index=${searchValue}`
            : searchColumn === "products"
            ? `search=${searchValue}`
            : `${searchColumn}.ilike=%${searchValue}%`;

        filterQuery += `&${searchKey}`;
      });

      // Column - Filters
      selectedFilters.forEach((e) => {
        Object.keys(e).forEach((k) => {
          if (["fulfillment_date", "startDate", "endDate"].includes(k)) {
            if (k !== "fulfillment_date") return;

            const params = new URLSearchParams(filterQuery);
            [...params.keys()].forEach((key) => {
              if (key.startsWith("fulfillment_date")) {
                params.delete(key);
              }
            });
            filterQuery = [...params.entries()]
              .map(([key, value]) => `${key}=${value}`)
              .filter(Boolean)
              .join("&")
              .trim();

            if (e.fulfillment_date.includes("customDatePicker")) {
              const startDate = e?.startDate ?? "";
              const endDate = e?.endDate ?? "";

              filterQuery += `&fulfillment_date.gte=${startDate}&fulfillment_date.lte=${endDate}`;
            } else if (e.fulfillment_date.includes("This Month")) {
              const startOfMonth = moment()
                .startOf("month")
                .format("YYYY-MM-DD");
              const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

              filterQuery += `&fulfillment_date.gte=${startOfMonth}&fulfillment_date.lte=${endOfMonth}`;
            } else if (e.fulfillment_date.includes("This Week")) {
              const startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
              const endOfWeek = moment().endOf("week").format("YYYY-MM-DD");

              filterQuery += `&fulfillment_date.gte=${startOfWeek}&fulfillment_date.lte=${endOfWeek}`;
            }

            filterQuery =
              filterQuery.length > 0
                ? filterQuery.startsWith("&")
                  ? filterQuery.trim()
                  : "&" + filterQuery.trim()
                : "";
          } else {
            const filterValues = e[k];

            if (!filterValues || filterValues.length === 0) return;

            if (k === "fulfillment_details") {
              filterValues.forEach((value) => {
                const [key, val] = value.split("**");

                if (key === "timed") filterQuery += `&fulfillment_time.ne=`;
                else filterQuery += `&${k}.${key}=${val}`;
              });
            } else if (k.includes("status.in")) {
              const statusInRegex = /status\.in=([^&]*)/;

              if (filterQuery.match(statusInRegex)) {
                filterQuery = filterQuery.replace(
                  statusInRegex,
                  (_, existingValues) => {
                    const uniqueValues = new Set([
                      ...existingValues.split(","),
                      ...filterValues,
                    ]);
                    return `status.in=${Array.from(uniqueValues).join(",")}`;
                  }
                );
              } else {
                filterQuery += `&${k}=${filterValues.join(",")}`;
              }
            } else {
              filterQuery += `&${k}=${filterValues.join(",")}`;
            }
          }
        });
      });

      // Column - Sorting
      let sortQuery = "";
      const selectedSorts = gridState
        .map((e) => (!isEmpty(e.appliedSort) ? e.appliedSort : false))
        .filter(Boolean);

      if (selectedSorts.length > 0) {
        sortQuery += "&sort=";
        selectedSorts.forEach((e) => {
          const query = e.split("::");

          if (query[0] === "external_id") {
            sortQuery +=
              query[1] === "external_id"
                ? `${query[1]}:${query[2]},`
                : `external_id:${query[2]},`;
            filterQuery +=
              query[1] === "external_id"
                ? "&external_id.ilike=%MHQ%"
                : "&external_id.notiLike=%MHQ%";
          } else if (["contact", "recipient"].includes(query[0])) {
            sortQuery += `${query[0]}.${query[1]}:${query[2]},`;
          } else {
            sortQuery += `${query[1]}:${query[2]},`;
          }
        });
        filterQuery += sortQuery.slice(0, -1);
      }
    }

    if (searchQuery && searchQuery.trim() !== "")
      filterQuery += `&search=${encodeURIComponent(searchQuery.trim())}`;

    // Fetch the list of zones
    if (!zones.length) {
      const listOfZones = yield call(zonesRequest, { shopCode: sendingMember });
      yield put(setPageZones(listOfZones));
    }

    const { totalRows: needsActionCount = 0 } = yield call(
      needsActionCountRequest,
      {
        sendingMember,
        limit: 0,
        offset: 0,
        filterQuery: "&required=actions",
      }
    );
    yield put(setNeedsActionCount(needsActionCount));

    const { api: response, totalRows } = yield call(ordersRequest, {
      sendingMember,
      limit: showDesigners ? 1000 : limit,
      offset,
      filterQuery,
    });

    let rowData = response;
    if (response.length < limit && totalRows > limit) {
      rowData = new Array(limit).fill(null);
      rowData.forEach((_, index) => {
        rowData[index] = { id: `${index}_invalid`, ...response[index] };
      });
    }

    yield put(
      setAPIResponse(showDesigners ? rowData : [...apiResponse, ...rowData])
    );
    yield put(filterOrders({ callback, group, totalRows }));
  } catch (error) {
    console.log(`Error fetching orders - ${error}`);
  }
}

function* handleFilterOrders(action = {}) {
  try {
    const {
      callback = () => {},
      group = false,
      totalRows = 0,
    } = action?.payload ?? {};

    const api = yield select(selectAPIResponse);
    const limit = yield select(selectPageLimit);
    const data = processOrdersResponse(api);

    yield put(setPageData(data));

    // AG-Grid requires only latest data
    callback &&
      callback({
        data: group ? data : data.slice(data.length - limit, data.length),
        totalRows,
      });
  } catch (error) {
    console.log(`Error filtering orders - ${error}`);
  }
}

function* handleSetPageActions() {
  try {
    const gridInstance = yield select(selectGridInstance);
    gridInstance?.api?.deselectAll();
  } catch (error) {
    console.log(`Error filtering orders - ${error}`);
  }
}

function* handleFetchPrintDetails(action = {}) {
  const orderDetailRequest = (params) => request("order-details", params);
  const productDetailRequest = (params) =>
    request("get-product-details", params);

  const {
    rowId = "",
    recordId = "",
    deliveryMethod = "",
    sourceMemberCode = "",
    callback = () => {},
  } = action?.payload ?? {};

  try {
    const gridInstance = yield select(selectGridInstance);
    const res = yield call(orderDetailRequest, {
      recordId,
      deliveryMethod,
      sourceMemberCode,
    });

    if (res && res.orderItems) {
      const {
        orderSource,
        multiProductEligible = false,
        lineItems,
        deliveryInfo: { deliveryMethod },
      } = res.orderItems[0];

      const products = multiProductEligible
        ? lineItems.filter((lineItem) => lineItem.type === "Product")
        : lineItems;

      if (products.length) {
        let promises = [];

        for (let i = 0; i < products.length; i++) {
          const productId = products[i]?.productFirstChoiceCode || "";
          if (productId) {
            const productResponse = yield call(productDetailRequest, {
              productId: productId.toUpperCase(),
              siteId: sourceMemberCode,
              orderSource:
                orderSource === "FLORIST" &&
                deliveryMethod !== "FLORIST_PARTNER"
                  ? "LOCAL"
                  : orderSource,
            });
            promises.push(productResponse);
          }
        }

        Promise.all(promises).then((responses = []) => {
          let productsInfo = [];

          responses.map((productResp) => {
            if (productResp?.products?.length > 0) {
              const productInfo = processSelectedProductResponse(
                (productResp && productResp.products) || []
              );
              productsInfo.push(productInfo);
            }
          });

          const rowNode = gridInstance?.api?.getRowNode(rowId);
          rowNode &&
            rowNode.setData({
              ...rowNode.data,
              print_details: {
                ...rowNode.data.print_details,
                details_printed: true,
              },
            });
          callback({ res, productsInfo });
        });
      }
    }
  } catch (error) {
    console.log(`Error filtering orders - ${error}`);
  }
}

function* handleMeetBallAction(action = {}) {
  const meetBallActionRequest = (requestMethod, params) =>
    request(requestMethod, params);
  const { requestMethod, params, callback = () => {} } = action?.payload ?? {};

  try {
    yield call(meetBallActionRequest, requestMethod, params);
    callback();
  } catch (error) {
    callback(error);
    console.log(`Error triggering meetball action for the order - ${error}`);
  }
}

function* handleSetAssignDesigner(action = {}) {
  const assignDesignerRequest = (params) => request("assign-designer", params);
  const { designer, selectedOrders, memberCode } = action?.payload ?? {};

  try {
    yield put(setLoading(true));

    const isAllOrders = selectedOrders.includes("all");
    const apiResponse = yield select(selectAPIResponse);

    const orders = isAllOrders
      ? apiResponse.map(
          ({ source, fulfillment_type, external_id, eros_details }) => ({
            orderItemId: external_id,
            deliveryMethod: getDeliveryMethod(
              source,
              fulfillment_type,
              eros_details
            ),
          })
        )
      : selectedOrders
          .map((e) => {
            const orderId = e.split("***")[0];
            const order = apiResponse.find(({ id }) => id === orderId);
            const { source, fulfillment_type, external_id, eros_details } =
              order;

            return {
              orderItemId: external_id,
              deliveryMethod: getDeliveryMethod(
                source,
                fulfillment_type,
                eros_details
              ),
            };
          })
          .filter(Boolean);

    yield call(assignDesignerRequest, {
      designer,
      orders,
      memberCode,
    });
    yield put(triggerRefreshGrid());
    yield delay(2000);
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    console.log(`Error while assigning the designer - ${error}`);
  }
}

function* handleFollowupOrder(action = {}) {
  const lockOrderRequest = (params) => request("lock-order", params);
  const followupOrderRequest = (params) => request("modify-order", params);
  const {
    rowId = "",
    recordId = "",
    sourceMemberCode = "",
    deliveryMethod = "",
    orderUpdates = [],
  } = action?.payload ?? {};

  try {
    const gridInstance = yield select(selectGridInstance);
    const needsActionCount = yield select(selectNeedActionCount);
    const { orderGroups = [] } = yield select(selectActions);

    yield put(setLoading(true));

    const { status: lockStatus = "" } = yield call(lockOrderRequest, {
      deliveryMethod,
      recordId,
      sourceMemberCode,
    });

    if (lockStatus === "SUCCESS") {
      const { status: modifyStatus = "" } = yield call(followupOrderRequest, {
        deliveryMethod,
        orderUpdates,
        recordId,
        sourceMemberCode,
      });

      if (modifyStatus === "SUCCESS") {
        const rowNode = gridInstance?.api?.getRowNode(rowId);

        if (!rowNode || orderUpdates.length === 0) return;

        const actionsCount =
          (rowNode?.data?.actions?.filter((e) => e?.type !== "FOLLOW_UP")
            ?.length ?? 0) > 0;

        if (orderUpdates[0].value) {
          rowNode.setData({
            ...rowNode.data,
            actions: [
              ...rowNode.data.actions,
              {
                type: "FOLLOW_UP",
                category: "FULFILLMENT",
                severity: "Information",
              },
            ],
          });
        } else {
          const isNeedsActionActive =
            orderGroups.filter((e) => e.category === "actions").length > 0;

          if (isNeedsActionActive && !actionsCount) {
            rowNode.setRowHeight(0);
            gridInstance?.api?.onRowHeightChanged();
          } else {
            rowNode.setData({
              ...rowNode.data,
              actions: rowNode.data.actions.filter(
                (e) => e.type !== "FOLLOW_UP"
              ),
            });
          }
        }

        if (!actionsCount)
          yield put(
            setNeedsActionCount(
              orderUpdates[0].value
                ? Number(needsActionCount) + 1
                : Number(needsActionCount) - 1
            )
          );
      }
    }

    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    console.log(`Error in follow up order - ${error}`);
  }
}

function* handleOrderDetailActions(action = {}) {
  const { rowId = "", orderDetailAction = "" } = action?.payload ?? "";

  try {
    const findAction = [
      "add-follow-up",
      "remove-follow-up",
      "accept",
      "design",
      "designed",
      "out-for-delivery",
      "delivery-confirmation",
    ].find((item) => orderDetailAction.startsWith(item));
    const statusMap = {
      accept: "Accepted",
      design: "In Design",
      designed: "Designed",
      "out-for-delivery": "Out for Delivery",
      "delivery-confirmation": "Delivered",
    };

    if (!findAction) return;

    const gridInstance = yield select(selectGridInstance);
    const needsActionCount = yield select(selectNeedActionCount);
    const rowNode = gridInstance?.api?.getRowNode(rowId);

    if (!rowNode) return;

    if (findAction === "add-follow-up") {
      rowNode.setData({
        ...rowNode.data,
        actions: [
          ...rowNode.data.actions,
          {
            type: "FOLLOW_UP",
            category: "FULFILLMENT",
            severity: "Information",
          },
        ],
      });
      yield put(setNeedsActionCount(needsActionCount + 1));
    }

    if (findAction === "remove-follow-up") {
      rowNode.setData({
        ...rowNode.data,
        actions: rowNode.data.actions.filter((e) => e.type !== "FOLLOW_UP"),
      });
      yield put(setNeedsActionCount(needsActionCount - 1));
    }

    if (statusMap[findAction]) {
      rowNode.setData({
        ...rowNode.data,
        status: statusMap[findAction],
      });
    }
  } catch (error) {
    console.log(`Error while handling order detail actions - ${error}`);
  }
}

const processOrdersResponse = (api = []) => {
  const response = api.map((e) => ({
    ...e,
    id: `${e.id}***${Math.random() * 10}`, // To avoid duplicate identifiers (split using *** for finding the original id)
    designer: e.designer ? e.designer : { id: 0, name: "Unassigned" },
  }));

  return response;
};

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(setPageInitialise.type, handleSetGridDataSource);
  yield takeLatest(setGridDataSource.type, handleSetGridDataSource);
  yield takeLatest(triggerRefreshGrid.type, handleRefreshGrid);
  yield takeLatest(fetchDesigners.type, handleFetchDesigners);
  yield takeLatest(fetchOrders.type, handleFetchOrders);
  yield takeLatest(filterOrders.type, handleFilterOrders);
  yield takeLatest(fetchPrintDetails.type, handleFetchPrintDetails);
  yield takeLatest(triggerMeetBallAction.type, handleMeetBallAction);
  yield takeLatest(assignDesigner.type, handleSetAssignDesigner);
  yield takeLatest(followupOrder.type, handleFollowupOrder);
  yield takeLatest(orderDetailActions.type, handleOrderDetailActions);
  yield takeLatest(setPageActions.type, handleSetPageActions);
}

export default watchSaga;
