/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useContext } from "react";
import {
  View,
  TouchableOpacity,
  Platform,
  Image,
  ScrollView,
} from "react-native";
import { Text } from "react-native";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import tw from "tailwind-rn";
import moment from "moment";

import { useNavigation } from "@react-navigation/native";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { useDispatch, useSelector } from "react-redux";
import { ToasterHandler } from "components/elements";

import { request } from "library/utils/request";

import { fonts, colors, theme } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import UserProfileContext from "library/contexts/userProfile";
import UserProfileStorage from "library/storage/userProfile";

import {
  scanActionsMap,
  bulkScanActionLabel,
  desiredOrderActions,
} from "components/views/drawer/order-details/printHelper";
import { actionMap } from "components/views/drawer/order-details/order-head/order-breadcrumb/helper";
import { setEditOrder } from "library/sagas/views/home/drawer/create-order/slice";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import {
  generateQRCode,
  setShowRecipe,
  setShowPrint,
  setOrderDetailsFromBulkScan,
} from "library/sagas/ongoing/order-details/slice";
import {
  setActiveTab,
  setRecordId,
  setDeliveryMethod,
  setSourceMemberCode,
} from "library/sagas/ongoing/current-orders/slice";
import {
  setActionsQR,
  removeTileById,
  removeTileByIds,
} from "library/sagas/views/home/drawer/qr-scan/slice";

import {
  selectActionsQR,
  selectOrderActions,
  selectScannedOrders,
} from "library/sagas/views/home/drawer/qr-scan/selector";
import { getActionEligibleOrders } from "./helper";
import AssignDesigner from "./assign-designer";

const BulkScanActions = ({ setShowProcessing, pullOrders }) => {
  const dispatch = useDispatch();
  const scannedOrders = useSelector(selectScannedOrders);
  const actionsQR = useSelector(selectActionsQR);
  const navigation = useNavigation();

  const { Localise, messages } = React.useContext(I18NContext);
  const orderActionsList = useSelector(selectOrderActions);
  const [qrScanMenuConfig, setQrScanMenuConfig] = useStateIfMounted({});
  const [qrImageData, setQrImageData] = useStateIfMounted([]);
  const [detailView, setDetailView] = useStateIfMounted("");

  const { userProfile: { firstName: operator } = {} } =
    useContext(UserProfileContext);

  const isUnPaidOrder = (order) => {
    const { hasPendingPayLaterPayment, status } = order;
    return hasPendingPayLaterPayment && status !== "CANCELLED";
  };

  function rearrangeArray(inputArray) {
    const resultArray = [];

    Object.keys(desiredOrderActions).forEach((item) => {
      const index = inputArray.indexOf(item);
      if (index !== -1) {
        resultArray.push(inputArray[index]);
        inputArray.splice(index, 1);
      }
    });

    return resultArray;
  }

  useEffect(() => {
    if (Platform.OS === "web") {
      const qrPayload = [];
      uniq(Object.values(scanActionsMap)).map((each) =>
        qrPayload.push({ id: each })
      );
      // generating QR codes for all the order actions and keeping in redux.
      dispatch(
        generateQRCode({
          params: { qrPayload },
          type: "BULK",
          qrType: "ORDER_ACTIONS",
          resolve: (resp) => {
            setQrImageData(resp);
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    const actionsList = uniq(Object.values(orderActionsList).flat());
    const desiredActionsList = rearrangeArray(actionsList);

    const allowedStatusActions = desiredActionsList.map((each) => {
      return {
        name: desiredOrderActions[each],
        displayText: bulkScanActionLabel[each],
      };
    });
    getMoveToNextConfig(allowedStatusActions);
  }, [orderActionsList]);

  useEffect(() => {
    // validating actions QR.
    const validateQR = (val) => {
      if (val.id) {
        // checking the scanned qr is available in the actions list or not.
        if (Object.keys(qrScanMenuConfig).includes(val.id.toLowerCase())) {
          return true;
        } else {
          setShowProcessing(false);
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              scannedOrders?.length
                ? "Action not valid for this order"
                : "Please scan an order first and then scan the action QR code"
            )
          );
        }
      }
    };

    // If action is matching with MHQ Order actions then do the action otherwise show error.
    if (!isEmpty(actionsQR)) {
      // validate actions QR
      if (validateQR(actionsQR)) {
        // calling the handler
        onPressHandler(actionsQR?.id.toLowerCase());
        setShowProcessing(false);
      }
      // resetting actions qr
      dispatch(setActionsQR({}));
    }
  }, [actionsQR]);

  const getMoveToNextConfig = (allowedStatusActions) => {
    const isSingleOrder = scannedOrders.length === 1;
    let showRePrint = false;

    if (isSingleOrder) {
      const {
        orderLastModifiedDate,
        orderDetailsLastPrintedDate,
        internalStatus,
      } = scannedOrders[0];

      showRePrint =
        orderLastModifiedDate &&
        orderDetailsLastPrintedDate &&
        moment(orderLastModifiedDate).isAfter(orderDetailsLastPrintedDate) &&
        !["cancelled", "error", "forfeited", "rejected"].includes(
          internalStatus
        );
    }

    const moveToNextConfig =
      (!isEmpty(allowedStatusActions) &&
        allowedStatusActions
          .map((each) => {
            return {
              [each.name]: {
                buttonText: each.displayText,
                testId: `${each.name}`,
              },
            };
          })
          .reduce((acc, obj) => ({ ...acc, ...obj }))) ||
      {};

    const finalQrMenuConfig = {
      ...(isSingleOrder &&
        isUnPaidOrder(scannedOrders[0]) && {
          unpaid: {
            buttonText: "Take Payment",
            testId: "pay_later_order_payment_link",
          },
        }),
      ...moveToNextConfig,
      ...(isSingleOrder && {
        showrecipe: {
          buttonText: "Show Recipe",
          testId: "show_recipe",
        },
      }),
      ...(isSingleOrder &&
        showRePrint && {
          showreprint: {
            buttonText: "Reprint",
            testId: "re_print",
          },
        }),
      ...(isSingleOrder && {
        vieworder: {
          buttonText: "View Order",
          testId: "view_order",
        },
      }),
    };

    setQrScanMenuConfig(finalQrMenuConfig);
    setDetailView("");
  };

  const onPressHandler = (item) => {
    console.log("item - onPressHandler :>> ", item);
    if (
      ["accepted", "design", "designed", "delivery", "completed"].includes(item)
    ) {
      setShowProcessing(true);

      // getting the eligible orders to apply the action.
      const eligibleOrders = getActionEligibleOrders(
        scannedOrders,
        item,
        orderActionsList
      );
      const requests = eligibleOrders.map((each, index) => {
        const {
          orderItemId: recordId,
          deliveryMethod,
          receivingMember: { memberCode },
          deliveryDate,
        } = each || {};
        let shopTimeZone =
          UserProfileStorage.getZFormatShopTimeZone(memberCode);
        return request("order-actions", {
          recordId,
          deliveryMethod,
          sourceMemberCode: memberCode,
          action: actionMap[item],
          askId: "",
          operator: operator,
          isMessage: false,
          transferMemberCode: "",
          ...(item === "completed" && {
            deliveryDate: deliveryDate,
            deliveredDate: moment().utc().format("YYYY-MM-DD"),
            deliveryTime: moment().utc().format("HH:mm"),
            timeZone: shopTimeZone,
            dconAtDoor: false,
            photo: "",
            signature: "",
            invoiceNumber: "",
            driver: null,
            productDescription: "",
            isMobile: false,
            comments: "",
          }),
        });
      });

      Promise.allSettled(requests)
        .then((responses) => {
          if (!isEmpty(responses)) {
            const succeededOrders = [];
            responses.forEach((each, index) => {
              // incase of delivery confirmation - we are not setting recordId in the response.
              const recordId = eligibleOrders[index]?.orderItemId;
              if (
                each.status === "fulfilled" &&
                (each.value.response === "Success" || !isEmpty(each.value))
              ) {
                succeededOrders.push(each.recordId || recordId);
              }
            });
            // Removing the order tiles
            dispatch(removeTileByIds(succeededOrders));

            if (succeededOrders.length > 0) {
              pullOrders();
              ToasterHandler(
                "hooray",
                `${succeededOrders.length} ${Localise(
                  messages,
                  "order(s) updated."
                )} `
              );
            }
          }
          setShowProcessing(false);
        })
        .catch((error) => {
          console.log("Failed to get order(s) info -", error);
          setShowProcessing(false);
        });
    } else if (isUnPaidOrder(scannedOrders[0]) && item === "unpaid") {
      const {
        orderItemId: recordId,
        deliveryMethod,
        receivingMember: { memberCode },
      } = scannedOrders[0];

      dispatch(setEditOrder());
      // locking the order
      request("lock-order", {
        recordId: recordId,
        deliveryMethod: deliveryMethod,
      });
      dispatch(removeTileById(recordId));

      // navigating to edit order screen.
      navigation.navigate({
        name: "create-order",
        params: {
          id: recordId,
          action: "edit",
          smc: memberCode,
          dm: deliveryMethod,
        },
      });
      dispatch(setCurrentPage("create-order"));
    } else if (item === "assign") {
      setDetailView(item);
    } else {
      // This has code for the viewOrder / showRecipie / showRePrint
      const {
        orderItemId = "",
        deliveryMethod = "",
        direction = "",
        sendingMember,
        receivingMember,
      } = scannedOrders[0];

      dispatch(removeTileById(orderItemId));

      const sourceMemberCode =
        direction === "INBOUND"
          ? receivingMember?.memberCode
          : sendingMember?.memberCode;
      dispatch(setRecordId(orderItemId));
      dispatch(setDeliveryMethod(deliveryMethod));
      dispatch(setSourceMemberCode(sourceMemberCode));
      if (item === "showrecipe") {
        dispatch(setShowRecipe(true));
        dispatch(setActiveTab("order-summary"));
      } else if (item === "showreprint") {
        dispatch(setShowPrint(true));
      }
      navigation.navigate("orders");
    }
    dispatch(setOrderDetailsFromBulkScan(true));
  };

  return (
    <View>
      {scannedOrders.length > 0 ? (
        isEmpty(qrScanMenuConfig) ? (
          <View
            testID="loading_view"
            style={tw("flex items-center justify-center")}
          >
            <Text
              style={{
                ...fonts.heading3,
              }}
              testID="loading_text"
            >
              {Localise(
                messages,
                "There are no actions that apply to these orders"
              )}
            </Text>
          </View>
        ) : (
          <View
            testID="qr_scan_action"
            style={{
              maxHeight: `calc(100vh - 150px)`,
              ...tw("flex items-start w-full"),
              borderLeftWidth: 1,
              borderLeftColor: colors.grayScaleLight,
              paddingVertical: 10,
              paddingHorizontal: 15,
              minHeight: 300,
            }}
          >
            {detailView === "" ? (
              <>
                <View
                  style={{
                    ...tw("flex items-start justify-between"),
                    paddingLeft: 30,
                  }}
                >
                  <Text
                    style={{
                      ...fonts.heading2,
                    }}
                  >
                    {Localise(
                      messages,
                      "What would you like to do with the scanned orders?"
                    )}
                  </Text>
                  <Text style={tw("pb-2")}>
                    {Localise(
                      messages,
                      "Click or scan a code below to take action on the orders."
                    )}
                  </Text>
                </View>
                <ScrollView
                  contentContainerStyle={{
                    paddingHorizontal: 10,
                    ...tw("flex flex-row flex-wrap justify-between w-full"),
                  }}
                  style={tw("w-full")}
                  scrollEnabled={true}
                >
                  {Object.keys(qrScanMenuConfig).map((item, index) => {
                    const { buttonText, testId } = qrScanMenuConfig[item] || {};
                    const qr = qrImageData[item];
                    return (
                      <View
                        key={testId}
                        style={{
                          width: "38%",
                          ...tw("flex px-5 py-4 items-start justify-center"),
                        }}
                      >
                        {qr ? (
                          <View
                            style={{
                              minHeight: 115,
                              minWidth: 115,
                              ...tw("flex items-center justify-center"),
                            }}
                            testID="qr_image_container"
                          >
                            <Image
                              style={{
                                width: 110,
                                height: 110,
                              }}
                              resizeMode="cover"
                              source={{ uri: qr }}
                              testID={`qrImage_${testId}`}
                            />
                          </View>
                        ) : null}
                        <TouchableOpacity
                          onPress={() => onPressHandler(item)}
                          style={{
                            ...theme.Button.secondaryButtonStyle,
                            marginTop: 5,
                            ...tw("flex flex-row items-center"),
                            justifyContent: "center",
                            borderColor: colors.highlighter,
                            width: qr ? 115 : "100%",
                            paddingHorizontal: 10,
                          }}
                          testID={testId}
                          accessibilityLabel={testId}
                        >
                          <Text
                            style={{
                              ...theme.Button.secondaryTitleStyle,
                              color: colors.highlighter,
                              fontSize: 12,
                              textAlign: "center",
                            }}
                          >
                            {Localise(messages, buttonText)}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    );
                  })}
                </ScrollView>
              </>
            ) : (
              <AssignDesigner
                setDetailView={setDetailView}
                pullOrders={pullOrders}
              />
            )}
          </View>
        )
      ) : null}
    </View>
  );
};

export default React.memo(BulkScanActions);
