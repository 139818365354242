import React from "react";
import { View, Platform } from "react-native";
import { Text } from "react-native-elements";

import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import some from "lodash/some";
import get from "lodash/get";
import isObject from "lodash/isObject";
import toLower from "lodash/toLower";
import orderBy from "lodash/orderBy";
import tw from "tailwind-rn";
import moment from "moment";

import UserProfileStorage from "library/storage/userProfile";
import { DSRequestsColorCodes, colors } from "styles/theme";

import { shipmentDriverPickupManifestHTML } from "components/views/drawer/delivery/delivery-service-dashboard/listing-screen/helper.js";
import { PrintIframeAction, CustomModal } from "components/elements";
import { request } from "library/utils/request";

// DSForm Properties
export const DS_OPENING_HOUR = "07:00:00";
export const DS_CLOSING_HOUR = "20:00:00";

//For existing member codes with delivery Provider as ""(empty) default to "burq"(FTD Delivery Service)
export const defaultDeliveryProvider = "burq";

export const timedOrderBuffer = 20; //in minutes

export const cutOffTimeInterval = {
  //minutes
  roadie: 15,
  doordash: 15,
  "vida-doordash": 60, // 1hour
  vida: 60, // 1hour
  burq: 15,
};

export const undeliverableActions = [
  { label: "Return to Store", value: "Return to Store" },
  { label: "Leave at Location", value: "Leave at Location" },
];

export const isBurqProvider = (value = "") =>
  (value && value?.toLowerCase() === "burq") || false;

export const DSEstimatedFees = ({ Localise, messages, style = {} }) => {
  return (
    <View style={[style]}>
      <Text>{Localise(messages, "Delivery Service Charges:")}</Text>
      <Text>{Localise(messages, "$11.99 (Less than 7 miles)")}</Text>
      <Text>{Localise(messages, "$14.99 (7-15 miles)")}</Text>
    </View>
  );
};

export const IsCanceledBySender = (state, notes = []) =>
  state === "CANCELLED" &&
  (notes.length === 0 ||
    !some(notes, ["state", "CANCELLED"]) ||
    some(notes, ["note", "Cancelled by Sender"]));

export const IsCanceledByServiceProvider = (state, notes = []) =>
  state === "CANCELLED" &&
  notes.length !== 0 &&
  !some(notes, ["note", "Cancelled by Sender"]);

export const handleError = (
  err,
  ToasterHandler,
  Localise,
  locMessages,
  callBack = () => {},
  defaultMsg = "Something went wrong. Please try again!"
) => {
  let errorMessage = defaultMsg;
  if (!["ERROR", "CATCH ALL ERROR", "null"].includes(err) && err.length > 0) {
    errorMessage = err;
  }
  ToasterHandler("uh oh", Localise(locMessages, errorMessage));
  callBack();
};

export const processDSResponse = (DSRequests = [], init) => {
  const timeStamp = moment().utc().format();
  let DSRequestsCollection = cloneDeep(init);
  DSRequestsCollection["topNeedsAction"] = { list: [] };
  DSRequestsCollection["middleNeedsAction"] = { list: [] };
  DSRequestsCollection["bottomNeedsAction"] = { list: [] };

  !isEmpty(DSRequests) &&
    DSRequests.map((request) => {
      const recCity = get(request, "delivery.address.city");
      const recState = get(request, "delivery.address.state");
      const shopTimeZone = UserProfileStorage.getShopTimeZone(request.siteId);
      const floristTimeZone = shopTimeZone || "America/Chicago";
      //adding 20 mins to check is current time past pickuptime
      const currentFloristTime = convertToTimeZone(
        moment.utc().add(20, "minutes").format(),
        floristTimeZone
      );
      const pickUpDateTime = convertToTimeZone(
        request?.pickup?.timeWindow?.start,
        floristTimeZone
      );
      const isHurryPickup = currentFloristTime >= pickUpDateTime;
      const displayStatus = getDisplayStatus(request);
      const statusColor =
        DSRequestsColorCodes[
          displayStatus === "Pickup"
            ? isHurryPickup
              ? "HurryPickup"
              : "Pickup"
            : displayStatus
        ];
      const imageName = getImageName(request, isHurryPickup);
      const deliveryDate = convertToTimeZone(
        request.delivery.timeWindow.start,
        floristTimeZone
      );
      const displayDate = moment(deliveryDate).format("MM/DD/YYYY");
      const requestedDate = convertToTimeZone(
        request.createdDate,
        floristTimeZone
      );
      const pickupTime = moment(pickUpDateTime).format("h:mm A");
      const displayPrice = `$${
        get(request, "items.0.cost.value", "")
          ? parseFloat(get(request, "items.0.cost.value", "")).toFixed(2)
          : ``
      }`;
      const deliveryFee = parseFloat(
        get(request, "billing.deliveryFee.value", "")
      ).toFixed(2);
      const deliveryPrice = isNaN(deliveryFee) ? "" : `$${deliveryFee}`;
      const requestCategory = getRequestCategory(request, isHurryPickup);
      if (!requestCategory) return;
      const item = "Bouquet";
      const firstName = get(request, "delivery.address.firstName", "");
      const lastName = get(request, "delivery.address.lastName", "");
      const displayName = [firstName, lastName].join(" ").trim();
      DSRequestsCollection[requestCategory]?.list.push({
        ...cloneDeep(request),
        statusColor,
        displayStatus,
        imageName,
        displayPrice,
        deliveryPrice,
        displayDate,
        deliveryDate,
        requestedDate,
        recipientCity: recCity ? `${recCity}, ${recState}` : "",
        item,
        displayName,
        floristTimeZone,
        requestCategory,
        pickupTime,
        isHurryPickup,
      });
    });

  DSRequestsCollection["needsAction"].list = [
    ...DSRequestsCollection["needsAction"].list,
    ...DSRequestsCollection["topNeedsAction"].list,
    ...DSRequestsCollection["middleNeedsAction"].list,
    ...DSRequestsCollection["bottomNeedsAction"].list,
  ];
  delete DSRequestsCollection["topNeedsAction"];
  delete DSRequestsCollection["middleNeedsAction"];
  delete DSRequestsCollection["bottomNeedsAction"];
  return { timeStamp, DSRequestsCollection };
};

const getRequestCategory = (dsRequest, hurryPickup) => {
  const { state, notes } = dsRequest;

  if (["AT_PICKUP", "COURIER_ASSIGNED", "PICKUP_IN_PROGRESS"].includes(state))
    return hurryPickup ? "topNeedsAction" : "middleNeedsAction";
  if (
    [
      "SCHEDULED",
      "PICKUP_COMPLETE",
      "DELIVERY_IN_PROGRESS",
      "AT_DELIVERY",
      "STAGED",
      "CREATED",
    ].includes(state) ||
    getDSRequestRerouteStatus(dsRequest, "rerouting")
  )
    return "activeRequests";

  if (state === "DELIVERED") return "completedRequests";
  if (
    ["REJECTED_ACK", "CANCELLED_ACK", "RETURNED_ACK"].includes(state) ||
    IsCanceledBySender(state, notes)
  )
    return "cancelledRequests";
  if (
    [
      "ATTEMPTED_DELIVERY",
      "ENROUTE_TO_RETURN",
      "ARRIVED_AT_RETURN",
      "RETURNED",
      "REJECTED",
    ].includes(state) ||
    IsCanceledByServiceProvider(state, notes) ||
    getDSRequestRerouteStatus(dsRequest, "rerouteFail")
  )
    return "bottomNeedsAction";
};

export const getDisplayStatus = (dsRequest) => {
  const { state, notes } = dsRequest;

  return ["COURIER_ASSIGNED", "PICKUP_IN_PROGRESS", "AT_PICKUP"].includes(state)
    ? "Pickup"
    : ["SCHEDULED", "STAGED", "CREATED"].includes(state) ||
      getDSRequestRerouteStatus(dsRequest, "rerouting")
    ? "Scheduled"
    : ["PICKUP_COMPLETE", "DELIVERY_IN_PROGRESS", "AT_DELIVERY"].includes(state)
    ? "Delivery"
    : [
        "ATTEMPTED_DELIVERY",
        "ENROUTE_TO_RETURN",
        "ARRIVED_AT_RETURN",
        "RETURNED",
        "REJECTED",
      ].includes(state) ||
      IsCanceledByServiceProvider(state, notes) ||
      getDSRequestRerouteStatus(dsRequest, "rerouteFail")
    ? "Fulfillment Error"
    : ["REJECTED_ACK", "CANCELLED_ACK", "RETURNED_ACK"].includes(state) ||
      IsCanceledBySender(state, notes)
    ? "Cancelled"
    : state === "DELIVERED"
    ? "Completed"
    : "Scheduled";
};

export const getDSRequestRerouteStatus = (dsRequest = {}, status) => {
  const {
    state,
    notes,
    events,
    vendor: { name: DsProviderName = "" } = {},
  } = dsRequest;

  if (!isBurqProvider(DsProviderName)) return false;

  if (status === "rerouting") {
    return (
      IsCanceledByServiceProvider(state, notes) &&
      !events.some((each) =>
        ["REROUTED", "REROUTE_FAIL"].includes(each.eventType)
      ) &&
      events.some((each) => ["REROUTING"].includes(each.eventType))
    );
  } else if (status === "rerouteFail") {
    return (
      IsCanceledByServiceProvider(state, notes) &&
      events.some((each) => ["REROUTE_FAIL"].includes(each.eventType))
    );
  }
  return false;
};

const getImageName = (dsRequest, isHurry) => {
  const { state, notes } = dsRequest;
  return ["COURIER_ASSIGNED", "PICKUP_IN_PROGRESS", "AT_PICKUP"].includes(state)
    ? isHurry
      ? "delivery-service-red-hurry"
      : "delivery-service-design"
    : ["SCHEDULED", "STAGED", "CREATED"].includes(state) ||
      getDSRequestRerouteStatus(dsRequest, "rerouting")
    ? "delivery-service-design"
    : ["PICKUP_COMPLETE", "DELIVERY_IN_PROGRESS", "AT_DELIVERY"].includes(state)
    ? "delivery-service-delivery"
    : [
        "ATTEMPTED_DELIVERY",
        "ENROUTE_TO_RETURN",
        "ARRIVED_AT_RETURN",
        "RETURNED",
        "REJECTED",
      ].includes(state) ||
      IsCanceledByServiceProvider(state, notes) ||
      getDSRequestRerouteStatus(dsRequest, "rerouteFail")
    ? "delivery-service-error"
    : ["REJECTED_ACK", "CANCELLED_ACK", "RETURNED_ACK"].includes(state) ||
      IsCanceledBySender(state, notes)
    ? "delivery-service-cancelled"
    : state === "DELIVERED"
    ? "delivery-service-completed"
    : "delivery-service";
};

export const convertToTimeZone = (date, tzString) => {
  if (date === "") return "";
  let convTime = moment.utc(date).tz(tzString).format("YYYY-MM-DDTHH:mm:ss");
  return convTime;
};

export const refineDSListings = (
  DSRequests,
  DSCategory,
  { filters = [], sort = {}, search = {} }
) => {
  DSRequests = DSRequests.filter((DSRequest) => {
    let matchesFilter = true;

    //filter DS Requests based on Search query
    if (!isEmpty(search) && matchesFilter) {
      matchesFilter =
        toLower(DSRequest?.referenceId).includes(toLower(search.value)) ||
        toLower(
          get(DSRequest, "delivery.address.firstName") +
            " " +
            get(DSRequest, "delivery.address.lastName")
        ).includes(toLower(search.value)) ||
        toLower(get(DSRequest, "delivery.address.city")).includes(
          toLower(search.value)
        ) ||
        toLower(
          DSRequest?.displayStatus?.replace("Cancelled", "Canceled")
        ).includes(toLower(search.value)) ||
        toLower(DSRequest?.siteId).includes(toLower(search.value)) ||
        toLower(get(DSRequest, "billing.invoiceNumber")).includes(
          toLower(search.value)
        );
    }

    const filterCategoryMap = {};

    if (filters.length) {
      filters.map((e) => {
        let [type, { value }] = Object.entries(e).pop();
        if (type !== "ApproachingDrivers") {
          filterCategoryMap[type] = filterCategoryMap[type]
            ? filterCategoryMap[type].concat(":" + value)
            : value;
        } else {
          let initialVal = "";
          value.map((data) => {
            initialVal = initialVal + data.title + "::" + data.value;
          });
          filterCategoryMap[type] = filterCategoryMap[type]
            ? filterCategoryMap[type].concat("," + initialVal)
            : initialVal;
        }
      });
    }
    if (filters.length && matchesFilter) {
      // Filtering list by filters
      matchesFilter = Object.keys(filterCategoryMap).reduce(
        (matches, category) => {
          const value = filterCategoryMap[category];
          // filters a day, week or month newer or older orders
          if (matches && category === "Date Range") {
            const today = moment();
            if (!isObject(value)) {
              const multiValues = value.split(":");
              const results = multiValues.filter((value) => {
                const diffInDays = Math.ceil(
                  moment(DSRequest?.displayDate).diff(today, "days", true)
                );

                if (value == 0 && diffInDays == 0) return true;

                if (value == 1 && diffInDays == 1) return true;

                if (value > 1 && diffInDays >= 1 && diffInDays <= value)
                  return true;

                if (value < 0 && diffInDays < 0 && diffInDays >= value)
                  return true;
              });
              matches = results.length ? true : false;
            }
          }

          // filter DS Requests by status
          if (matches && category === "Delivery Service Request Status") {
            const multiValues = value.split(":");
            const results = multiValues.filter((value) => {
              return value === DSRequest.displayStatus;
            });

            matches = results.length ? true : false;
          }

          // filter DS Requests based on source
          if (matches && category === "Type") {
            const multiValues = value.split(":");
            const result = multiValues.filter((value) => {
              return value === DSRequest.source;
            });

            matches = result.length ? true : false;
          }

          // filter DS Requests based on ApproachingDrivers quick filters
          if (matches && category === "ApproachingDrivers") {
            const multiValues = value.split(",");
            let valuesTobeChecked = [];
            const data = multiValues.map((data) => {
              const [title, value] = data.split("::");
              valuesTobeChecked.push(title);
              return value;
            });
            const filterMap = {
              "Display Date": `displayDate`,
              "Pickup Time": `pickupTime`,
              "Courier Name": `courier.name`,
              ShopCode: `siteId`,
            };

            let matched = true;
            valuesTobeChecked.map((each) => {
              if (!data.includes(get(DSRequest, filterMap[each])))
                matched = data.includes(get(DSRequest, filterMap[each]));
            }).length;

            return matched;
          }

          return matches;
        },
        true
      );
    }

    if (matchesFilter) return DSRequest;
  });

  const sortNeedsAction = (direction = "asc") => {
    let DSList = {
      needsAction: [],
      topNeedsAction: [],
      middleNeedsAction: [],
      bottomNeedsAction: [],
    };
    DSRequests.map((request) => {
      DSList[request.requestCategory].push(request);
    });
    DSList.needsAction = orderBy(DSList.needsAction, "displayDate", direction);
    DSList.topNeedsAction = orderBy(
      DSList.topNeedsAction,
      "displayDate",
      direction
    );
    DSList.middleNeedsAction = orderBy(
      DSList.middleNeedsAction,
      "displayDate",
      direction
    );
    DSList.bottomNeedsAction = orderBy(
      DSList.bottomNeedsAction,
      "displayDate",
      direction
    );

    return [
      ...DSList.needsAction,
      ...DSList.topNeedsAction,
      ...DSList.middleNeedsAction,
      ...DSList.bottomNeedsAction,
    ];
  };

  if (isEmpty(sort) || sort.value === "Delivery Date: Old to New") {
    if (DSCategory === "needsAction") {
      DSRequests = sortNeedsAction("asc");
    } else {
      DSRequests = orderBy(DSRequests, "displayDate", "asc");
    }
  } else if (sort.value === "Delivery Date: New to Old") {
    if (DSCategory === "needsAction") {
      DSRequests = sortNeedsAction("desc");
    } else {
      DSRequests = orderBy(DSRequests, "displayDate", "desc");
    }
  }

  return DSRequests;
};

export const getErrorMessage = (dsRequestDetails) => {
  const {
    state,
    notes,
    vendor: { name: DsProviderName = "", responseMessage = "" } = {},
  } = dsRequestDetails;

  const errorMessageForRejectedDSRequest = getErrorMessageForRejectedDSRequest(
    responseMessage,
    state,
    notes
  );
  return ["RETURNED"].includes(state)
    ? "Returned by Delivery Service"
    : ["REJECTED"].includes(state)
    ? errorMessageForRejectedDSRequest?.length > 200
      ? errorMessageForRejectedDSRequest.slice(0, 200) + "..."
      : errorMessageForRejectedDSRequest
    : IsCanceledByServiceProvider(state, notes)
    ? isBurqProvider(DsProviderName)
      ? getDSRequestRerouteStatus(dsRequestDetails, "rerouteFail")
        ? `Canceled by Delivery Service`
        : getDSRequestRerouteStatus(dsRequestDetails, "rerouting")
        ? "Request canceled by delivery service. Rerouting to another provider"
        : "Request canceled by the Delivery Service"
      : "Request canceled by the Delivery Service"
    : "Unknown error occurred";
};

export const getErrorMessageForRejectedDSRequest = (
  vendorResponseMessage,
  state,
  notes = []
) => {
  let errorMessage;
  if (IsDSRequestRejectedDueToTechnicalError(state, notes)) {
    const cancelled_notes = notes.filter((note) => note?.state === "CANCELLED");
    errorMessage =
      cancelled_notes.length != 0
        ? cancelled_notes[cancelled_notes.length - 1]?.note || ""
        : "";
  } else {
    errorMessage = vendorResponseMessage ? vendorResponseMessage : "";
  }
  return errorMessage;
};

export const printCallBack = (
  pickupDriverBatch,
  printType,
  create = true,
  getShipments
) => {
  const { shipments = [], reqParams } = pickupDriverBatch;
  const { siteId: shopCode } = shipments[0];
  //if we don't have entry in DB, create an entry
  if (create) {
    request("create-pickup-manifest-print-status", {
      shopCode,
      body: {
        ...reqParams,
        printed: true,
        printType,
      },
    }).then(() => {
      getShipments(printType === "AUTO");
    });
  } else {
    //if we already have entry for print hash, patch it
    request("update-pickup-manifest-print-status", {
      shopCode,
      ...reqParams,
      body: {
        printed: true,
        printType,
      },
    }).then(() => {
      getShipments(printType === "AUTO");
    });
  }
};

export const printPickupManifest = (
  pickupDriverBatch,
  printType,
  create,
  getShipments,
  Localise,
  messages
) => {
  let iframeId = "iframeId";
  let printParams = {};
  const { name: driverName = "", shipments = [] } = pickupDriverBatch;
  const { pickupTime, siteId: shopCode } = shipments[0];
  const shopsPreferences = UserProfileStorage.getAllShopPreferences();

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";
  const macAddress =
    isElectronApp && document.getElementById("macAddress").value;
  if (isElectronApp) {
    if (!macAddress) return;

    const preferences = shopsPreferences[shopCode];
    iframeId = `iframeForPickupManifest_${shopCode}`;

    const pickupManifestPreferencesArray =
      preferences["pickup_manifest_print_settings"] || [];

    const pickupManifestPreferences = pickupManifestPreferencesArray.find(
      (each) => each.macAddress === macAddress
    );

    if (printType === "AUTO" && !pickupManifestPreferences?.auto_print) return;

    printParams = {
      printType: "pickupManifest",
      preferences: pickupManifestPreferences,
    };
  }

  PrintIframeAction(
    shipmentDriverPickupManifestHTML(
      Localise,
      messages,
      shipments,
      driverName,
      pickupTime,
      isElectronApp ? iframeId : "iframe"
    ),
    printParams,
    () => {
      printCallBack(pickupDriverBatch, printType, create, getShipments);
    },
    iframeId
  );
};

//pickup Time field options for Doordash - DS tasks/Orders
export const getPickupTimeOptions = (
  pickupTimeWindow,
  isSameDay,
  fieldTime,
  cutOffTime,
  pickUpDateTime,
  modify = false
) => {
  const ExpressTimeOptions = [
    {
      label: "---------",
      value: null,
      enabled: false,
    },
    {
      label: "Express",
      value: null,
      enabled: false,
    },
    {
      label: "7:00 AM",
      secondaryLabel: "Express",
      value: "07:00 AM Express",
    },
    {
      label: "7:30 AM",
      secondaryLabel: "Express",
      value: "07:30 AM Express",
    },
    {
      label: "8:00 AM",
      secondaryLabel: "Express",
      value: "08:00 AM Express",
    },
    {
      label: "8:30 AM",
      secondaryLabel: "Express",
      value: "08:30 AM Express",
    },
    {
      label: "9:00 AM",
      secondaryLabel: "Express",
      value: "09:00 AM Express",
    },
    {
      label: "9:30 AM",
      secondaryLabel: "Express",
      value: "09:30 AM Express",
    },
    {
      label: "10:00 AM",
      secondaryLabel: "Express",
      value: "10:00 AM Express",
    },
    {
      label: "10:30 AM",
      secondaryLabel: "Express",
      value: "10:30 AM Express",
    },
    {
      label: "11:00 AM",
      secondaryLabel: "Express",
      value: "11:00 AM Express",
    },
    {
      label: "11:30 AM",
      secondaryLabel: "Express",
      value: "11:30 AM Express",
    },
    {
      label: "12:00 PM",
      secondaryLabel: "Express",
      value: "12:00 PM Express",
    },
    {
      label: "12:30 PM",
      secondaryLabel: "Express",
      value: "12:30 PM Express",
    },
    {
      label: "1:00 PM",
      secondaryLabel: "Express",
      value: "13:00 PM Express",
    },
    {
      label: "1:30 PM",
      secondaryLabel: "Express",
      value: "13:30 PM Express",
    },
    {
      label: "2:00 PM",
      secondaryLabel: "Express",
      value: "14:00 PM Express",
    },
    {
      label: "2:30 PM",
      secondaryLabel: "Express",
      value: "14:30 PM Express",
    },
    {
      label: "3:00 PM",
      secondaryLabel: "Express",
      value: "15:00 PM Express",
    },
    {
      label: "3:30 PM",
      secondaryLabel: "Express",
      value: "15:30 PM Express",
    },
    {
      label: "4:00 PM",
      secondaryLabel: "Express",
      value: "16:00 PM Express",
    },
    {
      label: "4:30 PM",
      secondaryLabel: "Express",
      value: "16:30 PM Express",
    },
    {
      label: "5:00 PM",
      secondaryLabel: "Express",
      value: "17:00 PM Express",
    },
    {
      label: "5:30 PM",
      secondaryLabel: "Express",
      value: "17:30 PM Express",
    },
    {
      label: "6:00 PM",
      secondaryLabel: "Express",
      value: "18:00 PM Express",
    },
    {
      label: "6:30 PM",
      secondaryLabel: "Express",
      value: "18:30 PM Express",
    },
    {
      label: "7:00 PM",
      secondaryLabel: "Express",
      value: "19:00 PM Express",
    },
    {
      label: "7:30 PM",
      secondaryLabel: "Express",
      value: "19:30 PM Express",
    },
    {
      label: "8:00 PM",
      secondaryLabel: "Express",
      value: "20:00 PM Express",
    },
  ];

  const StdHeaderTimeOption = {
    label: "Standard",
    value: null,
    enabled: false,
  };
  const Std9AMTimeOption = {
    label: "9:00 AM ", // added Space after 9:00 AM as label should be unique for dropList
    secondaryLabel: "Standard",
    value: "09:00 AM Standard",
  };
  const Std3PMTimeOption = {
    label: "3:00 PM ", // added Space after 3:00 PM as label should be unique for dropList
    secondaryLabel: "Standard",
    value: "15:00 PM Standard",
  };
  const Std12PMTimeOption = {
    label: "12:00 PM ", // added Space after 12:00 PM as label should be unique for dropList
    secondaryLabel: "Standard",
    value: "12:00 PM Standard",
  };

  const allOptions = [
    StdHeaderTimeOption,
    Std9AMTimeOption,
    Std12PMTimeOption,
    Std3PMTimeOption,
    ...ExpressTimeOptions,
  ];

  let pickupTimeOptions = [];

  if (pickupTimeWindow.length === 2) {
    [StdHeaderTimeOption, Std9AMTimeOption, Std3PMTimeOption].map((each) =>
      pickupTimeOptions.push(each)
    );
  } else if (pickupTimeWindow.length === 1) {
    if (pickupTimeWindow[0] === "09") {
      [StdHeaderTimeOption, Std9AMTimeOption].map((each) =>
        pickupTimeOptions.push(each)
      );
    } else {
      [StdHeaderTimeOption, Std3PMTimeOption].map((each) =>
        pickupTimeOptions.push(each)
      );
    }
  } else {
    [
      StdHeaderTimeOption,
      Std9AMTimeOption,
      Std12PMTimeOption,
      Std3PMTimeOption,
    ].map((each) => pickupTimeOptions.push(each));
    pickupTimeOptions.push(...ExpressTimeOptions);
  }

  pickupTimeOptions = pickupTimeOptions.map((option) => {
    if (!option.value) return option;
    const { enabled = true, secondaryLabel = "", value } = option;
    let isEnabled = enabled;
    //Disabling past times for same day DS task/order
    if (isSameDay) {
      const dateTime = moment(
        moment(fieldTime || cutOffTime).format("YYYY-MM-DD") +
          "T" +
          option.value.split(" ")[0] +
          ":00"
      );

      isEnabled =
        isEnabled && moment(cutOffTime).isSameOrBefore(moment(dateTime));
    }
    //Allow to modify to same fulfillmentType - Modify Task
    if (pickUpDateTime && modify) {
      isEnabled = isEnabled && pickUpDateTime.includes(secondaryLabel);
    }
    return {
      ...option,
      ...(Platform.OS === "ios"
        ? { value: isEnabled ? value : null }
        : { value: value }),
      enabled: isEnabled,
    };
  });

  //In case of pickupTimeWindow update if selected pickupTime is not available, adding it and disabling it
  if (
    pickUpDateTime &&
    pickupTimeOptions.findIndex((option) => option.value === pickUpDateTime) ===
      -1
  ) {
    pickupTimeOptions.push({
      ...allOptions.find((option) => option.value === pickUpDateTime),
      enabled: false,
    });
  }

  return pickupTimeOptions;
};

//to get minimun available pickup date for DS tasks/Orders
export const getMinPickupDate = (
  shopCode,
  timezone,
  dsMinDate,
  pickUpTimeWindow
) => {
  let updatedDate = convertToTimeZone(moment.utc().format(), timezone);
  const currentTime = moment(moment(dsMinDate).format("HH:mm:ss"), "HH:mm:ss");
  if (shopCode && timezone) {
    let closingTime = moment(
      `${moment(dsMinDate).format("YYYY-MM-DD")}T${DS_CLOSING_HOUR}`,
      "YYYY-MM-DDTHH:mm:ss"
    );

    const isSameDay = moment(updatedDate).isSame(dsMinDate, "day");
    if (isSameDay) {
      let newUpdatedDate = convertToTimeZone(
        moment.utc().add(1, "days").format(),
        timezone
      );
      if (moment(dsMinDate).isAfter(closingTime)) {
        updatedDate = moment(newUpdatedDate).toDate();
      } else if (pickUpTimeWindow.length) {
        let timeWindow1 = moment(pickUpTimeWindow[0] + ":00:00", "HH:mm:ss");
        let timeWindow2 = moment(pickUpTimeWindow[1] + ":00:00", "HH:mm:ss");
        if (
          currentTime.isAfter(timeWindow1) &&
          currentTime.isAfter(timeWindow2)
        ) {
          updatedDate = moment(newUpdatedDate).toDate();
        }
      }
    }
  }

  return updatedDate;
};

//To get Active shipment deatils from shipmentsByRefeenceId
export const getActiveShipment = (shipments = []) => {
  let activeShipment = {};
  shipments?.map((DSRequest) => {
    const isActiveShipment = !["Cancelled", "Fulfillment Error"].includes(
      getDisplayStatus(DSRequest)
    );
    if (isActiveShipment) activeShipment = cloneDeep(DSRequest);
  });
  return activeShipment;
};

//modal when DS cancel failed when DS and order cancel
export const OrderDSCancelFailModal = ({
  modalVisible = false,
  errorMessage = "",
  primaryhandler = () => {},
  secondaryhandler = () => {},
  Localise,
  messages,
  confirmMessage = "Do you still want to proceed with Order cancelation",
}) => {
  const modalContent = {
    content: (
      <View style={{ textAlign: "center" }}>
        <Text
          style={{
            ...tw("px-4 pt-4"),
            fontSize: 15,
            color: colors.highlighter,
          }}
        >
          {Localise(
            messages,
            "Unable to cancel Delivery Service request because"
          )}{" "}
          {errorMessage || ""}
        </Text>
        <Text
          style={{
            ...tw("px-4 pb-4"),
            fontSize: 15,
            color: colors.highlighter,
          }}
        >
          {Localise(messages, `${confirmMessage}`)}?
        </Text>
      </View>
    ),
    buttons: [
      { type: "secondary", title: Localise(messages, "Nevermind") },
      { type: "primary", title: Localise(messages, "Continue") },
    ],
  };

  return (
    <CustomModal
      modalVisible={modalVisible}
      modalContent={modalContent}
      primaryhandler={primaryhandler}
      secondaryhandler={secondaryhandler}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: "white" },
      ]}
      modalStyle={
        Platform.OS !== "web"
          ? {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: "#FFFFFF",
            }
          : {
              width: "35%",
            }
      }
    />
  );
};

export const IsDSRequestRejectedDueToTechnicalError = (
  dsRequestState,
  notes = []
) =>
  ["REJECTED", "REJECTED_ACK"].includes(dsRequestState) &&
  notes.length != 0 &&
  some(notes, ["state", "CANCELLED"]);

export const getDateRangeFromFlexDates = (
  deliveryFlexDates = "",
  routeDeliveryDate
) => {
  const flexDates = deliveryFlexDates.split(",") || [];
  const isFlexDate = flexDates.includes(
    moment(routeDeliveryDate).format("YYYY-MM-DD")
  );
  const startDate = isFlexDate
    ? flexDates[0]
    : moment(routeDeliveryDate).format("YYYY-MM-DD");
  const endDate = isFlexDate
    ? flexDates[flexDates.length - 1]
    : moment(routeDeliveryDate).format("YYYY-MM-DD");

  return { startDate, endDate };
};

export const fetchZoneDetails = async ({
  latitude = "",
  longitude = "",
  shopCode = "",
}) => {
  if (!latitude || !longitude || !shopCode) return [];

  let zoneDetails = [];
  await request("get-zone-details", {
    latitude,
    longitude,
    shopCode,
  })
    .then((res) => {
      zoneDetails = res;
    })
    .catch((err) => {
      console.log("Unable to fetch Zone details: >>", err);
    });

  return zoneDetails;
};
