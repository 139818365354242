import React, { useState, useEffect } from "react";
import { View, ActivityIndicator } from "react-native";
import { Input, Icon, Text } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { useNavigation, useRoute } from "@react-navigation/native";

import { BulkActionHeader } from "../header";
import { Widget } from "../helper";
import {
  LabelField,
  SecondaryButton,
  ConfirmModal,
  PrimaryButton,
  SearchResults,
  FilterResults,
  Currency,
} from "components/wrappers";
import { Switch, ToasterHandler } from "components/elements";
import { Form, FormField, FormFieldSwitch } from "components/elements/forms";
import Search from "components/containers/search";
import { PriceMinimumProductsFilter } from "./ui-config";
import { getValidationSchemaPrice } from "./yup";

import {
  selectAllowNewProducts,
  selectCatalogPriceMinimum,
  selectCatalogPriceMinimumValue,
  selectPriceMinimumBulkActionType,
  selectPriceMinimumProductsSelected,
  selectPriceMinimumCurrentLimit,
  selectPriceMinimumSearchText,
  selectPriceMinimumFilter,
  selectIsRulesFetched,
  selectAllowSyndicate,
  selectShopCode,
  selectScheduledPriceAdjustment,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";
import {
  setSideCar,
  saveCatalogSettings,
  setPriceMinimumAction,
  setPriceMinimumProductSelection,
  navigateScreen,
} from "library/sagas/views/home/drawer/product-catalog/common/slice";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import { fonts, theme } from "styles/theme";
import tw from "tailwind-rn";
import Filters from "components/containers/listing-new/header/controls/filters";

export const Settings = ({ sideCar = "" }) => {
  const dispatch = useDispatch();
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const { isMobile } = React.useContext(DeviceContext);
  const navigation = useNavigation();

  const { Localise, messages } = React.useContext(I18NContext);
  const allowNewProducts = useSelector(selectAllowNewProducts);
  const catalogPriceMinimumPrice = useSelector(selectCatalogPriceMinimumValue);
  const rules = useSelector(selectScheduledPriceAdjustment) || [];
  const { name: routeName = "" } = useRoute();

  const isShopSettingsScreen = routeName === "catalog-settings";
  const initialValues = {
    allowNewProducts,
    catalogPriceMinimumPrice,
    onSuccess: () => {},
    onFailure: () => {},
  };
  const onSubmit = ({ onSuccess, onFailure, ...values }, formikBag) => {
    dispatch(
      saveCatalogSettings({
        params: values,
        resolve: () => {
          ToasterHandler(
            "looking good",
            Localise(messages, "Catalog Settings have been updated")
          );
          formikBag.setSubmitting(false);
          onSuccess && onSuccess();
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              `Catalog Settings didn’t save, please try again.`
            )
          );
          formikBag.setSubmitting(false);
          onSuccess && onFailure();
        },
      })
    );
  };

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={() => (
          <View style={tw("flex flex-col")}>
            <AllowNewProducts />
          </View>
        )}
      />

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={true}
        validationSchema={getValidationSchemaPrice(Localise, messages)}
        validateOnBlur={false}
        render={() => (
          <View style={tw("flex flex-col")}>
            <CatalogPriceMinimum sideCar={sideCar} />
          </View>
        )}
      />
      {!allowSyndicate && (
        <View style={tw("mt-5")}>
          <Text
            style={{
              width: "100%",
              ...fonts.heading4,
              paddingBottom: isMobile ? 15 : 0,
            }}
          >
            {Localise(messages, "Apply Price Adjustment Rules")}
          </Text>
          <Text style={{ ...fonts.style1, paddingTop: 10 }}>
            {Localise(
              messages,
              "Adjust product prices based on specific days or date ranges."
            )}{" "}
            {rules.length > 0 ? (
              <Text style={{ ...fonts.style1, paddingTop: 5 }}>
                {Localise(
                  messages,
                  `There ${
                    rules.length === 1
                      ? `is currently ${rules.length} rule`
                      : `are currently ${rules.length} rules`
                  } configured for this store`
                )}{" "}
              </Text>
            ) : null}
          </Text>
          <SecondaryButton
            title={Localise(messages, "Manage Rules")}
            style={{ width: 100, marginTop: 10 }}
            action={() =>
              isShopSettingsScreen
                ? navigation.navigate("product-catalog", {
                    screen: "priceAdjustments",
                    params: {
                      handle: "rules",
                    },
                  })
                : dispatch(
                    navigateScreen({
                      name: "priceAdjustments",
                      params: { handle: "rules" },
                    })
                  )
            }
          />
        </View>
      )}
    </>
  );
};

const AllowNewProducts = () => {
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile } = React.useContext(DeviceContext);
  const { setValues, setFieldValue, submitForm, values } = useFormikContext();
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const { allowNewProducts } = values;

  const onSubmit = () => {
    setLoading(true);
    setValues({
      allowNewProducts: !allowNewProducts,
      onSuccess: () => setLoading(false),
      onFailure: () => {
        setFieldValue("allowNewProducts", allowNewProducts);
        setLoading(false);
      },
    });
    submitForm();
  };

  return (
    <View style={tw("flex flex-col mb-6")}>
      <View style={tw("flex flex-row flex-wrap items-center")}>
        <View style={tw("flex flex-row items-center pr-5")}>
          <Text
            style={{
              width: 275,
              ...fonts.heading4,
              paddingBottom: isMobile ? 15 : 0,
            }}
          >
            {Localise(messages, "Auto Merchandise New FTD Products")}
          </Text>

          <FormFieldSwitch
            name={"allowNewProducts"}
            value={allowNewProducts}
            onValueChange={() => {
              allowSyndicate ? setShowPopup(!showPopup) : onSubmit();
            }}
          />
          {!isMobile && (
            <LabelField
              text={allowNewProducts ? "Active" : "Inactive"}
              style={{ ...fonts.heading5, paddingHorizontal: 10, marginTop: 3 }}
            />
          )}
          {loading && <ActivityIndicator size={"small"} />}
        </View>
      </View>
      <Text style={{ ...fonts.style1, paddingTop: 10 }}>
        {Localise(
          messages,
          "Newly added FTD Products will automatically appear active in your Catalogs"
        )}
      </Text>
      <ConfirmModal
        modalVisible={!!showPopup}
        handlePrimary={() => {
          onSubmit();
          setShowPopup(!showPopup);
        }}
        handleSecondary={() => {
          setShowPopup(!showPopup);
        }}
        data={{
          modal: {
            primary: "Update",
            content: Localise(
              messages,
              "This change will apply to All shops and will override any local shop changes."
            ),
          },
        }}
      />
    </View>
  );
};

const CatalogPriceMinimum = ({ sideCar }) => {
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const [active, setActive] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile, isDesktop } = React.useContext(DeviceContext);
  const currency = Currency(shopCode);
  const {
    setValues,
    setFieldValue,
    submitForm,
    errors: { catalogPriceMinimumPrice: error },
    dirty,
    values,
  } = useFormikContext();
  const [loading, setLoading] = useState(false);
  const { catalogPriceMinimumPrice } = values;
  const status = useSelector(selectIsRulesFetched);
  const buttonProps = {
    width: 100,
    paddingLeft: 10,
  };

  const onPress = () => {
    const sideCarTitle = sideCar ? sideCar : setSideCar("priceMinimumProducts");
    showLink && dispatch(sideCarTitle);
  };

  const onSubmit = (newValues) => {
    setLoading(true);
    setValues({
      ...newValues,
      onSuccess: () => setLoading(false),
      onFailure: () => {
        setFieldValue("catalogPriceMinimumPrice", catalogPriceMinimumPrice);
        setLoading(false);
      },
    });
    submitForm();
  };

  useEffect(() => {
    const priceMinimumApplied =
      !!catalogPriceMinimumPrice && parseFloat(catalogPriceMinimumPrice) > 0;
    setActive(priceMinimumApplied);
    setShowLink(priceMinimumApplied);
  }, [status]);

  useEffect(() => {
    const priceMinimumApplied =
      !!catalogPriceMinimumPrice && parseFloat(catalogPriceMinimumPrice) > 0;
    setShowLink(priceMinimumApplied && !dirty);
  }, [catalogPriceMinimumPrice, dirty]);

  return (
    <View style={tw("flex flex-col my-6")}>
      <View style={tw("flex flex-row flex-wrap items-center")}>
        <View style={tw("flex flex-row items-center")}>
          <Text
            style={{
              width: 275,
              ...fonts.heading4,
              paddingBottom: isMobile ? 15 : 0,
            }}
          >
            {Localise(messages, "Apply Product Minimum to Entire Catalog")}
          </Text>
          <Switch
            value={active}
            onValueChange={(e) => {
              setActive(e);
              if (showLink && !e)
                allowSyndicate
                  ? setShowPopup(!showPopup)
                  : onSubmit({ catalogPriceMinimumPrice: "0.00" });
            }}
            circleSize={16}
            changeValueImmediately={true}
            renderActiveText={false}
            renderInActiveText={false}
            switchLeftPx={1.25}
            switchRightPx={1.25}
            switchWidthMultiplier={2.5}
            switchBorderRadius={30}
          />
          {!isMobile && (
            <LabelField
              text={active ? "" : "Inactive"}
              style={{ ...fonts.heading5, paddingHorizontal: 10, marginTop: 3 }}
            />
          )}

          {!active && loading && <ActivityIndicator size={"small"} />}
        </View>

        {active && (
          <>
            <FormField
              name={"catalogPriceMinimumPrice"}
              isPrice={true}
              containerStyle={{
                width: 100,
                height: isMobile ? 35 : 30,
                paddingLeft: isMobile ? 0 : 5,
              }}
              inputContainerStyle={{ height: !isDesktop ? 35 : 30, padding: 6 }}
              errorStyle={{ display: "none" }}
              leftIconContainerStyle={{
                paddingRight: 0,
              }}
              isUpdateOnChange={true}
              onChange={() => {
                setShowLink(false);
              }}
              iconType={"material-community"}
              iconSize={14}
              iconName={"currency-usd"}
              currency={currency}
            />

            <PrimaryButton
              title={"Apply"}
              action={() => {
                allowSyndicate
                  ? setShowPopup(!showPopup)
                  : onSubmit({ catalogPriceMinimumPrice });
              }}
              style={buttonProps}
              loading={loading}
              disabled={!!error || !dirty}
            />
          </>
        )}
      </View>
      <Text style={{ ...fonts.style1, paddingTop: 10 }}>
        {Localise(
          messages,
          "All products below this amount will be inactive except"
        )}{" "}
        {!isMobile ? (
          <Text
            style={active && showLink ? fonts.link3 : fonts.style1}
            onPress={onPress}
          >
            {Localise(messages, "Products Under Minimum")}
          </Text>
        ) : null}
        {active && (
          <Text style={{ paddingLeft: 10, ...theme.Input.errorStyle }}>
            {error}
          </Text>
        )}
      </Text>
      {isMobile ? (
        <Text
          style={active && showLink ? fonts.link3 : fonts.style1}
          onPress={onPress}
        >
          {Localise(messages, "Products Under Minimum")}
        </Text>
      ) : null}
      <ConfirmModal
        modalVisible={!!showPopup}
        handlePrimary={() => {
          const price = active ? catalogPriceMinimumPrice : "0.00";
          onSubmit({ catalogPriceMinimumPrice: price });
          setShowPopup(!showPopup);
        }}
        handleSecondary={() => {
          setShowPopup(!showPopup);
        }}
        data={{
          modal: {
            primary: "Update",
            content: Localise(
              messages,
              "This change will apply to All shops and will override any local shop changes."
            ),
          },
        }}
      />
    </View>
  );
};

export const PriceMinimumProducts = ({ shopSettings }) => {
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);
  const currency = Currency(shopCode);
  const { Localise, messages } = React.useContext(I18NContext);
  const { isDesktop, isMobile } = React.useContext(DeviceContext);
  const selectedProducts = useSelector(selectPriceMinimumProductsSelected);
  const currentLimit = useSelector(selectPriceMinimumCurrentLimit);

  const { price, priceMinimumProducts, excludedProducts } = useSelector(
    selectCatalogPriceMinimum
  );

  const searchProps = {
    type: "fullWidth",
    containerStyle: {
      marginBottom: isDesktop ? 0 : 6,
      marginTop: 6,
      paddingRight: 10,
      paddingLeft: 0,
      width: "80%",
    },
    icon: "search",
    placeholder: Localise(messages, "Search Products"),
    selectors: {
      selectValue: selectPriceMinimumSearchText,
    },
    actions: {
      setAction: setPriceMinimumAction,
    },
  };

  const filterProps = {
    width: "5%",
    icon: "filter",
    options: {
      "Filter By": PriceMinimumProductsFilter(Localise, messages),
    },
    selectors: {
      selectValue: selectPriceMinimumFilter,
    },
    actions: {
      setAction: setPriceMinimumAction,
    },
  };

  const showViewMore = currentLimit < priceMinimumProducts.length;

  const baseLimit = isMobile ? 4 : 12;
  const productsSlice = priceMinimumProducts.slice(0, currentLimit);
  const displayProducts = productsSlice
    .concat(Array.from(Array(baseLimit)).map(() => ""))
    .slice(0, currentLimit);

  const setViewMore = (limit) =>
    setPriceMinimumAction({
      type: "viewMore",
      value: limit,
    });

  const setBulkAction = (action) => setPriceMinimumAction(action);

  useEffect(() => {
    if (currentLimit === 0) dispatch(setViewMore(baseLimit));
  }, [currentLimit]);

  return (
    <View>
      <View style={tw("flex flex-row items-center")}>
        <LabelField
          text={"Catalog Minimum"}
          style={{ ...fonts.heading4, marginTop: 3 }}
        />

        <Input
          disabled={true}
          leftIconContainerStyle={{
            paddingRight: 0,
          }}
          leftIcon={
            <Icon
              name={"currency-usd"}
              type={"material-community"}
              size={14}
              iconStyle={{ color: "rgba(170, 170, 170, 1)" }}
            />
          }
          rightIcon={<Text style={fonts.default}> {currency}</Text>}
          containerStyle={{ width: 80, marginLeft: 5, marginTop: 10 }}
          inputContainerStyle={{ height: !isDesktop ? 35 : 20, padding: 6 }}
          inputStyle={{ height: !isDesktop ? 35 : 20 }}
          value={price}
        />

        {!shopSettings && (
          <Text style={{ ...fonts.style1, lineHeight: 25, marginLeft: 3 }}>
            {Localise(messages, "Edit Price in")}{" "}
            <Text
              style={fonts.link3}
              onPress={() => dispatch(setSideCar("catalogSettings"))}
            >
              {Localise(messages, "Catalog Settings")}
            </Text>
          </Text>
        )}
      </View>

      <View style={tw("flex flex-col my-2")}>
        <Text style={{ ...fonts.heading4, lineHeight: 25, paddingBottom: 6 }}>
          {Localise(
            messages,
            "Select which products to exclude from minimum price requirements"
          )}
        </Text>

        <View style={tw("flex flex-row items-center")}>
          <Search {...searchProps} />
          <Filters {...filterProps} />
        </View>
        {isDesktop ? (
          <View style={tw("flex flex-row items-center mt-3")}>
            <FilterResults
              input={selectPriceMinimumFilter}
              action={setPriceMinimumAction}
            />
          </View>
        ) : null}
        <SearchResults
          selectSearchText={selectPriceMinimumSearchText}
          setSearchText={setPriceMinimumAction}
        />
      </View>
      <View
        style={[
          tw(`flex flex-row flex-wrap items-center justify-between`),
          { minHeight: 55 },
        ]}
      >
        <BulkActionHeader
          setBulkAction={setBulkAction}
          selectBulkActionType={selectPriceMinimumBulkActionType}
          setProductSelection={setPriceMinimumProductSelection}
          selectProductsSelected={selectPriceMinimumProductsSelected}
          screen={"priceMinimum"}
        />
      </View>

      <View style={tw("flex flex-col my-3")}>
        <View style={[tw("flex flex-row flex-wrap"), { maxWidth: 504 }]}>
          {displayProducts.map((productId, index) => {
            const selected = selectedProducts.find((p) => p === productId);
            return (
              <Widget
                key={index}
                type="product"
                style={{ marginRight: 6 }}
                productId={productId}
                overlayText={
                  excludedProducts.includes(productId)
                    ? "Excluded From Minimum"
                    : ""
                }
                selected={selected}
                onSelect={(e) => dispatch(setPriceMinimumProductSelection(e))}
                onLoadSideCar={isDesktop ? "priceMinimumProducts" : ""}
              />
            );
          })}
        </View>
        {showViewMore && (
          <View
            style={tw(
              `flex flex-row-reverse w-full justify-between items-center py-4`
            )}
          >
            <SecondaryButton
              title={Localise(messages, "View More")}
              action={() => dispatch(setViewMore(currentLimit))}
            />
          </View>
        )}
      </View>
    </View>
  );
};
