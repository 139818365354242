import { takeLatest } from "redux-saga/effects";
import { setActiveTab } from "./slice";
import get from "lodash/get";
import * as Navigation from "library/utils/navigation";

function* handleNavigation(action = {}) {
  const name = get(action, "payload", "");
  yield Navigation.navigate("reports", {
    screen: name,
    params: {},
  });
}

export function* watchSaga() {
  yield takeLatest(setActiveTab.type, handleNavigation);
}

export default watchSaga;
