import { select, call, put } from "redux-saga/effects";
import request from "../request";
import * as Navigation from "library/utils/navigation.js";
import {
  selectData,
  selectPriceMinimumProductsSelected,
  selectPriceMinimumSearchText,
  selectPriceMinimumFilter,
  selectScreen,
  selectProductLookup,
  selectShopCode,
} from "./selector";
import {
  setData,
  setBulkUpdates,
  fetchCatalogSettings,
  applyPriceAdjustmentRule,
  setAPISuccess,
} from "./slice";

import {
  fetchProduct,
  setData as setProductAPIResponse,
} from "../product/slice";

import UserProfileStorage from "library/storage/userProfile";
import get from "lodash/get";
import toLower from "lodash/toLower";

export function* handleFetchCatalogSettings() {
  const shopCode = yield select(selectShopCode);
  try {
    const memberCodes = UserProfileStorage.getProfileMemberCodes();
    const memberCode = shopCode === "all" ? memberCodes[0] : shopCode;
    const section = "catalogSettings";
    const serviceRequest = (params) =>
      request("get-mol-content", {
        component: `scheduledPriceRules`,
        context: params.memberCode,
        assetId: "json",
        shopCode: params.memberCode,
      });
    let scheduledPriceAdjustment;
    try {
      const response = yield call(serviceRequest, { memberCode });
      scheduledPriceAdjustment = processScheduledPriceAdjustment(response);
    } catch {
      scheduledPriceAdjustment = [];
    }

    const price =
      get(
        UserProfileStorage.getShopPreferences(memberCode),
        "catalog_price_minimum"
      ) || "0.00";

    const allowNewProducts =
      get(
        UserProfileStorage.getShopPreferences(memberCode),
        "allow_new_products",
        "true"
      ) !== "false";

    const catalogSettings = yield call(processCatalogSettings, {
      allowNewProducts,
      catalogPriceMinimum: { price },
      scheduledPriceAdjustment,
    });

    yield put(
      setData({
        section,
        content: { ...catalogSettings, isRulesFetched: true },
      })
    );
    yield put(setAPISuccess(section));

    const {
      name: screen,
      params: { handle },
    } = yield select(selectScreen);
    if (screen === "product") {
      if (handle && !handle.includes("create_"))
        yield put(fetchProduct(handle));
    }
  } catch (error) {
    if (error.includes("NOT_FOUND")) {
      yield put(setAPISuccess("catalogSettings"));
    }
  }
}

export function* handleUIRefresh(action = {}) {
  const catalogSettings = yield call(processCatalogSettings);
  const { section, priceAdjustmentsApplied } = get(action, "payload", {});

  if (!section || section === "global" || section === "local") {
    yield put(
      setData({
        section: "catalogSettings",
        content: catalogSettings,
      })
    );

    if (section && !priceAdjustmentsApplied)
      yield put(applyPriceAdjustmentRule(section));
  }
}

export function* handleBulkActions(action = {}) {
  const {
    type: actionType,
    value: actionValue,
    resolve,
    reject,
  } = get(action, "payload", {});

  const { name: screen, params } = yield Navigation.getCurrentRoute(true);
  const shopCode = yield select(selectShopCode);

  try {
    if (actionType === "bulkActionType") {
      const [property, value] = actionValue.split(/::/);

      const serviceRequest = (params) =>
        request("bulk-products-update", params);

      const selectedProducts = yield select(selectPriceMinimumProductsSelected);

      yield call(serviceRequest, {
        productIds: selectedProducts,
        property,
        value: value === "true",
        shopCode,
      });

      yield put(
        setBulkUpdates({
          productIds: selectedProducts,
          property,
          value: value === "true",
        })
      );

      if (screen === "product") {
        const { handle } = params;
        const productHandle = selectedProducts.find((p) => p === handle);

        if (productHandle) {
          yield put(
            setProductAPIResponse({
              patch: {
                productId: productHandle,
                excludeFromPriceMinimum: value === "true",
              },
            })
          );
        }
      }

      resolve && resolve();
    } else if (actionType === "search") {
      const catalogSettings = yield call(processCatalogSettings);
      yield put(
        setData({
          section: "catalogSettings",
          content: catalogSettings,
        })
      );
    } else if (actionType === "filters") {
      const catalogSettings = yield call(processCatalogSettings);
      yield put(
        setData({
          section: "catalogSettings",
          content: catalogSettings,
        })
      );
    }
  } catch {
    reject && reject();
  }
}

export function* processCatalogSettings(params) {
  try {
    const productLookup = yield select(selectProductLookup);
    const { catalogSettings } = yield select(selectData);

    const {
      allowNewProducts = true,
      catalogPriceMinimum: { price },
      scheduledPriceAdjustment = [],
    } = params || catalogSettings;

    const searchText = yield select(selectPriceMinimumSearchText);
    const filters = yield select(selectPriceMinimumFilter);

    let priceMinimumProducts =
      parseFloat(price) <= 0
        ? []
        : Object.values(productLookup).filter((product) => {
            return (
              product.catalogType !== "addons" &&
              parseFloat(product.adjustedPrice) < parseFloat(price) &&
              (toLower(product.name).includes(toLower(searchText)) ||
                toLower(product.description).includes(toLower(searchText)) ||
                toLower(product.productId).includes(toLower(searchText)) ||
                toLower(product.variationDescription).includes(
                  toLower(searchText)
                ) ||
                toLower(product.color).includes(toLower(searchText)) ||
                toLower(product.flowerType).includes(toLower(searchText)))
            );
          });

    if (filters.length) {
      priceMinimumProducts = applyFilters(priceMinimumProducts, filters);
    }

    const excludedProducts =
      parseFloat(price) <= 0
        ? []
        : Object.values(productLookup).filter(
            (product) => product.excludeFromPriceMinimum
          );

    return {
      allowNewProducts,
      catalogPriceMinimum: {
        price,
        priceMinimumProducts: priceMinimumProducts.map((p) => p.productId),
        excludedProducts: excludedProducts.map((p) => p.productId),
      },
      scheduledPriceAdjustment,
    };
  } catch (error) {
    // do nothing for now
  }
}

const applyFilters = (data = [], filters) =>
  data.filter((entry) => {
    let matchesFilter = true;
    if (filters.length) {
      const filterValues = filters.map((e) => e.value);

      if (
        matchesFilter &&
        (filterValues.includes("local") || filterValues.includes("global"))
      ) {
        if (filterValues.includes("local") && entry.catalogType === "local")
          matchesFilter = true;
        else if (
          filterValues.includes("global") &&
          entry.catalogType === "global"
        )
          matchesFilter = true;
        else matchesFilter = false;
      }

      if (
        matchesFilter &&
        (filterValues.includes("includeInMinimum") ||
          filterValues.includes("excludeFromMinimum"))
      ) {
        if (
          filterValues.includes("includeInMinimum") &&
          !entry.excludeFromPriceMinimum
        )
          matchesFilter = true;
        else if (
          filterValues.includes("excludeFromMinimum") &&
          entry.excludeFromPriceMinimum
        )
          matchesFilter = true;
        else matchesFilter = false;
      }

      if (
        matchesFilter &&
        (filterValues.includes("active") || filterValues.includes("inactive"))
      ) {
        if (filterValues.includes("active") && entry.status === "active")
          matchesFilter = true;
        else if (
          filterValues.includes("inactive") &&
          entry.status === "inactive"
        )
          matchesFilter = true;
        else matchesFilter = false;
      }

      if (matchesFilter && filterValues.includes("soldOut")) {
        if (entry.soldOut) matchesFilter = true;
        else matchesFilter = false;
      }

      if (
        matchesFilter &&
        (filterValues.includes("callForPrice") ||
          filterValues.includes("instore") ||
          filterValues.includes("rushDelivery") ||
          filterValues.includes("localDelOnly") ||
          filterValues.includes("dropship"))
      ) {
        if (
          (filterValues.includes("callForPrice") && entry.callForPrice) ||
          (filterValues.includes("instore") && entry.inStorePickUp) ||
          (filterValues.includes("rushDelivery") &&
            !entry.excludeFromRushDelivery) ||
          (filterValues.includes("localDelOnly") && entry.localDelOnly) ||
          (filterValues.includes("dropship") && entry.dropShippingProduct)
        )
          matchesFilter = true;
        else matchesFilter = false;
      }

      // if (matchesFilter && filterValues.includes("codified")) {
      //   if (entry.codified) matchesFilter = true;
      //   else matchesFilter = false;
      // }
    }

    return matchesFilter;
  });

export function* handleSaveCatalogSettings(action = {}) {
  const { resolve, reject, params } = get(action, "payload", {});

  const serviceRequest = (payload) => request("save-card-settings", payload);

  try {
    const shopCode = yield select(selectShopCode);

    const { allowNewProducts, catalogPriceMinimumPrice } = params;

    const isUpdatingAllowNewProductsStatus = "allowNewProducts" in params;

    const payload = {
      preferences: [
        {
          id: isUpdatingAllowNewProductsStatus
            ? "allow_new_products"
            : "catalog_price_minimum",
          values: isUpdatingAllowNewProductsStatus
            ? [allowNewProducts ? "true" : "false"]
            : [catalogPriceMinimumPrice],
        },
      ],
      catalogSettings: true,
      shopCode,
    };

    const response = yield call(serviceRequest, payload);

    if (response) {
      const payload = isUpdatingAllowNewProductsStatus
        ? {
            allow_new_products: allowNewProducts ? "true" : "false",
          }
        : {
            catalog_price_minimum: catalogPriceMinimumPrice,
          };
      const memberCodes = UserProfileStorage.getProfileMemberCodes();
      if (shopCode === "all") {
        memberCodes.forEach((shop) => {
          UserProfileStorage.setShopPreferences(shop, payload);
        });
      } else {
        UserProfileStorage.setShopPreferences(shopCode, payload);
      }

      yield put(fetchCatalogSettings());

      resolve && resolve();
    }
  } catch (error) {
    reject && reject();
  }
}

export function* handleUpdatePriceAdjustment(action = {}) {
  const {
    resolve,
    reject,
    params: { values },
  } = get(action, "payload", {});
  const shopCode = yield select(selectShopCode);
  try {
    const serviceRequest = (payload) => request("save-mol-content", payload);

    const payload = preparePriceAdjustmentPayload(values);

    yield call(serviceRequest, { ...payload, shopCode });

    yield put(fetchCatalogSettings());

    resolve && resolve();
  } catch (error) {
    reject && reject();
  }
}

const preparePriceAdjustmentPayload = (values, _shopCode) => {
  const jsonBody = { rules: values };
  return {
    body: JSON.stringify(jsonBody),
    assetId: "json",
    component: "scheduledPriceRules",
    context: "",
    mimeType: "application/json",
  };
};

function processScheduledPriceAdjustment(response = {}) {
  const { body: priceRulesBody = {} } = response || {};
  let productRules = [];
  try {
    const parsedBody = JSON.parse(priceRulesBody);
    productRules = parsedBody.rules || [];
  } catch {
    productRules = [];
  }

  return productRules.map((rule) => {
    const { fieldValue = "", startDate = "", endDate = "" } = rule;
    const operation =
      fieldValue !== ""
        ? fieldValue.includes("-")
          ? "decrement"
          : "increment"
        : "";
    const activeStartDate = startDate === "01/01/2025" ? "" : startDate;
    const activeEndDate = endDate === "01/01/2100" ? "" : endDate;

    return {
      ...rule,
      startDate: activeStartDate,
      endDate: activeEndDate,
      id: `${Math.floor(Math.random() * 90000) + 10000}`,
      operation,
      fieldValue: fieldValue.replace("-", ""),
    };
  });
}
