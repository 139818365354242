import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { View, Text } from "react-native";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import { Form } from "components/elements/forms";

import {
  selectShopPreferences,
  selectShopLogoData,
  selectShopCode,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { ToasterHandler } from "components/elements";
import ImagePicker from "components/wrappers/image-picker";
import { saveLogoImages } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import { fonts } from "styles/theme";

import tw from "tailwind-rn";
import { SaveCancelButtons } from "components/wrappers";
import { maxFileSize } from "../../helper";

const ShopLogo = () => {
  const { card_settings = {} } = useSelector(selectShopPreferences);
  const shopLogoData = useSelector(selectShopLogoData);
  const shopCode = useSelector(selectShopCode);
  const errorMsgRef = useRef({});

  const dispatch = useDispatch();

  const {
    logoInfo: { uri: logoImageURL = "", name: logoName = "" } = {},
    bannerInfo: { uri: bannerImageURL = "", name: bannerName = "" } = {},
  } = shopLogoData || {};
  const {
    logoImageURL: card_settings_logoImageName = "",
    bannerImageURL: card_settings_bannerImageName = "",
  } = card_settings;

  const { isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);

  const initialValues = {
    logoImageURL: logoImageURL || "",
    bannerImageURL: bannerImageURL || "",
    logoName: card_settings_logoImageName || "",
    bannerName: card_settings_bannerImageName || "",
  };

  const onSubmit = (values, formikBag) => {
    dispatch(
      saveLogoImages({
        params: {
          memberCode: shopCode,
          values,
        },
        resolve: () => {
          ToasterHandler(
            "looks good",
            Localise(messages, `Content has been updated.`)
          );
          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  const handleImageUpload = (value, setFieldValue, fieldUrlName, fieldName) => {
    const fieldType = fieldUrlName === "logoImageURL" ? "Logo" : "Banner";
    if (!value || value.size > maxFileSize) {
      setFieldValue(fieldUrlName, "");
      setFieldValue(fieldName, "");
      errorMsgRef.current[fieldUrlName] = !value
        ? ""
        : `The ${fieldType} Image Exceeds The 1.5MB Limit.\nPlease Upload A Smaller Image.`;
    } else {
      const timestamp = new Date().toISOString();
      const fileName = `${timestamp}_${value.name.replace(/ /g, "_")}`;
      setFieldValue(fieldUrlName, value.uri);
      setFieldValue(fieldName, fileName);
      errorMsgRef.current[fieldUrlName] = "";
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={(props) => {
        const {
          setFieldValue,
          values: { logoImageURL = "", bannerImageURL = "" },
        } = props;

        return (
          <>
            <View>
              <Text style={[fonts.heading3, tw("mb-2")]}>
                {Localise(messages, "Shop Logo")}
              </Text>
            </View>
            <View style={tw(`flex flex-${isMobile ? "col" : "row"} flex-wrap`)}>
              <ImagePicker
                value={logoImageURL}
                toolTipTitle={logoName}
                setValue={(value) =>
                  handleImageUpload(
                    value,
                    setFieldValue,
                    "logoImageURL",
                    "logoName"
                  )
                }
                title={"Standard Logo"}
                errorMsg={errorMsgRef?.current?.logoImageURL}
              />
              <ImagePicker
                value={bannerImageURL}
                toolTipTitle={bannerName}
                setValue={(value) =>
                  handleImageUpload(
                    value,
                    setFieldValue,
                    "bannerImageURL",
                    "bannerName"
                  )
                }
                title={"Banner Logo"}
                errorMsg={errorMsgRef?.current?.bannerImageURL}
              />
            </View>

            <SaveCancelButtons
              buttonTitle={Localise(messages, "Save")}
              cancelTestId="clear_shop_logo_form"
              saveTestId="save_shop_logo"
            />
          </>
        );
      }}
    />
  );
};

export default ShopLogo;
