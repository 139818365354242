import React, { memo } from "react";
import { Platform, PermissionsAndroid } from "react-native";
import { Button } from "react-native-elements";
import I18NContext from "library/contexts/i18N";
import { theme } from "styles/theme";
import * as ImagePicker from "react-native-image-picker";
import * as DocumentPicker from "expo-document-picker";
import { ToasterHandler } from "components/elements";
import { useActionSheet } from "@expo/react-native-action-sheet";
import {
  imageLibraryOptions,
  cameraOptions,
  actionSheetOptions,
  maxImageSize,
} from "./config";

// eslint-disable-next-line react/display-name
const UploadImage = memo((props) => {
  const {
    title,
    testID,
    onImageSelect,
    disabled,
    buttonStyle = {},
    containerStyle = {},
    titleStyle = {},
  } = props;
  const { messages, Localise } = React.useContext(I18NContext);
  const { showActionSheetWithOptions } = useActionSheet();

  const onSelect = (response) => {
    if (response?.didCancel) {
      console.log("User cancelled image picker");
    } else if (response?.error) {
      console.log("ImagePicker Error: ", response.error);
    }
    onImageSelect(response);
  };

  const onImageLibraryPress = () => {
    ImagePicker.launchImageLibrary(imageLibraryOptions, onSelect);
  };

  const onCameraPress = async () => {
    if (Platform.OS === "ios") {
      launchCamera();
    } else if (Platform.OS === "android") {
      try {
        const permissionGranted = await PermissionsAndroid.check(
          PermissionsAndroid.PERMISSIONS.CAMERA
        );

        if (permissionGranted) {
          launchCamera();
        } else {
          const result = await PermissionsAndroid.request(
            PermissionsAndroid.PERMISSIONS.CAMERA,
            {
              title: Localise(messages, "Camera Permission Required"),
              message: Localise(
                messages,
                "Mercury HQ would like to use your camera."
              ),
              buttonPositive: Localise(messages, "OK"),
            }
          );

          if (result === PermissionsAndroid.RESULTS.GRANTED) {
            launchCamera();
          } else {
            ToasterHandler(
              "error",
              Localise(
                messages,
                "Camera permission not granted, please give  permission."
              )
            );
          }
        }
      } catch (err) {
        ToasterHandler(
          "error",
          Localise(
            messages,
            "An error occurred while capturing the delivery status image, please try again."
          )
        );
      }
    }
  };

  const launchCamera = () => {
    ImagePicker.launchCamera(cameraOptions, onSelect);
  };

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/*",
    });

    // Image size should be less than 3MB - Ref: MSOL-2571
    if (result?.size < maxImageSize) {
      const selectedImage = result.uri.replace(
        /^data:image\/[a-z]+;base64,/,
        ""
      );
      onSelect(selectedImage);
    } else {
      ToasterHandler(
        "error",
        Localise(messages, "Image size should be less than 3MB.")
      );
    }
  };

  const updateSheetOptions = () => {
    // https://github.com/expo/react-native-action-sheet
    showActionSheetWithOptions(actionSheetOptions, (buttonIndex) => {
      if (buttonIndex === 0) {
        onCameraPress();
      } else if (buttonIndex === 1) {
        onImageLibraryPress();
      }
    });
  };

  return (
    <Button
      title={Localise(messages, title)}
      titleStyle={{ ...theme.Button.secondaryTitleStyle, ...titleStyle }}
      testID={testID || title}
      buttonStyle={{
        ...theme.Button.secondaryButtonStyle,
        paddingVertical: 8,
        paddingHorizontal: 10,
        ...buttonStyle,
      }}
      containerStyle={{
        margin: 5,
        justifyContent: "center",
        ...containerStyle,
      }}
      onPress={() => {
        Platform.OS === "web" ? pickDocument() : updateSheetOptions();
      }}
      disabled={disabled}
    />
  );
});

export default UploadImage;
