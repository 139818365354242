import React, { useContext, useEffect } from "react";
import { View, ActivityIndicator, Text } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import { Picker, TabBar } from "components/elements";
import ScreenHeader from "components/containers/split-screen-new/header";

import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import UserProfileStorage from "library/storage/userProfile";
import {
  setActiveTab,
  setShopCode,
} from "library/sagas/views/home/drawer/reports/common/slice";
import {
  selectIsPageLoading,
  selectTabNames,
  selectActiveTab,
  selectShopCode,
} from "library/sagas/views/home/drawer/reports/common/selector";

import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";

import Reports from "./reports-tab";
import Scorecard from "./scorecard";

const HeaderSiblings = () => {
  const loading = useSelector(selectIsPageLoading);

  return loading ? (
    <ActivityIndicator
      color={colors.activityIndicator}
      style={{ height: 25 }}
    />
  ) : null;
};

const TabBarSiblings = ({ shopCodes = [] }) => {
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);
  const { Localise, messages } = useContext(I18NContext);
  const { isMobile, isDesktop } = useContext(DeviceContext);
  const shopNames = UserProfileStorage.getAllShopNames();

  return shopCodes.length > 1 ? (
    <View
      style={[
        tw(`flex flex-row items-center justify-${isMobile ? "start" : "end"}`),
        { flexGrow: 1 },
      ]}
    >
      <Text
        style={{
          ...fonts.heading4,
          maxWidth: isMobile ? 100 : 150,
        }}
      >
        {Localise(messages, "Select Shop:")}
      </Text>
      <Picker
        containerStyle={{
          height: 40,
          ...(!isDesktop ? { width: "60%" } : {}),
        }}
        items={shopCodes.map((code) => ({
          label: `${code} ${shopNames[code]}`,
          value: code,
        }))}
        placeholder={{}}
        value={shopCode}
        onValueChange={(val) => {
          dispatch(setShopCode(val));
        }}
      ></Picker>
    </View>
  ) : null;
};

export const MainScreen = ({ defaultTab }) => {
  const dispatch = useDispatch();
  const currentTab = useSelector(selectActiveTab);

  const setCurrentTab = (name) => {
    dispatch(setActiveTab(name));
  };

  useEffect(() => {
    if (!currentTab) setCurrentTab(defaultTab);
  }, []);

  if (currentTab && defaultTab != currentTab) return null;

  return (
    <View fsClass="fs-unmask" style={{ maxWidth: "100%", minHeight: 500 }}>
      {currentTab === "reports-tab" && <Reports />}
      {currentTab === "score-card" && <Scorecard />}
    </View>
  );
};

export const SideScreen = () => {
  return <></>;
};

export const CustomHeader = ({ defaultTab, shopCodes = [] }) => {
  const dispatch = useDispatch();
  const tabNames = useSelector(selectTabNames);
  const currentTab = useSelector(selectActiveTab);
  const { messages, Localise } = useContext(I18NContext);

  const showShopSelection = currentTab === "score-card";

  const setCurrentTab = (name) => {
    dispatch(setActiveTab(name));
  };

  return (
    <>
      <ScreenHeader
        title={Localise(messages, "Reports")}
        style={{ justifyContent: "flex-start", paddingHorizontal: 0 }}
        siblings={HeaderSiblings}
      />
      {tabNames?.length > 1 || showShopSelection ? (
        <TabBar
          containerStyle={tw("w-full")}
          tabNames={tabNames}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          style={{ marginTop: -10, paddingHorizontal: 0 }}
          siblings={() => {
            if (!showShopSelection) return <></>;
            return <TabBarSiblings shopCodes={shopCodes} />;
          }}
        />
      ) : null}
    </>
  );
};
