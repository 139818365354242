import React, { useContext } from "react";
import { ActivityIndicator, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import { fonts, colors } from "styles/theme";
import { SubmitButton } from "components/elements/forms";
import I18NContext from "library/contexts/i18N";
import {
  selectRecordData,
  selectOrderItemId,
} from "library/sagas/ongoing/current-orders/selector";
import { ToasterHandler } from "components/elements";
import { revertOriginalAddress } from "library/sagas/views/home/drawer/create-order/slice";
import ORDERS from "library/constants/orders";
import useStateIfMounted from "library/utils/useStateIfMounted";

const AddressRevert = ({ index, receivingMemberCode, actionCallback }) => {
  const dispatch = useDispatch();
  const orderDetailResponse = useSelector(selectRecordData);
  const orderItemId = useSelector(selectOrderItemId) || "";
  const [loading, setLoading] = useStateIfMounted(false);
  const { messages: locMessages, Localise } = useContext(I18NContext);
  const originalRecipientAddress =
    orderDetailResponse?.orderItems[index]?.recipientInfo
      ?.originalRecipientAddress;
  const {
    addressLine1: originalRecipientAddressLine1 = "",
    city = "",
    state = "",
    zip = "",
  } = originalRecipientAddress;
  const originalRecipientAddressLine2 = `${city} ${state} ${zip}`;

  const revertToOriginalAddress = () => {
    setLoading(true);
    dispatch(
      revertOriginalAddress({
        params: {
          recordId: orderItemId,
          memberCode: receivingMemberCode,
          action: "REVERT",
        },
        resolve: () => {
          setLoading(false);
          ToasterHandler(
            "success",
            Localise(locMessages, ORDERS.ADDRESS_REVERTED_MESSAGE)
          );
          actionCallback("save-order-changes", orderItemId, true, true);
        },
        reject: () => {
          setLoading(false);
          ToasterHandler(
            "uh oh",
            Localise(locMessages, ORDERS.ADDRESS_NOT_REVERTED_MESSAGE)
          );
        },
      })
    );
  };

  return (
    <>
      <View style={[tw("flex flex-col"), { zIndex: -1 }]}>
        <Text
          style={[
            fonts.style1,
            {
              paddingVertical: 5,
              fontSize: 15,
              color: colors.primary,
              fontWeight: "bold",
            },
          ]}
          testID={"original_address"}
        >
          {Localise(locMessages, ORDERS.ORIGINAL_ADDRESS)}
        </Text>
        <Text
          style={{
            fontSize: 14,
            paddingVertical: 2,
            fontWeight: "normal",
          }}
          testID={"original_address_line1"}
        >
          {originalRecipientAddressLine1}
        </Text>
        <Text
          style={{
            fontSize: 14,
            paddingVertical: 2,
            fontWeight: "normal",
          }}
          testID={"original_address_line2"}
        >
          {originalRecipientAddressLine2}
        </Text>
      </View>
      <View style={[tw(`flex flex-1 flex-row`)]}>
        <SubmitButton
          containerStyle={{ margin: 0, marginTop: 5 }}
          buttonStyle={{
            paddingVertical: 8,
            paddingHorizontal: 10,
            marginBottom: 3,
          }}
          title={Localise(locMessages, ORDERS.REVERT_ADDRESS)}
          onPress={() => revertToOriginalAddress()}
          disabled={loading}
          testID="revert_to_original"
        />
        {loading ? (
          <ActivityIndicator
            style={{ marginLeft: 8, marginBottom: 8, marginTop: 8 }}
            color={colors.activityIndicator}
          />
        ) : null}
      </View>
    </>
  );
};

export default AddressRevert;
