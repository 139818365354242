import React, { useContext, memo } from "react";
import I18NContext from "library/contexts/i18N";
import { View, Text } from "react-native";
import { Icon } from "react-native-elements";
import tw from "tailwind-rn";
import { fonts, colors } from "styles/theme";
import { Tooltip } from "components/elements";

const InfoLabel = ({
  testID = "infoLabel",
  containerStyle = {},
  iconProps = {},
  toolTipProps = {},
  labelProps = {},
}) => {
  const {
    iconPosition = "left", // Default icon position (could be left or right)
    iconStyle = {},
    name = "info", // Default icon name
    size = 15, // Default icon size
    type = "simple-line-icon",
  } = iconProps;

  const {
    showLabel = true,
    labelStyle = {},
    labelText,
    labelTestId,
  } = labelProps;

  const { showToolTip = false, toolTipText = "" } = toolTipProps;

  const { messages, Localise } = useContext(I18NContext);

  const renderTooltip = () => {
    if (showToolTip && toolTipText) {
      return (
        <Tooltip text={Localise(messages, toolTipText)}>
          <Icon
            name={name}
            type={type}
            color={colors.primary}
            size={size}
            style={iconStyle}
          />
        </Tooltip>
      );
    }
    return (
      <Icon
        name={name}
        type={type}
        color={colors.primary}
        size={size}
        style={iconStyle}
      />
    );
  };

  return (
    <View style={[tw("flex flex-row"), containerStyle]} testID={testID}>
      {iconPosition === "left" && renderTooltip()}
      {showLabel && labelText && (
        <Text
          style={[fonts.default, tw("font-normal"), labelStyle]}
          testID={labelTestId}
        >
          {Localise(messages, labelText)}
        </Text>
      )}
      {iconPosition === "right" && renderTooltip()}
    </View>
  );
};

export default memo(InfoLabel);
