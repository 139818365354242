import React, { useEffect } from "react";
import { ActivityIndicator, View, StyleSheet, Platform } from "react-native";
import { Button, Image, Text } from "react-native-elements";
import { MessageText, Send, InputToolbar } from "react-native-gifted-chat";
import { useSelector } from "react-redux";
import tw from "tailwind-rn";
import moment from "moment";
import toLower from "lodash/toLower";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import pick from "lodash/pick";
import {
  Chat,
  CustomModal,
  ToasterHandler,
  Tooltip,
} from "components/elements";
import { request } from "library/utils/request";
import { fonts, colors } from "styles/theme";
import { getOrderChatInfo } from "../helper";
import IMAGES from "static/assets/images";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";
import { OrderDSCancelFailModal } from "library/utils/deliveryService";
import { orderDsRequestRerouting } from "library/utils/orderListing";

const OrderMessages = ({
  index,
  operator,
  triggerAction,
  triggerRequest,
  hideSendInput,
  isPickUpOrder,
}) => {
  const { messages: locMessages, Localise } = React.useContext(I18NContext);
  const orderDetailResponse = useSelector(selectRecordData);

  const orderDetails = orderDetailResponse.orderItems[index];
  const [loading, setLoading] = useStateIfMounted(false);

  const {
    orderItemId,
    direction = "",
    receivingMember: {
      memberCode: receivingUserId,
      businessName: receivingUserName,
      timezone = "",
    },
    sendingMember: { memberCode: sendingUserId, businessName: sendingUserName },
    deliveryInfo: { deliveryDate: orderDeliveryDate, deliveryType = "" },
    messages: orderMessages = [],
    orderSource,
    status,
  } = orderDetails;

  const [messages, setMessages] = useStateIfMounted(orderMessages);

  useEffect(() => {
    setMessages(orderMessages);
  }, [orderMessages]);

  const isIncomingOrder = direction === "INBOUND";
  const fillerMemberCode = isIncomingOrder ? receivingUserId : "";
  const userId = isIncomingOrder ? receivingUserId : sendingUserId;
  const userName = isIncomingOrder ? receivingUserName : sendingUserName;
  const isMOLCancelledOrder = orderSource === "MOL" && status === "CANCELLED";

  const isDSSubmitted = deliveryType === "Delivery Service";
  const [isCancelDSModal, setShowCancelDSModal] = useStateIfMounted(false);
  const [showDSCancelFailModal, setShowDSCancelFailModal] = useStateIfMounted({
    modalVisible: false,
    errorMessage: "",
  });

  const getMessages = () => {
    request("order-messages", { orderItemId }).then((res) => {
      setMessages((res && res) || []);
      setLoading(false);
    });
  };

  const chatMessages = messages.map((message) => {
    const headerText = `${
      message.operator && message.operator !== "SYSTEM"
        ? message.operator + " at " + message.sendingMember
        : ""
    } ${message.businessName || ""}`;

    const { messageNumber = "", status = "" } = message || {};

    const isForwardedMessage = message?.messageType == "Transfer";
    const isSuccess = status !== "ERROR";

    const errorDescription = get(message, "errorDescription", "");
    const comments = get(message, "comments", "");
    const messageText = comments.includes("chat message:")
      ? comments.split("chat message:")[1]
      : isForwardedMessage
      ? isSuccess
        ? `Reference #: ${messageNumber}`
        : `Reason: Error`
      : comments;

    return {
      _id: message.id,
      user: {
        _id: message.sendingMember,
        name: headerText,
        avatar: true,
      },
      text: messageText?.replace(/<[^>]*>/g, ""),
      createdAt: message.createdOn,
      messageType: message.messageType,
      direction: message.direction,
      price: message?.price,
      requestStatus: message?.requestStatus,
      deliveryDate: message?.deliveryDate,
      operatorName: message?.operator,
      businessName: message?.businessName,
      errorDescription: errorDescription,
      status: message?.status,
      pickUpDateTime: message?.pickUpDateTime,
      forwardedMember: message?.forwardedMember,
      forwardSendingMember: message?.sendingMember,
      timezone: timezone,
      dsFee: message?.dsFee,
    };
  });

  const CustomMessageText = (props) => {
    const { currentMessage } = props;
    const {
      messageType = "",
      direction = "",
      _id,
      price,
      requestStatus,
      deliveryDate,
      status = "",
      errorDescription = "",
      dsFee,
      pickUpDateTime,
      forwardedMember = "",
      text = "",
      forwardSendingMember = "",
      timezone = "",
    } = currentMessage;

    const isErrorMessage = status === "ERROR" && !isEmpty(errorDescription);

    const {
      approveRequestAction,
      approveRequestReason,
      denyRequestAction,
      denyRequestReason,
      headingTitle,
      isStructuredMessage,
      isNonMHQPriceMessage,
      isNonMHQPriceRequest,
    } = getOrderChatInfo(
      direction,
      messageType,
      forwardedMember,
      text,
      forwardSendingMember,
      status
    );

    const callActionRequest = (action, reason) => {
      triggerAction({
        action: action === "cancel-deny" ? "OrderJourneyCancelDeny" : action,
        recordId: orderItemId,
        reason: reason,
        askId: _id,
        price: price,
        deliveryDate: deliveryDate,
      });
    };

    const handleDateChangeRequest = (action) => {
      if (action === "revert") {
        const updatesObj = {
          deliveryDate: {
            path: `orderItems.0.deliveryInfo.deliveryDate`,
            value: deliveryDate,
          },
        };
        // triggerring delivery date patch call
        triggerAction({
          action: "delivery-date-change",
          params: updatesObj,
        });
      } else {
        // triggerring markAsRead call
        triggerAction({
          action: "markAsRead",
          params: { isCheckResponse: true },
        });
      }
    };

    const floristTimeZone = moment.tz(timezone).format("z");
    const message =
      messageType === "OLC" &&
      toLower(text) !== toLower("Received Order from Mercury Network") &&
      !text.includes("Track Your Order") &&
      !text.includes("Order Copied From") &&
      toLower(text) !== toLower("Sent Order to Mercury Network")
        ? `Order Status Update: ${text}`
        : text;

    const dsRerouteInProgress = orderDsRequestRerouting(
      pick(orderDetails, ["latestDSRequestedStatus"])
    );
    const CancelDSModal = () => {
      const modalContent = {
        content: (
          <View style={{ textAlign: "center" }}>
            <Text
              style={{
                ...tw("px-4 pt-4"),
                fontSize: 15,
                color: colors.highlighter,
              }}
            >
              {Localise(
                messages,
                "This order has been scheduled with Delivery Service."
              )}
            </Text>
            <Text
              style={{
                ...tw("px-4 pb-4"),
                fontSize: 15,
                color: colors.highlighter,
              }}
            >
              {Localise(
                messages,
                "Click Continue to approve Mercury HQ and Delivery Service cancelation."
              )}
            </Text>
          </View>
        ),
        buttons: [
          { type: "secondary", title: Localise(messages, "Nevermind") },
          { type: "primary", title: Localise(messages, "Continue") },
        ],
      };

      return (
        <CustomModal
          modalVisible={isCancelDSModal}
          modalContent={modalContent}
          primaryhandler={() => {
            setShowCancelDSModal(false);
            const dsCancelPayload = {
              deliveryMethod: orderDetails.deliveryInfo.deliveryMethod,
              memberCode: fillerMemberCode,
              orderItemId,
            };
            request("delivery-service-cancel", dsCancelPayload)
              .then(() => {
                ToasterHandler(
                  "success",
                  Localise(messages, "The delivery service request is canceled")
                );
                callActionRequest(approveRequestAction, approveRequestReason);
              })
              .catch(({ errors = {} }) => {
                const errorMessage = get(
                  errors,
                  "0.message",
                  "Something went wrong, please try again."
                );
                setShowDSCancelFailModal({
                  modalVisible: true,
                  errorMessage,
                });
              });
          }}
          secondaryhandler={() => {
            setShowCancelDSModal(false);
          }}
          contentStyle={[
            tw("border border-black p-4"),
            { backgroundColor: "white" },
          ]}
          modalStyle={
            Platform.OS !== "web"
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  backgroundColor: "#00000070",
                  color: "#FFFFFF",
                }
              : {
                  width: "35%",
                }
          }
        ></CustomModal>
      );
    };

    return (
      <View>
        {!!headingTitle && (
          <Text style={{ fontWeight: "bold" }}>{headingTitle}</Text>
        )}

        {isStructuredMessage && isNonMHQPriceRequest && (
          <View style={styles.nonMHQstructured}>
            <Text style={styles.messageCustomTextStyle}>
              {`Price Adjustment: Please approve for $${parseFloat(
                price
              ).toFixed(2)}`}
            </Text>
          </View>
        )}
        <MessageText
          {...props}
          currentMessage={{ ...currentMessage, text: message }}
          customTextStyle={styles.messageCustomTextStyle}
        />
        {pickUpDateTime && (
          <Text style={styles.messageCustomTextStyle}>{`Pickup Time: ${moment
            .utc(pickUpDateTime)
            .tz(timezone)
            .format("hh:mm A MM/DD/YYYY")} - ${floristTimeZone}`}</Text>
        )}

        {!isNaN(dsFee) && (
          <Text
            style={{
              ...styles.messageCustomTextStyle,
              color: "#242424",
              paddingVertical: 5,
            }}
          >{`${Localise(
            locMessages,
            "Estimated Delivery Service Charge"
          )}: $${parseFloat(dsFee).toFixed(2)}`}</Text>
        )}

        {isErrorMessage && (
          <Text
            style={[styles.messageCustomTextStyle, { color: colors.error }]}
          >
            {errorDescription}
          </Text>
        )}

        {isStructuredMessage && (
          <>
            <CancelDSModal />
            {showDSCancelFailModal?.modalVisible ? (
              <OrderDSCancelFailModal
                modalVisible={showDSCancelFailModal?.modalVisible}
                errorMessage={showDSCancelFailModal?.errorMessage}
                primaryhandler={() => {
                  setShowDSCancelFailModal({
                    modalVisible: false,
                    errorMessage: "",
                  });
                  //cancel order
                  callActionRequest(approveRequestAction, approveRequestReason);
                }}
                secondaryhandler={() => {
                  setShowDSCancelFailModal({
                    modalVisible: false,
                    errorMessage: "",
                  });
                }}
                Localise={Localise}
                messages={messages}
              />
            ) : null}

            <View style={styles.actionContainers}>
              {requestStatus === "WAITING" && (
                <>
                  <Button
                    testID="approve"
                    accessibilityLabel="approve"
                    title="Approve"
                    onPress={() => {
                      if (
                        approveRequestAction === "cancel-approval" &&
                        (isDSSubmitted || dsRerouteInProgress)
                      ) {
                        setShowCancelDSModal(true);
                      } else {
                        callActionRequest(
                          approveRequestAction,
                          approveRequestReason
                        );
                      }
                    }}
                    containerStyle={{ width: 100 }}
                    buttonStyle={{ paddingVertical: 5, paddingHorizontal: 15 }}
                  />
                  {!isPickUpOrder && (
                    <Text
                      style={{
                        ...tw("underline mt-2"),
                        ...(isMOLCancelledOrder && {
                          pointerEvents: "none",
                        }),
                        opacity: isMOLCancelledOrder ? 0.4 : 1,
                      }}
                      onPress={() => {
                        if (isMOLCancelledOrder) return;

                        callActionRequest(denyRequestAction, denyRequestReason);
                      }}
                    >
                      {Localise(locMessages, "Deny")}
                    </Text>
                  )}
                </>
              )}
              {["APPROVED", "DENIED", "RESPONDED"].includes(requestStatus) && (
                <View style={styles.structuredStatus}>
                  <Text>
                    {["APPROVED", "RESPONDED"].includes(requestStatus)
                      ? capitalize(requestStatus)
                      : `Request ${capitalize(requestStatus)}`}
                  </Text>
                </View>
              )}
              {requestStatus === "CHECK_RESPONSE" && (
                <>
                  <Tooltip
                    text={Localise(
                      messages,
                      "Keep New Delivery Date You Requested.\nDelivery Date Override: It is your responsibility to ensure the sender is aware of the date change and to provide backup documentation of their knowledge. Override buttons should only be used in cases where you do not receive proper acceptance from the sender."
                    )}
                    renderForWebOnly={true}
                  >
                    <Button
                      testID="keep"
                      accessibilityLabel="keep"
                      title={Localise(
                        locMessages,
                        `Keep ${moment(orderDeliveryDate).format("MM/DD")}`
                      )}
                      onPress={() => {
                        handleDateChangeRequest("keep");
                      }}
                      buttonStyle={{
                        paddingVertical: 5,
                        paddingHorizontal: 15,
                      }}
                    />
                  </Tooltip>
                  <Tooltip
                    text={Localise(
                      messages,
                      "Revert Back to the Sender's Original Delivery Date.\nDelivery Date Override: It is your responsibility to ensure the sender is aware of the date change and to provide backup documentation of their knowledge. Override buttons should only be used in cases where you do not receive proper acceptance from the sender."
                    )}
                    renderForWebOnly={true}
                  >
                    <Text
                      style={{
                        ...tw("underline mt-2"),
                      }}
                      testID="revert"
                      onPress={() => {
                        handleDateChangeRequest("revert");
                      }}
                    >
                      {Localise(
                        locMessages,
                        `Revert to ${moment(deliveryDate).format("MM/DD")}`
                      )}
                    </Text>
                  </Tooltip>
                </>
              )}
            </View>
          </>
        )}
        {isNonMHQPriceMessage && (
          <View>
            <Text>
              {requestStatus === "APPROVED"
                ? `$${parseFloat(price).toFixed(2)} ${capitalize(
                    requestStatus
                  )}`
                : `$${parseFloat(price).toFixed(2)} ${capitalize(
                    requestStatus
                  )}`}
            </Text>
          </View>
        )}
      </View>
    );
  };

  const onSendCallback = (chatMessage) => {
    const { text } = chatMessage[0];
    setLoading(true);
    const orderReq = {
      comments: text,
      deliveryDate: orderDeliveryDate,
      recordId: orderItemId,
      reason: "chat message",
      operator: operator,
    };
    const requestCallBack = () => {
      getMessages();
      setLoading(false);
    };
    triggerRequest({
      requestType: "order-communication",
      orderReq,
      requestCallBack,
    });
  };

  const renderSend = (props) => {
    const { text } = props;
    return (
      <Send {...props} containerStyle={styles.sendingContainer}>
        <>
          {loading && <ActivityIndicator color={colors.activityIndicator} />}
          {!loading && (
            <Image
              style={{ width: 28, height: 28 }}
              source={text === "" ? IMAGES["chat-incomplete"] : IMAGES["send"]}
            />
          )}
        </>
      </Send>
    );
  };

  const renderTime = (props) => {
    const {
      currentMessage: { createdAt },
    } = props;

    return (
      <Text style={{ fontStyle: "italic", marginTop: 3, fontSize: 11 }}>
        {moment(createdAt).format("M/D/YY [at] h:mm A")}
      </Text>
    );
  };

  const renderAvatar = (props) => {
    const {
      currentMessage: { direction = "", operatorName = "", businessName = "" },
    } = props;
    const IncomingMessage = direction === "INBOUND";
    const isButterCup =
      operatorName === "FTD Messaging System" ||
      businessName === "Buttercup, Mercury Bot";

    return (
      <>
        {isButterCup ? (
          <Image
            style={styles.butterCupAvatar}
            source={IMAGES["buttercup-chat-profile"]}
          />
        ) : IncomingMessage ? (
          <Image style={styles.chatAvatar} source={IMAGES["chat-profile2"]} />
        ) : (
          <Image style={styles.chatAvatar} source={IMAGES["chat-profile1"]} />
        )}
      </>
    );
  };

  const renderInputToolbar = (props) => {
    //Add the extra styles via containerStyle
    return <InputToolbar {...props} />;
  };

  return (
    <View>
      <View style={[tw("px-2 py-1"), { height: 480 }]}>
        <Chat
          messages={chatMessages}
          userId={userId}
          userName={userName}
          customMessageText={CustomMessageText}
          placeholder={Localise(locMessages, "Ask or answer a question")}
          renderAvatar={renderAvatar}
          renderSend={renderSend}
          renderTime={renderTime}
          onSendCallback={onSendCallback}
          renderInputToolbar={renderInputToolbar}
          containerStyle={styles.mainContainerStyle}
          hideSendInput={hideSendInput}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  actionContainers: {
    flexDirection: "row",
    alignItems: "center",
  },
  butterCupAvatar: {
    width: 35,
    height: 35,
  },
  chatAvatar: {
    width: 30,
    height: 30,
  },
  mainContainerStyle: {
    flex: 1,
    height: 500,
  },
  messageCustomTextStyle: {
    fontSize: 12,
    marginLeft: 0,
    fontFamily: fonts.fontFamily.default,
  },
  sendingContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  structuredStatus: {
    borderWidth: 1,
    borderColor: "#ccc",
    padding: 5,
    margin: 10,
  },
  nonMHQstructured: {
    marginTop: 5,
  },
});

export default OrderMessages;
