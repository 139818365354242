import React, { useContext, memo } from "react";
import { TouchableOpacity, Image } from "react-native";
import tw from "tailwind-rn";
import IMAGES from "static/assets/images";

import useStateIfMounted from "library/utils/useStateIfMounted";
import get from "lodash/get";
import { useSelector } from "react-redux";
import I18NContext from "library/contexts/i18N";
import AppSettingsContext from "library/contexts/appSettings";

import { ToasterHandler, Tooltip } from "components/elements";
import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";
import { hasFollowUpFeature } from "library/utils/featureAvailability";
import { isDeliveryDatePast } from "library/utils/createOrder";

const FollowUp = ({ index = 0, triggerAction }) => {
  const { messages, Localise } = useContext(I18NContext);
  const { permissions } = React.useContext(AppSettingsContext);

  const orderDetailResponse = useSelector(selectRecordData);
  const [loading, setLoading] = useStateIfMounted(false); // To disable the flag when one request is in progress

  const {
    direction,
    receivingMember = {},
    sendingMember = {},
    isFollowUp,
    deliveryInfo: { deliveryDate },
  } = get(orderDetailResponse, `orderItems.${index}`, {});

  const sourceMemberCode =
    direction === "INBOUND"
      ? receivingMember?.memberCode
      : sendingMember?.memberCode;

  const showFollowUp = hasFollowUpFeature(permissions, sourceMemberCode);

  // Hide modify order action button in case order delivery date is older than 90 days
  const hideModifyOrderAction = isDeliveryDatePast(deliveryDate);

  if (!showFollowUp || hideModifyOrderAction) return null;

  const handleOnPress = () => {
    setLoading(true);
    const updatesObj = {
      isFollowUp: {
        path: `orderItems.${index}.isFollowUp`,
        value: !isFollowUp,
      },
    };
    // keeping separate actions for add/remove followup, because we are updating the order listing only when there is a change in the action.
    triggerAction({
      action: !isFollowUp ? "add-follow-up" : "remove-follow-up",
      params: updatesObj,
      resolve: () => {
        setLoading(false);
        ToasterHandler(
          "success",
          `${Localise(
            messages,
            !isFollowUp
              ? "Order Flagged for follow up."
              : "Flagged Order removed from follow up."
          )}`
        );
      },
      reject: () => {
        setLoading(false);
        ToasterHandler(
          "uh oh",
          Localise(messages, "Something went wrong. Please try again!")
        );
      },
    });
  };

  return (
    <TouchableOpacity
      style={tw("flex-row justify-around items-center pt-1")}
      key={`follow-up`}
      onPress={handleOnPress}
      testID="follow-up"
      accessibilityLabel="follow-up"
      disabled={loading}
    >
      <Tooltip
        text={Localise(
          messages,
          isFollowUp
            ? "Order flagged for follow up"
            : "Flag your order for follow up"
        )}
        renderForWebOnly={true}
      >
        <Image
          style={{
            width: 24,
            height: 30,
            ...tw("ml-2 mr-1 mb-1"),
          }}
          source={IMAGES[isFollowUp ? "followup-flag-on" : "followup-flag-off"]}
        />
      </Tooltip>
    </TouchableOpacity>
  );
};

export default memo(FollowUp);
