import { request } from "library/utils/request";
import { ToasterHandler } from "components/elements";
import moment from "moment";
import startCase from "lodash/startCase";
import { Platform, PermissionsAndroid } from "react-native";
import FileViewer from "react-native-file-viewer";
import { months } from "./config";

let RNFS;
if (Platform.OS !== "web") {
  RNFS = require("react-native-fs");
}

export const onFormSubmit = (
  values,
  formikBag,
  shopNames = [],
  messages,
  Localise,
  setValidationOnChange,
  reportTypes = []
) => {
  const {
    selectedOccasions,
    selectedMemberCodes,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    reportType,
    allShopCodes,
    rangeType,
    month,
    year,
    filterBy,
    printBy,
    detailOrSummaryReport,
    businessAnalysisType,
    reportFormat = "",
  } = values;

  let requestParams;
  if (reportType === "customer-report") {
    let shopCodes = [...allShopCodes];
    if (allShopCodes.length > 1) allShopCodes.shift();
    let allMemberCodes = allShopCodes.map((code) => code.value);

    requestParams = {
      memberCodes: allMemberCodes,
      userTimeZone: encodeURIComponent(moment.tz.guess()),
    };
    formikBag.setFieldValue("allShopCodes", shopCodes);
  } else if (reportType === "house-account-statements") {
    requestParams = {
      memberCodes: selectedMemberCodes,
      month: months[month],
      year,
    };
  } else if (reportType === "aged-analysis-report") {
    requestParams = {
      memberCodes: selectedMemberCodes,
      shopName: encodeURIComponent(shopNames[selectedMemberCodes[0]]),
      reportFormat,
      userTimeZone: encodeURIComponent(moment.tz.guess()),
    };
  } else if (reportType === "sales-tax-report") {
    requestParams = {
      memberCodes: selectedMemberCodes,
      startDate,
      endDate,
      filterBy,
      printBy,
      userTimeZone: encodeURIComponent(moment.tz.guess()),
    };
  } else if (reportType === "occasion-reminder") {
    requestParams = {
      occasions: selectedOccasions,
      startDate,
      endDate,
    };
  } else if (
    ["sales-by-promo-code", "credit-card-settlement-report"].includes(
      reportType
    )
  ) {
    requestParams = {
      memberCodes: selectedMemberCodes,
      shopName: encodeURIComponent(shopNames[selectedMemberCodes[0]]),
      startDate,
      endDate,
      userTimeZone: encodeURIComponent(moment.tz.guess()),
      ...{ detailOrSummaryReport, reportFormat },
    };
  } else if (reportType === "advance-optimization-usage-report") {
    requestParams = {
      memberCodes: selectedMemberCodes,
      shopName: encodeURIComponent(shopNames[selectedMemberCodes[0]]),
      startDate,
      endDate,
      userTimeZone: encodeURIComponent(moment.tz.guess()),
    };
  } else if (reportType === "product-comparison-report") {
    requestParams = {
      memberCodes: Array.isArray(selectedMemberCodes)
        ? selectedMemberCodes
        : [selectedMemberCodes],
      shopName: encodeURIComponent(shopNames[selectedMemberCodes]),
      rangeType,
      startDate,
      endDate,
      comparisonStartDate,
      comparisonEndDate,
      userTimeZone: encodeURIComponent(moment.tz.guess()),
    };
  } else if (reportType === "business-snapshot-report") {
    requestParams = {
      memberCodes: selectedMemberCodes,
      shopName: encodeURIComponent(shopNames[selectedMemberCodes[0]]),
      startDate,
      endDate,
      rangeType,
      userTimeZone: encodeURIComponent(moment.tz.guess()),
    };
  } else if (reportType === "business-snapshot-report-by-type") {
    requestParams = {
      memberCodes: selectedMemberCodes,
      shopName: encodeURIComponent(shopNames[selectedMemberCodes[0]]),
      startDate,
      endDate,
      rangeType,
      userTimeZone: encodeURIComponent(moment.tz.guess()),
      reportFormat,
      businessAnalysisType,
    };
  } else {
    requestParams = {
      memberCodes: selectedMemberCodes,
      shopName: encodeURIComponent(shopNames[selectedMemberCodes[0]]),
      startDate,
      endDate,
      userTimeZone: encodeURIComponent(moment.tz.guess()),
      ...([
        "order-detail-report",
        "product-sales-report",
        "sales-summary-report",
      ].includes(reportType) && { rangeType }),
    };
  }

  const getFileName = (data) => {
    if (reportType === "customer-report") {
      return `${startCase(reportType)}.csv`;
    } else if (reportType === "house-account-statements") {
      return `${data?.fileName}.${data?.fileType}`;
    } else if (reportType === "aged-analysis-report") {
      const fileType = data?.fileType || "csv"; // default is csv for aged-analysis-report
      const fileName = data?.fileName || reportType;
      return `${startCase(fileName)}${
        selectedMemberCodes.length > 1 ? "(multi)" : "(single)"
      }.${fileType}`;
    } else {
      const startDateStr = moment(startDate).format("M.D.YY");
      const endDateStr = moment(endDate).format("M.D.YY");

      let reportTitle = "",
        extensionType = "csv";

      if (
        [
          "credit-card-settlement-report",
          "business-snapshot-report",
          "business-snapshot-report-by-type",
        ].includes(reportType)
      ) {
        reportTitle = startCase(data?.fileName?.replace("_report", ""));
      } else {
        reportTitle = startCase(reportType.replace("-report", ""));
      }
      if (
        [
          "credit-card-settlement-report",
          "business-snapshot-report",
          "business-snapshot-report-by-type",
          "sales-tax-report",
        ].includes(reportType)
      ) {
        // default reportFormat extension to xlsx
        extensionType = data?.fileType || "xlsx";
      }

      return `${startDateStr}_${endDateStr} ${reportTitle}${
        reportType === "product-comparison-report"
          ? ""
          : selectedMemberCodes.length > 1
          ? "(multi)"
          : "(single)"
      }.${extensionType}`;
    }
  };

  const determineReportType = (
    reportType,
    detailOrSummaryReport,
    businessAnalysisType
  ) => {
    switch (reportType) {
      case "sales-by-promo-code":
        return detailOrSummaryReport === "detail_report"
          ? "sales-by-promo-code-detail"
          : "sales-by-promo-code-summary";

      case "credit-card-settlement-report":
        return detailOrSummaryReport === "detail_report"
          ? "credit-card-settlement-detail"
          : "credit-card-settlement-summary";

      case "sales-tax-report":
        return detailOrSummaryReport === "detail_report"
          ? "sales-tax-detail"
          : "sales-tax-summary";

      case "aged-analysis-report":
        return detailOrSummaryReport === "detail_report"
          ? "aged-analysis-detail"
          : "aged-analysis-summary";

      case "business-snapshot-report-by-type":
        return `${businessAnalysisType.replace("_", "-")}-business-snapshot`;

      default:
        return reportType; // If reportType is not in the predefined list, return it as is
    }
  };

  const requestReportType = determineReportType(
    reportType,
    detailOrSummaryReport,
    businessAnalysisType
  );

  const getLabelByValue = (value) => {
    const report = reportTypes?.find((item) => item?.value === value);

    let label = report?.label?.replace(/Report$/i, "").trim(); // Remove "Report" if it exists

    if (detailOrSummaryReport === "detail_report") {
      label += " Detail";
    } else if (detailOrSummaryReport === "summary_report") {
      label += " Summary";
    }

    return label || "";
  };

  const reportLabel = getLabelByValue(reportType);

  const successMsg = `${reportLabel} ${
    reportType !== "house-account-statements" ? "report" : ""
  } has been downloaded`;

  request(requestReportType, requestParams)
    .then((resp) => {
      const res = resp?.data;
      setValidationOnChange(false);
      const fileName = getFileName(resp);

      if (Platform.OS === "web") {
        // Creating blob link to download
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        // Force download
        link.click();

        formikBag.setSubmitting(false);
        ToasterHandler("success", Localise(messages, successMsg));
      } else {
        if (requiresBase64Encoding(reportType, reportFormat)) {
          var reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onloadend = function () {
            var base64String = reader?.result?.split(",")[1] || "";
            SaveFile(
              base64String,
              fileName,
              formikBag,
              messages,
              Localise,
              "base64",
              successMsg
            );
          };
        } else {
          SaveFile(res, fileName, formikBag, messages, Localise);
        }
      }
    })
    .catch(() => {
      formikBag.setSubmitting(false);
      ToasterHandler(
        "error",
        Localise(
          messages,
          reportType === "house-account-statements"
            ? "House Account statements are not available"
            : "Download failed, please try again"
        )
      );
    });
};

export const SaveFile = (
  res,
  fileName,
  formikBag,
  messages,
  Localise,
  encoding,
  successMsg = ""
) => {
  if (Platform.OS === "ios") {
    saveReportFileOnDevice(
      res,
      fileName,
      formikBag,
      messages,
      Localise,
      encoding,
      successMsg
    );
  } else if (Platform.OS === "android") {
    checkPermissionsAndDownloadReport(
      res,
      fileName,
      formikBag,
      messages,
      Localise,
      encoding,
      successMsg
    );
  }
};

const checkPermissionsAndDownloadReport = async (
  res,
  fileName,
  formikBag,
  messages,
  Localise,
  encoding,
  successMsg
) => {
  try {
    const granted = await PermissionsAndroid.check(
      PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
    );

    /**
     * Asking for storage permissions on Android 13 is no longer needed
     *
     * Ref Links - https://github.com/facebook/react-native/issues/36714#issuecomment-1491338276 and
     * https://developer.android.com/training/data-storage/shared/media#storage-permission-not-always-needed
     **/
    if (granted || Platform.Version >= 33) {
      saveReportFileOnDevice(
        res,
        fileName,
        formikBag,
        messages,
        Localise,
        encoding,
        successMsg
      );
    } else {
      const result = await PermissionsAndroid.requestMultiple(
        [
          PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
          PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
        ],
        {
          title: Localise(messages, ""),
          message: Localise(
            messages,
            "Please approve Mercury HQ access to download"
          ),
        }
      );

      if (
        result["android.permission.READ_EXTERNAL_STORAGE"] &&
        result["android.permission.WRITE_EXTERNAL_STORAGE"] ===
          PermissionsAndroid.RESULTS.GRANTED
      ) {
        saveReportFileOnDevice(
          res,
          fileName,
          formikBag,
          messages,
          Localise,
          encoding,
          successMsg
        );
      } else {
        formikBag?.setSubmitting(false);

        ToasterHandler(
          "error",
          Localise(messages, "Please approve Mercury HQ access to download")
        );
      }
    }
  } catch (err) {
    formikBag?.setSubmitting(false);

    ToasterHandler(
      "error",
      Localise(messages, "Download failed, please try again")
    );
  }
};

const saveReportFileOnDevice = (
  data,
  fileName,
  formikBag,
  messages,
  Localise,
  encoding = "utf8",
  successMsg = "Download complete"
) => {
  var directoryType =
    Platform.OS === "android"
      ? RNFS.DownloadDirectoryPath
      : RNFS.DocumentDirectoryPath;

  var reportsDirPath = `${directoryType}/Mercury HQ Reports`;

  RNFS.mkdir(reportsDirPath)
    .then(() => {
      var filePath = `${reportsDirPath}/${fileName}`;

      RNFS.writeFile(filePath, data, encoding)
        .then(async () => {
          console.log("Report generated and saved at " + filePath);

          FileViewer.open(filePath)
            .then(() => {
              console.log(
                "Generated report opened using selected application.."
              );
            })
            .catch((error) => {
              console.log("Unable to open generated report " + error.message);

              ToasterHandler("success", Localise(messages, successMsg));
            });

          formikBag?.setSubmitting(false);
        })
        .catch(() => {
          formikBag?.setSubmitting(false);
          ToasterHandler(
            "error",
            Localise(messages, "Download failed, please try again")
          );
        });
    })
    .catch(() => {
      formikBag?.setSubmitting(false);
      ToasterHandler(
        "error",
        Localise(messages, "Download failed, please try again")
      );
    });
};

export const showDurationReportsList = [
  "order-summary-report",
  "order-detail-report",
  "sales-tax-report",
  "occasion-reminder",
  "sales-by-promo-code",
  "sales-summary-report",
  "credit-card-settlement-report",
  "advance-optimization-usage-report",
  "business-snapshot-report",
  "business-snapshot-report-by-type",
];

const encodingFormats = new Map([
  ["house-account-statements", true],
  ["credit-card-settlement-report", ["xlsx"]],
  ["aged-analysis-report", ["xlsx"]],
]);

const requiresBase64Encoding = (type, format) => {
  const result = encodingFormats.get(type);
  if (result === true) return true;
  if (Array.isArray(result)) return result.includes(format);
  return false;
};
