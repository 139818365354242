import React, { memo, useEffect } from "react";
import { View } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import tw from "tailwind-rn";
import { Picker } from "components/elements";
import { getScorecardMonthOptions } from "library/utils/scorecard";
import { setSelectedDate } from "library/sagas/views/home/drawer/reports/scorecard/slice";
import { selectScorecardDate } from "library/sagas/views/home/drawer/reports/scorecard/selector";

const CustomMonthPicker = () => {
  const dispatch = useDispatch();
  const pickerOptions = getScorecardMonthOptions();
  const selectedScorecardDate = useSelector(selectScorecardDate);

  useEffect(() => {
    if (!selectedScorecardDate)
      dispatch(setSelectedDate(pickerOptions[0].value));
  }, []);

  const handleMonthSelect = (dateVal) => {
    dispatch(setSelectedDate(dateVal));
  };

  return (
    <View style={[tw("flex-grow")]}>
      <Picker
        containerStyle={{
          paddingRight: 0,
          paddingBottom: 0,
          marginVertical: 10,
          marginLeft: -5,
          marginRight: 10,
          width: 200,
        }}
        items={pickerOptions}
        placeholder={{}}
        value={selectedScorecardDate}
        onValueChange={handleMonthSelect}
      />
    </View>
  );
};

export default memo(CustomMonthPicker);
