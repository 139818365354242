import { isEnabledForMember } from "library/utils/featureAvailability";

export const acceptAction = { key: "accept", label: "Accept" };
export const acceptPrint = { key: "accept-print", label: "Accept & Print" };
export const inDesign = { key: "design", label: "In Design" };
export const designed = { key: "designed", label: "Designed" };
export const outForDelivery = {
  key: "out-for-delivery",
  label: "Out for Delivery",
};
export const confirmDelivery = {
  key: "delivery-confirmation",
  label: "Confirm Delivery",
};
export const attemptedDelivery = {
  key: "delivery-exception",
  label: "Attempted Delivery",
};
export const rejectAction = { key: "reject", label: "Reject" };
export const copyAction = { key: "copy-order", label: "Copy Order" };

export const readyForPickup = {
  key: "out-for-delivery",
  label: "Ready for Pickup",
};
export const pickedUp = { key: "delivery-confirmation", label: "Picked Up" };

export const ListOfOrderActions = () => {
  const isOrderStatusReversible = isEnabledForMember(
    "ORDER_STATUS_REVERSIBLE",
    "ALL"
  );

  return {
    incoming: [acceptAction, acceptPrint, rejectAction, copyAction],
    accepted: [
      inDesign,
      designed,
      outForDelivery,
      confirmDelivery,
      attemptedDelivery,
      rejectAction,
      copyAction,
    ],
    design: [
      ...(isOrderStatusReversible ? [acceptAction] : []),
      designed,
      outForDelivery,
      confirmDelivery,
      attemptedDelivery,
      rejectAction,
      copyAction,
    ],
    designed: [
      ...(isOrderStatusReversible ? [acceptAction, inDesign] : []),
      outForDelivery,
      confirmDelivery,
      attemptedDelivery,
      rejectAction,
      copyAction,
    ],
    delivery: [
      ...(isOrderStatusReversible ? [acceptAction, inDesign, designed] : []),
      confirmDelivery,
      attemptedDelivery,
      rejectAction,
      copyAction,
    ],
    completed: [
      ...(isOrderStatusReversible
        ? [acceptAction, inDesign, designed, outForDelivery]
        : []),
      attemptedDelivery,
      rejectAction,
      copyAction,
    ],
    outgoing: [{ key: "cancel", label: "Cancel" }, copyAction],

    //For Pickup Orders
    "incoming-pickup": [
      acceptAction,
      acceptPrint,
      inDesign,
      readyForPickup,
      pickedUp,
      copyAction,
    ],
    "accepted-pickup": [
      inDesign,
      designed,
      readyForPickup,
      pickedUp,
      copyAction,
    ],
    "design-pickup": [
      ...(isOrderStatusReversible ? [acceptAction] : []),
      designed,
      readyForPickup,
      pickedUp,
      copyAction,
    ],
    "designed-pickup": [
      ...(isOrderStatusReversible ? [acceptAction, inDesign] : []),
      readyForPickup,
      pickedUp,
      copyAction,
    ],
    "delivery-pickup": [
      ...(isOrderStatusReversible ? [acceptAction, inDesign, designed] : []),
      pickedUp,
      copyAction,
    ],
    "completed-pickup": [
      ...(isOrderStatusReversible
        ? [acceptAction, inDesign, designed, readyForPickup]
        : []),
      copyAction,
    ],
    "completed-walkIn": [copyAction],

    // errors
    dsfullfillmenterror: [rejectAction],
    "settlement-error": [],
  };
};
