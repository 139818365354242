import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text } from "react-native";
import { Image } from "react-native-elements";
import IMAGES from "static/assets/images";

import { Section, Header } from "../helper";
import { getUIConfig } from "../ui-config";
import { createMenuOptions } from "./customer-info/ui-config";

import { Spinner } from "components/elements";

import I18NContext from "library/contexts/i18N";
import { Entitlements } from "library/utils/entitlements";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import { setCurrentCustomer } from "library/sagas/views/home/drawer/customer-directory/slice";
import { selectExtendedScreenData } from "library/sagas/views/home/drawer/customer-directory/selector";
import { selectReopenWarnDetails } from "library/sagas/ongoing/current-orders/selector";
import get from "lodash/get";
import tw from "tailwind-rn";
import { isEnabledForMember } from "library/utils/featureAvailability";
import { colors, theme } from "styles/theme";
import { CreateMenu } from "components/elements/create-menu";
import { selectCustomerDetails } from "library/sagas/views/home/drawer/customer-directory/selector";
import ReopenModal from "components/views/drawer/order-details/order-head/take-action/reopenModal";

const CreateMenuHeader = ({ openAccordion, title }) => {
  const customerDetails = useSelector(selectCustomerDetails);
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <Text
        style={{
          ...theme.Accordion.titleStyle,
          color: colors.primary,
          fontWeight: "500",
        }}
      >
        {title}
      </Text>
      <View style={{ marginLeft: "auto" }}>
        <CreateMenu
          menuOptions={createMenuOptions}
          dataParams={{ customerDetails }}
        />
      </View>
      <Image
        style={{
          width: 20,
          height: 20,
        }}
        source={
          IMAGES[openAccordion ? "expanded-section" : "collapsed-section"]
        }
      />
    </View>
  );
};

const Customer = ({ id, isCreate, houseAccountId }) => {
  const dispatch = useDispatch();
  const extendedScreenData = useSelector(selectExtendedScreenData);
  const reopenWarnDetails = useSelector(selectReopenWarnDetails);

  const status = get(extendedScreenData, "status", "");
  const { permissions } = React.useContext(AppSettingsContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);

  const allShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.CUSTOMER_DIRECTORY in permissions[memberCode])
      allShopCodes.push(memberCode);
  });

  const showEmailMarketingToggle = allShopCodes.every(
    (memberCode) =>
      isEnabledForMember("ENABLE_OCCASION_REMINDER", memberCode) &&
      permissions[memberCode][Entitlements.OCCASION_REMINDERS]?.includes(
        Entitlements.OCCASION_REMINDER_PERMISSIONS.EMAIL_MARKETING_NOTIFICATION
      )
  );

  const UIConfig = getUIConfig(
    Localise,
    messages,
    isDesktop,
    isMobile,
    allShopCodes,
    showEmailMarketingToggle
  );

  const onClose = () => {
    dispatch(setCurrentCustomer());
  };

  return (
    <View style={[isTablet && { padding: 10 }]}>
      {!isCreate && (
        <Header
          title={Localise(messages, "Customer Details")}
          onClose={onClose}
        />
      )}
      {status === "progress" ? (
        <View style={{ minHeight: 150 }}>
          <Spinner size="large" />
        </View>
      ) : status === "fail" ? (
        <View
          style={[{ minHeight: 150 }, tw("items-center justify-center p-8")]}
        >
          <Text style={{ color: "red" }}>
            {Localise(messages, "Something went wrong, please try again")}
          </Text>
        </View>
      ) : (
        UIConfig.sections.map(
          ({ section, component: { name: MainComponent, props } }, index) => {
            const isHidden =
              (section === Localise(messages, "Payment") && !id) ||
              (section === Localise(messages, "Order History") && isCreate) ||
              (section === Localise(messages, "House Account") && !id) ||
              (section === Localise(messages, "Credit/Debit Memo") &&
                !houseAccountId) ||
              (section === Localise(messages, "Statements") &&
                !houseAccountId) ||
              (section === Localise(messages, "Subscriptions") && isCreate) ||
              (section === Localise(messages, "Activity") && !id);
            return (
              !isHidden && (
                <Section
                  title={section}
                  key={index}
                  headerContent={
                    section === Localise(messages, "Customer Info")
                      ? (openAccordion) => (
                          <CreateMenuHeader
                            openAccordion={openAccordion}
                            title={section}
                          />
                        )
                      : undefined
                  }
                >
                  <MainComponent UIConfig={props} isCreate={isCreate} />
                </Section>
              )
            );
          }
        )
      )}
      {reopenWarnDetails?.showModal &&
      reopenWarnDetails?.source === "listing" ? (
        <ReopenModal {...{ reopenWarnDetails }} />
      ) : null}
    </View>
  );
};

export default Customer;
