import React, { useContext, useRef } from "react";
import { ScrollView, View, Text, ActivityIndicator } from "react-native";
import { useSelector } from "react-redux";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileStorage from "library/storage/userProfile";
import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";
import { selectIsEmbeddedMode } from "library/sagas/ongoing/global-data/selector";

/* eslint-disable react-hooks/rules-of-hooks */
const SingleScreen = ({
  headerSiblings,
  children,
  title,
  isLoading = false,
  scrollViewRef = useRef(),
}) => {
  const { isTablet, isMobile } = useContext(DeviceContext);
  const shopGroups = UserProfileStorage.getAllShopGroups();
  const shopCodes = UserProfileStorage.getProfileMemberCodes();
  const isEmbedded = useSelector(selectIsEmbeddedMode);

  return (
    <View
      style={[
        tw("flex flex-col w-full h-full"),
        {
          padding: isEmbedded ? 0 : isMobile ? 15 : isTablet ? 20 : 30,
        },
      ]}
      fsClass="fs-unmask"
    >
      {title ? (
        <View>
          <View
            style={[
              tw("flex flex-row justify-between items-center pb-3"),
              {
                flexWrap:
                  isMobile ||
                  (isTablet && shopGroups.length > 0 && shopCodes.length > 1)
                    ? "wrap"
                    : "nowrap",
                paddingTop: isMobile ? 10 : 0,
              },
            ]}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text
                style={{
                  ...fonts.heading1,
                  ...(isTablet && { fontSize: 16, fontWeight: "400" }),
                }}
              >
                {title}
              </Text>
              {isLoading && (
                <ActivityIndicator
                  style={{ marginLeft: 10 }}
                  color={colors.activityIndicator}
                  testID={"loader"}
                />
              )}
            </View>
            {headerSiblings && headerSiblings()}
          </View>
          {!isMobile && (
            <View
              style={{
                borderBottomColor: colors.light,
                borderBottomWidth: 1,
                marginBottom: 20,
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.4,
                shadowRadius: 7,
              }}
            />
          )}
        </View>
      ) : null}

      <ScrollView ref={scrollViewRef}>
        <View style={tw("flex w-full")}>{children}</View>
      </ScrollView>
    </View>
  );
};

export default SingleScreen;
