import React, { useEffect } from "react";
import { View, Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { setSearchZipcode } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/slice";
import I18NContext from "library/contexts/i18N";

import Search from "components/containers/search";

import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const SearchFilter = (props) => {
  const {
    label,
    placeholder,
    searchAction,
    searchSelector,
    containerStyle,
    searchOn = "",
    resultsCount = "",
  } = props;
  const dispatch = useDispatch();
  const searchValue = useSelector(searchSelector);
  const { messages, Localise } = React.useContext(I18NContext);

  useEffect(() => {
    return () =>
      dispatch(
        setSearchZipcode({
          type: "search",
          value: "",
        })
      );
  }, []);

  const resultsTextPart3 = searchValue ? Localise(messages, "found") : "";
  const resultsText = `${resultsCount} ${searchOn} ${resultsTextPart3}`;

  return (
    <View style={{ flexDirection: "column" }}>
      <View style={tw("flex flex-row flex-wrap pb-3 mt-3 items-center")}>
        <Search
          type={"fullWidth"}
          selectors={{ selectValue: searchSelector }}
          actions={{ setAction: searchAction }}
          placeholder={Localise(messages, placeholder)}
          containerStyle={containerStyle}
        />

        {label && (
          <Text style={[fonts.h4, { width: 170 }, tw("italic pt-2")]}>
            {Localise(messages, label)}
          </Text>
        )}
      </View>
      <View style={[tw("flex-row items-center pb-3"), { width: 200 }]}>
        <Text style={fonts.heading4}>{resultsText}</Text>
      </View>
    </View>
  );
};

export default SearchFilter;
