import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { View, Platform } from "react-native";

import I18NContext from "library/contexts/i18N";
import useStateIfMounted from "library/utils/useStateIfMounted";
import {
  selectShopPreferences,
  selectShopDetails,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { memberEntitlements } from "library/utils/entitlements";

import { AccordionWithBorder } from "components/elements";

import FeesCoverage from "./fees-coverage";
import StorePickup from "./store-pickup";
import DeliveryServiceSettings from "./delivery-service";
import DeliveryCutoffTimes from "../delivery-cutoff-times";

const DeliveryPickup = ({ loadingKey, saveSettings }) => {
  const shopDetails = useSelector(selectShopDetails);
  const shopSettings = useSelector(selectShopPreferences);
  const { messages, Localise } = useContext(I18NContext);
  const [feesCoverageAccordionStatus, setFeeCoverageAccordionStatus] =
    useStateIfMounted(false);

  const isEntitlementExpired =
    shopDetails?.memberEntitlements?.message?.includes("expired") ?? false;

  const isCoreOnlyUser =
    shopDetails.memberEntitlements?.memberEntitlementName ===
      memberEntitlements.MHQ_CORE || isEntitlementExpired;
  const isMOLOnlyUser =
    shopDetails.memberEntitlements?.memberEntitlementName ===
    memberEntitlements.MERCURY_ONLINE;
  const isDeliveryServiceOnlyUser =
    shopDetails.memberEntitlements?.memberEntitlementName ===
    memberEntitlements.DELIVERY_SERVICE;
  const isCoreConnectUser =
    shopDetails.memberEntitlements?.memberEntitlementName ===
      memberEntitlements.MHQ_CONN || isEntitlementExpired;

  const hasMOLEntitlement =
    shopDetails.memberEntitlements?.memberEntitlementName.includes(
      memberEntitlements.MERCURY_ONLINE
    );

  return (
    <View style={{ paddingBottom: Platform.OS === "android" ? 270 : 0 }}>
      {!isCoreOnlyUser && !isCoreConnectUser && !isDeliveryServiceOnlyUser && (
        <>
          <AccordionWithBorder
            title={"Fees & Coverage"}
            defaultOpen={feesCoverageAccordionStatus}
            handleOnPress={() =>
              setFeeCoverageAccordionStatus(!feesCoverageAccordionStatus)
            }
          >
            <FeesCoverage accordionStatus={feesCoverageAccordionStatus} />
          </AccordionWithBorder>
        </>
      )}
      {shopSettings?.is_service_bureau_member !== "true" && (
        <AccordionWithBorder
          title={Localise(messages, "Store Pickup")}
          defaultOpen={false}
        >
          <StorePickup showStorePickupLocations={hasMOLEntitlement} />
        </AccordionWithBorder>
      )}
      {!isCoreOnlyUser && !isCoreConnectUser && !isDeliveryServiceOnlyUser && (
        <DeliveryCutoffTimes />
      )}
      <AccordionWithBorder title={"Delivery Service Settings"}>
        <DeliveryServiceSettings
          hideAutoSendToDS={isDeliveryServiceOnlyUser || isMOLOnlyUser}
          shopSettings={shopSettings}
          loadingKey={loadingKey}
          saveSettings={saveSettings}
        />
      </AccordionWithBorder>
    </View>
  );
};

export default DeliveryPickup;
