import { put, delay, takeLatest, call } from "redux-saga/effects";
import { request } from "library/utils/request";

import {
  saveNotifications,
  setMemberCodes,
  fetchMemberCodes,
  setInit,
  fetchNotificationHistory,
  setApiData,
} from "./slice";
import get from "lodash/get";
import toUpper from "lodash/toUpper";

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */

function* handleInit() {
  yield put(fetchMemberCodes());
  yield put(fetchNotificationHistory());
}

function* handleFetchAllMemberCodes(action = {}) {
  const serviceRequest = (params) => request("get-all-memberCodes", params);
  try {
    const response = yield call(serviceRequest);
    const data = [];
    Object.values(response).forEach((value) => {
      data.push(...value);
    });
    const memberCodes = [...new Set(data)];
    yield put(setMemberCodes({ memberCodes, response }));
  } catch (error) {
    console.log("error", error);
  }
}

function* handleFetchNotificationHistory(action = {}) {
  const serviceRequest = (params) =>
    request("get-notifications-history", params);
  try {
    const response = yield call(serviceRequest, {
      shopCode: "ftd",
      searchTerm: "",
      searchValue: "",
      types: "all",
      isDeleted: false,
    });
    yield put(setApiData(response));
  } catch (error) {
    console.log("error", error);
  }
}

function* handleSaveAdminNotifications(action = {}) {
  const { params, resolve, reject } = get(action, "payload", "");

  const serviceRequest = (params) => request("save-notifications", params);

  try {
    const requestPayload = prepareNotificationPayload(params);
    yield call(serviceRequest, requestPayload);
    yield delay(2000);
    yield put(fetchNotificationHistory());
    resolve && resolve();
  } catch {
    reject && reject();
  }
}

const prepareNotificationPayload = (params = {}) => {
  const {
    values: {
      memberCodes = [],
      senderType = "",
      startDate = "",
      startTime = "",
      messageTitle = "",
      messageBody = " ",
    },
  } = params;
  const time = startTime ? startTime.split("T")[1] : "00:00:00";
  const payload = {
    type: toUpper(senderType),
    memberCodes,
    messagePayload: {
      subject: messageTitle,
    },
    messageText: messageBody,
    hasBeenRead: false,
    hasBeenSent: false,
    isDeleted: false,
    messageSender: senderType,
    availableDate: `${startDate}T${time}`,
  };

  return payload;
};
/**
 * Will be using when integrating UI with backend
 */
export function* watchSaga() {
  yield takeLatest(saveNotifications.type, handleSaveAdminNotifications);
  yield takeLatest(fetchMemberCodes.type, handleFetchAllMemberCodes);
  yield takeLatest(
    fetchNotificationHistory.type,
    handleFetchNotificationHistory
  );
  yield takeLatest(setInit.type, handleInit);
}

export default watchSaga;
