import React, { useContext } from "react";
import {
  View,
  TouchableOpacity,
  Animated,
  Text,
  Image,
  Platform,
} from "react-native";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { Button } from "react-native-elements";
import tw from "tailwind-rn";
import get from "lodash/get";
import every from "lodash/every";
import moment from "moment";
import { IMAGES, DIMENSIONS } from "static/assets/images";
import { useNavigation, CommonActions } from "@react-navigation/native";
import {
  fonts,
  theme,
  backgroundColors,
  colors,
  routeStatusColorCodes,
} from "styles/theme";
import {
  PopupMenu,
  Accordion,
  CustomModal,
  Spinner,
} from "components/elements";
import {
  CompleteRouteModal,
  hasNonDeliveredOrdersInRoute,
} from "components/views/drawer/delivery/routes-dashboard/CompleteRouteModal";
import styles from "./styles";

import I18NContext from "library/contexts/i18N";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import UserProfileStorage from "library/storage/userProfile";
import { Entitlements } from "library/utils/entitlements";

//sagas
import { setRecordId } from "library/sagas/ongoing/current-orders/slice";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import {
  setactiveRouteId,
  setCompleteRouteModal,
} from "library/sagas/views/home/drawer/delivery/slice";
import {
  selectActiveRouteId,
  selectNonRoutedOrders,
} from "library/sagas/views/home/drawer/delivery/selector";

//utils
import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";
import {
  convertToTimeZone,
  timedOrderBuffer,
} from "library/utils/deliveryService";

//config
import {
  ListOfRouteActions,
  RouteStatusImages,
  InitViewMoreCounts,
} from "../config";
import { RouteStatusDisplayNames } from "../../config.js";

const RoutesListing = ({
  shopCode,
  loading,
  setLoading,
  routes,
  showMeatBalls = true,
  variation,
  setCurrentPage,
  setRecordId,
  setSelectedRecordId,
  title,
  defaultOpen = true,
  getRoutes = () => {},
  label,
  setactiveRouteId,
  setCompleteRouteModal,
  filteredShopCodes,
}) => {
  const { messages, Localise } = useContext(I18NContext);
  const { isMobile, isTablet } = useContext(DeviceContext);
  const { permissions = {} } = useContext(AppSettingsContext);

  const [viewMoreCounts, setViewMoreCounts] =
    useStateIfMounted(InitViewMoreCounts);
  const [modalVisible, setModalVisibile] = useStateIfMounted(false);
  const [accessModalVisible, setAccessModalVisible] = useStateIfMounted(false);

  const activeRouteId = useSelector(selectActiveRouteId);
  const nonRoutedOrders = useSelector(selectNonRoutedOrders);

  let displayRoutes = [...routes];
  if (label === "activeRoutes") {
    displayRoutes = routes.filter(
      (eachItem) => !["COMPLETED", "DELETED"].includes(eachItem.route.status)
    );
  } else if (label === "completedRoutes") {
    displayRoutes = routes.filter(
      (eachItem) => eachItem.route.status === "COMPLETED"
    );
  } else if (label === "deletedRoutes") {
    displayRoutes = routes.filter(
      (eachItem) => eachItem.route.status === "DELETED"
    );
  } else if (label === "routes") {
    // Do not show Deleted Routes in Driver Dashboard
    displayRoutes = routes.filter(
      (eachItem) => !["DELETED"].includes(eachItem.route.status)
    );
  }
  const showEditRoute =
    filteredShopCodes.some((memberCode) =>
      permissions[memberCode][Entitlements.DRIVER_DASHBOARD]?.includes(
        Entitlements.DRIVER_DASHBOARD_PAGE.EDIT_ROUTE
      )
    ) &&
    (isTablet || isMobile);

  const updateStatus = (newStatus, routeId) => {
    setLoading(true);
    request("update-route-status", { shopCode, newStatus, routeId })
      .then((res) => {
        setLoading(false);
        res && getRoutes();
      })
      .catch(() => {
        console.log("error");
        setLoading(false);
      });
  };
  const routeAction = (newStatus, routeId, route) => {
    setLoading(true);
    if (newStatus === "DELETED") {
      setactiveRouteId(routeId);
      setModalVisibile(true);
      setLoading(false);
    } else if (newStatus === "COMPLETED") {
      setactiveRouteId(routeId);
      if (hasNonDeliveredOrdersInRoute(route?.stops)) {
        //show warning popup if route has undelivered orders
        setCompleteRouteModal({
          modalVisible: true,
          routeStops: route?.stops,
        });
        setLoading(false);
      } else {
        updateStatus(newStatus, routeId);
      }
    } else {
      updateStatus(newStatus, routeId);
    }
  };
  const handleDeleteRoute = () => {
    updateStatus("DELETED", activeRouteId);
    setModalVisibile(false);
  };
  const handleCompleteRoute = () => {
    updateStatus("COMPLETED", activeRouteId);
    setCompleteRouteModal({ modalVisible: false });
  };

  const modalMessages = {
    delete:
      "By deleting this Route, all orders will be returned to the To Be Routed list.",
    complete:
      "All non-delivered orders are now going back into non-routed orders",
    access:
      "You do not have permission to view orders from some Shops on this route.",
  };
  const modalContent = {
    content: (
      <Text
        style={{
          ...tw("p-4"),
          fontSize: 15,
          color: colors.highlighter,
          textAlign: "center",
        }}
      >
        {Localise(
          messages,
          modalVisible
            ? modalMessages["delete"]
            : accessModalVisible
            ? modalMessages["access"]
            : ""
        )}
      </Text>
    ),
    buttons: [
      {
        type: "secondary",
        title: Localise(messages, accessModalVisible ? "OK" : "Nevermind"),
      },
      ...(modalVisible
        ? [
            {
              type: "primary",
              title: Localise(
                messages,
                modalVisible ? "Delete Route" : "Continue"
              ),
            },
          ]
        : []),
    ],
  };
  const modal = (
    <CustomModal
      modalVisible={modalVisible || accessModalVisible}
      modalContent={modalContent}
      primaryhandler={() => {
        if (modalVisible) handleDeleteRoute();
      }}
      secondaryhandler={() => {
        if (modalVisible) setModalVisibile(false);
        else if (accessModalVisible) setAccessModalVisible(false);
      }}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: backgroundColors.secondary },
      ]}
      modalStyle={
        Platform.OS !== "web"
          ? {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: colors.secondary,
            }
          : !modalVisible && { width: "35%" }
      }
    />
  );

  return (
    <Accordion
      title={`${Localise(messages, title)} (${displayRoutes.length})`}
      defaultOpen={defaultOpen}
      titleStyle={{
        ...fonts.heading3,
        fontWeight: "600",
        color: colors.primary,
      }}
      labelStyle={{
        backgroundColor: backgroundColors.navBar,
        borderColor: colors.grayScaleLight,
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        marginRight: 5,
        height: 40,
        marginTop: 5,
        paddingHorizontal: 12,
      }}
      iconStyle={{
        color: colors.primary,
      }}
      handleOnPress={() => {}}
    >
      {loading && !displayRoutes?.length ? (
        <View style={{ minHeight: 150 }}>
          <Spinner size="large" testID="route_listing_loader" />
        </View>
      ) : (
        <>
          <View
            style={[
              tw(
                `flex ${
                  isMobile ? `flex-col` : `flex-row`
                } flex-wrap items-center`
              ),
            ]}
          >
            {displayRoutes?.length
              ? displayRoutes
                  .slice(0, viewMoreCounts[label])
                  .map((eachItem, index) => {
                    const { route } = eachItem;
                    const { memberCodes = [] } = route;
                    let routeActions = get(
                      ListOfRouteActions,
                      variation === "sideCar" ? "DRIVER" : route.status,
                      []
                    );
                    if (!route.driver.name || route.ordersCount == 0) {
                      routeActions = routeActions.filter(
                        (action) => action.key !== "INPROGRESS"
                      );
                    }
                    if (variation === "sideCar" && !showEditRoute) {
                      routeActions = routeActions.filter(
                        (action) => action.key !== "EDIT"
                      );
                    }
                    const imageName = RouteStatusImages[route.status];
                    const { imageWidth = 20, imageHeight = 20 } =
                      DIMENSIONS[imageName] || {};

                    const hasEditRouteAccess =
                      every(memberCodes, (each) =>
                        filteredShopCodes.includes(each)
                      ) || false;

                    return (
                      <Animated.View
                        key={index}
                        style={{
                          width: isMobile ? "100%" : 210,
                        }}
                      >
                        <RouteItem
                          route={route}
                          imageName={imageName}
                          showMeatBalls={showMeatBalls}
                          routeActions={routeActions}
                          imageWidth={imageWidth}
                          imageHeight={imageHeight}
                          routeAction={routeAction}
                          variation={variation}
                          setSelectedRecordId={setSelectedRecordId}
                          setRecordId={setRecordId}
                          setCurrentPage={setCurrentPage}
                          nonRoutedOrders={nonRoutedOrders}
                          shopCode={shopCode}
                          hasEditRouteAccess={hasEditRouteAccess}
                          setAccessModalVisible={setAccessModalVisible}
                        />
                      </Animated.View>
                    );
                  })
              : null}
          </View>
          {displayRoutes?.length > viewMoreCounts[label] && (
            <Button
              titleStyle={theme.Button.secondaryTitleStyle}
              buttonStyle={{
                ...theme.Button.secondaryButtonStyle,
                paddingHorizontal: 10,
                paddingVertical: 6,
                width: 95,
                height: 35,
              }}
              containerStyle={{
                alignItems: "flex-end",
                marginHorizontal: 0,
                marginRight: 5,
              }}
              title={Localise(messages, "View More")}
              onPress={() => {
                setViewMoreCounts({
                  ...viewMoreCounts,
                  [label]: viewMoreCounts[label] + 8,
                });
              }}
              testID={`${title.split(" ").join("_")}_view_more`}
              accessibilityLabel={`${title.split(" ").join("_")}_view_more`}
            />
          )}
        </>
      )}
      {modal}
      {label === "activeRoutes" ? (
        <CompleteRouteModal
          Localise={Localise}
          messages={messages}
          primaryhandler={handleCompleteRoute}
        />
      ) : null}
    </Accordion>
  );
};

//eslint-disable-next-line
const RouteItem = React.memo(
  ({
    imageName,
    route,
    showMeatBalls,
    routeActions,
    imageWidth,
    imageHeight,
    routeAction,
    variation,
    setSelectedRecordId,
    setRecordId,
    setCurrentPage,
    nonRoutedOrders,
    shopCode,
    hasEditRouteAccess,
    setAccessModalVisible,
  }) => {
    const navigation = useNavigation();
    const { messages, Localise } = useContext(I18NContext);
    const { isDesktop, isMobile } = useContext(DeviceContext);
    const isSmallScreen = !isDesktop;
    const {
      routeId: itemRouteId,
      status: routeStatus,
      routeName,
      deliveryDate,
      recommendedStartTime,
      departureTime,
      estimatedReturnTime,
      returnTime,
      ordersCount = 0,
      completed = 0,
      driver: { name: driverName = "" },
      capacity,
      stops,
      memberCodes = [],
    } = route;

    const routeStartTime =
      (departureTime &&
        moment(moment.utc(departureTime).toDate()).format("h:mm A")) ||
      Localise(messages, "TBD");
    const routeEndTime = returnTime
      ? moment(moment.utc(returnTime).toDate()).format("h:mm A") ||
        Localise(messages, "TBD")
      : (estimatedReturnTime &&
          moment(moment.utc(estimatedReturnTime).toDate()).format("h:mm A")) ||
        Localise(messages, "TBD");
    const dDate = moment(deliveryDate).format("MM/DD/YYYY");

    //calculating earliest undelivered timed order deliverBy time
    let updatedStops = stops.map((stop) => {
      return {
        ...stop,
        deliveryStatus: nonRoutedOrders.find(
          (ord) => stop.orderId === ord.orderItemId
        )?.status,
      };
    });
    const undeliveredTimedOrdersInRoute = updatedStops.filter(
      (ord) => ord.deliveryStatus !== "DELIVERED" && ord.deliverByTime
    );
    const deliverByTimeMap = undeliveredTimedOrdersInRoute.map((ord) =>
      moment(ord?.deliverByTime)
    );
    const earliestTimedDelivery = deliverByTimeMap.length
      ? moment.min(deliverByTimeMap)
      : null;
    const shopTimeZone = UserProfileStorage.getShopTimeZone(shopCode);
    const floristTimeZone = shopTimeZone || "America/Chicago";

    const deliverBy = earliestTimedDelivery
      ? moment(
          convertToTimeZone(earliestTimedDelivery, floristTimeZone)
        ).format("h:mm A")
      : "";

    //checking if route has timed order
    const timedOrdersInRoute = nonRoutedOrders.filter(
      (ord) =>
        ord.routeId &&
        itemRouteId === ord.routeId &&
        (ord.isRushOrder || !!ord?.orderDeliveryTime)
    );
    const hasTimedOrder = timedOrdersInRoute.length > 0;

    //Suggested start in florist timezone
    const localRecommendedStartTime = moment(
      convertToTimeZone(moment(recommendedStartTime).format(), floristTimeZone)
    );
    let deliveryTimeDetail = "";

    if (recommendedStartTime && !departureTime) {
      deliveryTimeDetail =
        Localise(messages, "Suggested Start") +
        ": " +
        moment(localRecommendedStartTime).format("h:mm A");
    } else {
      deliveryTimeDetail = `${routeStartTime} / ${routeEndTime}`;
    }

    //checking if route may have delayed orders
    const currentFloristTimewithBuffer = moment(
      convertToTimeZone(moment.utc().format(), floristTimeZone)
    ).add(timedOrderBuffer || 20, "minutes");
    const localDepartureTime = moment(
      convertToTimeZone(departureTime, floristTimeZone)
    );

    const startTime =
      departureTime && routeStatus === "INPROGRESS"
        ? localDepartureTime
        : currentFloristTimewithBuffer;

    const isDelayed =
      moment(startTime).isAfter(localRecommendedStartTime) &&
      recommendedStartTime &&
      ["DRAFT", "PLANNED", "INPROGRESS"].includes(routeStatus);

    const openEditRoute = (params = {}) => {
      setCurrentPage("edit-route");
      if (route.autoRouteId) {
        UserProfileStorage.setAutoRouteId(route.autoRouteId);
      } else {
        UserProfileStorage.setAutoRouteId(undefined);
      }
      UserProfileStorage.setRouteShopList([...memberCodes]);
      navigation.dispatch(
        CommonActions.navigate({
          name: "edit-route",
          params: {
            routeId: route.routeId,
            memberCodes: memberCodes.join(","),
            accessByDriver: "false",
            ...params,
          },
        })
      );
    };

    return (
      <TouchableOpacity
        activeOpacity={0.6}
        style={{
          height: isMobile ? 230 : 200,
        }}
        onPress={() => {
          //Disable for Deleted Routes
          if (routeStatus !== "DELETED") {
            if (hasEditRouteAccess) {
              if (variation !== "sideCar") {
                openEditRoute();
              } else {
                setSelectedRecordId(route.routeId);
                setRecordId();
              }
            } else {
              setAccessModalVisible(true);
            }
          }
        }}
      >
        <View
          style={[
            styles.recordContainer,
            {
              borderBottomColor:
                routeStatusColorCodes[routeStatus?.toLowerCase()] ||
                colors.grayScaleLight,
            },
            !isMobile && { flex: 1 },
          ]}
          fsClass="fs-unmask"
        >
          <View
            style={[{ display: "flex", flexDirection: "row", minHeight: 50 }]}
          >
            <View
              style={[
                styles.record,
                showMeatBalls
                  ? styles.recordWithMeatBalls
                  : styles.recordWithNoMeatBalls,
                {
                  borderBottomColor:
                    routeStatusColorCodes[routeStatus?.toLowerCase()] ||
                    "transparent",
                },
              ]}
              fsClass="fs-unmask"
            >
              {[
                "routeName",
                "deliveryDate",
                "deliveryTime",
                "earliestRushTime",
                "driverName",
                "completed",
                "capacity",
              ].map((column) => {
                return (
                  <React.Fragment key={column}>
                    <View style={[styles.recordItemView]}>
                      <Text
                        numberOfLines={1}
                        style={[
                          styles[column],
                          {
                            ...styles.recordItem,
                            textAlign: "left",
                            fontSize: 12,
                            color: colors.primary,
                            minHeight: column === "capacity" ? 10 : 20,
                            ...(isMobile
                              ? { paddingVertical: 4 }
                              : { paddingBottom: 2 }),
                          },
                        ]}
                        title={route[column]}
                        fsClass={"fs-unmask"}
                      >
                        {column === "routeName" ? (
                          `${routeName}`
                        ) : column === "deliveryDate" ? (
                          `${dDate}`
                        ) : column === "deliveryTime" ? (
                          `${deliveryTimeDetail}`
                        ) : column === "earliestRushTime" ? (
                          deliverBy ? (
                            <View style={[tw("flex flex-row")]}>
                              <Text
                                numberOfLines={1}
                                style={{
                                  maxWidth: 120,
                                  fontSize: 12,
                                  color: colors.primary,
                                }}
                              >
                                {Localise(messages, "Next Timed")}
                              </Text>
                              <Text>:&nbsp;</Text>
                              <Text
                                numberOfLines={1}
                                style={{
                                  fontSize: 12,
                                  color: colors.primary,
                                  ...(isDelayed && { color: colors.red }),
                                }}
                              >
                                {deliverBy}
                              </Text>
                            </View>
                          ) : (
                            ""
                          )
                        ) : column === "completed" ? (
                          `${completed}/${ordersCount} ${Localise(
                            messages,
                            "Completed"
                          )}`
                        ) : column === "driverName" ? (
                          `${driverName}`
                        ) : column === "capacity" && capacity ? (
                          `${Localise(
                            messages,
                            "Capacity"
                          )}: ${ordersCount}/${capacity}`
                        ) : (
                          route[column] || ""
                        )}
                      </Text>
                    </View>
                  </React.Fragment>
                );
              })}
            </View>

            {showMeatBalls && routeActions && routeActions.length > 0 && (
              <PopupMenu
                containerStyle={
                  showMeatBalls ? styles.menuContainer : styles.noMenuContainer
                }
                optionsContainerStyle={{
                  width: 150,
                  padding: 5,
                }}
                menuOptions={routeActions}
                onSelect={(action) => {
                  if (action === "EDIT") {
                    if (hasEditRouteAccess)
                      openEditRoute({
                        accessByDriver: "true",
                        driverDashboardShopCode: shopCode,
                      });
                    else setAccessModalVisible(true);
                  } else {
                    routeAction(action, route.routeId, route);
                  }
                }}
                testID={`${routeName}_list_item_menu`}
                accessibilityLabel={`${routeName}_list_item_menu`}
              >
                <Image
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  resizeMode="contain"
                  source={IMAGES[`three-dots`]}
                />
              </PopupMenu>
            )}
          </View>

          <View style={[tw("flex flex-row flex-wrap items-center")]}>
            <View
              style={{
                ...styles.statusIcon,
                paddingRight: 5,
              }}
            >
              <Image
                style={{
                  width: imageWidth,
                  height: imageHeight,
                }}
                containerStyle={{
                  marginHorizontal: isSmallScreen ? 5 : 10,
                  marginVertical: 5,
                }}
                source={IMAGES[imageName]}
              />
            </View>
            {hasTimedOrder && (
              <View
                style={{
                  ...styles.statusIcon,
                  paddingRight: 10,
                }}
              >
                <Image
                  style={{
                    width: 35,
                    height: 25,
                  }}
                  containerStyle={{
                    marginHorizontal: isSmallScreen ? 5 : 10,
                    marginVertical: 5,
                  }}
                  source={
                    IMAGES[
                      routeStatus === "COMPLETED"
                        ? "timed-delivery-delivered"
                        : routeStatus === "DELETED"
                        ? "timed-delivery-cancelled"
                        : "timed-delivery-progress"
                    ]
                  }
                />
              </View>
            )}
            <View style={styles.statusText}>
              <Text
                numberOfLines={2}
                style={{
                  ...styles.recordItem,
                  textAlign: "left",
                  fontSize: 13,
                  fontWeight: "600",
                  color:
                    routeStatus?.toLowerCase() === "deleted"
                      ? colors.primary
                      : routeStatusColorCodes[routeStatus?.toLowerCase()] ||
                        colors.primary,
                }}
              >
                {Localise(messages, RouteStatusDisplayNames[routeStatus]) || ""}
              </Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRecordId,
      setCurrentPage,
      setactiveRouteId,
      setCompleteRouteModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(RoutesListing);
