import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectPageStatus = createSelector(
  selectState,
  ({ status }) => status
);

export const selectIsPageLoading = createSelector(
  selectPageStatus,
  (status) => status === "progress"
);

export const selectIsPageFailed = createSelector(
  selectPageStatus,
  (status) => status === "fail"
);

export const selectIsPageLoaded = createSelector(
  selectPageStatus,
  (status) => status === "done"
);

export const selectActions = createSelector(
  selectState,
  ({ actions }) => actions
);

export const selectApiResponse = createSelector(
  selectState,
  ({ apiResponse }) => apiResponse
);

export const selectApiError = createSelector(selectState, ({ error }) => error);

export const selectIsFullRefund = createSelector(
  selectState,
  ({ isRefund }) => isRefund
);

export const selectRouteDetails = createSelector(
  selectState,
  ({ routeDetails }) => routeDetails
);

export const selectOrderViewersInfo = createSelector(
  selectState,
  ({ orderViewersInfo }) => orderViewersInfo
);

export const selectCurrentViewerInfo = createSelector(
  selectState,
  ({ currentViewerInfo }) => currentViewerInfo
);

export const selectShowOrderUnpaidModal = createSelector(
  selectState,
  ({ showOrderUnpaidModal }) => showOrderUnpaidModal
);

export const selectIsOrderCancelled = createSelector(
  selectState,
  ({ isCancel }) => isCancel
);

export const selectQrCode = createSelector(selectState, ({ qrCode }) => qrCode);

export const selectOrderDetailsFromScan = createSelector(
  selectState,
  ({ orderDetailsFromScan }) => orderDetailsFromScan
);

export const selectShowRecipe = createSelector(
  selectState,
  ({ showRecipe }) => showRecipe
);

export const selectOrderActions = createSelector(
  selectState,
  ({ orderActions }) => orderActions
);

export const selectShowPrint = createSelector(
  selectState,
  ({ showPrint }) => showPrint
);

export const selectRefundSplitPreview = createSelector(
  selectState,
  ({ refundPreview }) => refundPreview
);

export const selectIsRefundPreviewLoading = createSelector(
  selectState,
  ({ refundPreviewStatus }) => refundPreviewStatus === "progress"
);

export const selectOrderHeadHeight = createSelector(
  selectState,
  ({ orderHeadHeight }) => orderHeadHeight
);

export const selectCanEditOrder = createSelector(
  selectState,
  ({ canEditOrder }) => canEditOrder || false
);

export const selectIsSideCarOpen = createSelector(
  selectState,
  ({ isSideCarOpen }) => isSideCarOpen || false
);
