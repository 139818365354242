import React, { useEffect, useCallback, useRef } from "react";
import { View, Platform } from "react-native";
import { Text } from "react-native-elements";
import { fonts } from "styles/theme";
import moment from "moment";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import AppSettingsStorage from "library/storage/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import UserProfileStorage from "library/storage/userProfile";
import CalendarWidget from "./calendarWidget";
import OrderActionWidget from "./orderActionWidget";
import OrderTodayWidget from "./orderTodayWidget";
import OrderTomorrowWidget from "./orderTomorrowWidget";
import { Picker, PrintIframeRenderer } from "components/elements";

import { request } from "library/utils/request";
import tw from "tailwind-rn";
import styles from "./styles";
import Notifications from "./notifications";
import AudibleNotification from "./audibleNotification";
import get from "lodash/get";
import SingleScreen from "components/containers/single-screen";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import SubHeader from "components/containers/header/sub-header";
import {
  setCurrentPage,
  setShopCode,
  setShopGroup,
  fetchQuickBooksSessionExpired,
  setIsOrdersCallInProgress,
  setIsOrderSearchApplied,
  setIsOrderSplitCallInProgress,
  setCurrentTimeMinus30Seconds,
} from "library/sagas/ongoing/global-data/slice";
import {
  selectCurrentTimeMinus30Seconds,
  selectCanCallRetryFetchOrders,
} from "library/sagas/ongoing/global-data/selector";
import { setCreateOrderShopCode } from "library/sagas/views/home/drawer/create-order/slice";
import fetchOrders from "library/utils/fetchOrders";
import {
  ListOfOrderStatus,
  InitOrdersData,
} from "components/views/drawer/order-listing/current-orders/config";
import {
  resetBasicInfo,
  fetchData,
} from "library/sagas/ongoing/current-orders/slice";
import useStateIfMounted from "library/utils/useStateIfMounted";
import isObject from "lodash/isObject";
import I18NContext from "library/contexts/i18N";
import { Entitlements } from "library/utils/entitlements";
import Environment from "library/utils/environment";
import { fetchAvailableSessions } from "library/sagas/session/slice";
import { isEmpty } from "lodash";
import { hasQuickBooksAccess } from "library/utils/featureAvailability";
import { setShopCodeForDraftOrder } from "library/sagas/views/home/drawer/create-order/slice";
import {
  checkDateRangeFilterApplied,
  retryFetchOrdersAfterDelay,
} from "./helper";

let fcmService;

if (Platform.OS !== "web") {
  fcmService = require("library/utils/PushNotifications/FCMService").fcmService;
}

const todayDate = moment().format("YYYY-MM-DD");

const DashboardScreen = ({
  resetBasicInfo,
  fetchData,
  currentOrdersInfo,
  currentPage,
  setCurrentPage,
  setShopCode,
  setCreateOrderShopCode,
  selectedShopCode,
  setShopGroup,
  selectedShopGroup,
  sessionInfo = {},
  fetchAvailableSessions,
}) => {
  const [widgetsRes, setWidgetsRes] = useStateIfMounted([]);
  const [loading, setLoading] = useStateIfMounted(false);
  const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);
  const [notifications, setNotifications] = useStateIfMounted({});
  const [shops, setShops] = useStateIfMounted([]);
  const [isNotificationsLoading, setIsNotificationsLoading] =
    useStateIfMounted(false);
  const isSmallScreen = !isDesktop;
  const { sessionId } = sessionInfo;
  const dispatch = useDispatch();
  const selectLastSyncTime = useSelector(selectCurrentTimeMinus30Seconds);
  const selectRetryFetchOrders = useSelector(selectCanCallRetryFetchOrders);

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const {
    // memberCodes = [],
    userProfile,
    suspendedMembers = [],
    setSuspendedMembers,
    shopGroups,
    setAccountMemberCodes,
    setFloristTypes,
  } = React.useContext(UserProfileContext);

  const scrollViewRef = useRef();
  const callRetryFetchOrders = useRef(true);
  const retryLastsyncTime = useRef("");

  const { permissions } = React.useContext(AppSettingsContext);

  const allShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.DASHBOARD in permissions[memberCode])
      allShopCodes.push(memberCode);
  });

  const isAudibleTurnedOn = !isObject(
    get(userProfile, "preferences", []).find(
      (e) => e.name === "Notification Sound" && e.value === "NO"
    )
  );

  const endDate = moment().format("YYYY-MM-DD");
  const startDate = moment().subtract(30, "days").format("YYYY-MM-DD");

  const getNotifications = () => {
    setIsNotificationsLoading(true);
    request("get-notifications", {
      memberCodes:
        selectedShopCode === "all" ? allShopCodes : [selectedShopCode],
      startDate,
      endDate,
    })
      .then((res) => {
        setNotifications(res || {});
      })
      .catch((err) => {
        console.log("get notifications error ", err);
      })
      .finally(() => {
        setIsNotificationsLoading(false);
      });
  };

  const shopNames = UserProfileStorage.getAllShopNames();
  const [calendarDates, setCalendarDates] = useStateIfMounted({
    startDate: moment().startOf("isoweek").format("YYYY-MM-DD"),
    endDate: moment().endOf("isoweek").format("YYYY-MM-DD"),
  });

  const pullShopSuspendDetails = () => {
    request("get-shop-suspend-details", { startDate: todayDate })
      .then((res) => {
        let suspendMembers = [];
        res &&
          res.forEach((shop) => {
            if (shop.suspendInfo && shop.suspendInfo.isSuspendActive) {
              suspendMembers.push(shop.memberCode);
            }
          });
        setSuspendedMembers(suspendMembers);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const pullShopDetails = () => {
    request("get-shop-details")
      .then((res) => {
        let floristTypes = [];
        res &&
          res.forEach((shop) => {
            const { memberCode, floristType } = shop;
            floristTypes.push({ [memberCode]: floristType });
          });
        setShops(res);
        setFloristTypes(floristTypes);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const pullDashboardWidgets = (accountMemberCodes = []) => {
    setLoading(true);
    request("get-dashboard-widgets", {
      memberCodes:
        selectedShopCode === "all"
          ? accountMemberCodes.length
            ? accountMemberCodes
            : allShopCodes
          : [selectedShopCode],
      ordersStartDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
      ordersEndDate: "",
      userTimeZone: encodeURIComponent(moment.tz.guess()),
      ...calendarDates,
    })
      .then((res) => {
        setLoading(false);
        if (res) {
          setWidgetsRes(res);
          AppSettingsStorage.setMenuBadge(res?.actions?.needsAction);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("error", error);
      });
  };

  const handleOnPress = (listingType = "current-orders") => {
    resetBasicInfo();
  };

  const pollInterval = 2 * 60 * 1000; // 2 mins

  let pollTimer = useRef(null);
  const initialRender = useRef(true);
  useEffect(() => {
    if (pollTimer) clearInterval(pollTimer);

    pollTimer = setInterval(pullDashboardWidgets, pollInterval);
    pullDashboardWidgets();

    return () => {
      clearInterval(pollTimer);
    };
  }, [selectedShopCode, calendarDates]);

  let shopDetailsPollTimer = useRef(null);

  useEffect(() => {
    if (shopDetailsPollTimer) clearInterval(shopDetailsPollTimer);

    shopDetailsPollTimer = setInterval(pullShopSuspendDetails, pollInterval);
    pullShopSuspendDetails();

    selectedShopCode && getNotifications();

    return () => {
      clearInterval(shopDetailsPollTimer);
    };
  }, [selectedShopCode]);

  useEffect(() => {
    scrollViewRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
    // Pulling latest data for dashboard widgets whenever user visits dashboard from second time[on initialRender we are already pulling latest data so restricting for first load] irrespective of timeInterval logic.
    if (currentPage === "dashboard") {
      if (initialRender.current) {
        initialRender.current = false;
      } else {
        pullDashboardWidgets();
      }
    }
  }, [currentPage]);

  const isCustomDateRangeApplied = useRef("");

  useEffect(() => {
    const isDateRangeApplied = checkDateRangeFilterApplied(
      currentOrdersInfo?.actions
    );
    isCustomDateRangeApplied.current = isDateRangeApplied ? true : false;
  }, [currentOrdersInfo]);

  useEffect(() => {
    callRetryFetchOrders.current = selectRetryFetchOrders;
    retryLastsyncTime.current = selectLastSyncTime;
  }, [selectLastSyncTime, selectRetryFetchOrders]);

  const pollIntervalForQuickBooksExpiration = Environment.get(
    "QUICK_BOOKS_POLL_INTERVAL",
    10 * 60 * 1000
  );

  const statusOrderFetchDelayMs = Environment.get(
    "SPLIT_ORDERS_CALL_INTERVAL",
    7000
  );

  useEffect((accountMemberCodes = []) => {
    if (
      Platform.OS !== "web" ||
      isElectronApp ||
      !hasQuickBooksAccess(permissions, "all")
    )
      return;
    // selecting 0 th element as quick books is implemented at shop group level
    // for the first run on page load
    dispatch(
      fetchQuickBooksSessionExpired({
        shopCode: accountMemberCodes[0],
      })
    );
    const fetchQuickBooksSessionExpiredDetails = async () => {
      dispatch(
        fetchQuickBooksSessionExpired({
          shopCode: accountMemberCodes[0],
        })
      );
    };
    const intervalId = setInterval(
      fetchQuickBooksSessionExpiredDetails,
      pollIntervalForQuickBooksExpiration
    );

    return () => clearInterval(intervalId);
  }, []);

  const handleShopGroupChange = (shopGroup) => {
    setLoading(true);
    setShopGroup(shopGroup);
    UserProfileStorage.setSelectedShopGroup(shopGroup);

    request("get-shop-group", {
      shopGroupName: encodeURIComponent(shopGroup),
      memberCode: userProfile.memberCode,
      partnerGroupName: userProfile.partnerGroup,
      memberCodes: "",
    })
      .then((res = []) => res[0])
      .then(({ memberCodes = [] } = {}) => {
        setAccountMemberCodes(memberCodes);
        request("get-shop-details", { memberCodes: memberCodes })
          .then((allMemberDetails = []) => {
            allMemberDetails.forEach((memberDetail) => {
              UserProfileStorage.setShopName(
                memberDetail.memberCode,
                memberDetail.businessName
              );
            });
          })
          .then(() => {
            pullDashboardWidgets(memberCodes);

            //on account change in dashboard, we are updating timeStamp as false (i.e. to send deltaOrders=false) to get all orders for newly selected account
            dispatch(setIsOrdersCallInProgress(true)); // To not call orders call again when user navigated to orders screen from dashborad, if dashboard orders call is still inprogress
            fetchOrders(
              "current",
              { ...currentOrdersInfo, timeStamp: false },
              ListOfOrderStatus,
              InitOrdersData,
              fetchData,
              () => {
                dispatch(setIsOrdersCallInProgress(false));
              }
            );
          })
          .catch((err) => {
            setLoading(false);
            console.log("Invalid Member response");
          });
      })
      .catch((error) => {
        setLoading(false);
        console.log("get shopgroup failed");
      });
  };

  useEffect(() => {
    // To play notification sound when ever newOrder: true, newApproveRequest: true, newChatMsg: true
    const playAudibleNotification = () => {
      const startTime = moment()
        .subtract(pollInterval, "milliseconds")
        .utc()
        .format(); // Last run time
      if (isAudibleTurnedOn) {
        request("get-audible-notification", {
          startTime: startTime,
          memberCodes: allShopCodes,
        })
          .then((res) => {
            if (
              res.newOrder ||
              res.newApproveRequest ||
              res.newChatMsg ||
              res.newResponse
            ) {
              AudibleNotification();
            }
          })
          .catch((err) => console.log("get audible notifications error ", err));
      }
    };

    const playTimer = setInterval(playAudibleNotification, pollInterval);
    playAudibleNotification();

    return () => {
      clearInterval(playTimer);
    };
  }, [userProfile.preferences]);

  const onOpenNotification = (payload) => {
    // Refreshing dashboard widgets when user opened app by click on notification
    pullDashboardWidgets();
  };

  useEffect(() => {
    // When user login, we are resetting selected shop group / account with first account which we received in user profile response.
    setShopGroup(UserProfileStorage.getSelectedShopGroup());
    setCurrentPage("dashboard");
    pullShopDetails();
    if (isElectronApp) {
      const macAddress = document.getElementById("macAddress").value;

      macAddress &&
        !sessionId &&
        fetchAvailableSessions({
          params: { macAddress: encodeURIComponent(macAddress) },
        });
    }

    // Pulling Current-orders before user lands on respective pages.
    dispatch(setIsOrdersCallInProgress(true)); // To not call orders call again when user navigated to orders screen from dashborad, if dashboard orders call is still inprogress

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const callFetchOrdersWithDelay = async () => {
      const createSet = (start, end = null, status) => {
        const set = {
          start,
          status,
        };
        if (end) set.end = end; // Add `end` property only if it exists
        return { set1: set };
      };
      const orderProcessIntervalTimeStamp = Environment.get(
        "ORDERS_PROCESSING_INTERVAL_TIME_STAMP",
        60
      );
      const statuses = [
        createSet("1d", "1d+", ListOfOrderStatus.set1.initStatuses),
        createSet("1w", null, ListOfOrderStatus.set1.status),
      ];
      dispatch(setIsOrderSplitCallInProgress(true));

      for (let i = 0; i < statuses.length; i++) {
        if (i === 0) {
          const formatLastSyncTime = moment()
            .utc()
            .subtract(orderProcessIntervalTimeStamp, "seconds")
            .format();

          dispatch(setCurrentTimeMinus30Seconds(formatLastSyncTime));
        }

        fetchOrders(
          "current",
          currentOrdersInfo,
          statuses[i], //statusList
          InitOrdersData,
          fetchData,
          async (response) => {
            if (i !== 0) {
              dispatch(setIsOrdersCallInProgress(false));
              dispatch(setIsOrderSearchApplied(true));
              dispatch(setIsOrderSplitCallInProgress(false));
              if (response) {
                const { set1 = {}, set2 = {} } = ListOfOrderStatus;

                const fetchStatuses = set1?.status?.join(",");
                const fetchStartDate = moment()
                  .subtract(1, "week")
                  .format("YYYY-MM-DD");
                const fetchEndDate = "";

                const delay = (ms) =>
                  new Promise((resolve) => setTimeout(resolve, ms));

                const statusOrderFetchDelayMs = Environment.get(
                  "ORDERS_PROCESSING_INTERVAL",
                  40000
                );

                // Wait for 40 seconds before the next call
                await delay(statusOrderFetchDelayMs);

                if (callRetryFetchOrders.current) {
                  retryFetchOrdersAfterDelay({
                    response,
                    currentTimeMinus30Seconds: retryLastsyncTime.current,
                    fetchStatuses,
                    fetchStartDate,
                    fetchEndDate,
                    set2,
                    actions: currentOrdersInfo?.actions,
                    InitOrdersData,
                    isCustomDateRangeApplied,
                    fetchData,
                  });
                }
              }
            }
          },
          i === 1 //showLoader
        );

        // Wait for 7 seconds before the next call
        if (i < statuses.length - 1) {
          await delay(statusOrderFetchDelayMs); // Delay only between calls
        }
      }
    };

    callFetchOrdersWithDelay();
    // Clearing all notifications, when user opened or resumed app from any state (background/killed/foreground) by click on notification in notification center.
    Platform.OS !== "web" &&
      fcmService.onNotificationOpenedListener(onOpenNotification);
  }, []);

  useEffect(() => {
    const enableAppcues = Environment.get("APPCUES_ENABLED", true);
    try {
      if (
        !isEmpty(shops) &&
        Platform.OS === "web" &&
        enableAppcues &&
        typeof Appcues !== "undefined" &&
        // eslint-disable-next-line no-undef
        window.Appcues &&
        // eslint-disable-next-line no-undef
        window.Appcues.identify
      ) {
        const address = UserProfileStorage.getShopLocation(
          userProfile.memberCode
        );
        if (userProfile.type === "FLORIST_USER") {
          // eslint-disable-next-line no-undef
          window.Appcues.identify(
            userProfile.id, // unique, required
            {
              email: userProfile.email, // Current user's email
              memberId: userProfile.memberCode,
              isGroceryStore: shops.find(
                (shop) => shop?.memberCode === userProfile.memberCode
              )?.isGroceryStore,
              entitlement: UserProfileStorage.getMemberEntitlement(
                userProfile.memberCode
              ),
              createdAt: Date.now(),
              role: UserProfileStorage.getRole(), // Current user’s role or permissions
              city: address ? address?.city : "",
              state: address ? address?.state : "",
            }
          );
        }
        // eslint-disable-next-line no-undef
        window.Appcues.group(
          selectedShopCode, // unique, required
          {
            createdAt: Date.now(),
            email: shops.find((shop) => shop?.memberCode === selectedShopCode)
              ?.email, // Current user's email
            memberId: selectedShopCode,
            isGroceryStore: shops.find(
              (shop) => shop?.memberCode === selectedShopCode
            )?.isGroceryStore,
            entitlement:
              UserProfileStorage.getMemberEntitlement(selectedShopCode),
            role: UserProfileStorage.getRole(), // Current user’s role or permissions
            city: shops.find((shop) => shop.memberCode === selectedShopCode)
              ?.address?.city,
            state: shops.find((shop) => shop.memberCode === selectedShopCode)
              ?.address?.state,
          }
        );
        // eslint-disable-next-line no-undef
        Appcues.track();
      }
    } catch (error) {
      console.error("Error tracking appcues", error);
    }
  }, [shops, selectedShopCode]);

  const {
    actions,
    today,
    todayOrderCounts,
    tomorrow,
    tomorrowOrderCounts,
    calendar,
    isBulkOrderPrint = false,
  } = widgetsRes;

  // Emulates `forceUpdate()`
  // eslint-disable-next-line no-unused-vars
  const [unusedState, setUnusedState] = useStateIfMounted();
  const forceUpdate = useCallback(() => setUnusedState({}), []);
  const { messages, Localise } = React.useContext(I18NContext);
  const enableAccountsSwitch = false;

  return (
    <>
      {isSmallScreen && <SubHeader />}
      <SingleScreen
        title={Localise(messages, "Dashboard")}
        isLoading={loading}
        scrollViewRef={scrollViewRef}
        headerSiblings={() => {
          return (
            <>
              {allShopCodes.length > 1 && (
                <View
                  style={{
                    ...tw("flex flex-row items-center"),
                    ...{ maxWidth: isSmallScreen ? 250 : null },
                    ...(isMobile && { paddingVertical: 10 }),
                  }}
                  fsClass="fs-unmask"
                  key={"showText"}
                >
                  <Text style={fonts.heading4} key="middle">
                    {Localise(messages, "Showing")}{" "}
                    {selectedShopCode === "all"
                      ? Localise(messages, "All Shops")
                      : `${Localise(messages, "Member")} : ${
                          shopNames[selectedShopCode]
                        }, ${selectedShopCode}`}
                  </Text>
                </View>
              )}
              <View
                style={{
                  ...tw("flex flex-row items-center"),
                  ...(isMobile ||
                  (isTablet && allShopCodes.length > 1 && shopGroups.length > 1)
                    ? {
                        width: "100%",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }
                    : {}),
                }}
                key="accounts"
                fsClass="fs-unmask"
              >
                {enableAccountsSwitch && shopGroups.length > 1 && (
                  <View
                    style={{
                      ...tw("flex flex-row items-center"),
                      ...(isMobile && { padding: 4 }),
                    }}
                    key="accounts"
                    fsClass="fs-unmask"
                  >
                    <Text style={fonts.heading4}>
                      {Localise(messages, "Select Account:")}
                    </Text>
                    <Picker
                      containerStyle={{
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                      items={[
                        ...shopGroups.map((shopGroup) => ({
                          label: shopGroup,
                          value: shopGroup,
                        })),
                      ]}
                      innerContainerStyle={{
                        width: isSmallScreen ? 180 : null,
                      }}
                      placeholder={{}}
                      value={selectedShopGroup}
                      onValueChange={handleShopGroupChange}
                    ></Picker>
                  </View>
                )}
                {allShopCodes.length > 1 && (
                  <View
                    style={{
                      ...tw("flex flex-row items-center"),
                      ...(isMobile && { padding: 4 }),
                    }}
                    key="end"
                    fsClass="fs-unmask"
                  >
                    <Text
                      style={{
                        ...fonts.heading4,
                        ...(isMobile && { minWidth: 100 }),
                      }}
                    >
                      {Localise(messages, "Select Shop:")}
                    </Text>
                    <Picker
                      containerStyle={{
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                      items={[
                        {
                          label:
                            suspendedMembers.length > 0
                              ? `${"‍ ‍ ‍ ‍ ‍ ‍ "}${Localise(
                                  messages,
                                  "All Shops"
                                )}`
                              : Localise(messages, "All Shops"),
                          value: "all",
                        },
                        ...allShopCodes.map((code) => ({
                          label:
                            suspendedMembers.length > 0
                              ? suspendedMembers.includes(code)
                                ? `⚠️  ${code} ${shopNames[code]}`
                                : `${"‍ ‍ ‍ ‍ ‍ ‍ "}${code} ${shopNames[code]}`
                              : `${code} ${shopNames[code]}`,
                          value: code,
                        })),
                      ]}
                      innerContainerStyle={{
                        width: isSmallScreen ? 180 : null,
                      }}
                      placeholder={{}}
                      value={selectedShopCode}
                      onValueChange={(val) => {
                        setShopCode(val);
                        setCreateOrderShopCode(val);
                        dispatch(setShopCodeForDraftOrder(val));
                      }}
                    ></Picker>
                  </View>
                )}
              </View>
            </>
          );
        }}
      >
        <View
          onLayout={styles.onLayout(() => forceUpdate())}
          style={[styles.mainContainer, tw("flex w-full")]}
          fsClass="fs-unmask"
        >
          <View
            style={[styles.leftContainer, tw("py-2 flex-wrap justify-start")]}
          >
            {actions && (
              <>
                <OrderActionWidget
                  widgetResponse={actions}
                  shopCodes={selectedShopCode}
                  shopNames={shopNames}
                  isSmallScreen={isSmallScreen}
                  isTablet={isTablet}
                  onPress={handleOnPress}
                  onBulkHirePrint={() => {
                    pullDashboardWidgets();
                  }}
                  isBulkOrderPrint={isBulkOrderPrint}
                  allShopCodes={allShopCodes}
                />
                {currentPage === "dashboard" ? (
                  <PrintIframeRenderer testID={"bulk-print"} />
                ) : null}
              </>
            )}
            {today && (
              <View style={{ marginTop: 30 }}>
                <OrderTodayWidget
                  widgetResponse={today}
                  orderCounts={todayOrderCounts}
                  shopCodes={selectedShopCode}
                  shopNames={shopNames}
                  isSmallScreen={isSmallScreen}
                  onPress={handleOnPress}
                />
              </View>
            )}
            {tomorrow && (
              <View style={{ marginTop: 30 }}>
                <OrderTomorrowWidget
                  widgetResponse={tomorrow}
                  orderCounts={tomorrowOrderCounts}
                  shopCodes={selectedShopCode}
                  shopNames={shopNames}
                  isSmallScreen={isSmallScreen}
                  onPress={handleOnPress}
                />
              </View>
            )}
          </View>
          <View
            style={[
              styles.rightContainer,
              tw("flex-row py-2 flex-wrap"),
              { paddingBottom: isMobile ? 50 : 0 },
            ]}
          >
            <CalendarWidget
              widgetResponse={calendar}
              calendarDates={calendarDates}
              setCalendarDates={setCalendarDates}
              isSmallScreen={isSmallScreen}
              shopCodes={selectedShopCode}
              shopNames={shopNames}
              onPress={handleOnPress}
            />
            <Notifications
              isSmallScreen={isSmallScreen}
              shopCodes={
                selectedShopCode === "all" ? allShopCodes : [selectedShopCode]
              }
              notifications={notifications}
              loading={isNotificationsLoading}
              setNotifications={setNotifications}
              currentPage={currentPage}
            />
          </View>
        </View>
      </SingleScreen>
    </>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    currentOrdersInfo: state.mhq.ongoing.currentOrders,
    currentPage: state.mhq.ongoing.global.currentPage,
    selectedShopCode: state.mhq.ongoing.global.shopCode,
    selectedShopGroup: state.mhq.ongoing.global.shopGroup,
    sessionInfo: state.mhq.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetBasicInfo,
      fetchData,
      setCurrentPage,
      setShopCode,
      setCreateOrderShopCode,
      setShopGroup,
      fetchAvailableSessions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
